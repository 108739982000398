import { takeEvery, call, put } from 'redux-saga/effects';
import { cancelOrder, getDevices, getOrders, updateRetailShippingAddress, updateRetailShippingDate } from './api';

import {
  ActionTypes,
  apiGetOrdersSuccessAction,
  apiGetOrdersFailureAction,
  apiCancelOrderSuccessAction,
  apiCancelOrderFailureAction,
  apiGetOrdersRequestAction,
  apiUpdateOrderShippingAddressFailureAction,
  apiUpdateOrderShippingAddressSuccessAction,
  apiUpdateOrderShippingDateSuccessAction,
  apiUpdateOrderShippingDateFailureAction,
  resetSuccessEditOrderAddressSuccessAction,
  apiGetDevicesSuccessAction
} from './apiOrdersActions';


export function* apiGetOrders() {
  const { response, error } = yield call(getOrders);
  if (response) {
    yield put(apiGetOrdersSuccessAction(response.data));
  } else {
    yield put(apiGetOrdersFailureAction(error.response.data));
  }
}

export function* apiCancelOrder(action) {
  const { response, error } = yield call(cancelOrder, action.payload);
  if (response) {
    yield put(apiCancelOrderSuccessAction(response.data));
    yield put(apiGetOrdersRequestAction());
  } else {
    yield put(apiCancelOrderFailureAction(error.response.data));
  }
}

export function* apiUpdateOrderShippingAddress(action) {
  const { response, error } = yield call(updateRetailShippingAddress, action.payload);
  if (response) {
    yield put(apiUpdateOrderShippingAddressSuccessAction(response.data));
    yield put(apiGetOrdersRequestAction());
  } else {

    yield put(apiUpdateOrderShippingAddressFailureAction(error.response.data));
  }
}

export function* apiUpdateOrderShippingDate(action) {
  const { response, error } = yield call(updateRetailShippingDate, action.payload);
  if (response) {
    yield put(apiUpdateOrderShippingDateSuccessAction(response.data));
  } else {

    yield put(apiUpdateOrderShippingDateFailureAction(error.response.data));
  }
}

export function* resetSuccessEditOrderAddress() {
  yield put(resetSuccessEditOrderAddressSuccessAction())
}

export function* apiGetDevices() {
  const { response, error } = yield call(getDevices);
  if (response) {
    yield put(apiGetDevicesSuccessAction(response.data));
  } else {
    yield put(apiGetOrdersFailureAction(error.response.data));
  }
}

export default function* apiOrdersSagas() {
  yield takeEvery(ActionTypes.API_GET_DEVICES_REQUEST, apiGetDevices);
  yield takeEvery(ActionTypes.API_GET_ORDERS_REQUEST, apiGetOrders);
  yield takeEvery(ActionTypes.API_CANCEL_ORDER_REQUEST, apiCancelOrder);
  yield takeEvery(ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_REQUEST, apiUpdateOrderShippingAddress);
  yield takeEvery(ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_REQUEST, apiUpdateOrderShippingDate);
  yield takeEvery(ActionTypes.RESET_SUCCESS_EDIT_ORDER_ADDRESS_REQUEST, resetSuccessEditOrderAddress)
}
