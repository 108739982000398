import { takeEvery, call, put, take, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
  getUserFromToken,
  createUserPassword,
  getRenterFromUserId,
  postApplyGiftCard,
  getAppliedGiftCards,
  getGiftCardsUsageSummary,
  postGenerateGiftCard,
  updateShippingDate,
  getRentalItems,
  postCancelRental,
  putUpdateRentalShippingAddress,
  getRentalShippingAddress,
  getPrices,
  getRentalPrice,
} from './api';

import appRoutes from '../../enums/appRoutes';

import {
  ActionTypes,
  apiPostUserPasswordCreateSuccessAction,
  apiPostUserPasswordCreateFailureAction,
  apiGetUserFromTokenSuccessAction,
  apiGetUserFromTokenFailureAction,
  apiGetRenterFromUserIdFailureAction,
  apiGetRenterFromUserIdSuccessAction,
  apiPostRenterApplyGiftCardSuccessAction,
  apiPostRenterApplyGiftCardFailureAction,
  apiGetRenterAppliedGiftCardsSuccessAction,
  apiGetRenterAppliedGiftCardsFailureAction,
  apiGetGiftCardsUsageSummarySuccessAction,
  apiGetGiftCardsUsageSummaryFailureAction,
  apiPostGenerateGiftCardSuccessAction,
  apiPostGenerateGiftCardFailureAction,
  apiPutRentalUpdateShippingDateSuccessAction,
  apiPutRentalUpdateShippingDateFailureAction,
  apiGetRentalItemsSuccessAction,
  apiGetRentalItemsFailureAction,
  apiPostRentalCancelSuccessAction,
  apiPostRentalCancelFailureAction,
  apiPutUpdateRentalShippingAddressSuccessAction,
  apiPutUpdateRentalShippingAddressFailureAction,
  apiGetRentalShippingAddressSuccessAction,
  apiGetRentalShippingAddressFailureAction,
  apiGetPricesSuccessAction,
  apiGetPricesFailureAction,
  apiGetRentalPriceSuccessAction,
  apiGetRentalPriceFailureAction,
} from './apiRentalActions';

import { apiGetRenterRentalsRequestAction } from '../apiRenter/apiRenterActions';

import * as Auth from '../../utils/webAuth';

export function* apiCreatePassword(action) {
  const { response, error } = yield call(createUserPassword, action.payload);

  if (response) {
    yield put(apiPostUserPasswordCreateSuccessAction(response.data));

    Auth.updateTokens({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });

    yield put(push(appRoutes.HOME.url));
  } else {
    yield put(apiPostUserPasswordCreateFailureAction(error));
  }
}

export function* apiGetUserFromToken(action) {
  const { response, error } = yield call(getUserFromToken, action.payload);

  if (response) {
    yield put(apiGetUserFromTokenSuccessAction(response.data));
  } else {
    yield put(apiGetUserFromTokenFailureAction(error));
  }
}

export function* apiGetRenterFromUserId(action) {
  const { response, error } = yield call(getRenterFromUserId, action.payload);
  if (response) {
    yield put(apiGetRenterFromUserIdSuccessAction(response.data));
  } else {
    yield put(apiGetRenterFromUserIdFailureAction(error));
  }
}

export function* apiPostApplyGiftCard(action) {
  const { response, error } = yield call(postApplyGiftCard, action.payload);

  if (response) {
    yield put(apiPostRenterApplyGiftCardSuccessAction(response.data));
  } else {
    yield put(apiPostRenterApplyGiftCardFailureAction(error));
  }
}

export function* apiGetRenterAppliedGiftCards() {
  yield take([ActionTypes.API_GET_RENTER_FROM_USER_ID_SUCCESS, ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_SUCCESS]);
  const { response, error } = yield call(getAppliedGiftCards);
  if (response) {
    yield put(apiGetRenterAppliedGiftCardsSuccessAction(response.data));
  } else {
    yield put(apiGetRenterAppliedGiftCardsFailureAction(error));
  }
}

export function* apiGetGiftCardsUsageSummary() {
  yield take([ActionTypes.API_GET_RENTER_FROM_USER_ID_SUCCESS, ActionTypes.API_POST_GENERATE_GIFT_CARD_SUCCESS]);
  const { response, error } = yield call(getGiftCardsUsageSummary);
  if (response) {
    yield put(apiGetGiftCardsUsageSummarySuccessAction(response.data));
  } else {
    yield put(apiGetGiftCardsUsageSummaryFailureAction(error));
  }
}

export function* apiPostGenerateGiftCard(action) {
  const { response, error } = yield call(postGenerateGiftCard, action.payload);
  if (response) {
    yield put(apiPostGenerateGiftCardSuccessAction(response.data));
  } else {
    yield put(apiPostGenerateGiftCardFailureAction(error));
  }
}
export function* apiUpdateShippingDate(action) {
  const { response, error } = yield call(updateShippingDate, action.payload);

  if (response) {
    yield put(apiPutRentalUpdateShippingDateSuccessAction(response.data));
    yield put(apiGetRenterRentalsRequestAction());
  } else {
    yield put(apiPutRentalUpdateShippingDateFailureAction(error));
  }
}

export function* apiGetRentalItems() {
  const { response, error } = yield call(getRentalItems);
  if (response) {
    yield put(apiGetRentalItemsSuccessAction(response.data));
  } else {
    yield put(apiGetRentalItemsFailureAction(error));
  }
}

export function* apiPostCancelRental(action) {
  const { response, error } = yield call(postCancelRental, action.payload);
  if (response) {
    yield put(apiPostRentalCancelSuccessAction(response.data));
    yield put(apiGetRenterRentalsRequestAction());
  } else {
    yield put(apiPostRentalCancelFailureAction(error));
  }
}

export function* apiUpdateRentalShippingAddress(action) {
  const { response, error } = yield call(putUpdateRentalShippingAddress, action.payload);

  if (response) {
    yield put(apiPutUpdateRentalShippingAddressSuccessAction(response.data));
    yield put(apiGetRenterRentalsRequestAction());
  } else {
    yield put(apiPutUpdateRentalShippingAddressFailureAction(error.response.data));
  }
}

export function* apiGetRentalShippingAddress() {
  const { response, error } = yield call(getRentalShippingAddress);
  if (response) {
    yield put(apiGetRentalShippingAddressSuccessAction(response.data));
  } else {
    yield put(apiGetRentalShippingAddressFailureAction(error));
  }
}

export function* apiGetPrices() {
  const { response, error } = yield call(getPrices);
  if (response) {
    yield put(apiGetPricesSuccessAction(response.data));
  } else {
    yield put(apiGetPricesFailureAction(error));
  }
}

export function* apiGetRentalPrice() {
  const { response, error } = yield call(getRentalPrice);
  if (response) {
    yield put(apiGetRentalPriceSuccessAction(response.data));
  } else {
    yield put(apiGetRentalPriceFailureAction(error));
  }
}

export default function* apiRentalSaga() {
  yield takeEvery(ActionTypes.API_GET_USER_TOKEN_INFO_REQUEST, apiGetUserFromToken);
  yield takeEvery(ActionTypes.API_POST_USER_PASSWORD_CREATE_REQUEST, apiCreatePassword);
  yield takeEvery(ActionTypes.API_GET_RENTER_FROM_USER_ID_REQUEST, apiGetRenterFromUserId);
  yield takeEvery(ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_REQUEST, apiPostApplyGiftCard);
  yield takeEvery(ActionTypes.API_GET_RENTER_APPLIED_GIFT_CARDS_REQUEST, apiGetRenterAppliedGiftCards);
  yield takeEvery(ActionTypes.API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_REQUEST, apiGetGiftCardsUsageSummary);
  yield takeEvery(ActionTypes.API_POST_GENERATE_GIFT_CARD_REQUEST, apiPostGenerateGiftCard);
  yield takeEvery(ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_REQUEST, apiUpdateShippingDate);
  yield takeEvery(ActionTypes.API_GET_RENTAL_ITEMS_REQUEST, apiGetRentalItems);
  yield takeEvery(ActionTypes.API_POST_CANCEL_RENTAL_REQUEST, apiPostCancelRental);
  yield takeEvery(ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_REQUEST, apiUpdateRentalShippingAddress);
  yield takeEvery(ActionTypes.API_GET_RENTAL_SHIPPING_ADDRESS_REQUEST, apiGetRentalShippingAddress);
  yield takeEvery(ActionTypes.API_GET_PRICES_REQUEST, apiGetPrices);
  yield takeEvery(ActionTypes.API_GET_RENTAL_PRICE_REQUEST, apiGetRentalPrice);
}
