import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import sumBy from 'lodash/sumBy';
import Currency from '../../formats/currencyFormat/currency.jsx';
import { provideContext } from '../../../../context/context.jsx';
import './usageSummaryTable.scss';

class UsageSummaryTable extends Component {
  render() {
    const { t, context, accountBalance, giftCardsSummary } = this.props;
    return (
      <div className="UsageSummaryTable">
        <table className="bill-table">
          <thead>
            <tr>
              <th>{t('usage_summary_table_usage','Usage')}</th>
              <th>{t('usage_summary_table_date_used','Date used')}</th>
              <th>{t('global_amount','Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {giftCardsSummary.map((giftCard, i) => {
              let usage = giftCard.bill;
              if (giftCard.bill == 'Period 1') {
                usage = t('usage_summary_table_initial_payment','Initial Payment');
              } else if (!usage.includes('Generated gift card')) {
                usage = giftCard.bill + t('usage_summary_table_bill',' bill');
              }
              return (
                <tr key={i}>
                  <td>{usage}</td>
                  <td>
                    <Moment format={context.apiConfig.date.format}>{giftCard.date}</Moment>
                  </td>
                  <td>
                    <span>{giftCard.amount<=0?'':'-'}</span>
                    <Currency value={Math.abs(giftCard.amount)} divider={1} context={context} suffix={context.apiConfig.stripe.currency}/>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <table className="total-table">
          <tbody>
            <tr>
              <td>
                <h4>{t('usage_summary_table_total_value','Total value applied')}</h4>
              </td>
              <td className="currency">
                <h4>
                  <Currency
                    value={sumBy(giftCardsSummary, item => Number(item.amount))}
                    context={context}
                    divider={1}
                  />
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <h4>{t('usage_summary_table_available_balance','Available balance')}</h4>
              </td>
              <td className="currency">
                <h4>
                  <Currency value={accountBalance} context={context} divider={1} />
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

UsageSummaryTable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default provideContext(UsageSummaryTable);
