import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';
import Currency from '../../formats/currencyFormat/currency.jsx'

import './paymentPlanTable.scss';

class PaymentPlanTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, rental, context } = this.props;
    const paymentPlan = rental.rental.paymentPlan;
    return (
      <div className="PaymentPlanTable">
        <h4 className="ScreenSubTitle">
          {t('payment_plan_table','Payment plan for ')}
          {rental.shopifyOrderName}
        </h4>
        <table className="payment-plan-table">
          <thead>
            <tr className="">
              <th className="header-month">{t('payment_plan_month','Month')}</th>
              <th className="header-amount">{t('global_amount','Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {paymentPlan.periods.map((pi, idx) => {
              return (
                <tr key={idx}>
                  <td>{pi.period}</td>
                  <td><Currency value={pi.value} divider={1} context={context} /></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

PaymentPlanTable.propTypes = {
  t: PropTypes.func.isRequired,
  rental: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

export default provideContext(PaymentPlanTable);
