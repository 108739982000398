export const ActionTypes = {
  API_POST_USER_PASSWORD_CREATE_REQUEST: 'API_POST_USER_PASSWORD_CREATE_REQUEST',
  API_POST_USER_PASSWORD_CREATE_SUCCESS: 'API_POST_USER_PASSWORD_CREATE_SUCCESS',
  API_POST_USER_PASSWORD_CREATE_FAILURE: 'API_POST_USER_PASSWORD_CREATE_FAILURE',
  API_GET_USER_TOKEN_INFO_REQUEST: 'API_GET_USER_TOKEN_INFO_REQUEST',
  API_GET_USER_TOKEN_INFO_SUCCESS: 'API_GET_USER_TOKEN_INFO_SUCCESS',
  API_GET_USER_TOKEN_INFO_FAILURE: 'API_GET_USER_TOKEN_INFO_FAILURE',
  API_GET_RENTER_FROM_USER_ID_REQUEST: 'API_GET_RENTER_FROM_USER_ID_REQUEST',
  API_GET_RENTER_FROM_USER_ID_SUCCESS: 'API_GET_RENTER_FROM_USER_ID_SUCCESS',
  API_GET_RENTER_FROM_USER_ID_FAILURE: 'API_GET_RENTER_FROM_USER_ID_FAILURE',
  API_POST_RENTER_APPLY_GIFT_CARD_REQUEST: 'API_POST_RENTER_APPLY_GIFT_CARD_REQUEST',
  API_POST_RENTER_APPLY_GIFT_CARD_SUCCESS: 'API_POST_RENTER_APPLY_GIFT_CARD_SUCCESS',
  API_POST_RENTER_APPLY_GIFT_CARD_FAILURE: 'API_POST_RENTER_APPLY_GIFT_CARD_FAILURE',
  API_GET_RENTER_APPLIED_GIFT_CARDS_REQUEST: 'API_GET_RENTER_APPLIED_GIFT_CARDS_REQUEST',
  API_GET_RENTER_APPLIED_GIFT_CARDS_SUCCESS: 'API_GET_RENTER_APPLIED_GIFT_CARDS_SUCCESS',
  API_GET_RENTER_APPLIED_GIFT_CARDS_FAILURE: 'API_GET_RENTER_APPLIED_GIFT_CARDS_FAILURE',
  API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_REQUEST: 'API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_REQUEST',
  API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_SUCCESS: 'API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_SUCCESS',
  API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_FAILURE: 'API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_FAILURE',
  API_POST_GENERATE_GIFT_CARD_REQUEST: 'API_POST_GENERATE_GIFT_CARD_REQUEST',
  API_POST_GENERATE_GIFT_CARD_SUCCESS: 'API_POST_GENERATE_GIFT_CARD_SUCCESS',
  API_POST_GENERATE_GIFT_CARD_FAILURE: 'API_POST_GENERATE_GIFT_CARD_FAILURE',
  API_PUT_RENTAL_UPDATE_SHIPPING_DATE_REQUEST: 'API_PUT_RENTAL_UPDATE_SHIPPING_DATE_REQUEST',
  API_PUT_RENTAL_UPDATE_SHIPPING_DATE_SUCCESS: 'API_PUT_RENTAL_UPDATE_SHIPPING_DATE_SUCCESS',
  API_PUT_RENTAL_UPDATE_SHIPPING_DATE_FAILURE: 'API_PUT_RENTAL_UPDATE_SHIPPING_DATE_FAILURE',
  API_GET_RENTAL_ITEMS_REQUEST: 'API_GET_RENTAL_ITEMS_REQUEST',
  API_GET_RENTAL_ITEMS_SUCCESS: 'API_GET_RENTAL_ITEMS_SUCCESS',
  API_GET_RENTAL_ITEMS_FAILURE: 'API_GET_RENTAL_ITEMS_FAILURE',
  API_POST_CANCEL_RENTAL_REQUEST: 'API_POST_CANCEL_RENTAL_REQUEST',
  API_POST_CANCEL_RENTAL_SUCCESS: 'API_POST_CANCEL_RENTAL_SUCCESS',
  API_POST_CANCEL_RENTAL_FAILURE: 'API_POST_CANCEL_RENTAL_FAILURE',
  API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_REQUEST: 'API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_REQUEST',
  API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_SUCCESS: 'API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_SUCCESS',
  API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_FAILURE: 'API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_FAILURE',
  API_GET_RENTAL_SHIPPING_ADDRESS_REQUEST: 'API_GET_RENTAL_SHIPPING_ADDRESS_REQUEST',
  API_GET_RENTAL_SHIPPING_ADDRESS_SUCCESS: 'API_GET_RENTAL_SHIPPING_ADDRESS_SUCCESS',
  API_GET_RENTAL_SHIPPING_ADDRESS_FAILURE: 'API_GET_RENTAL_SHIPPING_ADDRESS_FAILURE',
  API_GET_PRICES_REQUEST: 'API_GET_PRICES_REQUEST',
  API_GET_PRICES_SUCCESS: 'API_GET_PRICES_SUCCESS',
  API_GET_PRICES_FAILURE: 'API_GET_PRICES_FAILURE',
  API_GET_RENTAL_PRICE_REQUEST: 'API_GET_RENTAL_PRICE_REQUEST',
  API_GET_RENTAL_PRICE_SUCCESS: 'API_GET_RENTAL_PRICE_SUCCESS',
  API_GET_RENTAL_PRICE_FAILURE: 'API_GET_RENTAL_PRICE_FAILURE',
};

export const apiPostUserPasswordCreateRequestAction = ({ token, passwordData }) => ({
  type: ActionTypes.API_POST_USER_PASSWORD_CREATE_REQUEST,
  payload: { token, passwordData },
});

export const apiPostUserPasswordCreateSuccessAction = response => ({
  type: ActionTypes.API_POST_USER_PASSWORD_CREATE_SUCCESS,
  payload: response,
});

export const apiPostUserPasswordCreateFailureAction = error => ({
  type: ActionTypes.API_POST_USER_PASSWORD_CREATE_FAILURE,
  payload: error,
});

export const apiGetUserFromTokenRequestAction = token => ({
  type: ActionTypes.API_GET_USER_TOKEN_INFO_REQUEST,
  payload: token,
});

export const apiGetUserFromTokenSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_USER_TOKEN_INFO_SUCCESS,
    payload: response,
  };
};

export const apiGetUserFromTokenFailureAction = error => ({
  type: ActionTypes.API_GET_USER_TOKEN_INFO_FAILURE,
  payload: error,
});

export const apiGetRenterFromUserIdRequestAction = id => ({
  type: ActionTypes.API_GET_RENTER_FROM_USER_ID_REQUEST,
  payload: id,
});

export const apiGetRenterFromUserIdSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTER_FROM_USER_ID_SUCCESS,
    payload: response,
  };
};

export const apiGetRenterFromUserIdFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_FROM_USER_ID_FAILURE,
  payload: error,
});

export const apiPostRenterApplyGiftCardRequestAction = giftCard => ({
  type: ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_REQUEST,
  payload: giftCard,
});

export const apiPostRenterApplyGiftCardSuccessAction = response => ({
  type: ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_SUCCESS,
  payload: response,
});

export const apiPostRenterApplyGiftCardFailureAction = error => ({
  type: ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_FAILURE,
  payload: error,
});

export const apiGetRenterAppliedGiftCardsRequestAction = () => ({
  type: ActionTypes.API_GET_RENTER_APPLIED_GIFT_CARDS_REQUEST,
});

export const apiGetRenterAppliedGiftCardsSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTER_APPLIED_GIFT_CARDS_SUCCESS,
    payload: response,
  };
};

export const apiGetRenterAppliedGiftCardsFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_APPLIED_GIFT_CARDS_FAILURE,
  payload: error,
});

export const apiGetGiftCardsUsageSummaryRequestAction = () => ({
  type: ActionTypes.API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_REQUEST,
});

export const apiGetGiftCardsUsageSummarySuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_SUCCESS,
    payload: response,
  };
};

export const apiGetGiftCardsUsageSummaryFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_FAILURE,
  payload: error,
});

export const apiPostGenerateGiftCardRequestAction = amount => ({
  type: ActionTypes.API_POST_GENERATE_GIFT_CARD_REQUEST,
  payload: amount,
});

export const apiPostGenerateGiftCardSuccessAction = response => ({
  type: ActionTypes.API_POST_GENERATE_GIFT_CARD_SUCCESS,
  payload: response,
});

export const apiPostGenerateGiftCardFailureAction = error => ({
  type: ActionTypes.API_POST_GENERATE_GIFT_CARD_FAILURE,
  payload: error,
});

export const apiPutRentalUpdateShippingDateRequestAction = ({ rentalId, shippingDate }) => ({
  type: ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_REQUEST,
  payload: { rentalId, shippingDate },
});

export const apiPutRentalUpdateShippingDateSuccessAction = response => ({
  type: ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_SUCCESS,
  payload: response,
});

export const apiPutRentalUpdateShippingDateFailureAction = error => ({
  type: ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_FAILURE,
  payload: error,
});

export const apiGetRentalItemsRequestAction = () => ({
  type: ActionTypes.API_GET_RENTAL_ITEMS_REQUEST,
});

export const apiGetRentalItemsSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTAL_ITEMS_SUCCESS,
    payload: response,
  };
};

export const apiGetRentalItemsFailureAction = error => ({
  type: ActionTypes.API_GET_RENTAL_ITEMS_FAILURE,
  payload: error,
});

export const apiPostRentalCancelRequestAction = ({ rentalId }) => ({
  type: ActionTypes.API_POST_CANCEL_RENTAL_REQUEST,
  payload: { rentalId },
});

export const apiPostRentalCancelSuccessAction = response => ({
  type: ActionTypes.API_POST_CANCEL_RENTAL_SUCCESS,
  payload: response,
});

export const apiPostRentalCancelFailureAction = error => ({
  type: ActionTypes.API_POST_CANCEL_RENTAL_FAILURE,
  payload: error,
});

export const apiPutUpdateRentalShippingAddressRequestAction = address => ({
  type: ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_REQUEST,
  payload: address,
});

export const apiPutUpdateRentalShippingAddressSuccessAction = response => ({
  type: ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_SUCCESS,
  payload: response,
});

export const apiPutUpdateRentalShippingAddressFailureAction = error => ({
  type: ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const apiGetRentalShippingAddressRequestAction = () => ({
  type: ActionTypes.API_GET_RENTAL_SHIPPING_ADDRESS_REQUEST,
});

export const apiGetRentalShippingAddressSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTAL_SHIPPING_ADDRESS_SUCCESS,
    payload: response,
  };
};

export const apiGetRentalShippingAddressFailureAction = error => ({
  type: ActionTypes.API_GET_RENTAL_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const apiGetPricesRequestAction = () => ({
  type: ActionTypes.API_GET_PRICES_REQUEST,
});

export const apiGetPricesSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_PRICES_SUCCESS,
    payload: response,
  };
};

export const apiGetPricesFailureAction = error => ({
  type: ActionTypes.API_GET_PRICES_FAILURE,
  payload: error,
});

export const apiGetRentalPriceRequestAction = () => ({
  type: ActionTypes.API_GET_RENTAL_PRICE_REQUEST,
});

export const apiGetRentalPriceSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTAL_PRICE_SUCCESS,
    payload: response,
  };
};

export const apiGetRentalPriceFailureAction = error => ({
  type: ActionTypes.API_GET_RENTAL_PRICE_FAILURE,
  payload: error,
});
