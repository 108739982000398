import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';
import classnames from 'classnames';
import './defaultLoader.scss';

class DefaultLoader extends Component {
  render() {
    const { size, color, position, type } = this.props;

    return <span className={classnames('DefaultLoader', size, color, position, type)} />;
  }
}

DefaultLoader.defaultProps = {
  color: 'secondary',
  size: 'large',
  position: 'center-bottom',
  type: 'span'
};

DefaultLoader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  position: PropTypes.string,
  type: PropTypes.string,
};

export default provideContext(DefaultLoader);
