import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../../select/select';
import Input from '../../input/input';
import Button from '../../button/button';
import { createReturn } from '../../../../redux-api/apiOrders/api';
import './initiateReturnModal.scss';
import Checkbox from '../../checkbox/checkbox';
import validateTelephone from '../../../../utils/validateTelephone';
import getCurrencyForOrder from '../../../../utils/getCurrencyForOrder';
import isRetailSnooOrder from '../../../../utils/isRetailSnooOrder';
import { Auth } from 'aws-amplify';
import Message from '../../presentation/message/message';
import linkEnums from '../../../../enums/linksEnum';
import returnStatuses from '../../../../enums/returnStatuses';
import ReactGA from 'react-ga4';
import { apiConfig } from '../../../../config/apiConfig';
import * as apiRenterSelectors from '../../../../redux-api/apiRenter/apiRenterSelectors';
import { bindActionCreators } from 'redux';
import { apiGetBuyPackageRequestAction } from '../../../../redux-api/apiRenter/apiRenterActions';
import { provideContext } from '../../../../context/context';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Stepper from '../../stepper/stepper';

class ReturnModal extends Component {
  constructor(props) {
    super(props);

    const { order, provinces, countries } = props;
    this.state = {
      returnItems: {},
      returnReason: null,
      shippingAddress: { ...order.shipping_address },
      provinces: provinces,
      countries: countries,
      activeStep: 0,
      steps: 2,
      shippingAddressErrors: {},
      showSleepConsultation: false,
      returnReasonError: null,
    };

    this.lastStep = this.state.steps - 1;

    this.currency = getCurrencyForOrder(order);

    this.stepConfigs = [
      {
        heading: 'Return Items',
        render: this.renderReturnItemsStep
      },
      {
        heading: 'Shipping Details',
        render: this.renderShippingDetailsStep
      }
    ];

    this.reasonsMap = {
      'My baby sleeps well without SNOO': 'BABY_SLEEPS_WELL_WITHOUT_SNOO',
      'My baby does not like to be swaddled': 'BABY_DOES_NOT_LIKE_TO_BE_SWADDLED',
      'SNOO did not soothe my baby': 'SNOO_DID_NOT_SOOTHE_BABY',
      'Not worth the price': 'NOT_WORTH_THE_PRICE',
      'Uncomfortable with the motion and sound': 'WORRIED_ABOUT_HEAD_MOTION_OR_SOUND_LEVEL',
      'We experienced technical issues': 'HAD_TECHNICAL_ISSUES',
      'Moved baby to crib': 'MOVED_BABY_TO_CRIB',
      'Other': 'OTHER_RENT',
      'Does not fit - accessory': 'DOES_NOT_FIT_ACCESSORY',
      'Quality Concern - accessory': 'QUALITY_CONCERN_ACCESSORY',
      'Customer Order Error': 'CUSTOMER_ORDER_ERROR',
    }
  };

  componentDidMount() {
    this.props.actions.apiGetBuyPackage();
    const country = this.state.countries.find(c => c.name === this.state.shippingAddress.country);
    if (country) {
      this.props.apiGetProvinces(country._id);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.provinces !== state.provinces) {
      const selectedCountry = props.countries.find(country => country.name === state.shippingAddress.country);
      const provinceElement = props.provinces[selectedCountry._id].find(p => p.name === state.province);

      const provinceState = {
        provinces: props.provinces,
      };

      if (provinceElement) {
        provinceState.province = provinceElement.code;
      }

      return provinceState;
    }

    return null;
  };


  handleSubmit = async (event) => {
    event.preventDefault();
    const { returnItems, returnReason, shippingAddress, activeStep } = this.state;
    const { t, reasons, order, onCloseModal } = this.props;
    const user = await Auth.currentAuthenticatedUser();
    const returnLineItems = Object.values(returnItems).flat();
    if (activeStep !== this.lastStep) {
      if (!reasons[returnReason]) {
        this.setState({returnReasonError: t('return_form_first_name_required_error', 'Return reason is required.')});
        return
      }

      this.setState({
        activeStep: 1,
      });

      let hasSnoo = returnLineItems.some(lineItem => isRetailSnooOrder([{ variant_id: lineItem.variantId }]))
      const returnReasonValue = this.props.reasons[returnReason].reason;
      const sleepConsultationReasons = [
        'My baby does not like to be swaddled',
        'My baby sleeps well without SNOO',
        'SNOO did not soothe my baby',
        'Uncomfortable with the motion and sound',
        'Other'
        ];
      if (hasSnoo && sleepConsultationReasons.includes(returnReasonValue) ) {
        this.setState({
          showSleepConsultation: true,
        });
      }

      return;
    }

    const shippingAddressErrors = this.validateShippingAddress(shippingAddress);

    this.setState({
      shippingAddressErrors: Object.keys(shippingAddressErrors).length > 0
        ? { ...shippingAddressErrors }
        : {},
    });

    if (Object.keys(shippingAddressErrors).length > 0) {
      return;
    }

    const {
      first_name,
      last_name,
      province_code,
      country_code,
      ...restShippingAddress
    } = shippingAddress;
    const returnData = {
      lineItems: returnLineItems,
      reason: this.reasonsMap[this.props.reasons[returnReason].reason],
      shippingAddressFrom: {
        ...restShippingAddress,
        provinceCode: province_code,
        countryCode: country_code,
        firstName: first_name,
        lastName: last_name,
        name: `${first_name} ${last_name}`,
        email: user.username,
      },
    };

    try {
      this.setState({loading: true})
      ReactGA.event({
        category: "Button clicked",
        action: "retail_order_return_initiated",
        label: "Return has been initiated for retail order",
      });
      await createReturn(order._id, returnData);
      this.setState({initReturnError: false})
    } catch (e) {
      this.setState({initReturnError: true})
      return;
    } finally {
      this.setState({loading: false})
    }

    onCloseModal();
  };

  validateShippingAddress(shippingAddress) {
    const { t } = this.props;
    const errors = {};

    if (!shippingAddress.first_name) {
      errors.firstName = t('return_form_first_name_required_error', 'First name is required.');
    }

    if (!shippingAddress.last_name) {
      errors.lastName = t('return_form_last_name_required_error', 'Last name is required.')
    }

    if (!shippingAddress.phone) {
      errors.phone = t('return_form_phone_required_error', 'Phone is required.')
    } else if (!validateTelephone(shippingAddress.phone)) {
      errors.phone = t('return_form_invalid_phone_error', 'Invalid phone number.');
    }

    if (!shippingAddress.city) {
      errors.city = t('return_form_city_required_error', 'City is required.');
    }

    if (!shippingAddress.address1) {
      errors.address1 = t('return_form_address_required_error', 'Address is required.');
    }

    if (!shippingAddress.zip) {
      errors.zip = t('return_form_zip_required_error', 'Zip code is required.');
    }

    return errors;
  };

  handleCheckboxClick = (lineItem, serialNumber, campaignGroup) => {
    this.setState((prevState) => {
      const { returnItems } = prevState;
      let key = lineItem.id || campaignGroup;
      if (serialNumber) {
        key = `${key}-${serialNumber}`;
      }

      const checkboxExists = returnItems.hasOwnProperty(key);
      const updatedQuantities = { ...returnItems };
      if (checkboxExists) {
        delete updatedQuantities[key];
        // check if is bundle
      } else if(Array.isArray(lineItem)) {
        updatedQuantities[key] = lineItem.map(li => {
            return {
              quantity: li.quantity,
              id: li.id,
              productId: li.product_id,
              variantId: li.variant_id,
              deviceSerial: li.fulfilledDeviceSerials && li.fulfilledDeviceSerials[0],
              price: li.price,
              finalPrice: lineItem.finalPrice,
              SKU: li.SKU,
            }
          })
      } else {
        updatedQuantities[key] = {
          quantity: serialNumber ? 1 : lineItem.quantity,
          id: lineItem.id,
          productId: lineItem.product_id,
          variantId: lineItem.variant_id,
          deviceSerial: serialNumber,
          price: lineItem.price,
          finalPrice: lineItem.finalPrice,
          SKU: lineItem.SKU,
        };
      }

      return {
        returnItems: updatedQuantities,
      };
    });
  };

  handleQuantityChange = (lineItem, quantity, serialNumber) => {
    const key = serialNumber ? `${lineItem.id}-${serialNumber}` : `${lineItem.id}`;

    if (quantity > lineItem.quantity) {
      quantity = lineItem.quantity;
    }
    this.setState((prevState) => ({
      returnItems: {
        ...prevState.returnItems,
        [key]: {
          ...prevState.returnItems[key],
          quantity: quantity,
        },
      },
    }));
  };

  handleProceedWithReturn = () => {
    this.setState({
      showSleepConsultation: false,
    })
  };

  handleSleepConsultationSubmit = () => {
    ReactGA.event({
      category: "Button clicked",
      action: "retail_order_sleep_consultation",
      label: "Sleep consultation link clicked",
    });
    window.open(linkEnums.SLEEP_CONSULTATION_LINK, '_blank');
    this.props.onCloseModal();
    // this.setState({
    //   sleepSessionRegistrationSuccess: true
    // });
  }

  onSelectReasonChange = (e , i) => {
    this.setState({
      returnReason: i,
      returnReasonError: null,
    });
  };

  isItemActive(lineItemId, serialNumber) {
    const key = serialNumber ? `${lineItemId}-${serialNumber}` : `${lineItemId}`;
    return !!this.state.returnItems[key];
  };

  groupLineItemsByCampaign(lineItems) {
    return lineItems.reduce((acc, item) => {
      const campaignGroupObj = item.properties.find(prop => ['campaign_group', '_campaign_group'].includes(prop.name));
      const campaignGroup = campaignGroupObj && campaignGroupObj.value;

      if (campaignGroup) {
        acc[campaignGroup] = acc[campaignGroup] || [];
        acc[campaignGroup].push(item);
      }

      return acc;
    }, {});
  }

  findPropertyValue(lineItem, propertyNames) {
    const propertyObj = lineItem && lineItem.properties && lineItem.properties.find(prop => propertyNames.includes(prop.name));
    return propertyObj && propertyObj.value;
  };

  renderLineItems() {
    const { order, returns, buyPackage, t } = this.props;
    const { returnItems } = this.state;
    const grouppedByCampaign = this.groupLineItemsByCampaign(order.line_items);
    let li = order.line_items.filter(item => !item.properties.find(prop => ['campaign_group', '_campaign_group'].includes(prop.name)));

    if(Object.values(grouppedByCampaign).length){
      li.push(grouppedByCampaign);
    }

    const lineItems = li.flatMap(lineItem => {
      if (lineItem.fulfilledDeviceSerials && lineItem.fulfilledDeviceSerials.length > 0) {
        const returnLineItems = returns.flatMap((retailReturn) =>
          (retailReturn.status === "INITIATED" && !retailReturn.cancelledAtWarehouse) &&
          retailReturn.lineItems.flatMap((returnLineItem) =>
            lineItem.fulfilledDeviceSerials.filter(el =>
              el === returnLineItem.deviceSerial)
          )
        ).filter(Boolean);
        const lineItemSerials = returnLineItems.length ? lineItem.fulfilledDeviceSerials.filter(e => !returnLineItems.includes(e)) : lineItem.fulfilledDeviceSerials;
        return lineItemSerials.map(serial => (
          <div className={`line-item ${this.isItemActive(lineItem.id, serial) && 'active'}`} key={`${lineItem.id}-${serial}`}>
            <Checkbox
              name={`line-item-${lineItem.id}-${serial}`}
              checked={this.isItemActive(lineItem.id, serial)}
              onClick={() => this.handleCheckboxClick(lineItem, serial)}
            />
            <p className="item-name">{lineItem.name}</p>
            <p className="serial-number">Serial Number: {serial}</p>
            {this.isItemActive(lineItem.id, serial) &&
              <>
                <div className="item-quantity-wrapper">
                  <input
                    disabled={true}
                    type="number"
                    className="item-quantity-input"
                    name={`line-item-${lineItem.id}-${serial}`}
                    onChange={(e) => this.handleQuantityChange(lineItem, parseInt(e.target.value) || 0, serial)}
                    value="1"
                    min="1"
                    max="1"
                  />
                  <p className="item-price">{this.currency}{lineItem.finalPrice >= 0 ? lineItem.finalPrice : lineItem.price}</p>
                </div>
                {(isRetailSnooOrder([{ variant_id: lineItem.variant_id }]) || order.type === 'CORPORATE_RENTAL_10') &&
                <p className="note">*Make sure to return SNOO in its original packaging. If you don't have the original package, you can purchase it <a href={buyPackage.permalink} className="link" target="_blank">here</a> </p>
                }
              </>
            }
          </div>
        ));
        // check if is bundle
      } else if(Object.values(lineItem)[0] && Object.values(lineItem)[0][0]) {
       return Object.entries(lineItem).map(([promotionNameKey, lineItems]) => {
         const snooItems = lineItems.filter((item) => isRetailSnooOrder([{ variant_id: item.variant_id }]));
         const snooQuantity = snooItems.reduce((acc, snooItem) =>  acc + snooItem.quantity, 0);
         const promotionName = this.findPropertyValue(lineItems[0], ['promotion_name', '_promotion_name']);
         const campaignGroup = this.findPropertyValue(lineItems[0], ['campaign_group', '_campaign_group']);
         let isPromotionReturnable = this.findPropertyValue(lineItems[0], ['is_returnable', '_is_returnable']);
         const bundleHasLolaCrib = lineItems.find(li => apiConfig.shopify.lolaCribSkus.includes(li.SKU));

         const returnLineItems = returns.map((retailReturn) =>
           (retailReturn.status === "INITIATED" && !retailReturn.cancelledAtWarehouse) &&
           retailReturn.lineItems.find(returnLineItem =>
             returnLineItem.variantId === lineItems[0].variant_id
         )).filter(Boolean);

         if (returnLineItems.length ||  isPromotionReturnable == 'omit') {
           return;
         }

         isPromotionReturnable= JSON.parse(isPromotionReturnable);

         const totalAmount = lineItems.reduce((acc, li) => {
           const discountAmount = (li.discountAllocations || []).length
             ? li.discountAllocations.reduce((totalDiscount, discountAllocation) => totalDiscount + discountAllocation.amount * 1, 0)
             : 0;

          return acc + (parseFloat(li.price) * li.quantity) - discountAmount;
         }, 0);

         const showCheckbox = snooQuantity <= 1 && isPromotionReturnable;

         return (
           <div className={`line-item ${this.isItemActive(promotionNameKey) && 'active'} ${!isPromotionReturnable && 'disabled'}`} key={promotionNameKey}>
             {showCheckbox && <Checkbox
               checked={this.isItemActive(promotionNameKey)}
               name={`line-item-${promotionNameKey}`}
               onClick={() => this.handleCheckboxClick(lineItems, null, campaignGroup)}
             />
             }
             <p className="item-name">{promotionName || 'Promotion'}</p>
             {lineItems.map(item => (
               <div key={`individual-${item.id}`}>
                 <p className="serial-number">{item.quantity}x {item.name} </p>
               </div>
             ))}
             {!bundleHasLolaCrib && !isPromotionReturnable && snooQuantity === 0 && <i className="note">
               <Trans i18nKey="not_eligible_for_return">Not eligible for return. See <a className="link" target="_blank" href={linkEnums.SHIPPING_RETURNS}>Shipping & Returns</a>.</Trans>
             </i>
             }
             {snooQuantity > 1 || bundleHasLolaCrib &&
               <p className="note">
                 <Trans i18nKey="shipping_label_creation_error">Please <a className="link" target="_blank" href={linkEnums.SLEEP_CONSULTATION_LINK}>contact Customer Care</a> for help with this order.</Trans>
               </p>
             }

             {this.isItemActive(promotionNameKey) &&
               <div className="item-quantity-wrapper">
                 <input
                   disabled={true}
                   type="number"
                   className="item-quantity-input"
                   name={`line-item-${promotionNameKey}`}
                   min="1"
                   value="1"
                 />
                 <p className="item-price">{this.currency}{totalAmount.toFixed(2)}</p>
               </div>
             }
           </div>
         );
       })
    } else {
        const modifiedLineItem = { ...lineItem };
        const isReturnableObj = modifiedLineItem && modifiedLineItem.properties && modifiedLineItem.properties.find(prop =>  ['is_returnable', '_is_returnable'].includes(prop.name));
        const omitLineItem = isReturnableObj && isReturnableObj.value === 'omit';
        if (omitLineItem || !modifiedLineItem.requires_shipping) {
          return;
        }

        const returnLineItems = returns.map((retailReturn) =>
          (retailReturn.status === "INITIATED" && !retailReturn.cancelledAtWarehouse) &&
          retailReturn.lineItems.find(returnLineItem =>
            returnLineItem.variantId === modifiedLineItem.variant_id
          )
        ).filter(Boolean);
        const mergedReturnLineItems = returnLineItems.reduce((acc, cur) => {
          const existingObject = acc.find(obj => obj.id === cur.id);

          if (existingObject) {
            existingObject.quantity += cur.quantity;
          } else {
            acc.push({ ...cur });
          }

          return acc;
        }, []);

        if (mergedReturnLineItems[0]) {
          modifiedLineItem.quantity = modifiedLineItem.quantity - mergedReturnLineItems[0].quantity;
        }

        if (modifiedLineItem.quantity <= 0) {
          return;
        }

        const quantity = this.state.returnItems[modifiedLineItem.id] ? this.state.returnItems[modifiedLineItem.id].quantity : modifiedLineItem.quantity;
        const isLolaCrib = apiConfig.shopify.lolaCribSkus.includes(modifiedLineItem.SKU);
        return (
          <div className={`line-item ${this.isItemActive(modifiedLineItem.id) && 'active'}`} key={modifiedLineItem.id}>
            {!isLolaCrib && <Checkbox
                checked={this.isItemActive(modifiedLineItem.id)}
                name={`line-item-${lineItem.id}`}
                onClick={() => this.handleCheckboxClick(modifiedLineItem)}
              />
            }
            <p className="item-name">{modifiedLineItem.name}</p>
            {this.isItemActive(modifiedLineItem.id) &&
              <div className="item-quantity-wrapper">
                <input
                  disabled={modifiedLineItem.quantity === 1}
                  type="number"
                  className="item-quantity-input"
                  name={`line-item-${modifiedLineItem.id}`}
                  min="1"
                  max={modifiedLineItem.quantity}
                  value={quantity}
                  onChange={(e) => this.handleQuantityChange(modifiedLineItem, parseInt(e.target.value) || 0)}
                />
                <p className="item-price">{this.currency}{((modifiedLineItem.finalPrice >= 0 ? modifiedLineItem.finalPrice : modifiedLineItem.price) * quantity).toFixed(2)}</p>
              </div>
            }
            {isLolaCrib &&
              <p className="note">
                <Trans i18nKey="shipping_label_creation_error">Please <a className="link" target="_blank" href={linkEnums.SLEEP_CONSULTATION_LINK}>contact Customer Care</a> for help with this order.</Trans>
              </p>
              }
          </div>
        );
      }
    }).filter(Boolean);

    return lineItems.length > 0 ? lineItems : <h1>No available items</h1>;
  };

  renderReturnItemsStep = () => {
    const {t, reasons } = this.props;
    const { returnReason } = this.state;
    return (
      <>
        <p className="modal-text">{t('choose_items_modal_text', 'Please select the items you wish to return.')}</p>
        <div className="line-items">
          {this.renderLineItems()}
        </div>

        <p className="modal-text">{t('select_reason_modal_text', 'Please select the reason for return.')}</p>
        <Select
          list={Object.values(reasons)}
          onChange={this.onSelectReasonChange}
          labelKey={'reason'}
          placeholder={t('reason', 'Reason')}
          style={{ margin: '10px 0' }}
          selectListStyle={{height: '100px'}}
        />
        {this.state.returnReasonError && (
          <p className="error-message">{this.state.returnReasonError}</p>
        )}
      </>
    );
  };

  renderSleepConsultationStep = () => {
    const { t } = this.props;
    return (
      <>
        <div className="header-wrapper">
          <h3>{t('sleep_problem_heading', 'Having trouble with sleep?')}</h3>
          <span className="close-modal" onClick={() => this.props.onCloseModal(false)}>X</span>
        </div>
        <p className="modal-text">{t('sleep_problem_description', 'All babies are unique and some require some minor adjustments to improve sleep in SNOO. It can be hard to figure out exactly which tips to try with your little one. Please connect with our dedicated Sleep Consultants for personalized sleep support—we\'re here to help!')}</p>
        <div className="button-wrap">
          <div className="col-1-6">
            <Button color={'green-inverted'} onClick={this.handleProceedWithReturn}>{t('proceed_return_button', 'Proceed with Return Process')}</Button>
          </div>
          <div className="col-1-6">
            <Button onClick={this.handleSleepConsultationSubmit} color={'green'}>{t('free_consultation_button', 'Get FREE Sleep Consultation')}</Button>
          </div>
        </div>
      </>
    );
  };

  renderSleepSessionRegistrationSuccess = () => {
    const { t, onCloseModal } = this.props;
    return (
      <>
        <h2>{t('success_registration_heading', 'You have successfully registered for a sleep consultation!')}</h2>
        <p className="modal-text">{t('success_registration_description', 'A member of our Customer Care team will contact you shortly to schedule your consultation. Your return window will be extended if necessary, so no need to worry about that. Please feel free to ask your sleep consultant any questions you may have.')}</p>
        <div className="button-wrap center">
          <div className="col-1-6">
            <Button onClick={() => onCloseModal()} color={'green'}>Okay</Button>
          </div>
        </div>
      </>
    );
  };

  renderRefundSummary = () => {
    const { t, order } = this.props;
    const { returnItems } = this.state;

    if (order.type === 'CORPORATE_RENTAL_10') {
      return <></>
    }

    let snooCount = 0;
    let hasSoftGood = false;
    let refundSubtotal = 0;
    const returnLineItems = Object.values(returnItems).flat();
    const preLovedSnooCount = returnLineItems.reduce((acc, current) => {
      if (current.SKU === apiConfig.shopify.preLovedSnooSku) {
        acc += 1;
      }
      return acc;
    }, 0);
    returnLineItems.forEach((item) => {
      if (isRetailSnooOrder([{ variant_id: item.variantId }])) {
        snooCount += 1;
      } else {
        hasSoftGood = true;
      }
      const itemPrice = item.finalPrice >= 0 ? item.finalPrice : item.price;
      refundSubtotal += parseFloat(itemPrice * item.quantity);
    });

    const restockingFee = parseFloat(apiConfig.restockingFee);
    const snooShippingFee = parseFloat(apiConfig.snooShippingFee);
    const softGoodsShippingFee = parseFloat(apiConfig.softGoodsShippingFee);

    const restockingFeeTotal = snooCount * restockingFee;
    const shippingFeeTotal = snooShippingFee * (snooCount - preLovedSnooCount) + (hasSoftGood ? softGoodsShippingFee : 0);
    const totalEstimatedRefund = refundSubtotal - restockingFeeTotal - shippingFeeTotal;

    return (
      <div className="refund-summary-container">
        <p className="modal-text">{t('refund_summary','Refund summary')}</p>
        <div className="summary-item">
          <span className="item-label">{t('refund_subtotal','Refund subtotal')}</span>
          <span className="item-value">{this.currency}{refundSubtotal.toFixed(2)}</span>
        </div>
        {restockingFeeTotal > 0 &&
          <div className="summary-item">
              <span className="item-label">
                <span>{t('restocking_fee','SNOO Restocking Fee')}</span>
              </span>
              <span className="item-value item-negative">-{this.currency}{restockingFeeTotal.toFixed(2)}</span>
          </div>
        }
        {shippingFeeTotal > 0 && <div className="summary-item">
              <span className="item-label">
                <span>{t('shipping', 'Shipping')}</span>
                <span className="item-description">
                  {hasSoftGood && snooCount > 0
                    && t('shipping_fee_description',`*Shipping for each SNOO is ${this.currency}${snooShippingFee.toFixed(2)}, and shipping for all other items is ${this.currency}${softGoodsShippingFee.toFixed(2)} per package.`)}
                  {hasSoftGood && snooCount === 0 && t('shipping_fee_description_soft_goods_only', `Shipping is ${this.currency}${softGoodsShippingFee.toFixed(2)} per package.`)}
                  {!hasSoftGood && snooCount > 0 && t('shipping_fee_description_snoo_only', `Shipping for each SNOO is ${this.currency}${snooShippingFee.toFixed(2)}.`)}
                </span>
              </span>
            <span className="item-value item-negative">-{this.currency}{shippingFeeTotal.toFixed(2)}</span>
          </div>
        }
        <div className="summary-item total-refund">
          <span className="item-label bold">{('total_estimated_refund','Total estimated refund')}</span>
          <span className="item-value"><span>{this.currency}{totalEstimatedRefund.toFixed(2)}</span><span>+ {t('applicable_taxes','applicable taxes')}</span></span>
        </div>
        <div className="summary-item">
          <p className="note">{t('shipping_label_note', ' Your return must be scanned by the shipping carrier within 10 days of receiving the shipping label. Past 10 days, the label will be cancelled, and you will need to reinitiate the return..')}</p>
        </div>
      </div>
    );
  }

  renderShippingDetailsStep = () => {
    const {
      disabled,
      t,
    } = this.props;
    return (
      <>
        <p className="modal-text">{t('fill_shipping_info_modal_text', 'Please confirm your return shipping address.')}</p>
        <div className="shipping-address-wrapper">
          <div className="input-row">
            <div className="row">
              <div className="col-1-2">
                <Input
                  name="firstName"
                  placeholder={t('first_name', 'First Name')}
                  value={this.state.shippingAddress.first_name}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('first_name', e.target.value)}
                />
                {this.state.shippingAddressErrors.firstName && (
                  <p className="error-message">{this.state.shippingAddressErrors.firstName}</p>
                )}
              </div>
              <div className="col-1-2">
                <Input
                  name="lastName"
                  placeholder={t('last_name', 'Last Name')}
                  defaultValue={this.state.shippingAddress.last_name}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('last_name', e.target.value)}
                />
                {this.state.shippingAddressErrors.lastName && (
                  <p className="error-message">{this.state.shippingAddressErrors.lastName}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="phone"
                  placeholder={t('global_phone', 'Phone')}
                  defaultValue={this.state.shippingAddress.phone}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('phone', e.target.value)}
                />
                {this.state.shippingAddressErrors.phone && (
                  <p className="error-message">{this.state.shippingAddressErrors.phone}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="address1"
                  placeholder={t('global_address')}
                  defaultValue={this.state.shippingAddress.address1}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('address1', e.target.value)}
                />
                {this.state.shippingAddressErrors.address1 && (
                  <p className="error-message">{this.state.shippingAddressErrors.address1}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="address2"
                  placeholder={t('address_form_apartment', 'Apartment, suite, etc (optional)')}
                  defaultValue={this.state.shippingAddress.address2}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('address2', e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="city"
                  placeholder={t('address_form_city', 'City')}
                  defaultValue={this.state.shippingAddress.city}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('city', e.target.value)}
                />
                {this.state.shippingAddressErrors.city && (
                  <p className="error-message">{this.state.shippingAddressErrors.city}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1-3" style={{margin: '10px 2px'}}>{this.renderCountriesOptions()}</div>
              <div className="col-1-3" style={{margin: '10px 2px'}}>{this.renderProvincesOptions()}</div>
              <div className="col-1-3">
                <Input
                  name="zip"
                  smallPlaceholder={false}
                  placeholder={t('address_form_zip', 'Zip')}
                  defaultValue={this.state.shippingAddress.zip}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('zip', e.target.value)}
                />
                {this.state.shippingAddressErrors.zip && (
                  <p className="error-message">{this.state.shippingAddressErrors.zip}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.renderRefundSummary()}
      </>
    );
  };

  handleShippingAddressChange = (field, value) => {
    this.setState((prevState) => ({
      shippingAddress: {
        ...prevState.shippingAddress,
        [field]: value,
      },
    }));
  };

  renderCountriesOptions() {
    let { countries } = this.state;
    const countriesToShow = ['United States'];
    countries = countries.filter(country => countriesToShow.includes(country.name))

    const defaultValue = countries.findIndex(country => country.name === this.state.shippingAddress.country);
    const { disabled, t } = this.props;
    return (
      <Select
        placeholder={t('country', 'Country')}
        list={countries}
        onChange={this.onSelectCountry}
        labelKey={'name'}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    );
  };

  getProvinceList() {
    let { countries, provinces } = this.state;
    const { isLoadingGetProvinces } = this.props;

    const selectedCountry = countries.find(country => country.name === this.state.shippingAddress.country || country.code === this.state.shippingAddress.country_code);
    if (!isLoadingGetProvinces && selectedCountry && provinces[selectedCountry._id] && provinces[selectedCountry._id].length) {
      return provinces[selectedCountry._id];

    }
    return [
      {
        name: 'No provinces',
      },
    ];
  };

  getProvinceDefaultValue(list) {
    const defaultValue = list.findIndex(item => item.name === this.state.shippingAddress.province);
    if (defaultValue !== -1) {
      return defaultValue;
    }
    return 0;
  };

  renderProvincesOptions() {
    const countriesToShowState = ['United States']
    if (!countriesToShowState.includes(this.state.shippingAddress.country)) {
      return null;
    }
    const list = this.getProvinceList();
    const defaultValue = this.getProvinceDefaultValue(list);
    const { disabled, t } = this.props;
    return (
      <Select
        list={list}
        onChange={this.onSelectProvinceChange}
        labelKey={'name'}
        placeholder={t('state', 'state')}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    );
  };

  onSelectCountry = (e, i, selectedCountry) => {
    if (selectedCountry.name !== this.state.shippingAddress.country) {
      this.setState((prevState) => ({
        shippingAddress: {
          ...prevState.shippingAddress,
          country: selectedCountry.name,
        },
      }));

      this.props.apiGetProvinces(selectedCountry._id);
    }
  };

  onSelectProvinceChange = (e, i) => {
    const { countries } = this.state;
    const selectedCountry = countries.find(country => country.name === this.state.shippingAddress.country);
    const selectedProvince = this.state.provinces[selectedCountry._id][i];
    if (selectedProvince.name !== this.state.shippingAddress.province) {
      this.setState((prevState) => ({
        shippingAddress: {
          ...prevState.shippingAddress,
          province: selectedProvince.name,
          province_code: selectedProvince.code,
        },
      }));
    }
  };

  handleCancelBtn = () => {
    if (this.state.activeStep === this.lastStep) {
      this.setState({
        activeStep: 0,
        returnItems: {},
        returnReason: null
      })
      return;
    }

    this.props.onCloseModal(false);
  };

  renderContent() {
    const { order, t, reasons } = this.props;
    const { returnItems, loading, activeStep, showSleepConsultation, sleepSessionRegistrationSuccess, initReturnError, steps } = this.state;
    const hasActiveLineItems = Object.keys(returnItems).length > 0;
    const isContinueDisabled = !hasActiveLineItems;
    const stepHeadings = this.stepConfigs.map(stepConfig => stepConfig.heading);

    // if (sleepSessionRegistrationSuccess) {
    //   return this.renderSleepSessionRegistrationSuccess();
    // }

    if (showSleepConsultation) {
      return this.renderSleepConsultationStep();
    }

    return (
      <>
        <div className="header-wrapper">
          <h3>{t('return_order_heading', `Return Order ${order.name}`)}</h3>
          <span className="close-modal" onClick={() => this.props.onCloseModal(false)}>X</span>
        </div>
        <Stepper activeStep={activeStep} steps={steps} headings={stepHeadings}/>
        {this.stepConfigs[activeStep].render()}
        {initReturnError && (
          <div className="error-message-wrapper">
            <Message type="error">{t('generic_error', 'An unexpected error occurred. Please try again.')}</Message>
          </div>
        )}
        <div className="button-wrap">
          <div className="col-1-6">
            <Button color={'green-inverted'} onClick={this.handleCancelBtn}>
              {activeStep === this.lastStep ? t('back_button', 'Back') : t('cancel_button', 'Cancel')}
            </Button>
          </div>
          <div className="col-1-6">
            <Button
              disabled={isContinueDisabled || loading}
              onClick={this.handleSubmit}
              color={'green'}
            >
              {activeStep === 0 ? t('continue_button', 'Continue') : loading ? t('loading', 'Loading') : t('initiate_return_button', 'Initiate return')}
            </Button>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="InitiateReturnModal">
        <div className="modal-center">{this.renderContent()}</div>
      </div>
    );
  }
}

ReturnModal.propTypes = {
  order: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  buyPackage: apiRenterSelectors.getBuyPackage(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      apiGetBuyPackage: apiGetBuyPackageRequestAction,
    },
    dispatch,
  );
  return { actions };
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(ReturnModal));
