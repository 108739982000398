import React from 'react';
import PropTypes from 'prop-types';
import './stepper.scss';

const Stepper = ({ steps, activeStep, headings }) => {
  const circles = Array.from({ length: steps }).map((_, index) => {
    const isLast = steps - 1 === index;
    return (
      <React.Fragment key={index}>
        <div className="circle-wrapper">
          <div key={index} className={`circle ${index <= activeStep && 'active'}`}>
            {index + 1}
          </div>
          <p className={`step-heading ${index <= activeStep && 'active'}`}>{headings[index] || `Step ${index + 1}`}</p>
        </div>
        {!isLast && <div key={`${index}-line`} className={`line ${index <= activeStep && 'active'}`} />}
      </React.Fragment>
    );
  });

  return (
    <div className="stepper">
      {circles}
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  headings: PropTypes.array,
};

export default Stepper;
