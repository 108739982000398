import React from 'react';
import { withTranslation } from 'react-i18next';

const Context = React.createContext('/');

export const { Provider, Consumer } = Context;

export const provideContext = WrappedComponent => {
  const TranslatedWrappedComponent = withTranslation()(WrappedComponent);

  return props => <Consumer>{context => <TranslatedWrappedComponent context={context} {...props} />}</Consumer>;
};
