export default function CompanyImage({image}) {
    if (!image) {
        return null;
    }
    const src = `https://${image.bucket}.s3.${image.region}.amazonaws.com/${image.key.replaceAll(' ', '+')}`
    return (
        <img src={src} alt={""} className={'company-logo'}/>

    )
}
