export default {
  PACKAGING_OPTIONS: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
};
