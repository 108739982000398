import { takeEvery, call, put } from 'redux-saga/effects';

import {
  putUpdateRenter,
  postChangePassword,
  putUpdateRenterBillingAddress,
  getRenterBillingAddress,
  getRentals,
  getBuyPackage,
  getOrders,
  getBillingHistory,
  getTotalDebt,
} from './api';

import {
  ActionTypes,
  apiPutUpdateRenterSuccessAction,
  apiPutUpdateRenterFailureAction,
  apiPostChangePasswordSuccessAction,
  apiPostChangePasswordFailureAction,
  apiPutUpdateRenterBillingAddressSuccessAction,
  apiPutUpdateRenterBillingAddressFailureAction,
  apiGetRenterBillingAddressSuccessAction,
  apiGetRenterBillingAddressFailureAction,
  apiGetRenterRentalsSuccessAction,
  apiGetRenterRentalsFailureAction,
  apiGetBuyPackageSuccessAction,
  apiGetBuyPackageFailureAction,
  apiGetRenterOrdersSuccessAction,
  apiGetRenterOrdersFailureAction,
  apiGetRenterBillingHistorySuccessAction,
  apiGetRenterBillingHistoryFailureAction,
  apiGetRenterTotalDebtSuccessAction,
  apiGetRenterTotalDebtFailureAction,
} from './apiRenterActions';

export function* apiUpdateRenter(action) {
  const { response, error } = yield call(putUpdateRenter, action.payload);

  if (response) {
    yield put(apiPutUpdateRenterSuccessAction(response.data));
  } else {
    yield put(apiPutUpdateRenterFailureAction(error.response.data));
  }
}

export function* apiChangePassword(action) {
  const { response, error } = yield call(postChangePassword, action.payload);

  if (response) {
    yield put(apiPostChangePasswordSuccessAction(response));
  } else {
    yield put(apiPostChangePasswordFailureAction(error.response.data.error));
  }
}

export function* apiUpdateRenterBillingAddress(action) {
  const { response, error } = yield call(putUpdateRenterBillingAddress, action.payload);

  if (response) {
    yield put(apiPutUpdateRenterBillingAddressSuccessAction(response.data));
  } else {
    yield put(apiPutUpdateRenterBillingAddressFailureAction(error.response.data));
  }
}

export function* apiGetRenterBillingAddress() {
  const { response, error } = yield call(getRenterBillingAddress);
  if (response) {
    yield put(apiGetRenterBillingAddressSuccessAction(response.data));
  } else {
    yield put(apiGetRenterBillingAddressFailureAction(error.response.data));
  }
}

export function* apiGetBuyPackage() {
  const { response, error } = yield call(getBuyPackage);
  if (response) {
    yield put(apiGetBuyPackageSuccessAction(response.data));
  } else {
    yield put(apiGetBuyPackageFailureAction(error.response.data));
  }
}

export function* apiGetRentals() {
  const { response, error } = yield call(getRentals);
  if (response) {
    yield put(apiGetRenterRentalsSuccessAction(response.data));
  } else {
    yield put(apiGetRenterRentalsFailureAction(error.response.data));
  }
}

export function* apiGetOrders() {
  const { response, error } = yield call(getOrders);
  if (response) {
    yield put(apiGetRenterOrdersSuccessAction(response.data));
  } else {
    yield put(apiGetRenterOrdersFailureAction(error.response.data));
  }
}
export function* apiGetBillingHistory(action) {
  const { response, error } = yield call(getBillingHistory, action.payload);
  if (response) {
    yield put(apiGetRenterBillingHistorySuccessAction(response.data));
  } else {
    yield put(apiGetRenterBillingHistoryFailureAction(error.response.data));
  }
}

export function* apiGetTotalDebt(action) {
  const { response, error } = yield call(getTotalDebt);
  if (response) {
    yield put(apiGetRenterTotalDebtSuccessAction(response.data));
  } else {
    yield put(apiGetRenterTotalDebtFailureAction(error.response.data));
  }
}

export default function* apiRentalSaga() {
  yield takeEvery(ActionTypes.API_PUT_RENTER_UPDATE_REQUEST, apiUpdateRenter);
  yield takeEvery(ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_REQUEST, apiChangePassword);
  yield takeEvery(ActionTypes.API_GET_RENTER_BILLING_ADDRESS_REQUEST, apiGetRenterBillingAddress);
  yield takeEvery(ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_REQUEST, apiUpdateRenterBillingAddress);
  yield takeEvery(ActionTypes.API_GET_RENTER_RENTALS_REQUEST, apiGetRentals);
  yield takeEvery(ActionTypes.API_GET_BUY_PACKAGE_REQUEST, apiGetBuyPackage);
  yield takeEvery(ActionTypes.API_GET_RENTER_ORDERS_REQUEST, apiGetOrders);
  yield takeEvery(ActionTypes.API_GET_RENTER_BILLING_HISTORY_REQUEST, apiGetBillingHistory);
  yield takeEvery(ActionTypes.API_GET_RENTER_TOTAL_DEBT_REQUEST, apiGetTotalDebt);
}
