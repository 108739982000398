import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

import { Auth } from 'aws-amplify';
import i18next from '../../utils/i18n/i18next';
import { t } from 'i18next';


export const putUpdateRenter = async renterData => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.put(getApiRouteUrl(routes.RENTAL_RENTER_FROM_USER_ID), renterData, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};
export const postChangePassword = async ({ oldPassword, newPassword }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.USERS_RESET_PASSWORD), {oldPassword, newPassword}, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const putUpdateRenterBillingAddress = async address => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.put(getApiRouteUrl(routes.RENTAL_RENTER_BILLING_ADDRESS), address, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getRenterBillingAddress = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_BILLING_ADDRESS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getRentals = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_RENTALS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getBuyPackage = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_BUY_PACKAGE), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getOrders = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_ORDERS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getBillingHistory = async ({ orderId, period }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_BILLING_HISTORY, { orderId, period }), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getTotalDebt = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_TOTAL_DEBT), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};
