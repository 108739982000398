import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { provideContext } from '../context/context.jsx';
import ScreenTitle from '../components/views/presentation/screenTitle/screenTitle.jsx';
import AppContainer from '../containers/appContainer.jsx';
import * as apiReferralSelectors from '../redux-api/apiReferral/apiReferralSelectors.js';
import * as apiRentalSelectors from '../redux-api/apiRental/apiRentalSelectors.js';
import ReferralsForm from '../components/views/forms/referralsForm/referralsForm.jsx';
import DefaultLoader from '../components/views/loaders/defaultLoader/defaultLoader.jsx';

import {
  apiGetRenterReferralsRequestAction,
  apiPostRenterReferralsRequestAction,
  apiPostRetailReferralsRequestAction,
} from '../redux-api/apiReferral/apiReferralActions';
import { apiGetRenterRentalsRequestAction } from '../redux-api/apiRenter/apiRenterActions';
import { apiRenterSelector } from '../redux-api';

class ReferralsScreen extends PureComponent {
  state = {
    currentOrder: null,
    currentPeriod: null,
  };

  componentDidMount() {
    this.props.actions.getRentals();
    this.props.actions.getReferrals(this.props.rentals.length > 0);
  }

  renderReferralsForm() {
    const {
      user,
      isLoadingGetRenterReferrals,
      postReferralError,
      postReferralSuccess,
      referrals,
      rentals,
      actions,
    } = this.props;
    return (
      <ReferralsForm
        profile={{ ...user }}
        isLoadingGetRenterReferrals={isLoadingGetRenterReferrals}
        newReferral={this.props.actions.newReferral}
        referrals={referrals}
        postReferralError={postReferralError}
        postReferralSuccess={postReferralSuccess}
        getReferrals={actions.getReferrals}
        rentals={rentals}
      />
    );
  }
  render() {
    const { t, isLoadingGetRenterReferrals, isLoadingGetRenter, flaggedModes } = this.props;

    if (
      isLoadingGetRenterReferrals === undefined ||
      isLoadingGetRenterReferrals === true ||
      isLoadingGetRenter === true
    ) {
      return (
        <AppContainer>
          <DefaultLoader type="block" />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer>
          <div className="row">
            <div className="col-1">
              <ScreenTitle>{t('referrals_title')}</ScreenTitle>
            </div>
          </div>
          <div style={{ marginTop: '30px' }} className="row" id="main-content" tabIndex={0}>
            {this.renderReferralsForm(flaggedModes)}
          </div>
        </AppContainer>
      );
    }
  }
}

ReferralsScreen.propTypes = {
  actions: PropTypes.shape({
    getOrders: PropTypes.func,
    getBillingHistory: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  flaggedModes: apiRentalSelectors.getFlaggedModes(state),
  isLoadingGetRenterReferrals: apiReferralSelectors.isLoadingGetRenterReferrals(state),
  isLoadingGetRenter: apiRentalSelectors.isLoadingGetRenter(state),
  referrals: apiReferralSelectors.getReferrals(state),
  postReferralError: apiReferralSelectors.getPostRenterReferralsErrors(state),
  postReferralSuccess: apiReferralSelectors.getPostRenterReferralsSuccess(state),
  rentals: apiRenterSelector.getRentals(state),
});
const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      getReferrals: apiGetRenterReferralsRequestAction,
      newReferral: apiPostRenterReferralsRequestAction,
      newRetailReferral: apiPostRetailReferralsRequestAction,
      getRentals: apiGetRenterRentalsRequestAction,
    },
    dispatch,
  );
  return { actions };
};
export default provideContext(connect(mapStateToProps, mapDispatchToProps)(ReferralsScreen));
