export const ActionTypes = {
  API_GET_RETURN_ITEMS_REQUEST: 'API_GET_RETURN_ITEMS_REQUEST',
  API_GET_RETURN_ITEMS_SUCCESS: 'API_GET_RETURN_ITEMS_SUCCESS',
  API_GET_RETURN_ITEMS_FAILURE: 'API_GET_RETURN_ITEMS_FAILURE',
  API_POST_RENTAL_INITIATE_RETURN_REQUEST: 'API_POST_RENTAL_INITIATE_RETURN_REQUEST',
  API_POST_RENTAL_INITIATE_RETURN_SUCCESS: 'API_POST_RENTAL_INITIATE_RETURN_SUCCESS',
  API_POST_RENTAL_INITIATE_RETURN_FAILURE: 'API_POST_RENTAL_INITIATE_RETURN_FAILURE',
  API_GET_RENTAL_CANCELLING_REASONS_REQUEST: 'API_GET_RENTAL_CANCELLING_REASONS_REQUEST',
  API_GET_RENTAL_CANCELLING_REASONS_SUCCESS: 'API_GET_RENTAL_CANCELLING_REASONS_SUCCESS',
  API_GET_RENTAL_CANCELLING_REASONS_FAILURE: 'API_GET_RENTAL_CANCELLING_REASONS_FAILURE',
};

export const apiGetReturnItemsRequestAction = () => ({
  type: ActionTypes.API_GET_RETURN_ITEMS_REQUEST,
});

export const apiGetReturnItemsSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RETURN_ITEMS_SUCCESS,
    payload: response,
  };
};

export const apiGetReturnItemsFailureAction = error => ({
  type: ActionTypes.API_GET_RETURN_ITEMS_FAILURE,
  payload: error,
});

export const apiPostRentalInitiateReturnRequestAction = request => ({
  type: ActionTypes.API_POST_RENTAL_INITIATE_RETURN_REQUEST,
  payload: request,
});

export const apiPostRentalInitiateReturnSuccessAction = response => ({
  type: ActionTypes.API_POST_RENTAL_INITIATE_RETURN_SUCCESS,
  payload: response,
});

export const apiPostRentalInitiateReturnFailureAction = error => ({
  type: ActionTypes.API_POST_RENTAL_INITIATE_RETURN_FAILURE,
  payload: error,
});

export const apiGetRentalCancellingReasonsRequestAction = () => ({
  type: ActionTypes.API_GET_RENTAL_CANCELLING_REASONS_REQUEST,
});

export const apiGetRentalCancellingReasonsSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTAL_CANCELLING_REASONS_SUCCESS,
    payload: response,
  };
};

export const apiGetRentalCancellingReasonsFailureAction = error => ({
  type: ActionTypes.API_GET_RENTAL_CANCELLING_REASONS_FAILURE,
  payload: error,
});
