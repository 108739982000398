import React from 'react';
import { connect } from 'react-redux';
import { provideContext } from '../../../context/context.jsx';

import Message from '../../../components/views/presentation/message/message.jsx';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error(error, info)
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <div className="container padding-bottom">
          <br/>
          <Message type="error" fullWidth={true}>
            {t('general_message_error', 'Sorry, there was an issue while loading this Page. Please contact Customer Care for more information.')}
          </Message>
        </div>
      );
    }
    return this.props.children;
  }
}

export default provideContext(
  connect(
    null,
    null,
  )(ErrorBoundary),
);
