export default {
  ONE_TIME_ITEM_TYPES: [
    'DEPOSIT',
    'CLEANING_FEE',
    'PURCHASED_ITEMS',
    'REFUND',
    'SECOND_SECURITY_DEPOSIT',
    'SHIPPING',
    'MINIMUM_RENTAL_FEE',
    'RENTAL_RETURN_SHIPPING_FEE',
  ],
  RECURRING_ITEM_TYPES: ['RENTAL', 'DISCOUNT'],
  TAX_ITEM_TYPES: ['TAX'],
};
