import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { provideContext } from '../../../../context/context.jsx';

class Currency extends CurrencyFormat {
  render() {
    const {
      value,
      displayType,
      thousandSeparator,
      decimalScale,
      decimalSeparator,
      fixedDecimalScale,
      prefix,
      context,
      suffix,
      divider,
    } = this.props;
    const currency = {
      suffix,
      prefix: prefix || context.apiConfig.stripe.currency
    }
    if (process.env.REACT_APP_STRIPE_CURRENCY === "eur") {
      currency.suffix = context.apiConfig.stripe.currency
      currency.prefix = null
    }
    return (
      <CurrencyFormat
        value={value / divider}
        displayType={displayType}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        prefix={currency.prefix}
        suffix={currency.suffix}
      />
    );
  }
}

Currency.defaultProps = {
  displayType: 'text',
  thousandSeparator: true,
  decimalSeparator: '.',
  decimalScale: 2,
  suffix: '',
  fixedDecimalScale: true,
  divider: 100,
};

Currency.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  context: PropTypes.object,
  divider: PropTypes.number,
};

export default provideContext(Currency);
