import { createSelector } from 'reselect';
import { ActionTypes } from './apiUsersActions';

import {
  getAllLoadersFromState,
  getLoaderStatus,
  getAllErrorsFromState,
  getErrorStatus,
  getAllSuccessesFromState,
  getSuccessStatus,
} from '../apiUtils/apiUtils';

export const getUsers = state => state.apiUsers.users;

export const getUser = state => state.apiUsers.user;
export const getUserId = state => state.apiUsers.userId;
export const getLegalCheck = state => state.apiUsers.legalDocs;

export const isLoadingGetMe = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_USER_ME_REQUEST),
);

export const getUpdateUserSuccessMessage = createSelector(
  getAllSuccessesFromState,
  successes =>
    getSuccessStatus(successes, ActionTypes.API_UPDATE_USER_SUCCESS)
)

export const getUpdateUserErrorMessage = createSelector(
  getAllErrorsFromState,
  errors  =>
    getErrorStatus(errors, ActionTypes.API_UPDATE_USER_FAILURE)
)

export const isLoadingCheckLegal = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_USERS_LEGAL_CHECK_REQUEST),
);

export const getCorporateBenefits = state => state.apiUsers.corporateBenefits;
