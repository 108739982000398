import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import Moment from 'react-moment';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { provideContext } from '../../../../context/context.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Message from '../../presentation/message/message.jsx';
import * as apiOrdersSelector from '../../../../redux-api/apiOrders/apiOrdersSelectors.js';
import { Trans } from 'react-i18next';


import './overviewPanelRetail.scss';
import orderStatuses from '../../../../enums/orderStatuses';
import ReactTooltip from 'react-tooltip';
import returnStatuses from '../../../../enums/returnStatuses';

import ReactGA from "react-ga4";
import showMoreInfoIcon from '../../../../assets/icons/show-more.svg';
import linkEnums from '../../../../enums/linksEnum';



class OverviewPanelRetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shippingDate: moment(props.shippingDate),
      canBeEdited: true,
      showOptions: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  handleChangeShippingDate = newDate => {
    const {  order } = this.props;
    this.setState({ isOpen: false, shippingDate: moment(newDate) });
    this.props.apiUpdateShippingDate({
      orderId: order._id,
      shippingDate: moment(newDate).format('YYYY-MM-DD'),
    });

    ReactGA.event({
      category: "Button Clicked",
      action: "retail_ship_date_changed",
      label: "Retail Shipping date has been changed", // optional
    });
  };

  handleCancelOrderClick = () => {
    const { order } = this.props;
    this.props.showCancelOrderModal(order._id);
  };

  handleEditAddressClick = () => {
    const { order } = this.props;
    this.props.showEditAddressModal(order);
  };

  openCalendar = event => {
    this.setState({ isOpen: true });
  };

  closeCalendar = event => {
    this.setState({ isOpen: false });
  };

  renderError = errors => {
    return (
      <div className="order-status-overview-error">
        <Message type="error">{errors.error.message}</Message>
      </div>
    );
  };

  filterDate = (date) => {
    const today = moment();
    const fourMonthsFromNow = moment().add(4, 'months');

    const momentDate = moment(date);

    if (momentDate.isBefore(today, 'day')) {
      return false;
    }

    if (momentDate.day() === 0 || momentDate.day() === 6) {
      return false;
    }

    if (momentDate.isAfter(fourMonthsFromNow, 'day')) {
      return false;
    }

    return true;
  };

  toggleOptions = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      showOptions: !prevState.showOptions,
    }));
  };

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({ showOptions: false });
    }
  };

  setDropdownRef = (node) => {
    this.dropdownRef = node;
  };

  isInReturnPeriod = (order) => {
    if (order.type === 'CORPORATE_RENTAL_10') {
      return true;
    }
    const thirtyDaysAgo = moment().subtract(30, 'days');
    const deliveredStatus = order.statusHistory.find(status => status.status === orderStatuses.DELIVERED);
    return deliveredStatus && moment(deliveredStatus.date).isSameOrAfter(thirtyDaysAgo);
  }

  shouldShowReturn = (order) => {
    if (order.type === 'CORPORATE_RENTAL_10') {
      return true;
    }
    return order.name.startsWith('#SH') && !this.props.isReturn && order.status === orderStatuses.DELIVERED && this.isInReturnPeriod(order);
  };

  render() {
    const {
      t,
      context,
      userData,
      title,
      iconName,
      showButton,
      showShippingDate,
      itemData,
      lineItems,
      order,
      successShippingDateUpdate,
      errorShippingDateUpdate,
      currentReturn,
    } = this.props;

    let firstName, lastName, address1, address2, city, zip, province, country;
    if (currentReturn && currentReturn.shippingAddressFrom) {
      ({ firstName, lastName, address1, address2, city, zip, province, country } = currentReturn.shippingAddressFrom);
    } else {
      ({ first_name: firstName, last_name: lastName, address1, address2, city, zip, province, country } = order.shipping_address);
    }

    const fullName = `${firstName} ${lastName}`;
    const shippingAddress = `${address1}, ${address2 ? address2 + ', ' : ''}${city} ${zip}, ${
      province ? province + ',' : ''
    } ${country}`;
    const showSchedulePickup = currentReturn && currentReturn.status === returnStatuses || currentReturn && !currentReturn.cancelledAtWarehouse;

    const isDigitalProductOrderOnly = order.line_items.every(li => !li.requires_shipping)
    if (isDigitalProductOrderOnly) {
      order.status = orderStatuses.PLACED;
    }


    return (
      <div className="OverviewPanelRetail">
        <div className="edit-profile-overview">
          <div className="item">
            <h4>{t('overview_panel_name', 'Name')}</h4>
            <p>{fullName}</p>
          </div>
          <div className="item">
            <h4>{t('global_email')}</h4>
            <p className="email" data-tip={userData.email}>{userData.email}</p>
            <ReactTooltip place="top" type="dark" effect="float"/>
          </div>
          <div className="item">
            <h4>{t('global_shipping_address', 'Shipping address')}</h4>
            <p>{shippingAddress}</p>
          </div>
          {order.status === 'PLACED' && (
            <a className="edit-icon" onClick={this.handleEditAddressClick}>
              <FontAwesomeIcon icon={faEdit} size={'lg'} />
            </a>
          )}
          {showSchedulePickup &&
            <a
              className="schedule-link"
              href={linkEnums.SCHEDULE_PICKUPS}
              target="_blank"
            >
              Schedule Pickup
            </a>
          }
        </div>

        <div className="order-status-overview-retail">
          <div className="icon-wrap">
            <div className="icon-center">
              <div className="icon active">
                <div className="circle">
                  <img src={iconName} className="active" alt="" />
                </div>
                <div className="label">
                  <h4>{title}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="details-wrap">
            <div className="details-center">
              {this.shouldShowReturn(order) && (
                <div className="button-container" ref={this.setDropdownRef}>
                  <div className="button" id="menu-button" onClick={(e) => this.toggleOptions(e)}>
                    <img src={showMoreInfoIcon} />
                  </div>
                  {this.state.showOptions && (
                    <div className="dropdown" role="menu" aria-labelledby="menu-button" tabIndex="-1">
                      <button type="submit" className="form-button" role="menuitem" tabIndex="-1" id="menu-item-1" onClick={() => this.props.showInitReturnModal(this.props.order, this.props.returns)}>
                        Return Order
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="details">
                {itemData && itemData.map((item, index) => {
                  return (
                    <div key={index} className="details-item">
                      <h4>{item.title}</h4>
                      <div className="item-detail">{item.value}</div>
                    </div>
                  );
                })}

                {showShippingDate && (
                  <div className="details-item">
                    <h4>{t('overview_panel_shipping_date_title', 'Shipping date')}</h4>
                    {successShippingDateUpdate && (
                      <p className="success-message">
                        {t(
                          'overview_pannel_shipping_date_changed_success',
                          'You have successfully changed the shipping date.',
                        )}
                      </p>
                    )}
                    {!isEmpty(errorShippingDateUpdate) && (
                      <p className="error-message">
                        {t('overview_panel_shipping_date_not_changed', 'You did not change shipping date')}
                      </p>
                    )}
                    <p>
                      <span>
                        <Moment format={context.apiConfig.date.format} date={this.state.shippingDate} />
                      </span>
                      {order.status === 'PLACED' && (
                        <span className="edit-icon" onClick={this.openCalendar}>
                          <FontAwesomeIcon icon={faEdit} />
                        </span>
                      )}
                    </p>
                    <p className="shipping-date-disclaimer">
                    <Trans i18nKey="overview_panel_shipping_date_allow_two_to_five">
                      Ships within 4 business days of the date you pick. Allow an
                      {' '}
                      <em>additional</em>
                      {' '}
                      2-5 business days for SNOO to arrive.
                    </Trans>
                    </p>
                  </div>
                )}
                {order.status === orderStatuses.PLACED && !isDigitalProductOrderOnly && (
                  <div className="details-item">
                    <a className="danger-text cancel-order-action" onClick={this.handleCancelOrderClick}>
                      {t('button_text_cancel_order', 'Cancel order')}
                    </a>
                  </div>
                )}
                {order.status === orderStatuses.DELIVERED && !this.isInReturnPeriod(order) && !this.props.isReturn && (
                  <div className="details-item">
                    <p className="danger-text cancel-order-action">
                      {t('return_period_is_over', 'The 30-day return period has ended.')}
                    </p>
                  </div>
                )}
              </div>
              {this.state.isOpen && (
                <DatePicker
                  inline
                  withPortal
                  onClickOutside={this.closeCalendar}
                  onChange={this.handleChangeShippingDate}
                  selected={this.state.shippingDate.toDate()}
                  filterDate={this.filterDate}
                  calendarContainer={DatePickerContainer}
                />
              )}
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

function DatePickerContainer({ className, children }) {
  return (
    <CalendarContainer className={className}>
      <div>{children}</div>
      <div className="date-picker-footer">
        <p>
          <Trans i18nKey="overview_panel_shipping_date_allow_two_to_five">
            Ships within 3 business days of the date you pick. Allow an
            {' '}
            <i>additional</i>
            {' '}
            2-5 business days for SNOO to arrive.
          </Trans>
        </p>
      </div>
    </CalendarContainer>
  );
}

const mapStateToProps = state => ({
  successShippingDateUpdate: apiOrdersSelector.getUpdateOrderShippingDateSuccess(state),
  errorShippingDateUpdate: apiOrdersSelector.getUpdateOrderShippingDateErrors(state),
});

OverviewPanelRetail.propTypes = {
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  shippingDate: PropTypes.string,
  showCancelOrderModal: PropTypes.func,
  showEditAddressModal: PropTypes.func,
  showInitReturnModal: PropTypes.func,
  isReturn: PropTypes.bool,
  currentReturn: PropTypes.object,
  returns: PropTypes.array,
};

export default provideContext(connect(mapStateToProps)(OverviewPanelRetail));
