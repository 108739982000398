import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { provideContext } from '../../../../context/context.jsx';
import DefaultLoader from '../../loaders/defaultLoader/defaultLoader.jsx';

import './defaultButton.scss';

class DefaultButton extends Component {
  render() {
    const { label, isLoading, isBlock, isPrimary, isSecondary, isAuto, isWidth } = this.props;

    return (
      <button
        className={classnames('DefaultButton', {
          'btn-block': isBlock,
          'color-primary': isPrimary,
          'color-secondary': isSecondary,
          'ml-auto': isAuto,
          width: isWidth,
        })}
        disabled={isLoading}
        onClick={event => this.props.onClick(event)}
      >
        {isLoading ? <DefaultLoader size="small" color="white" /> : <span>{label}</span>}
      </button>
    );
  }
}

DefaultButton.defaultProps = {
  isLoading: false,
  isBlock: false,
  isPrimary: false,
  isSecondary: true,
  isAuto: false,
  isWidth: false,
};

DefaultButton.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isBlock: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isAuto: PropTypes.bool,
  isWidth: PropTypes.bool,
};

export default provideContext(DefaultButton);
