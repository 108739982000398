import { createSelector } from 'reselect';
import { ActionTypes } from './apiOrdersActions';

import {
  getAllErrorsFromState,
  getAllLoadersFromState,
  getAllSuccessesFromState,
  getErrorStatus,
  getLoaderStatus, getSuccessStatus
} from "../apiUtils/apiUtils";

export const getOrders = state => state.apiOrders.orders;


export const isLoadingGetOrders = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_GET_ORDERS_REQUEST),
);


export const isLoadingCancelOrder = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_CANCEL_ORDER_REQUEST),
);

export const isLoadingUpdateOrderShippingDate = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_REQUEST),
);

export const isLoadingUpdateOrderShippingAddress = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_REQUEST),
);

export const getCancelOrderErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_CANCEL_ORDER_FAILURE),
);


export const getCancelOrderSuccess = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_CANCEL_ORDER_SUCCESS),
);

export const getUpdateOrderShippingAddressErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_FAILURE),
);

export const getUpdateOrderShippingAddressSuccess = state => state.apiOrders.successEditOrderAddress;

export const getUpdateOrderShippingDateErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_FAILURE),
);

export const getUpdateOrderShippingDateSuccess = createSelector(
  getAllLoadersFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_SUCCESS),
);

export const getDevices = state => state.apiOrders.devices;

export const isLoadingGetDevices = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_GET_DEVICES_REQUEST),
);

