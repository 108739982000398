import { takeEvery, call, put } from 'redux-saga/effects';

import {
  getLoggedInUser,
  updateUser,
  getCorporateBenefits,
  checkLegal,
  acknowledgeLegal
} from './api';


import {
  ActionTypes,
  apiGetUserMeSuccessAction,
  apiGetUserMeFailureAction,
  apiUpdateUserSuccessAction,
  apiUpdateUserFailureAction,
  apiGetCorporateBenefitsSuccessAction,
  apiGetCorporateBenefitsFailureAction,
  apiCheckLegalSuccessAction,
  apiCheckLegalFailureAction,
  apiAcknowledgeLegalSuccessAction,
  apiAcknowledgeLegalFailureAction
} from './apiUsersActions';


export function* apiGetLoggedInUser(action) {
  const { response, error } = yield call(getLoggedInUser, action.payload);
  if (response) {
    yield put(apiGetUserMeSuccessAction(response.data));
  } else {
    yield put(apiGetUserMeFailureAction(error.response.data));
  }
}

export function* apiGetCorporateBenefits(action) {
  const { response, error } = yield call(getCorporateBenefits, action.payload)
  if (response) {
    yield put(apiGetCorporateBenefitsSuccessAction(response.data))
  } else {
    yield put(apiGetCorporateBenefitsFailureAction(error.response.data))
  }
}

export function* apiUpdateUser(action) {
  const { response, error } = yield call(updateUser, action.payload);

  if (response) {
    yield put(apiUpdateUserSuccessAction(response.data))
  } else {
    yield put(apiUpdateUserFailureAction(error))
  }
}

export function* apiCheckLegal() {
  const { response, error } = yield call(checkLegal);
  if (response) {
    yield put(apiCheckLegalSuccessAction(response.data));
  } else {
    yield put(apiCheckLegalFailureAction(error));
  }
}

export function* apiAcknowledgeLegal(action) {
  const { response, error } = yield call(acknowledgeLegal, action.payload);
  if (response) {
    yield put(apiAcknowledgeLegalSuccessAction(response.data));
  } else {
    yield put(apiAcknowledgeLegalFailureAction(error))
  }
}

export default function* apiUsersSaga() {
  yield takeEvery(ActionTypes.API_GET_USER_ME_REQUEST, apiGetLoggedInUser);
  yield takeEvery(ActionTypes.API_UPDATE_USER_REQUEST, apiUpdateUser);
  yield takeEvery(ActionTypes.API_GET_CORPORATE_BENEFITS_REQUEST, apiGetCorporateBenefits)
  yield takeEvery(ActionTypes.API_USERS_LEGAL_CHECK_REQUEST, apiCheckLegal);
  yield takeEvery(ActionTypes.API_USERS_LEGAL_ACKNOWLEDGE_REQUEST, apiAcknowledgeLegal)
}
