import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import { provideContext } from '../../../../context/context.jsx';
import ShippingTimeline from '../../../../components/views/presentation/shippingTimeline/shippingTimeline.jsx';

import snooBoxInactiveIcon from '../../../../assets/icons/snoo-box-inactive.svg';
import subscriptionStopedIcon from '../../../../assets/icons/subscription-stoped-active.svg';
import OverviewPanelRetail from "../../presentation/overviewPanelRetail/overviewPanelRetail";
import orderStatuses from '../../../../enums/orderStatuses';

class RetailOrderAccountOverview extends PureComponent {
  getDataForOverviewPanel(order) {
    const { t } = this.props;
    return {
      title: t('cancel_account_overview_order_cancelled_success', 'Your order has been canceled'),
      iconName: subscriptionStopedIcon,
      showButton: false,
      showShippingDate: false,
      editShippingDate: false,
      itemData: [
        {
          title: t('order_id', 'Order ID'),
          value: t('order_account_overview_order_id', { shopifyOrderName: order.name}),
        },
      ],
    };
  }

  renderOverview(order, userData) {
    const { t, context } = this.props;
    const canceledAt = order.statusHistory.find(history => history.status === orderStatuses.CANCELED);
    const headerData = [
      {
        className: 'order active',
        title: t('order_account_overview_order_id', { shopifyOrderName: order.name}),
      },
    ];

    const orderData = [
      {
        active: false,
        iconActive: snooBoxInactiveIcon,
        iconInactive: snooBoxInactiveIcon,
        title: t('order_placed', 'Order placed'),
        date: <Moment format={context.apiConfig.date.format}>{order.createdAt}</Moment>,
      },
      {
        active: true,
        iconActive: subscriptionStopedIcon,
        iconInactive: subscriptionStopedIcon,
        title: t('cancel_account_overview_order_cancelled', 'Order canceled'),
        date: <Moment format={context.apiConfig.date.format}>{canceledAt.date}</Moment>,
      },
    ];
    const overviewData = this.getDataForOverviewPanel(order);
    return (
      <React.Fragment key={order.id}>
        <div className="row">
          <div className="col-1">
            <ShippingTimeline headerData={headerData} bodyData={orderData} rentalPeriodStarted={false} />
          </div>
          <div className="col-1">
            <OverviewPanelRetail userData={userData} {...overviewData} order={order} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { t, userData, order } = this.props;
    return this.renderOverview(order, userData);
  }
}

RetailOrderAccountOverview.defaultProps = {
  userData: {},
  rental: {},
};

RetailOrderAccountOverview.propTypes = {
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired,
};

export default provideContext(RetailOrderAccountOverview);
