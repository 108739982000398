import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Currency from '../../formats/currencyFormat/currency.jsx';

import { provideContext } from '../../../../context/context.jsx';
import './giftCardForm.scss';
import Message from '../../presentation/message/message.jsx';
import Button from '../../button/button.jsx';
import Input from '../../input/input.jsx';

import giftCardActive from '../../../../assets/icons/gift-card-active.svg';

class GiftCardForm extends Component {
  state = {
    accountBalance: this.props.accountBalance,
    giftCard: '',
    invalidGiftCard: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.accountBalance !== state.accountBalance) {
      return {
        accountBalance: props.accountBalance,
        giftCard: '',
      };
    }
    return null;
  }

  onChangeForm = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  renderError() {
    return (
      <div className="">
        <Message type="error">{this.props.error.message}</Message>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className="">
        <Message type="success">{this.props.t('gift_card_form_success_message', 'Gift card has been applied')}</Message>
      </div>
    );
  }

  applyGiftCard = async event => {
    if (event) {
      event.preventDefault();
    }
    if (isEmpty(this.state.giftCard) || (this.state.giftCard.length < 8 || this.state.giftCard.length > 20)) {
      this.setState({ invalidGiftCard: true });
      return;
    } else {
      this.setState({ invalidGiftCard: false });
    }
    const giftCard = this.state.giftCard;
    this.formRef.reset();
    this.props.apiRentalApplyGiftCardCode(giftCard);
    this.props.apiPaymentGetCustomerAccountBalance();
    this.props.apiRentalGetRenterAppliedGiftCards();
    this.forceUpdate();
  };

  render() {
    const { t, accountBalance, context } = this.props;
    return (
      <div className="GiftCardForm">
        <div className="balance-form-inner">
          <div className="inner-cenrer">
            <div className="icon-wrap">
              <div className="icon">
                <img src={giftCardActive} />
              </div>
              <div className="label">
                <p>{t('gift_card_form_balance', 'You have')}</p>
                <div className="balance-label">
                  <Currency value={accountBalance} context={context} divider={1} />
                </div>
                <p>{t('gift_card_form_balance_account', 'on your account balance')}</p>
              </div>
            </div>
            <div className="message-wrap">
              {!isEmpty(this.props.error) && this.renderError()}
              {!isEmpty(this.props.success) && this.renderSuccess()}
            </div>
            <form className="form" onChange={event => this.onChangeForm(event)} ref={fr => (this.formRef = fr)}>
              <div className="input-wrap">
                <Input
                  id="giftCard"
                  placeholder={t('gift_card_form_code', 'Gift card code')}
                  type="text"
                  name="giftCard"
                  autoComplete="off"
                  error={this.state.invalidGiftCard}
                />
              </div>
              <div className="button-wrap">
                <Button color={'green'} onClick={this.applyGiftCard} center={true}>
                  {t('button_text_apply', 'Apply')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

GiftCardForm.propTypes = {
  t: PropTypes.func.isRequired,
  accountBalance: PropTypes.number,
  apiRentalApplyGiftCardCode: PropTypes.func.isRequired,
  apiRentalGetRenterAppliedGiftCards: PropTypes.func.isRequired,
  apiPaymentGetCustomerAccountBalance: PropTypes.func.isRequired,
  isLoadingGiftCardApplied: PropTypes.bool,
  error: PropTypes.object,
  success: PropTypes.object,
  context: PropTypes.object.isRequired,
};

export default provideContext(GiftCardForm);
