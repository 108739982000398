import React, { PureComponent } from 'react';
import { Trans } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { provideContext } from '../context/context.jsx';
import {
  apiGetShippingCountriesRequestAction,
  apiGetShippingProvincesRequestAction
} from '../redux-api/apiShipping/apiShippingActions';
import { apiGetDevicesRequestAction } from '../redux-api/apiOrders/apiOrdersActions';
import AppContainer from '../containers/appContainer.jsx';
import ScreenTitle from '../components/views/presentation/screenTitle/screenTitle.jsx';
import * as apiShippingSelectors from '../redux-api/apiShipping/apiShippingSelectors.js';
import * as apiOrdersSelector from '../redux-api/apiOrders/apiOrdersSelectors.js';
import ProductSupportModal from '../components/views/modals/productSupportModal/productSupportModal';
import ProductPanel from '../components/views/presentation/productPanel/productPanel';
import DefaultLoader from '../components/views/loaders/defaultLoader/defaultLoader';
import snooDeviceIcon from '../assets/icons/snoo-device-icon.svg';
import { apiGetRenterRentalsRequestAction } from '../redux-api/apiRenter/apiRenterActions';

class DevicesScreen extends PureComponent {
  state = {
    createProductSupportTicketModalVisible: false,
    currentDevice: null,
  };

  componentDidMount() {
    this.props.actions.getCountries();
    this.props.actions.getDevices();
    this.props.actions.getRentals();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.devices.length !== this.props.devices.length) {
      this.setState({
        order: this.props.devices,
      });
    }
  }

  openCreateProductSupportTicketModal = (device) => {
    this.setState({
      createProductSupportTicketModalVisible: true,
      currentDevice: device,
    })
  }

  onCloseCreateProductSupportTicketModal = () => {
    this.setState({
      createProductSupportTicketModalVisible: false,
      currentDevice: null,
    })
  }

  render() {
    const {
      devices,
      countries,
      provinces,
      isLoadingGetProvinces,
      isLoadingGetDevices,
      t,
    } = this.props;

    if (isLoadingGetDevices === true ) {
      return <AppContainer>
        <DefaultLoader type="block" />
      </AppContainer>;
    }

    return (
      <AppContainer>
        <div className="row">
          <div className="col-1">
            <ScreenTitle>{t('devices_page_title')}</ScreenTitle>
          </div>

          {devices.map((device, index) => {
            const headerData = [
              {
                className: 'snoo active',
                title: t('my_devices_page_device_serial', {deviceSerial: device.deviceSerial}),
              },
            ];

            const orderData = [
              {
                active: true,
                iconActive: snooDeviceIcon,
                title: t('snoo_smart_sleeper', 'snoo smart sleeper'),
              }
            ]
           return <ProductPanel key={device.serialNumber || index}  device={device} headerData={headerData} bodyData={orderData} showProductSupportModal={this.openCreateProductSupportTicketModal}/>
          })}
        </div>
        {this.state.createProductSupportTicketModalVisible && (
          <ProductSupportModal
            device={this.state.currentDevice}
            onCloseModal={this.onCloseCreateProductSupportTicketModal}
            countries={countries}
            provinces={provinces}
            isLoadingGetProvinces={isLoadingGetProvinces}
            apiGetProvinces={this.props.actions.getProvinces}
            t={t}
          />
        )}
      </AppContainer>
    );
  }
}

DevicesScreen.defaultProps = {
  isLoadingGetDevices: true,
};

DevicesScreen.propTypes = {
  actions: PropTypes.shape({
    getDevices: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoadingGetDevices: apiOrdersSelector.isLoadingGetDevices(state),
  devices: apiOrdersSelector.getDevices(state),
  countries: apiShippingSelectors.getCountries(state),
  isLoadingGetCountries: apiShippingSelectors.isLoadingGetCountries(state),
  provinces: apiShippingSelectors.getProvinces(state),
  isLoadingGetProvinces: apiShippingSelectors.isLoadingGetProvinces(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators(
    {
      getRentals: apiGetRenterRentalsRequestAction,
      getCountries: apiGetShippingCountriesRequestAction,
      getProvinces: apiGetShippingProvincesRequestAction,
      getDevices: apiGetDevicesRequestAction,
    },
    dispatch,
  );
  return { actions };
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(DevicesScreen));
