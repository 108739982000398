import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import Input from '../../input/input.jsx';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';
import ReferralsTable from '../../tables/referralsTable/referralsTable.jsx';
import './referralsForm.scss';
import referralIcon from '../../../../assets/icons/referral-thumbs.svg';
import Message from '../../presentation/message/message.jsx';
import ReactGA from 'react-ga4';

export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function validateEmail(email) {
  return emailPattern.test(email);
}
class ReferralsForm extends Component {
  state = {
    referrals: [],
    email: '',
  };

  sendReferralInvite = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      return;
    }

    const isRental = this.props.rentals.length > 0;
    this.props.newReferral(this.state.email, isRental);
    ReactGA.event({
      category: 'Button Clicked',
      action: 'referral_sent',
      label: 'Referral has been initiated',
    });
    this.props.getReferrals(isRental);
  };

  onEmailChange = (e) => {
    const { value } = e.target;
    const isValid = validateEmail(value);
    if (!isValid) {
      return;
    }
    this.setState({
      email: value,
    });
  };
  renderErrorMessage(error) {
    if (error.code === 1001) {
      return this.props.t('referrals_form_email_error', 'User email is not valid.');
    }
    if (error.code === 2006) {
      return this.props.t('referrals_form_email_error');
    }
    return error.message;
  }
  renderCurrencyAndAmount() {
    if (process.env.REACT_APP_STRIPE_CURRENCY === 'usd') {
      return `${this.props.context.apiConfig.stripe.currency}${process.env.REACT_APP_REFERRALS_GIFT_CARD_AMOUNT}`;
    }
    return `${process.env.REACT_APP_REFERRALS_GIFT_CARD_AMOUNT}${this.props.context.apiConfig.stripe.currency}`;
  }

  render() {
    const { t, referrals, postReferralSuccess, postReferralError, isLoadingGetRenterReferrals } = this.props;
    return (
      <div className="col-2-3">
        <div className="ReferralsForm">
          <div className="balance-form-inner">
            <div className="inner-cenrer">
              <div className="icon-wrap">
                <div className="icon">
                  <img style={{ width: '60%' }} src={referralIcon} />
                </div>
              </div>
              <div className="label lighter-text">
                <p>
                  {t('referrals_form_promo_message', {
                    defaultValue:
                      'Want some Zzzs...for free? Recommend SNOO to your friends, and for each one who rents SNOO (for 1+ months) using your code, you’ll get a {{amount}} gift card, and they will get {{amount}} off their rental!',
                    amount: this.renderCurrencyAndAmount(),
                  })}
                </p>
                <p>
                  <span className="nav-text">
                    {t('referrals_form_promo_week_text', 'Give a week, get a week!')}
                    <br />
                    {t('referrals_form_promo_week_text_footer', 'Everybody wins with the gift of sleep!')}
                  </span>
                </p>
              </div>
              <div className={'message-wrap' + ((postReferralSuccess || postReferralError) && ' active')}>
                {postReferralSuccess && (
                  <Message type="success">
                    {this.props.t('referrals_form_user_invited_successfully', 'User invited successfully')}
                  </Message>
                )}
                {postReferralError && (
                  <Message type="error">
                    {postReferralError.error && postReferralError.error.message
                      ? this.renderErrorMessage(postReferralError.error)
                      : this.props.t('general_error_unexpected', 'An unexpected error ocurred')}
                  </Message>
                )}
              </div>
              <form className="form" ref={(fr) => (this.formRef = fr)}>
                <div className="input-wrap">
                  <Input
                    placeholder={t('global_email')}
                    labelKey="name"
                    name="email"
                    type="email"
                    onChange={this.onEmailChange}
                  />
                </div>
                <div className="button-wrap">
                  <Button
                    color={'green'}
                    style={{ width: '80%' }}
                    t={t}
                    disabled={!this.state.email.length}
                    onClick={this.sendReferralInvite}
                  >
                    {t('button_text_send', 'Send')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>
          <div className="col-3" style={{ width: '100%', marginTop: '30px' }}>
            <div className="row">
              <div className="col-3" style={{ width: '100%' }}>
                {isLoadingGetRenterReferrals ? (
                  t('referrals_form_loading', 'Loading')
                ) : (
                  <ReferralsTable referrals={referrals} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReferralsForm.defaultProps = {
  isLoadingGetRenterReferrals: true,
};

ReferralsForm.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  isLoadingGetRenterReferrals: PropTypes.bool.isRequired,
  referrals: PropTypes.array.isRequired,
  newReferral: PropTypes.func.isRequired,
  postReferralError: PropTypes.object,
  postReferralSuccess: PropTypes.object,
};

export default provideContext(ReferralsForm);
