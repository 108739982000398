import { createSelector } from 'reselect';
import { ActionTypes } from './apiPaymentsActions';

import {
  getAllLoadersFromState,
  getLoaderStatus,
  getAllErrorsFromState,
  getErrorStatus,
  getAllSuccessesFromState,
  getSuccessStatus,
} from '../apiUtils/apiUtils';

export const getLastManualChargeAttempt = state => state.apiPayments.customer.lastManualChargeAttempt;
export const getCustomer = state => state.apiPayments.customer;
export const getCard = state => state.apiPayments.card;
export const getCustomerAccountBalance = state =>
  state.apiPayments.accountBalance;
export const getCustomerUpcomingInvoices = state =>
  state.apiPayments.upcomingInvoices;

export const isLoadingGetCustomer = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_GET_PAYMENT_CUSTOMER_REQUEST),
);

export const isLoadingGetCustomerCreditCard = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_PAYMENT_CARD_REQUEST),
);

export const isLoadingGetCustomerAccountBalance = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(
      loaders,
      ActionTypes.API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_REQUEST,
    ),
);

export const isLoadingGetCustomerUpcomingInvoices = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(
      loaders,
      ActionTypes.API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_REQUEST,
    ),
);

export const getPaymentCustomerSaveErrorMessage = createSelector(
  getAllErrorsFromState,
  errors =>
    getErrorStatus(errors, ActionTypes.API_POST_PAYMENT_CUSTOMER_FAILURE),
);

export const getPaymentCustomerSaveSuccessMessage = createSelector(
  getAllSuccessesFromState,
  successes =>
    getSuccessStatus(successes, ActionTypes.API_POST_PAYMENT_CUSTOMER_SUCCESS),
);

export const getPaymentSetupIntentCreateErrorMessage = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_PAYMENT_SETUPINTENT_FAILURE)
)

export const getPaymentSetupIntentCreateSuccessMessage = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_POST_PAYMENT_SETUPINTENT_SUCCESS)
)

export const isLoadingCreateSetupIntent = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_POST_PAYMENT_SETUPINTENT_REQUEST),
);

export const getPaymentCardSaveErrorMessage = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_PAYMENT_CARD_FAILURE),
);

export const getPaymentCardSaveSuccessMessage = createSelector(
  getAllSuccessesFromState,
  successes =>
    getSuccessStatus(successes, ActionTypes.API_POST_PAYMENT_CARD_SUCCESS),
);

export const isLoadingReplaceCustomerCreditCard = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_POST_PAYMENT_CARD_REQUEST),
);

export const getPaymentCardUpdateSuccessMessage = createSelector(
  getAllSuccessesFromState,
  successes =>
    getSuccessStatus(successes, ActionTypes.API_PUT_PAYMENT_CARD_SUCCESS),
);

export const getPaymentCardUpdateErrorMessage = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_PUT_PAYMENT_CARD_FAILURE),
);

export const isLoadingUpdateCustomerCreditCard = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_PUT_PAYMENT_CARD_REQUEST),
);

export const isLoadingPostChargePastDueInvoices = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_POST_CHARGE_PAST_DUE_INVOICES_REQUEST),
);

export const getChargePastDueInvoicesSuccess = createSelector(
  getAllSuccessesFromState,
  success => getSuccessStatus(success, ActionTypes.API_POST_CHARGE_PAST_DUE_INVOICES_SUCCESS),
);

export const getChargePastDueInvoicesErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_CHARGE_PAST_DUE_INVOICES_FAILURE),
);