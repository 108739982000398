import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import sumBy from 'lodash/sumBy';
import { provideContext } from '../../../../context/context.jsx';
import Currency from '../../formats/currencyFormat/currency.jsx';
import './giftCardsTable.scss';

class GiftCardsTable extends Component {
  render() {
    const { t, context, giftCards } = this.props;
    return (
      <div className="GiftCardsTable">
        <table className="main-table">
          <thead>
            <tr>
              <th>{t('gift_card_table_number','Gift card no.')}</th>
              <th>{t('gift_card_table_date_applied','Date applied')}</th>
              <th>{t('global_amount','Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {giftCards.map((giftCard, i) => (
              <tr key={i}>
                <td>{'**** '.repeat(3) + giftCard.lastCharacters}</td>
                <td>
                  <Moment format={context.apiConfig.date.format}>{giftCard.createdAt}</Moment>
                </td>
                <td>
                  <Currency value={giftCard.amount} context={context} divider={1} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="total-table">
          <tbody>
            <tr>
              <td>
                <h4>{t('gift_card_total_gift_card_value','Total gift card value')}</h4>
              </td>
              <td className="currency">
                <h4>
                  <Currency value={sumBy(giftCards, item => Number(item.amount))} context={context} divider={1} />
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

GiftCardsTable.propTypes = {
  t: PropTypes.func.isRequired,
  giftCards: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
};

export default provideContext(GiftCardsTable);
