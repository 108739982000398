export const redirectToLogin = (path = '') => {
  const redirectData = { route: getFullRoute(path) };
  localStorage.setItem('redirect', JSON.stringify(redirectData));
  const host = modifyHost(window.location.host);
  window.location.href = `${window.location.protocol}//${host}/auth/login`;
};

export const redirectToLogout = (path = '') => {
  const redirectData = { route: getFullRoute(path) };
  localStorage.setItem('redirect', JSON.stringify(redirectData));
  window.location.href = `${window.location.protocol}//${modifyHost(window.location.host).replace(
    'account.',
    '',
  )}/account/logout`;
};

export const modifyHost = host => {
  if (host.startsWith('snoo-rental')) {
    return host.replace('snoo-rental', 'account');
  }
  return host;
};

const getFullRoute = path => {
  if (process.env.PUBLIC_URL && process.env.PUBLIC_URL !== '.') {
    return `${process.env.PUBLIC_URL}${path}`;
  }
  return path;
};
