import React, { PureComponent } from 'react';
import appRoutes from '../enums/appRoutes';
import { Auth } from 'aws-amplify';
import { redirectToLogin } from '../utils/redirect.js';

class LoginScreen extends PureComponent {
  componentDidMount() {
    this.checkAuthenticatedUser();
  }
  async checkAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        window.location.href = appRoutes.HOME.url;
      }
    } catch (error) {
      // User is not authenticated
      redirectToLogin();
    }
  }

  render() {
    return <div></div>
  }
}

export default LoginScreen;
