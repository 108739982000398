import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';

import './productPanel.scss';
import showMoreInfoIcon from '../../../../assets/icons/show-more.svg';
import DropdownButtons from '../../dropdownButtons/dropdownButtons';

class ProductPanel extends Component {

  renderItems() {
    const { bodyData } = this.props;
    return bodyData.map((item, index) => (
      <div className={`item ${item.active && 'active'}`} key={index}>
        <div className="circle">
          <img src={item.iconInactive} className="inactive" alt="" />
          <img src={item.iconActive} className="active" alt="" />
        </div>
        <div className="label">
          <h4>{item.title}</h4>
          <h5>{item.date}</h5>
        </div>
      </div>
    ));
  }

  renderHeader() {
    const { headerData } = this.props;

    return headerData.map((item, index) => (
      <span key={index} className={item.className} aria-label={item.title}>
        {item.title}
      </span>
    ));
  }

  render() {
    const { t, showProductSupportModal, device } = this.props;

    return (
      <div className="ProductPanel">
        <div className="inner-wrap">
          <div className="heading">
              {this.renderHeader()}
              <DropdownButtons buttonContent={<img src={showMoreInfoIcon} />}>
                <button type="submit" className="form-button" role="menuitem" tabIndex="-1" onClick={() => showProductSupportModal(device)}>
                  Product Support
                </button>
              </DropdownButtons>
          </div>
          <div className="body">
            <div className="circle-center">{this.renderItems()}</div>
            {device && device.orderNumber &&
              <div className="order-number-wrapper">
                <p className="text-primary">FROM ORDER NUMBER</p>
                <p>Order {device.orderNumber}</p>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ProductPanel.propTypes = {
  t: PropTypes.func.isRequired,
  headerData: PropTypes.array.isRequired,
  bodyData: PropTypes.array.isRequired,
  showProductSupportModal: PropTypes.func.isRequired,
};

export default provideContext(ProductPanel);
