import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

export const getCountries = async () => {
    const headers = await constructAuthHeaders();
    try {
        const response = await axios.get(getApiRouteUrl(routes.SHIPPING_GET_COUNTRIES), headers)

        return { response };
    } catch (error) {
        return { error };
    }
}

export const getProvinces = async (id) => {
    const headers = await constructAuthHeaders();
    try {
        const response = await axios.get(getApiRouteUrl(routes.SHIPPING_GET_PROVINCES, { id }), headers)

        return { response };
    } catch (error) {
        return { error };
    }
}
