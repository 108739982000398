import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

export const savePaymentCustomer = async (customer) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.post(
      getApiRouteUrl(routes.PAYMENTS_CUSTOMER_SAVE),
      customer,
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const updatePaymentCustomer = async (data) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.put(
      getApiRouteUrl(routes.PAYMENTS_CUSTOMER_UPDATE),
      data,
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getPaymentCustomer = async (customerId) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.get(
      getApiRouteUrl(routes.PAYMENTS_CUSTOMER_GET, { id: customerId }),
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const createSetupIntent = async () => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.post(
      getApiRouteUrl(routes.PAYMENTS_SETUPINTENT_CREATE),
      undefined,
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const savePaymentCard = async (data) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.post(
      getApiRouteUrl(routes.PAYMENTS_CARD_SAVE),
      data,
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getPaymentCustomerAccountBalance = async (id) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.get(
      getApiRouteUrl(routes.PAYMENTS_CUSTOMER_ACCOUNT_BALANCE, { id }),
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const postPaymentCustomerChargePastDueInvoices = async (id) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.post(
      getApiRouteUrl(routes.PAYMENTS_CHARGE_PAST_DUE_INVOICES, { id }),
      null,
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getPaymentCustomerUpcomingInvoices = async (id) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.get(
      getApiRouteUrl(routes.PAYMENTS_CUSTOMER_UPCOMING_INVOICES, {
        id,
      }),
       headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getPaymentCard = async (id) => {
  const headers = await constructAuthHeaders();
  try {
    const response = await axios.get(
      getApiRouteUrl(routes.PAYMENTS_CARD_GET, { id }),
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};

export const updatePaymentCard = async (data) => {
  const headers = await constructAuthHeaders();
  const { card: id, ...rest } = data;
  try {
    const response = await axios.put(
      getApiRouteUrl(routes.PAYMENTS_CARD_UPDATE, { id }),
      rest,
      headers
    );
    return { response };
  } catch (error) {
    return { error };
  }
};
