import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Currency from '../../formats/currencyFormat/currency.jsx';
import { provideContext } from '../../../../context/context.jsx';
import moment from 'moment';

import BillingHistoryTableRow from './billHistoryTableRow/billHistoryTableRow.jsx';

import './billHistoryTable.scss';

class BillHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: this.props.period,
      periodIndex: this.props.periodIndex,
      collapseRental: false,
    };
  }

  showCurrentPeriod = period => {
    let collapseRental = period === this.state.periodIndex ? !this.state.collapseRental : false;
    this.setState({
      collapseRental: collapseRental,
      periodIndex: period,
    });
  };

  static getDerivedStateFromProps(props, state) {
    return {
      period: props.period,
    };
  }

  render() {
    const { t, totalDebt } = this.props;
    const { periodIndex } = this.state;

    const selectedPeriod = period => period === periodIndex;
    const periodNotPaid = ({ upcoming, paid }) => !paid && !upcoming;
    const periodLabel = (periods, current) => {
      const currentPeriod = Math.max.apply(
        Math,
        periods.filter(({ upcoming }) => !upcoming).map(p => p.period),
      );
      if (currentPeriod === current.period) {
        return t('current_amount_due_text');
      } else if (current.upcoming) {
        return t('bill_history_table_upcoming', 'Upcoming activity');
      }
      return t('bill_history_table_previos_activity', 'Previous activity');
    };
    const amountClassResolver = current => {
      if (current.upcoming) return 'upcoming-amount';
      else if (periodNotPaid(current)) return 'past-due';
      return '';
    };


    const mapToRow = periods =>
      periods.map((p, idx) => (
        <React.Fragment key={idx}>
          <tr className="head" onClick={() => this.showCurrentPeriod(p.period)}>
            <td
              className={classnames('arrow', {
                close: selectedPeriod(p.period) ? this.state.collapseRental : true,
              })}
            />
            <td>{periodLabel(periods, p)}</td>
            <td>{p.periodName}</td>
            <td className="amount">
              <div className={amountClassResolver(p)}>
                <Currency value={p.total * 100} didvider={1} />
                {periodNotPaid(p) && (
                  <span className="past-due-text">{t('bill_history_past_due_info', '(past due)')}</span>
                )}
              </div>
            </td>
          </tr>
          {selectedPeriod(p.period) && (
            <BillingHistoryTableRow
              periodItems={p.items}
              period={p.periodName}
              collapseRental={this.state.collapseRental}
            />
          )}
        </React.Fragment>
      ));

    return (
      <div className="BillHistoryTable">
        <table className="bill-table">
          <thead>
            <tr className="">
              <th />
              <th className="header-description">{t('bill_history_description', 'Description')}</th>
              <th className="header-period">{t('bill_history_period', 'Period')}</th>
              <th className="header-amount">{t('global_amount', 'Amount')}</th>
            </tr>
          </thead>
          <tbody>{mapToRow(this.props.periods)}</tbody>
        </table>

        <table className="total-table">
          <tbody>
            <tr>
              <td>
                <h5>{t('total_due_text')}</h5>
                <p>
                  {t('bill_history_on', 'On')} {moment().format('MMM DD')}
                </p>
              </td>
              <td className="amount">
                <div>
                  <Currency value={totalDebt.debt / 100} divider={1} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

BillHistoryTable.defaultProps = {
  period: '1',
};

BillHistoryTable.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired,
  periodIndex: PropTypes.number.isRequired,
  periods: PropTypes.array.isRequired,
  periodTotal: PropTypes.string,
  totalDebt: PropTypes.object,
};

export default provideContext(BillHistoryTable);
