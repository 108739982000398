import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import dateFnsFormat from 'date-fns/format';
import isDate from 'lodash/isDate';
import constant from 'lodash/constant';

import TRANSLATIONS_DE from '../../translations/de/translation.json';
import TRANSLATIONS_EN from '../../translations/en/translation.json';
import TRANSLATIONS_NL from '../../translations/nl/translation.json';
import TRANSLATIONS_EN_AU from '../../translations/en_AU/translation.json';
import TRANSLATIONS_EN_GB from '../../translations/en_GB/translation.json';
import TRANSLATIONS_FR from '../../translations/fr/translation.json';
import TRANSLATIONS_FR_CA from '../../translations/fr_CA/translation.json';
import TRANSLATIONS_FR_FR from '../../translations/fr_FR/translation.json';
import TRANSLATIONS_IT from '../../translations/it/translation.json';
import TRANSLATIONS_ES from '../../translations/es/translation.json';
import TRANSLATIONS_SV from '../../translations/sv/translation.json';

const detectionOptions = {
  order: ['localStorage'],
};
i18next
  .use(initReactI18next)
  .use(intervalPlural)
  .init(
    {
      keySeparator: false,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
        format: (value, format) => {
          if (isDate(value)) {
            return dateFnsFormat(value, format);
          }
          return value;
        },
      },
      fallbackLng: 'en',
      react: {
        wait: true,
        bindI18n: 'loaded languageChanged',
        useSuspense: false,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      },
      resources: {
        en: {
          translation: TRANSLATIONS_EN,
        },
        en_AU: {
          translation: TRANSLATIONS_EN_AU,
        },
        en_GB: {
          translation: TRANSLATIONS_EN_GB,
        },
        de: {
          translation: TRANSLATIONS_DE,
        },
        nl: {
          translation: TRANSLATIONS_NL,
        },
        fr: {
          translation: TRANSLATIONS_FR,
        },
        fr_CA: {
          translation: TRANSLATIONS_FR_CA,
        },
        // fr_FR: {
        //   translation: TRANSLATIONS_FR_FR,
        // },
        it: {
          translation: TRANSLATIONS_IT,
        },
        es: {
          translation: TRANSLATIONS_ES,
        },
        sv: {
          translation: TRANSLATIONS_SV,
        },
      },
    },
    () => constant(),
  );

i18next.use(LanguageDetector).init({
  detection: detectionOptions,
});
export default i18next;
