export const ActionTypes = {
  API_GET_USER_ME_REQUEST: 'API_GET_USER_ME_REQUEST',
  API_GET_USER_ME_SUCCESS: 'API_GET_USER_ME_SUCCESS',
  API_GET_USER_ME_FAILURE: 'API_GET_USER_ME_FAILURE',
  API_UPDATE_USER_REQUEST: 'API_UPDATE_USER_REQUEST',
  API_UPDATE_USER_SUCCESS: 'API_UPDATE_USER_SUCCESS',
  API_UPDATE_USER_FAILURE: 'API_UPDATE_USER_FAILURE',
  API_GET_CORPORATE_BENEFITS_REQUEST: 'API_GET_CORPORATE_BENEFITS_REQUEST',
  API_GET_CORPORATE_BENEFITS_SUCCESS: 'API_GET_CORPORATE_BENEFITS_SUCCESS',
  API_GET_CORPORATE_BENEFITS_FAILURE: 'API_GET_CORPORATE_BENEFITS_FAILURE',
  API_USERS_LEGAL_CHECK_REQUEST: 'API_USERS_LEGAL_CHECK_REQUEST',
  API_USERS_LEGAL_CHECK_SUCCESS: 'API_USERS_LEGAL_CHECK_SUCCESS',
  API_USERS_LEGAL_CHECK_FAILURE: 'API_USERS_LEGAL_CHECK_FAILURE',
  API_USERS_LEGAL_ACKNOWLEDGE_REQUEST: 'API_USERS_LEGAL_ACKNOWLEDGE_REQUEST',
  API_USERS_LEGAL_ACKNOWLEDGE_SUCCESS: 'API_USERS_LEGAL_ACKNOWLEDGE_SUCCESS',
  API_USERS_LEGAL_ACKNOWLEDGE_FAILURE: 'API_USERS_LEGAL_ACKNOWLEDGE_FAILURE',
};

export const apiGetCorporateBenefitsRequestAction = () => ({
  type: ActionTypes.API_GET_CORPORATE_BENEFITS_REQUEST,
  payload: {},
})
export const apiGetCorporateBenefitsSuccessAction = benefits => ({
  type: ActionTypes.API_GET_CORPORATE_BENEFITS_SUCCESS,
  payload: benefits,
})
export const apiGetCorporateBenefitsFailureAction = error => ({
  type: ActionTypes.API_GET_CORPORATE_BENEFITS_FAILURE,
  payload: error,
})
export const apiGetUserMeRequestAction = () => ({
  type: ActionTypes.API_GET_USER_ME_REQUEST,
  payload: {},
});

export const apiGetUserMeSuccessAction = user => ({
  type: ActionTypes.API_GET_USER_ME_SUCCESS,
  payload: user,
});

export const apiGetUserMeFailureAction = error => ({
  type: ActionTypes.API_GET_USER_ME_FAILURE,
  payload: error,
});


export const apiUpdateUserRequestAction = data => ({
  type: ActionTypes.API_UPDATE_USER_REQUEST,
  payload: data,
});

export const apiUpdateUserSuccessAction = user => ({
  type: ActionTypes.API_UPDATE_USER_SUCCESS,
  payload: user,
});

export const apiUpdateUserFailureAction = error => ({
  type: ActionTypes.API_UPDATE_USER_FAILURE,
  payload: error,
});

export const apiCheckLegalRequestAction = data => ({
  type: ActionTypes.API_USERS_LEGAL_CHECK_REQUEST,
  payload: data,
});

export const apiCheckLegalSuccessAction = data => ({
  type: ActionTypes.API_USERS_LEGAL_CHECK_SUCCESS,
  payload: data,
});

export const apiCheckLegalFailureAction = error => ({
  type: ActionTypes.API_USERS_LEGAL_CHECK_FAILURE,
  payload: error,
});

export const apiAcknowledgeLegalRequestAction = data => ({
  type: ActionTypes.API_USERS_LEGAL_ACKNOWLEDGE_REQUEST,
  payload: data,
});

export const apiAcknowledgeLegalSuccessAction = () => ({
  type: ActionTypes.API_USERS_LEGAL_ACKNOWLEDGE_SUCCESS,
  payload: {},
});

export const apiAcknowledgeLegalFailureAction = error => ({
  type: ActionTypes.API_USERS_LEGAL_ACKNOWLEDGE_FAILURE,
  payload: error,
});
