import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';

import './requestDataExportModal.scss';
import { createDataExportRequest } from '../../../../redux-api/apiUsers/api';
import Message from '../../presentation/message/message';

import ReactGA from 'react-ga4';

class RequestDataExportModal extends Component {
  state = {
    isSubmitSuccess: false,
    hasError: false,
    loading: false,
  };
  renderError(error) {
    return <Message type="error">{error}</Message>;
  }

  handleSubmit = async () => {
    try {
      this.setState({ loading: true });
      const response = await createDataExportRequest();
      ReactGA.event({
        category: 'Button Clicked',
        action: 'user_data_export_requested',
        label: 'User Data Export has been requested',
      });
      if (response && response.err) {
        if (response.err.response && response.err.response.status !== 429) {
          throw response.err;
        }
      }
      this.setState({ isSubmitSuccess: true, hasError: false });
      const { onCloseModal, onRequestSent } = this.props;
      onCloseModal();
      onRequestSent();

    } catch (error) {
      console.error(error);
      this.setState({ hasError: true });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { t, onCloseModal } = this.props;
    return (
      <div className="RequestDataExportModal">
        <div className="modal-center" onClick={(e) => e.stopPropagation()}>
            <>
              <h2>{t('data_export_popup_title', 'Request Your Data Export?')}</h2>
              <div className="inner-modal-center">
                <div className="modal-text">
                  <p>
                    {t(
                      'data_export_popup_text',
                      'After your data export process is complete, we will email you at the address linked to your account. You can obtain your data exported once every 30 days, regardless of the number of requests made.',
                    )}
                  </p>
                  {this.state.hasError && this.renderError(t('general_error_unexpected'))}
                </div>
                <div className="button-wrap">
                  <Button onClick={onCloseModal} color={'green-inverted'}>
                    {t('button_text_cancel')}
                  </Button>
                  <Button color={'green'} onClick={() => this.handleSubmit()}>
                    {this.state.loading
                      ? t('button_text_loading', 'Loading...')
                      : t('button_text_continue', 'Continue')}
                  </Button>
                </div>
              </div>
            </>
        </div>
      </div>
    );
  }
}

RequestDataExportModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRequestSent: PropTypes.func.isRequired,
};

export default provideContext(RequestDataExportModal);
