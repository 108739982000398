import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { provideContext } from '../../../context/context.jsx';

import './select.scss';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      focusedBtn: null
    };
    this.close = this.close.bind(this);
  }

  componentDidUpdate() {
    const { listOpen } = this.state;

    setTimeout(() => {
      if (listOpen) {
        const listElements = document.querySelectorAll('ul.select-list li[tabIndex="0"]');
        if (listElements.length > 0) {
          // set the focus on the first element of the list
          listElements[0].focus();
        }
        window.addEventListener('click', this.close);
      } else {
        window.removeEventListener('click', this.close);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  close(timeOut) {
    this.setState({
      listOpen: false,
    });
  }
  selectItem(item, index) {
    this.setState({
      headerTitle: item[this.props.labelKey] || item,
      listOpen: false,
    });
    this.props.onChange(this.props.name, index, item);
  }

  toggleList() {
    const { disabled } = this.props;
    if (!disabled) {
      this.setState(prevState => ({
        listOpen: !prevState.listOpen,
      }));
    }
  }

  static getDerivedStateFromProps(props) {
    const { defaultValue, list, label } = props;
    if (defaultValue !== undefined && list[defaultValue] && list.length) {
      return {
        headerTitle: list[defaultValue][label],
      };
    }
    return null;
  }

  handleButtonFocus = () => {
    if (!this.state.listOpen) {
      this.toggleList();
    }
  }

  handleKeyDown = (event, item, index) => {
    if (event.key === 'Enter') {
      this.selectItem(item, index);
    }
    if (event.key === 'ArrowDown' && event.target.nextSibling) {
      event.target.nextSibling.focus();
    }
    if (event.key === 'ArrowUp' && event.target.previousSibling) {
      event.target.previousSibling.focus();
    }
    if (event.key === 'Escape') {
      this.close()
      if (this.state.focusedBtn) {
        this.state.focusedBtn.focus();
      }
    }
  }
  handleListItemFocus = (e) => {
    e.target.classList.add('selected');
  }
  handleListItemBlur = (e) => {
    e.target.classList.remove('selected');
  }
  handleButtonFocus = (e) => {
    this.setState({
      focusedBtn: e.target
    });
  }
  render() {
    const { list, labelKey, placeholder, defaultValue, disabled, selectListStyle, style} = this.props;
    const { listOpen, headerTitle } = this.state;
    return (
      <div className="Select" style={style} >
        <button type="button" onFocus={this.handleButtonFocus} tabIndex="0" onClick={() => this.toggleList()} aria-expanded={listOpen ? 'true' : 'false'} className={classnames('select-header', { disabled: disabled })} >
          <div
            className={classnames('placeholder', {
              'large-placeholder': defaultValue == undefined && headerTitle == undefined,
            })}
            id={placeholder}
          >
            {placeholder}
          </div>
          <div className="header-title">
            {headerTitle ? headerTitle : list[defaultValue] ? list[defaultValue][labelKey] : []}
          </div>
          {listOpen ? <i className="angle-down" /> : <i className="angle-up" />}
        </button>
        {listOpen && (
          <ul className="select-list"  onClick={e => e.stopPropagation()} aria-labelledby={placeholder} style={selectListStyle}>
            {list.map((item, index) => (
              <li
                className={'select-list-item'}
                key={item.key ? item.key : index}
                onClick={() => this.selectItem(item, index)}
                onFocus={this.handleListItemFocus}
                onBlur = {this.handleListItemBlur}
                onKeyDown = {(e) => this.handleKeyDown(e, item, index)}
                tabIndex={0}
              >
                {item[labelKey] || item}
              </li>
            ))}
          </ul>
        )}
      </div>

    );
  }
}

Select.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.number,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  selectListStyle: PropTypes.object,
  style: PropTypes.object,
};

export default provideContext(Select);
