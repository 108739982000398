import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './dropdownButtons.scss'

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setDropdownRef = (node) => {
    this.dropdownRef = node;
  };

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({ showOptions: false });
    }
  };

  toggleOptions = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      showOptions: !prevState.showOptions,
    }));
  };

  render() {
    const { fromProfile } = this.props;
    return (
      <div className="dropdown-button-container" ref={this.setDropdownRef}>
        <div className="button" id="menu-button" onClick={(e) => this.toggleOptions(e)}>
          {this.props.buttonContent}
        </div>
        {this.state.showOptions && (
          <div className={`dropdown${fromProfile? "_profile" : ""}`} role="menu" aria-labelledby="menu-button" tabIndex="-1">
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  fromProfile: PropTypes.bool,
};

export default Dropdown;
