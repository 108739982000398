import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { provideContext } from '../context/context.jsx';
import AppContainer from '../containers/appContainer.jsx';
import DefaultLoader from '../components/views/loaders/defaultLoader/defaultLoader.jsx';
import ProfileForm from '../components/views/forms/profileForm/profileForm.jsx';
import AddressForm from '../components/views/forms/addressForm/addressForm.jsx';
import ChangePasswordForm from '../components/views/forms/changePasswordForm/changePasswordForm.jsx';
import ScreenTitle from '../components/views/presentation/screenTitle/screenTitle.jsx';
import ScreenSubTitle from '../components/views/presentation/screenSubTitle/screenSubTitle.jsx';
import * as apiUsersSelectors from '../redux-api/apiUsers/apiUsersSelectors.js';
import * as apiRenterSelectors from '../redux-api/apiRenter/apiRenterSelectors.js';
import * as apiRentalSelectors from '../redux-api/apiRental/apiRentalSelectors.js';
import * as apiShippingSelectors from '../redux-api/apiShipping/apiShippingSelectors.js';

import addressType from '../enums/addressType.js';

import {
  apiGetRenterBillingAddressRequestAction,
  apiGetRenterRentalsRequestAction,
  apiPutUpdateRenterRequestAction,
  apiPostChangePasswordRequestAction,
  apiPutUpdateRenterBillingAddressRequestAction,
} from '../redux-api/apiRenter/apiRenterActions.js';

import { apiGetPaymentCustomerUpcomingInvoicesRequestAction } from '../redux-api/apiPayments/apiPaymentsActions.js';

import {
  apiGetShippingCountriesRequestAction,
  apiGetShippingProvincesRequestAction,
} from '../redux-api/apiShipping/apiShippingActions.js';
import { apiGetUserMeRequestAction, apiUpdateUserRequestAction } from '../redux-api/apiUsers/apiUsersActions';
import RequestAccountDeletionModal
  from '../components/views/modals/requestAccountDeletionModal/requestAccountDeletionModal';
import RequestDataExportModal from "../components/views/modals/requestDataExportModal/requestDataExportModal";
import showMoreInfoIcon from "../assets/icons/show-more.svg";
import DropdownButtons from "../components/views/dropdownButtons/dropdownButtons";
import RequestDataExportSuccessModal
  from "../components/views/modals/requestDataExportSuccessModal/requestDataExportSuccessModal";

class ProfileScreen extends PureComponent {
  state = {
    hideMultipleAddressBilling: true,
    usedOneMultipleAddressChange: false,
    lastRental: null,
    showDeleteAccountModal: false,
    showDataExportModal: false,
    showDataExportSuccessModal: false,
    showDropDownButtons: false,
  };

  componentWillMount() {
    this.props.actions.getRentals();
    this.props.actions.getBillingAddress();
    this.props.actions.getCountries();
    this.props.actions.getUser();
  }

  componentDidUpdate() {
    let { rentals } = this.props;
    this.setState({
      lastRental: rentals
        .filter(r => !!r.active)
        .reduce((max, r) => (max && moment(max.createdAt) > moment(r.createdAt) ? max : r), null),
    });
  }

  renderProfileForm() {
    const { renter, renterSuccess, renterError, user, updateUserError, updateUserSuccess } = this.props;
    if(!Object.keys(renter).length) {
      const profile = {
        givenName: user.givenName,
        surname: user.surname,
        email: user.email,
      }
      return <ProfileForm
        profile={profile}
        emailMarketing={false}
        apiSaveProfile={(profile, phone) => {
          this.props.actions.updateUser({ ...profile, phone });
        }
        }
        renterSuccess={updateUserSuccess}
        showMarketing={false}
        renterError={updateUserError}
      />
    }

    return (
      <ProfileForm
        profile={renter.profile}
        phone={renter.phone}
        emailMarketing={renter.emailMarketing}
        apiSaveProfile={(profile, phone, emailMarketing) =>
          this.props.actions.updateRenter({ profile, phone, emailMarketing })
        }
        renterSuccess={renterSuccess}
        showMarketing={true}
        renterError={renterError}
      />
    );
  }

  renderBillingAddressForm() {
    const {
      billingAddress,
      t,
      countries,
      provinces,
      user,
      billingAddressError,
      billingAddressSuccess,
      isLoadingGetProvinces,
    } = this.props;
    return (
      <AddressForm
        user={user}
        title={t('profile_screen_billing_address','Billing Address')}
        submitText={t('button_text_save_changes','Save Changes')}
        address={{ ...billingAddress }}
        billingAddressError={billingAddressError}
        billingAddressSuccess={billingAddressSuccess}
        countries={countries}
        provinces={provinces}
        type={addressType.BILLING}
        isLoadingGetProvinces={isLoadingGetProvinces}
        apiGetProvinces={this.props.actions.getProvinces}
        apiSaveAddress={address => this.props.actions.updateRenterBillingAddress(address)}
      />
    );
  }

  renderChangePasswordForm() {
    const { t, changePasswordError, changePasswordSuccess } = this.props;
    return (
      <ChangePasswordForm
        changePasswordError={changePasswordError}
        changePasswordSuccess={changePasswordSuccess}
        isLoadingChangePassword={this.props.isLoadingChangePassword}
        apiSubmitPassword={passwordData => this.props.actions.changePassword(passwordData)}
      />
    );
  }

  render() {
    const {
      isLoadingGetRenter,
      isLoadingGetRenterRentals,
      isLoadingGetRenterBillingAddress,
      isLoadingGetCountries,
      isLoadingGetUser,
      t,
    } = this.props;
    if (
      !isLoadingGetCountries &&
      !isLoadingGetRenter &&
      !isLoadingGetRenterBillingAddress &&
      !isLoadingGetRenterRentals &&
      !isLoadingGetUser
    ) {
      return (
        <AppContainer paddingBottom={true}>
          <div className="row">
            <div className="col-1">
              <div className="row">
                <div className="col-1">
                  <ScreenTitle>{t('menu_edit_profile', 'Edit profile')}</ScreenTitle>
                </div>
                <DropdownButtons buttonContent={<img src={showMoreInfoIcon}/>} fromProfile>
                  <button className="form-button"  style={{fontSize:"15px"}}role="menuitem" tabIndex="-1"
                          onClick={() => this.setState({ showDataExportModal: true })}>{t('data_export_button')}</button>
                  <div style={{ borderBottom: '1px solid lightgray', width: '100%' }}></div>
                  <button style={{
                    background: "white",
                    color: "#C2413E",
                    fontSize: "15px",
                  }} className="form-button" role="menuitem" tabIndex="-1"
                          onClick={() => this.setState({ showDeleteAccountModal: true })}>{t('profile_data_deletion_button')}</button>
                </DropdownButtons>
              </div>
            </div>
            <div className="col-1-2">
              <div className="row">
                <div className="col-1">
                  <ScreenSubTitle>{t('edit_profile_account_info', 'Account information')}</ScreenSubTitle>
                </div>
              </div>
              <div className="row">
                <div className="col-1">{!isLoadingGetRenter && this.renderProfileForm()}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-1-2">
            <div className="row">
                <div className="col-1">
                  <ScreenSubTitle>{t('button_text_change_password', 'Change password')}</ScreenSubTitle>
                </div>
              </div>
              <div className="row">
                <div className="col-1">{this.renderChangePasswordForm()}</div>
              </div>
            </div>
          </div>
          { this.props.rentals.length && Object.keys(this.props.billingAddress).length  ?
            <div className="row">
              <div className="col-1-2">
                <div className="row">
                  <div className="col-1">
                    <ScreenSubTitle>{t('profile_screen_billing_address','Billing Address')}</ScreenSubTitle>
                  </div>
                </div>

                  <div className='row'>
                    <div className='col-1'>{this.renderBillingAddressForm()}</div>
                  </div>
              </div>
            </div> : null }
          {this.state.showDeleteAccountModal && (<div>
              <RequestAccountDeletionModal onCloseModal={() => this.setState({ showDeleteAccountModal: false })}/>
            </div>
          )}
          {this.state.showDataExportModal && (<div>
              <RequestDataExportModal onCloseModal={() => this.setState({ showDataExportModal: false })} onRequestSent={() => {
                this.setState({ showDataExportModal: false, showDataExportSuccessModal: true })
              }}/>
            </div>
          )}
          {this.state.showDataExportSuccessModal && (<div>
              <RequestDataExportSuccessModal onCloseModal={() => this.setState({ showDataExportSuccessModal: false })}/>
            </div>
          )}
        </AppContainer>
      );
    } else {
      return (
        <AppContainer>
          <DefaultLoader type="block"/>
        </AppContainer>
      );
    }
  }
}

ProfileForm.defaultProps = {
  isLoadingGetRenter: true,
  isLoadingGetRenterRentals: true,
  isLoadingGetRenterBillingAddress: true,
  isLoadingGetCountries: true,
  isLoadingGetProvinces: true,
  isLoadingGetUser: true,
};

ProfileScreen.propTypes = {
  actions: PropTypes.shape({
    getBillingAddress: PropTypes.func,
    getUpcomingInvoices: PropTypes.func,
    getCountries: PropTypes.func,
    getProvinces: PropTypes.func,
    updateUser: PropTypes.func,
    getUser: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: apiUsersSelectors.getUser(state),
  isLoading: apiUsersSelectors.isLoadingGetMe(state),
  renter: apiRentalSelectors.getRenter(state),
  rentals: apiRenterSelectors.getRentals(state),
  billingAddress: apiRenterSelectors.getRenterBillingAddress(state),
  isLoadingGetRenter: apiRentalSelectors.isLoadingGetRenter(state),
  isLoadingGetRenterBillingAddress: apiRenterSelectors.isLoadingGetRenterBillingAddress(state),
  isLoadingGetRenterRentals: apiRenterSelectors.isLoadingGetRenterRentals(state),
  countries: apiShippingSelectors.getCountries(state),
  isLoadingGetCountries: apiShippingSelectors.isLoadingGetCountries(state),
  provinces: apiShippingSelectors.getProvinces(state),
  isLoadingGetProvinces: apiShippingSelectors.isLoadingGetProvinces(state),
  changePasswordError: apiRenterSelectors.getRenterChangePasswordErrors(state),
  changePasswordSuccess: apiRenterSelectors.getRenterChangePasswordSuccess(state),
  isLoadingChangePassword: apiRenterSelectors.isLoadingChangePassword(state),
  billingAddressError: apiRenterSelectors.getRenterUpdateBillingAddressErrors(state),
  billingAddressSuccess: apiRenterSelectors.getRenterUpdateBillingAddressSuccess(state),
  renterSuccess: apiRenterSelectors.putRenterUpdateSuccess(state),
  renterError: apiRenterSelectors.putRenterUpdateErrors(state),
  updateUserError: apiUsersSelectors.getUpdateUserErrorMessage(state),
  updateUserSuccess: apiUsersSelectors.getUpdateUserSuccessMessage(state),
  isLoadingGetUser: apiUsersSelectors.isLoadingGetMe(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      getRentals: apiGetRenterRentalsRequestAction,
      getCountries: apiGetShippingCountriesRequestAction,
      getProvinces: apiGetShippingProvincesRequestAction,
      getBillingAddress: apiGetRenterBillingAddressRequestAction,
      getUpcomingInvoices: apiGetPaymentCustomerUpcomingInvoicesRequestAction,
      updateRenter: apiPutUpdateRenterRequestAction,
      changePassword: apiPostChangePasswordRequestAction,
      updateRenterBillingAddress: apiPutUpdateRenterBillingAddressRequestAction,
      updateUser: apiUpdateUserRequestAction,
      getUser: apiGetUserMeRequestAction,
    },
    dispatch,
  );
  return { actions };
};

export default provideContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProfileScreen),
);
