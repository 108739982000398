import isEmpty from 'lodash/isEmpty';
import tokenTypesEnum from '../enums/tokenTypesEnum';
import utilityEnum from '../enums/utilityEnum';
import { Auth } from 'aws-amplify';

export function updateTokens({ accessToken, refreshToken }) {
  window.localStorage.setItem(tokenTypesEnum.ACCESS_TOKEN, accessToken);
  window.localStorage.setItem(tokenTypesEnum.REFRESH_TOKEN, refreshToken);
}

async function getAccessToken() {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getIdToken().getJwtToken();
    return accessToken;
  } catch (error) {
    return null;
  }
}

export function getRefreshToken() {
  return window.localStorage.getItem(tokenTypesEnum.REFRESH_TOKEN);
}

export function logout() {
  const rememberedUser = window.localStorage.getItem(utilityEnum.REMEMBER_ME);
  window.localStorage.clear();
  if (rememberedUser) {
    window.localStorage.setItem(utilityEnum.REMEMBER_ME, rememberedUser);
  }
}

export function isAuthenticated() {
  const token = window.localStorage.getItem(tokenTypesEnum.ACCESS_TOKEN);
  const refreshToken = window.localStorage.getItem(tokenTypesEnum.REFRESH_TOKEN);
  return !isEmpty(token) && !isEmpty(refreshToken);
}


export async function constructAuthHeaders() {
  try {
    const accessToken = await getAccessToken();
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  } catch (error) {
    return {
      headers: {},
    };
  }
}
