import moment from 'moment-timezone';
import { ActionTypes } from './apiReferralActions';

export const initialState = {
  errors: {},
  referrals: {},
  result: {},
};

const statusText = (now, referral) => {
  if (referral.status === 'SENT' && now.isAfter(moment.tz(referral.expiryDate, 'GMT'))) {
    return 'Referral Code Expired';
  }
  switch (referral.status) {
    case 'USED':
      return 'Referral Started';
    case 'FULFILLED':
      return 'Referral Complete';
    case 'CREATED':
    case 'SENT':
      return 'Sent Referral';
  }
}

const apiReferrals = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_GET_RENTER_REFERRALS_SUCCESS:
      const now = moment();
      const referrals = action.payload.reverse();
      return {
        ...state,
        referrals: referrals.map(r => Object.assign({}, r, {status: statusText(now, r)})),
      };
    case ActionTypes.API_POST_RENTER_REFERRALS_SUCCESS:
      return {
        ...state,
        result: action.payload,
      };
    case ActionTypes.API_GET_RENTER_REFERRALS_FAILURE:
    case ActionTypes.API_POST_RENTER_REFERRALS_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default apiReferrals;
