import { takeEvery, call, put } from 'redux-saga/effects';
import { getCountries, getProvinces } from './api';

import {
  ActionTypes,
  apiGetShippingCountriesSuccessAction,
  apiGetShippingCountriesFailureAction,
  apiGetShippingProvincesSuccessAction,
  apiGetShippingProvincesFailureAction,
} from './apiShippingActions';

import * as Auth from '../../utils/webAuth';

export function* apiGetCountries() {
  const { response, error } = yield call(getCountries);
  if (response) {
    yield put(apiGetShippingCountriesSuccessAction(response.data));
  } else {
    yield put(apiGetShippingCountriesFailureAction(error.response.data));
  }
}

export function* apiGetProvinces(action) {
  const { response, error } = yield call(getProvinces, action.payload);
  if (response) {
    yield put(
      apiGetShippingProvincesSuccessAction(response.data, action.payload),
    );
  } else {
    yield put(apiGetShippingProvincesFailureAction(error.response.data));
  }
}

export default function* apiShippingSaga() {
  yield takeEvery(
    ActionTypes.API_GET_SHIPPING_COUNTRIES_REQUEST,
    apiGetCountries,
  );
  yield takeEvery(
    ActionTypes.API_GET_SHIPPING_PROVINCES_REQUEST,
    apiGetProvinces,
  );
}
