import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { provideContext } from '../../../context/context.jsx';

import './button.scss';
import { Link } from 'react-router-dom';

class ExtButton extends Component {
  linkButton() {
    const { color, center, large, to, children, newTab, fullWidth } = this.props;
    return (
      <a
        className={classnames('Button', color, {
          'in-center': center,
          'full-width': fullWidth,
          large: large,
        })}
        href={to}
        target={newTab ? "_blank" : "_self"}
      >
        {children}
      </a>
    );
  }

  onClickButton() {
    const { color, center, large, onClick, disabled, children, fullWidth, pullRight } = this.props;
    return (
      <button
        className={classnames('Button', color, {
          'in-center': center,
          'full-width': fullWidth,
          large: large,
          disabled: disabled,
          'pull-right': pullRight,
        })}
        disabled={disabled}
        onClick={event => onClick(event)}
      >
        {children}
      </button>
    );
  }

  render() {
    if (this.props.to) {
      return this.linkButton();
    }
    return this.onClickButton();
  }
}

ExtButton.defaultProps = {
  color: 'red',
  center: false,
  large: false,
  disabled: false,
  fullWidth: false,
  pullRight: false,
};

ExtButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  color: PropTypes.string,
  center: PropTypes.bool,
  large: PropTypes.bool,
  newTab: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  pullRight: PropTypes.bool
};

export default provideContext(ExtButton);
