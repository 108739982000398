import React, { PureComponent } from 'react';
import { Trans } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { provideContext } from '../context/context.jsx';

import AppContainer from '../containers/appContainer.jsx';
import ScreenTitle from '../components/views/presentation/screenTitle/screenTitle.jsx';
import OrderAccountOverview from '../components/views/wrapper/accountOverview/orderAccountOverview.jsx';
import RetailOrderAccountOverview from '../components/views/wrapper/accountOverview/retailOrderAccountOverview';
import SnooAccountOverview from '../components/views/wrapper/accountOverview/snooAccountOverview.jsx';
import CancelAccountOverview from '../components/views/wrapper/accountOverview/cancelAccountOverview.jsx';
import ChangeShippingDateModal from '../components/views/modals/changeShippingDateModal/changeShippingDateModal.jsx';

import * as apiUsersSelectors from '../redux-api/apiUsers/apiUsersSelectors.js';
import * as apiRenterSelectors from '../redux-api/apiRenter/apiRenterSelectors.js';
import * as apiPaymentsSelectors from '../redux-api/apiPayments/apiPaymentsSelectors.js';
import * as apiRentalSelectors from '../redux-api/apiRental/apiRentalSelectors.js';
import * as apiShippingSelectors from '../redux-api/apiShipping/apiShippingSelectors.js';
import * as apiOrdersSelector from '../redux-api/apiOrders/apiOrdersSelectors.js';

import CancelOrderModal from '../components/views/modals/cancelOrderModal/cancelOrderModal.jsx';
import EditAddressModal from '../components/views/modals/editAddressModal/editAddressModal.jsx';

import Message from '../components/views/presentation/message/message.jsx';
import Button from '../components/views/button/button.jsx';
import linkEnums from '../enums/linksEnum';

import { apiGetRenterRentalsRequestAction } from '../redux-api/apiRenter/apiRenterActions.js';

import { apiGetPaymentCustomerUpcomingInvoicesRequestAction } from '../redux-api/apiPayments/apiPaymentsActions.js';
import {
  apiPutRentalUpdateShippingDateRequestAction,
  apiPostRentalCancelRequestAction,
  apiPutUpdateRentalShippingAddressRequestAction, apiGetRentalPriceRequestAction
} from '../redux-api/apiRental/apiRentalActions.js';

import {
  apiGetShippingCountriesRequestAction,
  apiGetShippingProvincesRequestAction,
} from '../redux-api/apiShipping/apiShippingActions.js';
import {
  apiCancelOrderRequestAction,
  apiGetOrdersRequestAction,
  apiUpdateOrderShippingAddressRequestAction,
  apiUpdateOrderShippingDateRequestAction,
  resetSuccessEditOrderAddressRequestAction
} from '../redux-api/apiOrders/apiOrdersActions';
import RetailCancelAccountOverview from '../components/views/wrapper/accountOverview/retailCancelAccountOverview';
import orderStatuses from '../enums/orderStatuses';
import { Auth } from 'aws-amplify';
import { modifyHost } from '../utils/redirect';

import ReactGA from 'react-ga4';
import BenefitDisplay from '../components/views/wrapper/accountOverview/corporateBenefits/benefitDisplay';
import { apiGetRentalPrice } from '../redux-api/apiRental/apiRentalSagas';
import ReturnModal from '../components/views/modals/initiateReturnModal/initiateReturnModal';

class HomeScreen extends PureComponent {
  state = {
    rentalId: null,
    shippingDate: null,
    editShippingDateModal: false,
    editRetailShippingDateModal: false,
    cancelOrderModalVisible: false,
    cancelRetailOrderModalVisible: false,
    editAddressModalVisible: false,
    editRetailAddressModalVisible: false,
    cancelingRental: null,
    cancelOrderId: null,
    addressEditingRental: null,
    addressEditingOrder: null,
    initReturnModalVisible: false,
    refetch: new Date(),
  };

  componentWillMount() {
    this.props.actions.getCountries();
    this.props.actions.getUpcomingInvoices();
    this.props.actions.getRentals();
    this.props.actions.getOrders();
    this.props.actions.getRentalPrice();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.successOrderCancel && this.props.successOrderCancel !== prevProps.successOrderCancel) {
      this.setState({ cancelOrderModalVisible: false });
    }

    if (
      this.props.successRetailOrderCancel &&
      this.props.successRetailOrderCancel !== prevProps.successRetailOrderCancel
    ) {
      this.setState({ cancelRetailOrderModalVisible: false });
    }

    if (
      this.props.successEditAddress &&
      this.props.isLoadingPutRentalShippingAddress === false &&
      this.props.isLoadingPutRentalShippingAddress !== prevProps.isLoadingPutRentalShippingAddress
    ) {
      //this.props.actions.getRentals();
      this.setState({ editAddressModalVisible: false });
    }

    if (
      this.props.successEditOrderAddress &&
      this.props.isLoadingUpdateOrderShippingAddress !== prevProps.isLoadingUpdateOrderShippingAddress
    ) {
      this.setState({ editRetailAddressModalVisible: false });
      this.props.actions.resetSuccessEditOrderAddress();

    }

    if (prevProps.rentals !== this.props.rentals) {
      this.setState({ rentals: this.props.rentals });
    }
    if (prevProps.errorShippingDateUpdate !== this.props.errorShippingDateUpdate) {
      this.setState({
        errorShippingDateUpdate: this.props.errorShippingDateUpdate,
      });
    }
    if (prevProps.shippingDate !== this.props.shippingDate) {
      this.setState({
        shippingDate: this.props.shippingDate,
      });
    }
    if (prevProps.successShippingDateUpdate !== this.props.successShippingDateUpdate) {
      this.setState({
        successShippingDateUpdate: this.props.successShippingDateUpdate,
      });
    }

    if (prevProps.successEditOrderShippingDate !== this.props.successEditOrderShippingDate) {
      this.setState({
        successEditOrderShippingDate: this.props.successEditOrderShippingDate,
      });
    }

    if (prevProps.orders !== this.props.orders) {
      this.setState({
        order: this.props.orders,
        refetch: new Date()
      });
    }
    return null;
  }

  renderRentalOrders(userData) {
    const {
      t,
      rentals,
      upcomingInvoices,
      isLoadingGetRenterRentals,
      isLoadingGetOrders,
      errorShippingDateUpdate,
      orders,
      actions,
    } = this.props;
    const mailComponent = (
      <a href="mailto:customercare@happiestbaby.com" target="_blank">
        customercare@happiestbaby.com
      </a>
    );
    let rentalsView = '';
    if (!isLoadingGetRenterRentals && !rentals.length && !isLoadingGetOrders && !orders.length) {
      rentalsView = (
        <div>
          <div className="row">
            <div className="col-2-3">
              <Message type="info" fullWidth={true}>
                <Trans
                  i18nKey="no_orders_message"
                  defaults="Looks like you don't have any orders!"
                  components={[mailComponent]}
                />
                <a
                  className="Button green btn-link"
                  color={'green'}
                  id="btn-go-to-shop"
                  href={`${window.location.protocol}//${modifyHost(window.location.host).replace('account.', '')}`}
                >
                  {t('button_go_to_shop', 'Go to Shop')}
                </a>
              </Message>
            </div>
          </div>
        </div>
      );
    } else {
      rentalsView = rentals.map((rental, index) => {
        if (rental.overview === 'SNOO') {
          return (
            <SnooAccountOverview
              rental={rental}
              upcomingInvoices={upcomingInvoices}
              userData={userData}
              key={index}
              showEditAddressModal={this.openEditAddressModal}
            />
          );
        } else {
          return rental.active !== false && rental.active !== 'canceled' ? (
            <OrderAccountOverview
              rental={rental}
              upcomingInvoices={upcomingInvoices}
              userData={userData}
              key={index}
              apiUpdateShippingDate={actions.apiUpdateShippingDate}
              showCancelOrderModal={this.openCancelOrderModal}
              showEditAddressModal={this.openEditAddressModal}
              errorShippingDateUpdate={errorShippingDateUpdate}
            />
          ) : (
            <CancelAccountOverview
              rental={rental}
              upcomingInvoices={upcomingInvoices}
              userData={userData}
              key={index}
            />
          );
        }
      });
    }
    return <div>{rentalsView}</div>;
  }

  renderRetailOrders(userData) {
    const { t, orders, isLoadingGetOrders, errorOrderShippingDateUpdate, actions } = this.props;
    if (!isLoadingGetOrders && orders.length) {
      return orders.map((order, index) => {
        if (order.status === orderStatuses.CANCELED) {
          return <RetailCancelAccountOverview order={order} userData={userData} key={index} />;
        }
        return (
          <RetailOrderAccountOverview
            order={order}
            userData={userData}
            key={index + this.state.refetch}
            showEditAddressModal={this.openEditRetailAddressModal}
            showCancelOrderModal={this.openCancelRetailOrderModal}
            apiUpdateShippingDate={actions.apiUpdateOrderShippingDate}
            errorShippingDateUpdate={errorOrderShippingDateUpdate}
                  showInitReturnModal={this.openInitReturnModal}
          />
        );
      });
    }
  }

  openCancelOrderModal = (rental) => {
    this.setState({
      cancelOrderModalVisible: true,
      cancelingRental: rental,
    });
  };

  openCancelRetailOrderModal = (id) => {
    this.setState({
      cancelRetailOrderModalVisible: true,
      cancelOrderId: id,
    });
  };

  onCancelOrder = (e) => {
    this.props.actions.apiCancelRental({
      rentalId: this.state.cancelingRental._id,
    });
  };

  onCancelRetailOrder = (e) => {
    this.props.actions.apiCancelOrder(this.state.cancelOrderId);
    ReactGA.event({
      category: 'Button clicked',
      action: 'retail_order_canceled',
      label: 'Retail order has been canceled', // optional
    });
  };

  onCloseCancelOrderModal = (e) => {
    this.setState({
      cancelOrderModalVisible: false,
      cancelingRental: null,
    });
  };

  onCloseCancelRetailOrderModal = (e) => {
    this.setState({
      cancelRetailOrderModalVisible: false,
      cancelOrderId: null,
    });
  };

  openEditAddressModal = (rental) => {
    this.setState({
      editAddressModalVisible: true,
      addressEditingRental: rental,
    });
  };

  openEditRetailAddressModal = (order) => {
    this.setState({
      editRetailAddressModalVisible: true,
      addressEditingOrder: order,
    });
  };

  onEditAddress = (address) => {
    this.props.actions.apiUpdateShippingAddress({
      rentalId: this.state.addressEditingRental._id,
      address,
    });
  };

  onEditRetailAddress = (address) => {
    this.props.actions.apiUpdateOrderShippingAddress({
      orderId: this.state.addressEditingOrder._id,
      address,
    });

    ReactGA.event({
      category: 'Form submitted',
      action: 'retail_shipping_address_changed',
      label: 'Retail Shipping address has been changed', // optional
    });
  };

  onCloseEditAddressModal = (e) => {
    this.setState({
      editAddressModalVisible: false,
      addressEditingRental: null,
    });
  };

  onCloseEditOrderAddressModal = (e) => {
    this.setState({
      editRetailAddressModalVisible: false,
      addressEditingOrder: null,
    });
  };

  openInitReturnModal = (order, returns) => {
    this.setState({
      initReturnModalVisible: true,
      currentOrder: order,
      returns
    })
  }

  onCloseInitReturnModal = (refetch = true) => {
    const newState = {
      initReturnModalVisible: false,
      currentOrder: null,
    };

    if (refetch) {
      newState.refetch = new Date();
    }

    this.setState(newState);
  }

  render() {
    const {
      t,
      isLoadingCancelRental,
      isLoadingCancelOrder,
      errorCancelOrder,
      errorCancelRetailOrder,
      isLoadingPutRentalShippingAddress,
      isLoadingUpdateOrderShippingAddress,
      isLoadingGetProvinces,
      errorEditAddress,
      errorEditOrderAddress,
      countries,
      provinces,
      isLoadingGetRentalPrice,
      rentalPrice
    } = this.props;


    const userData = {
      email: Auth.user.attributes.email,
    };

    const returnReasons = [
      { reason: 'My baby does not like to be swaddled' },
      { reason: 'My baby sleeps well without SNOO' },
      { reason: 'SNOO did not soothe my baby' },
      { reason: 'We experienced technical issues' },
      { reason: 'Uncomfortable with the motion and sound' },
      { reason: 'Moved baby to crib' },
      { reason: 'Not worth the price' },
      { reason: 'Does not fit - accessory' },
      { reason: 'Quality Concern - accessory' },
      { reason: 'Other' },
    ];

    if (isLoadingGetRentalPrice) {
      return <AppContainer />
    }

    return (
      <AppContainer>
        <div className="row">
          <BenefitDisplay rentalPrice={rentalPrice / 100} corporateBenefits={this.props.corporateBenefits} />
        </div>
        <div className="row">
          <div className="col-1">
            <ScreenTitle>{t('account_overview_title')}</ScreenTitle>
          </div>
        </div>
        {this.renderRentalOrders(userData)}
        <div className="col-1">{this.renderRetailOrders(userData)}</div>
        {this.state.cancelOrderModalVisible && (
          <CancelOrderModal
            onCloseModal={this.onCloseCancelOrderModal}
            onCancelOrder={this.onCancelOrder}
            isLoadingCancelRental={isLoadingCancelRental}
            errors={errorCancelOrder}
          />
        )}
        {this.state.cancelRetailOrderModalVisible && (
          <CancelOrderModal
            onCloseModal={this.onCloseCancelRetailOrderModal}
            onCancelOrder={this.onCancelRetailOrder}
            isLoadingCancelRental={isLoadingCancelOrder}
            errors={errorCancelRetailOrder}
          />
        )}
        {this.state.editAddressModalVisible && (
          <EditAddressModal
            title={t('global_shipping_address')}
            onCloseModal={this.onCloseEditAddressModal}
            onEditAddress={this.onEditAddress}
            isLoadingEditAddress={isLoadingPutRentalShippingAddress}
            errors={errorEditAddress}
            countries={countries}
            provinces={provinces}
            isLoadingGetProvinces={isLoadingGetProvinces}
            apiGetProvinces={this.props.actions.getProvinces}
            address={this.state.addressEditingRental.shippingAddress}
          />
        )}
        {this.state.editRetailAddressModalVisible && (
          <EditAddressModal
            title={t('global_shipping_address')}
            onCloseModal={this.onCloseEditOrderAddressModal}
            onEditAddress={this.onEditRetailAddress}
            isLoadingEditAddress={isLoadingUpdateOrderShippingAddress}
            errors={errorEditOrderAddress}
            countries={countries}
            provinces={provinces}
            isLoadingGetProvinces={isLoadingGetProvinces}
            apiGetProvinces={this.props.actions.getProvinces}
            address={this.state.addressEditingOrder.shipping_address}
          />
        )}
        {this.state.initReturnModalVisible && (
          <ReturnModal
            reasons={returnReasons}
            returns={this.state.returns}
            order={this.state.currentOrder}
            onCloseModal={this.onCloseInitReturnModal}
            title={t('global_shipping_address')}
            countries={countries}
            provinces={provinces}
            isLoadingGetProvinces={isLoadingGetProvinces}
            apiGetProvinces={this.props.actions.getProvinces}
            t={t}
          />
        )}
      </AppContainer>
    );
  }
}

HomeScreen.defaultProps = {
  isLoadingGetRenterRentals: true,
  isLoadingGetOrders: true,
};

HomeScreen.propTypes = {
  actions: PropTypes.shape({
    getUpcomingInvoices: PropTypes.func,
    getRentals: PropTypes.func,
    getOrders: PropTypes.func,
    getUser: PropTypes.func,
    getRentalPrice: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  upcomingInvoices: apiPaymentsSelectors.getCustomerUpcomingInvoices(state),
  rentals: apiRenterSelectors.getRentals(state),
  isLoadingGetCustomerUpcomingInvoices: apiPaymentsSelectors.isLoadingGetCustomerUpcomingInvoices(state),
  isLoadingUpdateShippingDate: apiRentalSelectors.isLoadingUpdateShippingDate(state),
  isLoadingGetRenterRentals: apiRenterSelectors.isLoadingGetRenterRentals(state),
  isLoadingCancelRental: apiRentalSelectors.isLoadingCancelRental(state),
  countries: apiShippingSelectors.getCountries(state),
  isLoadingGetCountries: apiShippingSelectors.isLoadingGetCountries(state),
  orders: apiOrdersSelector.getOrders(state),
  isLoadingGetOrders: apiOrdersSelector.isLoadingGetOrders(state),
  isLoadingCancelOrder: apiOrdersSelector.isLoadingCancelOrder(state),
  errorCancelRetailOrder: apiOrdersSelector.getCancelOrderErrors(state),
  successRetailOrderCancel: apiOrdersSelector.getCancelOrderSuccess(state),
  provinces: apiShippingSelectors.getProvinces(state),
  isLoadingGetProvinces: apiShippingSelectors.isLoadingGetProvinces(state),
  successShippingDateUpdate: apiRentalSelectors.getUpdateShippingDateSuccess(state),
  successOrderCancel: apiRentalSelectors.getCancelOrderSuccess(state),
  errorShippingDateUpdate: apiRentalSelectors.getUpdateShippingDateErrors(state),
  errorCancelOrder: apiRentalSelectors.getCancelRentalErrors(state),
  isLoadingPutRentalShippingAddress: apiRentalSelectors.isLoadingPutRentalShippingAddress(state),
  isLoadingUpdateOrderShippingAddress: apiOrdersSelector.isLoadingUpdateOrderShippingAddress(state),
  successEditAddress: apiRentalSelectors.getRentalUpdateShippingAddressSuccess(state),
  errorEditAddress: apiRentalSelectors.getRentalUpdateShippingAddressErrors(state),
  errorEditOrderAddress: apiOrdersSelector.getUpdateOrderShippingAddressErrors(state),
  successEditOrderAddress: apiOrdersSelector.getUpdateOrderShippingAddressSuccess(state),
  errorOrderShippingDateUpdate: apiOrdersSelector.getUpdateOrderShippingDateErrors(state),
  successEditOrderShippingDate: apiOrdersSelector.getUpdateOrderShippingDateSuccess(state),
  isLoadingUpdateOrderShippingDate: apiOrdersSelector.isLoadingUpdateOrderShippingDate(state),
  corporateBenefits: apiUsersSelectors.getCorporateBenefits(state),
  isLoadingGetRentalPrice: apiRentalSelectors.isLoadingGetRentalPrice(state),
  rentalPrice: apiRentalSelectors.getRentalPrice(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators(
    {
      getUpcomingInvoices: apiGetPaymentCustomerUpcomingInvoicesRequestAction,
      getRentals: apiGetRenterRentalsRequestAction,
      apiUpdateShippingDate: apiPutRentalUpdateShippingDateRequestAction,
      apiUpdateShippingAddress: apiPutUpdateRentalShippingAddressRequestAction,
      apiCancelRental: apiPostRentalCancelRequestAction,
      getCountries: apiGetShippingCountriesRequestAction,
      getProvinces: apiGetShippingProvincesRequestAction,
      getOrders: apiGetOrdersRequestAction,
      apiCancelOrder: apiCancelOrderRequestAction,
      apiUpdateOrderShippingAddress: apiUpdateOrderShippingAddressRequestAction,
      apiUpdateOrderShippingDate: apiUpdateOrderShippingDateRequestAction,
      resetSuccessEditOrderAddress: resetSuccessEditOrderAddressRequestAction,
      getRentalPrice: apiGetRentalPriceRequestAction,
    },
    dispatch,
  );
  return { actions };
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(HomeScreen));
