import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

export const getReturnItems = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RETURN_GET_RETURNS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
}

export const postInitiateReturn = async (request) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.RENTAL_RENTER_INITIATE_RETURN), request, headers);
    return { response };
  } catch (error) {
    return { error };
  }
}

export const getCancellingReasons = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_CANCELLING_REASONS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
}
