import { ActionTypes } from './apiPaymentsActions';

export const initialState = {
  customer: {},
  card: {},
  accountBalance: {},
  upcomingInvoices: [],
  errors: {},
};

const apiPayments = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_POST_PAYMENT_CUSTOMER_FAILURE:
    case ActionTypes.API_POST_PAYMENT_CARD_FAILURE:
    case ActionTypes.API_GET_PAYMENT_CUSTOMER_FAILURE:
    case ActionTypes.API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_FAILURE:
    case ActionTypes.API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_FAILURE:
    case ActionTypes.API_GET_PAYMENT_CARD_FAILURE:
    case ActionTypes.API_PUT_PAYMENT_CARD_FAILURE:
    case ActionTypes.API_POST_PAYMENT_SETUPINTENT_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case ActionTypes.API_POST_PAYMENT_SETUPINTENT_SUCCESS:
      return {
        ...state,
        card: {
          ...state.card,
          setupIntent: action.payload
        }
      }
    case ActionTypes.API_POST_PAYMENT_CARD_SUCCESS:
    case ActionTypes.API_PUT_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        card: action.payload
      }
    case ActionTypes.API_GET_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        card: action.payload,
      };
    case ActionTypes.API_POST_PAYMENT_CUSTOMER_SUCCESS:
    case ActionTypes.API_GET_PAYMENT_CUSTOMER_SUCCESS:
    case ActionTypes.API_PUT_PAYMENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload,
      };
    case ActionTypes.API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        accountBalance: action.payload,
      };
    case ActionTypes.API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_SUCCESS:
      return {
        ...state,
        upcomingInvoices: action.payload,
      };
    case ActionTypes.API_POST_PAYMENT_CUSTOMER_REQUEST:
    case ActionTypes.API_POST_PAYMENT_CARD_REQUEST:
    case ActionTypes.API_PUT_PAYMENT_CARD_REQUEST:
    case ActionTypes.API_POST_PAYMENT_SETUPINTENT_REQUEST:
    case ActionTypes.API_PUT_PAYMENT_CUSTOMER_REQUEST:
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
};

export default apiPayments;
