import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../context/context.jsx';

import './radio.scss';

class Radio extends Component {
  render() {
    const { list, onChange, name } = this.props;

    return (
      <div className="Radio">
        {list.map((item, index) => {
          return (
            <div key={index} className="radio-item">
              <label>
                {item.label}
                <input
                  type="radio"
                  name={name}
                  onChange={onChange}
                  value={item.value}
                />
                <span className="checkmark" />
              </label>
            </div>
          );
        })}
      </div>
    );
  }
}

Radio.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default provideContext(Radio);
