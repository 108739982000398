import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

export const getOrders = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.GET_ORDERS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const cancelOrder = async (id) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.CANCEL_ORDER, { id }), {reason: 'OTHER'}, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const updateRetailShippingAddress = async ({ orderId, address }) => {
  try {
    const headers = await constructAuthHeaders();
    const shippingAddress = {
      first_name: address.firstName,
      last_name: address.lastName,
      ...address,
    }
    const response = await axios.put(getApiRouteUrl(routes.UPDATE_ORDER, { id: orderId }), { shippingAddress }, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const updateRetailShippingDate = async ({ orderId, shippingDate }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.UPDATE_SHIPPING_DATE, { id: orderId }), { shippingDate }, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};


export const createReturn = async (orderId, payload) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.RETURNS, { id: orderId }), { ...payload }, headers);
    return { response };
  } catch (error) {
    throw error;
  }
};


export const getReturns = async ({ orderId }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RETURNS, { id: orderId }),  headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getDevices = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.DEVICES), headers);
    return { response };
  } catch (error) {
    return { error }
  }
}

export const initProductSupportRequest = async (payload) => {
  try {
    const headers = await constructAuthHeaders();
    headers.headers['Content-Type'] = 'multipart/form-data';
    const response = await axios.post(getApiRouteUrl(routes.PRODUCT_SUPPORT), payload, headers);
    return { response };
  } catch (error) {
    return { error }
  }
}
