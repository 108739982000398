import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';
import Currency from '../../formats/currencyFormat/currency.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './autopayDetailSegment.scss';

class AutopayDetailSegment extends Component {
  render() {
    const { t, context, total, details, card, paid, upcoming, period, periodIndex, show = true } = this.props;
    return (
      <div className={`AutopayDetailSegment ${show ? 'show' : 'hide'}`}>
        <FontAwesomeIcon icon={faTimes} className="close" onClick={this.props.onClickClose} />
        <h3>{t('view_details_text', 'View details')}</h3>
        {!paid && !upcoming ? (
          <p className="red">
            {t(
              'autopay_detail_segment_view_details_update_payment_info',
              'You need to update your payment information to cover subscription payment of ',
            )}
            <Currency value={total} context={context} />
          </p>
        ) : upcoming ? (
          <div>
            <p className="details">
              {t('autopay_detial_segment_view_details_update_account_balance_covers', 'Your Account balance covers ')}
              <Currency value={details.balance} context={context} />
            </p>
            <p className="details">
              {t('autopay_detail_segment_will_be_charged', 'You will be charged ')}
              <Currency value={details.card} context={context} />{' '}
              {t('autopay_detail_segment_will_be_charged_from_card', ' from your card')}
            </p>
            <p className="details">**** **** **** {card.last4}</p>
          </div>
        ) : (
          <table>
            <tbody>
              <tr>
                <td>
                  <h4>
                    {periodIndex == 1
                      ? t('autopay_detail_segment_gift_card', 'Gift card')
                      : t('autopay_detail_segment_account_balance_g', 'Account balance')}
                  </h4>
                </td>
                <td />
                <td>
                  <span className="price">
                    {details.balance !== 0 ? '-' : ''}
                    <Currency value={details.balance} context={context} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{t('global_credit_card')}</h4>
                  <span aria-label={t('autopay_detail_segment_credit_card_ending', 'Card ending in: ')}>
                    **** **** **** {card.last4}
                  </span>
                </td>
                <td />
                <td>
                  <span className="price">
                    {details.card !== 0 ? '-' : ''}
                    <Currency value={details.card} context={context} />
                  </span>
                </td>
              </tr>
              <tr className="border">
                <td>
                  <h4>{t('autopay_detail_segment_credit_card_total', 'Total')}</h4>
                </td>
                <td />
                <td>
                  <span className="price">
                    {total !== 0 ? '-' : ''}
                    <Currency value={total} context={context} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

AutopayDetailSegment.propTypes = {
  t: PropTypes.func.isRequired,
};

export default provideContext(AutopayDetailSegment);
