import assign from 'lodash/assign';
import { ActionTypes } from './apiUsersActions';

export const initialState = {
  user: {},
  userId: localStorage.getItem('userId'),
  corporateBenefits: {
    loading: false,
    data: null,
    error: null,
  },
  errors: {},
  successes: {},
  legalDocs: {},
};

const apiUsers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_GET_USER_ME_SUCCESS:
    case ActionTypes.API_UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: assign({}, state.user, action.payload),
      };
    case ActionTypes.API_GET_USER_ME_FAILURE:
    case ActionTypes.API_UPDATE_USER_FAILURE:
    case ActionTypes.API_USERS_LEGAL_CHECK_FAILURE:
    case ActionTypes.API_USERS_LEGAL_ACKNOWLEDGE_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case ActionTypes.API_GET_CORPORATE_BENEFITS_REQUEST:
      return {
        ...state,
        corporateBenefits: {
          loading: true,
          data: null,
          error: null,
        }
      }
    case ActionTypes.API_GET_CORPORATE_BENEFITS_FAILURE:
      return {
        ...state,
        corporateBenefits: {
          loading: false,
          data: null,
          error: action.payload,
        }
      }
      case ActionTypes.API_GET_CORPORATE_BENEFITS_SUCCESS:
        return {
          ...state,
          corporateBenefits: {
            loading: false,
                data: action.payload,
            error: null,
          }
        }
    case ActionTypes.API_USERS_LEGAL_CHECK_SUCCESS:
      return {
        ...state,
        legalDocs: action.payload,
      }
    case ActionTypes.API_USERS_LEGAL_ACKNOWLEDGE_SUCCESS:
      localStorage.setItem('hasAcknowledge', 'true');
      return {
        ...state,
        legalDocs: {},
      }
    default:
      return state;
  }
};

export default apiUsers;
