import { createSelector } from 'reselect';
import { ActionTypes } from './apiReferralActions';

import {
  getAllLoadersFromState,
  getLoaderStatus,
  getAllErrorsFromState,
  getErrorStatus,
  getAllSuccessesFromState,
  getSuccessStatus,
} from '../apiUtils/apiUtils';

export const getReferrals = state => state.apiReferral.referrals;

export const isLoadingGetRenterReferrals = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_REFERRALS_REQUEST),
);

export const getGetRenterReferralsSuccess = createSelector(
  getAllSuccessesFromState,
  success => getSuccessStatus(success, ActionTypes.API_GET_RENTER_REFERRALS_SUCCESS),
);

export const getGetRenterReferralsErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_GET_RENTER_REFERRALS_FAILURE),
);

export const isLoadingPostRenterReferrals = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_POST_RENTER_REFERRALS_REQUEST),
);

export const getPostRenterReferralsSuccess = createSelector(
  getAllSuccessesFromState,
  success => getSuccessStatus(success, ActionTypes.API_POST_RENTER_REFERRALS_SUCCESS),
);

export const getPostRenterReferralsErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_RENTER_REFERRALS_FAILURE),
);