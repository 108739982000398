import { createSelector } from 'reselect';
import { ActionTypes } from './apiShippingActions';

import { getAllLoadersFromState, getLoaderStatus } from '../apiUtils/apiUtils';

export const getCountries = state => state.apiShipping.countries;
export const getProvinces = state => state.apiShipping.provinces;

export const isLoadingGetCountries = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_GET_SHIPPING_COUNTRIES_REQUEST),
);

export const isLoadingGetProvinces = createSelector(
  getAllLoadersFromState,
  loaders =>
    getLoaderStatus(loaders, ActionTypes.API_GET_SHIPPING_PROVINCES_REQUEST),
);
