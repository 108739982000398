import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { provideContext } from '../../../../context/context.jsx';
import snooLabelActive from '../../../../assets/icons/snoo-label-active.svg';
import Button from '../../button/button.jsx';
import ExtButton from '../../button/external-button.jsx';

import './downloadLabel.scss';

class DownloadLabel extends Component {
  render() {
    const { t, context, snooId, link, initiatedDate } = this.props;
    return (
      <div className="download-label">
        <div className="download-label-inner">
          <div className="download-label-heading">
            <span className="tag">SNOO ID {snooId}</span>
          </div>
          <div style={{
            display: 'inline-block',
            marginTop: '10px',
            color: 'rgb(77, 89, 107)',
            fontSize: '0.9em',
          }}>Use the printable shipping label to return the SNOO with the SNOO ID presented above.</div>
          <div className="download-label-body">
            <div className="circle-wrap">
              <div className="circle">
                <img src={snooLabelActive} />
              </div>
            </div>
            <div className="link-wrap">
              {link && (

                  <ExtButton to={link} newTab={true}>
                    {t('download_lable_printable','Download Printable Label')}
                  </ExtButton>
              )}
              {!link && <p>{t('download_label_emailed','We have emailed you a return email. Please check your email.')}</p>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DownloadLabel.propTypes = {
  snooId: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default provideContext(DownloadLabel);
