import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';

import './screenTitle.scss';

class ScreenTitle extends Component {
  render() {
    return <h1 className="ScreenTitle">{this.props.children}</h1>;
  }
}

ScreenTitle.propTypes = {
  t: PropTypes.func.isRequired,
};

export default provideContext(ScreenTitle);
