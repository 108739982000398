import { createSelector } from 'reselect';
import { ActionTypes } from './apiRentalActions';

import {
  getAllLoadersFromState,
  getLoaderStatus,
  getAllErrorsFromState,
  getErrorStatus,
  getAllSuccessesFromState,
  getSuccessStatus,
} from '../apiUtils/apiUtils';

export const getRenter = state => state.apiRental.renter;
export const getFlaggedModes = state => state.apiRental.renter.flaggedModes;
export const getAppliedGiftCard = state => state.apiRental.giftCardApplied;
export const getGiftCardsUsageSummary = state => state.apiRental.giftCardsUsageSummary;
export const getGeneratedGiftCard = state => state.apiRental.giftCard;
export const getRenterAppliedGiftCards = state => state.apiRental.appliedGiftCards;
export const getShippingDate = state => state.apiRental.shippingDate;
export const getRentalShippingAddress = state => state.apiRental.shippingAddress;
export const getRentalItems = state => state.apiRental.rentalItems;
export const getPrices = state => state.apiRental.prices;
export const getRentalPrice = state => state.apiRental.rentalPrice;

export const isLoadingGetUserInfo = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_USER_TOKEN_INFO_REQUEST),
);

export const isLoadingGetRenter = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_FROM_USER_ID_REQUEST),
);

export const isLoadingGiftCardApplied = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_REQUEST),
);

export const isLoadingGetRenterAppliedGiftCards = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_APPLIED_GIFT_CARDS_REQUEST),
);

export const isLoadingGetRenterGiftCardsUsageSummary = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_REQUEST),
);

export const getGiftCardAppliedErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_FAILURE),
);

export const getGiftCardAppliedSuccess = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_SUCCESS),
);

export const getPasswordCreateErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_USER_PASSWORD_CREATE_FAILURE),
);

export const getUserFromTokenErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_GET_USER_TOKEN_INFO_FAILURE),
);

export const isLoadingGetGeneratedGiftCard = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_POST_GENERATE_GIFT_CARD_REQUEST),
);

export const getGeneratedGiftCardErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_GENERATE_GIFT_CARD_FAILURE),
);

export const getGeneratedGiftCardSuccess = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_POST_GENERATE_GIFT_CARD_SUCCESS),
);

export const isLoadingUpdateShippingDate = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_REQUEST),
);

export const getUpdateShippingDateErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_FAILURE),
);

export const getUpdateShippingDateSuccess = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_SUCCESS),
);

export const isLoadingGetRentalItems = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTAL_ITEMS_REQUEST),
);

export const isLoadingCancelRental = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_POST_CANCEL_RENTAL_REQUEST),
);

export const getCancelRentalErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_CANCEL_RENTAL_FAILURE),
);

export const getCancelOrderSuccess = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_POST_CANCEL_RENTAL_SUCCESS),
);

export const getRentalUpdateShippingAddressSuccess = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_SUCCESS),
);

export const getRentalUpdateShippingAddressErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_FAILURE),
);

export const isLoadingGetRentalShippingAddress = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTAL_SHIPPING_ADDRESS_REQUEST),
);

export const isLoadingPutRentalShippingAddress = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_REQUEST),
);

export const isLoadingGetPrices = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_PRICES_REQUEST),
);

export const isLoadingGetRentalPrice = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTAL_PRICE_REQUEST),
)
