import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';

import './requestDataExportSuccessModal.scss';

class RequestDataExportSuccessModal extends Component {
  render() {
    const { t, onCloseModal } = this.props;
    return (
      <div className="RequestDataExportSuccessModal">
        <div className="modal-center" onClick={(e) => e.stopPropagation()}>
            <>
              <h2>{t('data_export_success_title', "We've received your Data Export Request")}</h2>
              <div className="inner-modal-center">
                <div className="modal-text">
                  <p>
                    {t(
                      'data_export_success_description',
                      'After your data export process is complete, we will email you at the address linked to your account. You can obtain your data exported once every 30 days, regardless of the number of requests made.',
                    )}
                  </p>
                </div>
                <div className="button-wrap">
                  <Button color={'green'} onClick={onCloseModal}>
                    {t('button_text_ok', 'OK')}
                  </Button>
                </div>
              </div>
            </>
        </div>
      </div>
    );
  }
}

RequestDataExportSuccessModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default provideContext(RequestDataExportSuccessModal);
