import { takeEvery, call, put } from 'redux-saga/effects';

import { getReturnItems, postInitiateReturn, getCancellingReasons } from './api';

import {
  ActionTypes,
  apiGetReturnItemsSuccessAction,
  apiGetReturnItemsFailureAction,
  apiPostRentalInitiateReturnSuccessAction,
  apiPostRentalInitiateReturnFailureAction,
  apiGetRentalCancellingReasonsSuccessAction,
  apiGetRentalCancellingReasonsFailureAction,
} from './apiReturnActions';

export function* apiGetReturnItems() {
  const { response, error } = yield call(getReturnItems);
  if (response) {
    yield put(apiGetReturnItemsSuccessAction(response.data));
  } else {
    yield put(apiGetReturnItemsFailureAction(error.response.data));
  }
}

export function* apiPostInitiateReturn(action) {
  const { error } = yield call(postInitiateReturn, action.payload);
  if (error) {
    yield put(apiPostRentalInitiateReturnFailureAction(error.response.data));
    return;
  }
  const { response, error: err } = yield call(getReturnItems);
  if (response) {
    yield put(apiPostRentalInitiateReturnSuccessAction(response.data));
  } else {
    yield put(apiPostRentalInitiateReturnFailureAction(err.response.data));
  }
}

export function* apiGetCancellingReasons() {
  const { response, error } = yield call(getCancellingReasons);
  if (response) {
    yield put(apiGetRentalCancellingReasonsSuccessAction(response.data));
  } else {
    yield put(apiGetRentalCancellingReasonsFailureAction(error.response.data));
  }
}

export default function* apiReturnSaga() {
  yield takeEvery(ActionTypes.API_GET_RETURN_ITEMS_REQUEST, apiGetReturnItems);
  yield takeEvery(ActionTypes.API_POST_RENTAL_INITIATE_RETURN_REQUEST, apiPostInitiateReturn);
  yield takeEvery(ActionTypes.API_GET_RENTAL_CANCELLING_REASONS_REQUEST, apiGetCancellingReasons);
}
