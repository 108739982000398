import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import Select from '../../select/select.jsx';
import Currency from '../../formats/currencyFormat/currency.jsx';
import { provideContext } from '../../../../context/context.jsx';
import BillHistoryTable from '../../tables/billHistoryTable/billHistoryTable.jsx';
import AutopaySegment from '../../segments/autopaySegment/autopaySegment.jsx';
import rentalStatuses from '../../../../enums/rentalStatuses.js';
import './billHistoryForm.scss';
import ExtButton from '../../button/external-button.jsx';
import {apiConfig} from "../../../../config/apiConfig";

const renderPeriod = period => {
  let name = `Initial Payment`;
  if (period.start && period.end) {
    const start = moment.utc(period.start).format('MMM D');
    const end = moment.utc(period.end).format('MMM D, Y');
    name = `${start} - ${end}`;
  }

  return (
    <span className="select-row">
      <span className="select-left-cell">{name}</span>
      <span className="select-right-cell">
        <Currency value={period.total} divider={1} />
      </span>
    </span>
  );
};

const formatData = (orders, t) => {
  return orders.map(order => {
    const periods = order.periods.map(period => {
      if (period.start && period.end) {
        const start = moment.utc(period.start).format('MMM D');
        const end = moment.utc(period.end).format('MMM D, Y');
        return {
          name: renderPeriod(period),
          period: period.period,
          total: period.total,
          periodName: `${start} - ${end}`,
          upcoming: period.upcoming,
          paid: period.paid,
          items: period.items,
          details: period.details,
          card: period.card,
        };
      }
      return {
        name: renderPeriod(period),
        period: period.period,
        total: period.total,
        periodName: t('usage_summary_table_initial_payment','Initial Payment'),
        upcoming: period.upcoming,
        paid: period.paid,
        items: period.items,
        details: period.details,
        card: period.card,
      };
    });
    return {
      ...order,
      periods,
    };
  });
};

const determineCurrentPeriod = (orders, selectedOrder) => {
  if (orders[selectedOrder].currentPeriod && orders[selectedOrder].periods) {
    return findIndex(orders[selectedOrder].periods, {
      period: Number(orders[selectedOrder].currentPeriod),
    });
  }

  return 0;
};

class BillHistoryForm extends Component {
  state = {
    orders: [],
    selectedPeriod: null,
    selectedOrder: null,
    billingHistory: {},
    currentOrder: 0,
  };

  componentDidMount() {
    this.loadTableData(this.props.currentOrder, this.props.currentPeriod);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentOrder !== this.props.currentOrder) {
      this.loadTableData(this.props.currentOrder, this.props.currentPeriod);
    }
  }

  loadTableData(currentOrder = null, currentPeriod = null) {
    const { orders, selectedPeriod, selectedOrder } = this.state;

    let currentSelectedOrder = selectedOrder;
    let currentSelectedPeriod = selectedPeriod;

    if (currentOrder !== 0 && !isNull(currentOrder)) {
      currentSelectedOrder = findIndex(orders, { id: currentOrder });
      currentSelectedPeriod = findIndex(orders[currentSelectedOrder].periods, { period: Number(currentPeriod) });
      if (currentSelectedPeriod === -1) {
        currentSelectedPeriod = 0;
      }
      this.setState({
        selectedOrder: currentSelectedOrder,
        selectedPeriod: currentSelectedPeriod,
      });
    }

    if (currentOrder === 0 && currentPeriod === 0) {
      currentSelectedOrder = 0;
      currentSelectedPeriod = 0;
      this.setState({
        selectedOrder: currentSelectedOrder,
        selectedPeriod: currentSelectedPeriod,
      });
    }

    const orderId = orders[currentSelectedOrder].id;
    const period = orders[currentSelectedOrder].periods[currentSelectedPeriod].period;

    if (period > 1) {
      this.props.apiGetBillingHistory({
        orderId,
        period: period - 1,
      });
    }

    this.props.apiGetBillingHistory({
      orderId,
      period,
    });

    this.props.apiGetTotalDebt();
  }

  onOrderChange = (name, value) => {
    this.setState(
      {
        selectedOrder: value,
        selectedPeriod: null,
      },
      this.loadTableData,
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (
      (props.orders !== state.orders && props.orders[0]) ||
      props.billingHistory !== state.billingHistory ||
      props.currentOrder !== props.orders[0].id
    ) {
      const orders = formatData(props.orders, props.t);
      const selectedOrder = state.selectedOrder || 0;
      const selectedPeriod =
        state.selectedPeriod === null ? determineCurrentPeriod(props.orders, selectedOrder) : state.selectedPeriod;

      return {
        orders,
        selectedOrder,
        selectedPeriod,
      };
    }
    return null;
  }

  render() {
    const { t, billingHistory, lastManualChargeAttempt, chargeAttemptSuccess, chargeAttemptFailure, isLoadingGetBillingHistory, rentals, isLoadingGetRenterRentals, postChargePastDueInvoices, customer, totalDebt } = this.props;
    const { orders, selectedOrder, selectedPeriod } = this.state;
    let autopayActive = false;
    const periodName = orders[selectedOrder].periods[selectedPeriod].periodName;
    const periodIndex = orders[selectedOrder].periods[selectedPeriod].period;
    const orderId = orders[selectedOrder].id;
    const period = orders[selectedOrder].periods[selectedPeriod];
    const previousPeriod = period.period > 1 ? orders[selectedOrder].periods[selectedPeriod + 1] : false;
    const allPeriods = orders[selectedOrder].periods.slice().reverse();
    const currentPeriodIndex= Math.max.apply(Math, allPeriods.filter(({upcoming}) => !upcoming).map(p => p.period));
    const currentPeriod = allPeriods.find(({period}) => period === currentPeriodIndex);

    const orderBillingHistory =
      isLoadingGetBillingHistory === false && billingHistory[orderId] ? billingHistory[orderId][currentPeriodIndex] : {};

    if (!isLoadingGetRenterRentals) {
      autopayActive = find(rentals, { _id: orderId }).rental.status == rentalStatuses.RENTING;
    }

    const shouldDisplayGoToInsuranceCompaniesButtons = rentals.find(rental => {
      return rental && rental.rental && rental.rental.corporateBenefits && rental.rental.corporateBenefits.companyAlias === apiConfig.insurance.companyAliasToShowRedirectButtons;
    });

    return (
      <div className="BillHistoryForm">
        <div className="col-2-3">
          <form className="row" onChange={this.onChangeForm}>
            <div className="col-1-2">
              <Select
                list={orders}
                placeholder={''}
                labelKey="name"
                name="selectedOrder"
                defaultValue={selectedOrder}
                onChange={this.onOrderChange}
              />
            </div>
            {shouldDisplayGoToInsuranceCompaniesButtons ?
                <div className={"insurance-buttons col-1-2"}>
                  <div>
                    <ExtButton to={apiConfig.insurance.aetnaUrl} newTab color={"green-inverted"}>Aetna</ExtButton>
                  </div>
                  <div>
                    <ExtButton to={apiConfig.insurance.anthemUrl} newTab color={"green-inverted"}>Anthem</ExtButton>
                  </div>
                </div>
                : null}
          </form>
          <div className="row">
            <div className="col-1">
              {!isEmpty(orderBillingHistory) && orderBillingHistory.items && orderBillingHistory.items.length !== 0 && (
                <BillHistoryTable
                  period={periodName}
                  periodIndex={periodIndex}
                  periodTotal={period.total}
                  previousPeriodTotal={previousPeriod ? `$${previousPeriod.total}` : '-'}
                  previousPeriodName={previousPeriod ? previousPeriod.periodName : '-'}
                  totalDebt={totalDebt}
                  periods={allPeriods}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-1-3">
          {!isLoadingGetRenterRentals &&
            !isEmpty(orderBillingHistory) &&
            orderBillingHistory.items &&
            orderBillingHistory.items.length !== 0 && (
              <AutopaySegment
                paid={orderBillingHistory.paid}
                period={currentPeriod.periodName}
                periodIndex={currentPeriodIndex}
                total={orderBillingHistory.total}
                details={orderBillingHistory.details}
                card={orderBillingHistory.card}
                periodItems={orderBillingHistory.items}
                upcoming={orderBillingHistory.upcoming}
                active={autopayActive}
                postChargePastDueInvoices={postChargePastDueInvoices}
                customer={customer}
                totalDebt={totalDebt}
                lastManualChargeAttempt={lastManualChargeAttempt}
                chargeAttemptSuccess={chargeAttemptSuccess}
                chargeAttemptFailure={chargeAttemptFailure}
              />
            )}
        </div>
      </div>
    );
  }
}

BillHistoryForm.defaultProps = {
  isLoadingGetBillingHistory: true,
};

BillHistoryForm.propTypes = {
  t: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  apiGetBillingHistory: PropTypes.func.isRequired,
  apiGetTotalDebt: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  billingHistory: PropTypes.object,
  totalDebt: PropTypes.object,
  isLoadingGetBillingHistory: PropTypes.bool.isRequired,
  rentals: PropTypes.array,
  isLoadingGetRenterRentals: PropTypes.bool,
};

export default provideContext(BillHistoryForm);
