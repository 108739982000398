import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { NavLink } from 'react-router-dom';
import { provideContext } from '../../../../context/context.jsx';
import linkEnums from '../../../../enums/linksEnum.js';
import './mainMenu.scss';

class MainMenu extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(this.props.buyPackage, nextProps.buyPackage)) {
      return true;
    }
    if (!isEqual(this.props.showInitiateReturn, nextProps.showInitiateReturn)) {
      return true;
    }
    if (!isEqual(this.props.showBills, nextProps.showBills)) {
      return true;
    }
    if (!isEqual(this.props.showPayments, nextProps.showPayments)) {
      return true;
    }
    if (!isEqual(this.props.isNotFlagged, nextProps.isNotFlagged)) {
      return true;
    }
    if (!isEqual(this.props.language, nextProps.language)) {
      return true;
    }
    return false;
  }

  render() {
    const { context, buyPackage, showInitiateReturn, showBills, showPayments, showBuyPackage, showReferrals } = this.props;
    const { t } = this.props.context;
    let linkSupport;
    let showReferralLink = false
    if (window.location.host.endsWith('eu')) {
      linkSupport = linkEnums.FAQ_LINK.eu;
    } else if (window.location.host.endsWith('uk')) {
      linkSupport = linkEnums.FAQ_LINK.uk;
    } else if (window.location.host.endsWith('au')) {
      linkSupport = linkEnums.FAQ_LINK.au;
    } else if (window.location.host.endsWith('com')) {
      linkSupport = linkEnums.FAQ_LINK.us;
      if (showReferrals) {
        showReferralLink = true;
      }
    }


    return (
      <div className="MainMenu">
        <ul>
          <li>
            <NavLink activeClassName="active" to={context.appRoutes.HOME.url} exact>
              {t('account_overview_title', 'Account overview')}
            </NavLink>
          </li>
          {showReferralLink && (
            <li>
              <a href="https://referrals.happiestbaby.com" target="_blank">{t('referrals_title', 'Referrals')}</a>
            </li>
          )}
          <li>
            <NavLink activeClassName="active" to={context.appRoutes.PROFILE.url} exact>
              {t('menu_edit_profile', 'Edit profile')}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={context.appRoutes.DEVICES.url} exact>
              {t('devices')}
            </NavLink>
          </li>
          {showPayments && (
            <li>
              <NavLink activeClassName="active" to={context.appRoutes.PAYMENT.url} exact>
                {t('payments_title', 'Payments')}
              </NavLink>
            </li>
          )}
          {showBills && (
            <li>
              <NavLink activeClassName="active" to={context.appRoutes.BILLS.url}>
                {t('menu_bills', 'Bills')}
              </NavLink>
            </li>
          )}
          {showBuyPackage && (
            <li>
              <a target="_blank" href={buyPackage.permalink}>
                {t('buy_packaging')}
              </a>
            </li>
            )}
          <li>
            {showInitiateReturn && (
              <NavLink activeClassName="active" to={context.appRoutes.RETURN.url} exact>
                {t('initiate_return_text')}
              </NavLink>
            )}
          </li>
          <li>
            <a target="_blank" href={linkSupport}>
              {t('cc_title', 'FAQ')}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

MainMenu.propTypes = {
  context: PropTypes.object.isRequired,
  showInitiateReturn: PropTypes.bool.isRequired,
  isNotFlagged: PropTypes.bool.isRequired,
  showBills: PropTypes.bool.isRequired,
  showPayments: PropTypes.bool.isRequired,
  showBuyPackage: PropTypes.bool.isRequired,
  showReferrals: PropTypes.bool.isRequired,
};

export default provideContext(MainMenu);
