import { fork, all } from 'redux-saga/effects';

import apiUsersSagas from './apiUsers/apiUsersSagas';
import apiPaymentsSagas from './apiPayments/apiPaymentsSagas';
import apiRentalSagas from './apiRental/apiRentalSagas';
import apiRenterSagas from './apiRenter/apiRenterSagas';
import apiShippingSagas from './apiShipping/apiShippingSagas';
import apiReturnSagas from './apiReturn/apiReturnSagas';
import apiReferralSagas from './apiReferral/apiReferralSagas';
import apiOrdersSagas from './apiOrders/apiOrdersSagas';

export default function*() {
  yield all([
    fork(apiUsersSagas),
    fork(apiPaymentsSagas),
    fork(apiRentalSagas),
    fork(apiRenterSagas),
    fork(apiShippingSagas),
    fork(apiReturnSagas),
    fork(apiReferralSagas),
    fork(apiOrdersSagas)
  ]);
}
