import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

export const getReferrals = async payload => {
  const { isRental } = payload;
  const headers = await constructAuthHeaders();
  try {
    if (!isRental) {
      const response = await axios.get(getApiRouteUrl(routes.RETAIL_REFERRALS), headers);
      return { response };
    }
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_REFFERALS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const postReferrals = async payload => {
  const { email, isRental } = payload;

  const headers = await constructAuthHeaders();
  try {
    if (!isRental) {
      const response = await axios.post(getApiRouteUrl(routes.RETAIL_REFERRALS), { referredEmail: email }, headers);
      return { response };
    }

    const response = await axios.post(getApiRouteUrl(routes.RENTAL_RENTER_REFFERALS), { email }, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};
