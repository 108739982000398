import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import exclamationRedIcon from '../../../../assets/symbols/exclamation-red-icon.svg';
import exclamationGreenIcon from '../../../../assets/symbols/exclamation-green-icon.svg';
import { faCheckCircle, faMinusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import './message.scss';

class Message extends Component {
  render() {
    const { type, fullWidth, t } = this.props;

    return (
      <div className={classnames('Message', type, { 'full-width': fullWidth })} role="alert">
        {type !== 'info' && (
          <div className="icon">
            {type === 'success' && <FontAwesomeIcon icon={faCheckCircle} size={'lg'} />}
            {type === 'error' && <FontAwesomeIcon icon={faMinusCircle} size={'lg'} />}
            {type === 'success-light' && <img src={exclamationGreenIcon} alt="" />}
            {type === 'error-light' && <img src={exclamationRedIcon} alt="" />}
          </div>
        )}
        <div className="text">
          {type === 'info' && <h4>{t('note')}:</h4>}
          <p>{this.props.children}</p>
        </div>
      </div>
    );
  }
}

Message.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

export default provideContext(Message);
