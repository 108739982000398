import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { provideContext } from '../context/context.jsx';
import ScreenTitle from '../components/views/presentation/screenTitle/screenTitle.jsx';
import AppContainer from '../containers/appContainer.jsx';
import * as apiRenterSelectors from '../redux-api/apiRenter/apiRenterSelectors.js';
import BillHistoryForm from '../components/views/forms/billHistoryForm/billHistoryForm.jsx';
import {
  apiGetRenterRentalsRequestAction,
  apiGetRenterOrdersRequestAction,
  apiGetRenterBillingHistoryRequestAction,
  apiGetRenterTotalDebtRequestAction,
} from '../redux-api/apiRenter/apiRenterActions.js';
import {
  apiPostPaymentCustomerChargePastDueInvoicesAction,
  apiGetPaymentCustomerRequestAction,
} from '../redux-api/apiPayments/apiPaymentsActions.js';
import * as apiPaymentsSelectors from '../redux-api/apiPayments/apiPaymentsSelectors';
import DefaultLoader from '../components/views/loaders/defaultLoader/defaultLoader.jsx';

class BillsScreen extends PureComponent {
  state = {
    currentOrder: null,
    currentPeriod: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.currentOrder !== props.match.params.order) {
      const currentOrder = props.match.params.order;
      const currentPeriod = props.match.params.period;
      return {
        currentOrder,
        currentPeriod,
      };
    }
    return null;
  }


  componentDidMount() {
    this.props.actions.getOrders();
    this.props.actions.getRentals();
    this.props.actions.apiPaymentGetCustomer();
  }

  renderBillHistoryForm() {
    const { user, isLoadingGetRenterRentals, rentals } = this.props;
    return (
      <BillHistoryForm
        profile={{ ...user }}
        orders={this.props.orders}
        currentOrder={this.state.currentOrder}
        currentPeriod={this.state.currentPeriod}
        apiGetBillingHistory={this.props.actions.getBillingHistory}
        postChargePastDueInvoices={this.props.actions.postChargePastDueInvoices}
        customer={this.props.customer}
        lastManualChargeAttempt={this.props.lastManualChargeAttempt}
        chargeAttemptSuccess={this.props.chargeAttemptSuccess}
        chargeAttemptFailure={this.props.chargeAttemptFailure}
        apiGetTotalDebt={this.props.actions.getTotalDebt}
        billingHistory={this.props.billingHistory}
        totalDebt={this.props.totalDebt}
        isLoadingGetBillingHistory={this.props.isLoadingGetBillingHistory}
        rentals={rentals}
        isLoadingGetRenterRentals={isLoadingGetRenterRentals}
      />
    );
  }

  render() {
    const { t, isLoadingGetOrders, isLoadingPostChargePastDueInvoices, isLoadingGetCustomer } = this.props;

    if (isLoadingGetOrders === undefined || isLoadingGetOrders === true || isLoadingPostChargePastDueInvoices === true || isLoadingGetCustomer === true ) {
      return <AppContainer>
          <DefaultLoader type="block" />
        </AppContainer>;
    } else {
      return (
        <AppContainer>
          <div className="row">
            <div className="col-1">
              <ScreenTitle>{t('bill_screen_bill_history','Bill history')}</ScreenTitle>
            </div>
          </div>
          <div className="row">{this.renderBillHistoryForm()}</div>
        </AppContainer>
      );
    }
  }
}

BillsScreen.propTypes = {
  actions: PropTypes.shape({
    getOrders: PropTypes.func,
    getBillingHistory: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isLoadingGetOrders: apiRenterSelectors.isLoadingGetRenterOrders(state),
  isLoadingGetRenterRentals: apiRenterSelectors.isLoadingGetRenterRentals(state),
  isLoadingGetBillingHistory: apiRenterSelectors.isLoadingGetRenterBillingHistory(state),
  isLoadingGetCustomer: apiPaymentsSelectors.isLoadingGetCustomer(state),
  lastManualChargeAttempt: apiPaymentsSelectors.getLastManualChargeAttempt(state),
  chargeAttemptSuccess: apiPaymentsSelectors.getChargePastDueInvoicesSuccess(state),
  chargeAttemptFailure: apiPaymentsSelectors.getChargePastDueInvoicesErrors(state),
  orders: apiRenterSelectors.getOrders(state),
  rentals: apiRenterSelectors.getRentals(state),
  billingHistory: apiRenterSelectors.getBillingHistory(state),
  customer: apiPaymentsSelectors.getCustomer(state),
  totalDebt: apiRenterSelectors.getTotalDebt(state),
});
const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      getRentals: apiGetRenterRentalsRequestAction,
      getOrders: apiGetRenterOrdersRequestAction,
      getBillingHistory: apiGetRenterBillingHistoryRequestAction,
      postChargePastDueInvoices: apiPostPaymentCustomerChargePastDueInvoicesAction,
      apiPaymentGetCustomer: apiGetPaymentCustomerRequestAction,
      getTotalDebt: apiGetRenterTotalDebtRequestAction,
    },
    dispatch,
  );
  return { actions };
};
export default provideContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BillsScreen),
);
