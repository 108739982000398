import merge from 'lodash/merge';
import { ActionTypes } from './apiRenterActions';

export const initialState = {
  errors: {},
  billingAddressSuccess: {},
  billingAddressError: {},
  registeringUser: {},
  renter: {},
  billingAddress: {},
  buyPackage: {},
  rentals: [],
  orders: [],
  billingHistory: {},
  totalDebt: {},
};

const apiRenter = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_PUT_RENTER_UPDATE_SUCCESS:
      return {
        ...state,
        renter: action.payload,
      };
    case ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_SUCCESS:
    case ActionTypes.API_GET_RENTER_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        billingAddress: action.payload,
        billingAddressSuccess: action.payload,
      };
    case ActionTypes.API_PUT_RENTER_UPDATE_FAILURE:
    case ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_FAILURE:
    case ActionTypes.API_GET_RENTER_BILLING_ADDRESS_FAILURE:
    case ActionTypes.API_GET_BUY_PACKAGE_FAILURE:
    case ActionTypes.API_GET_RENTER_ORDERS_FAILURE:
    case ActionTypes.API_GET_RENTER_TOTAL_DEBT_FAILURE:
    case ActionTypes.API_GET_RENTER_BILLING_HISTORY_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case ActionTypes.API_GET_RENTER_RENTALS_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_FAILURE:
      return {
        ...state,
        billingAddressError: action.payload,
      };
    case ActionTypes.API_GET_RENTER_RENTALS_SUCCESS:
      return {
        ...state,
        rentals: action.payload,
      };
    case ActionTypes.API_GET_BUY_PACKAGE_SUCCESS:
      return {
        ...state,
        buyPackage: action.payload,
      };
    case ActionTypes.API_GET_RENTER_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case ActionTypes.API_GET_RENTER_BILLING_HISTORY_SUCCESS:
      const billingHistory = {
        [action.payload.order]: { [action.payload.period]: action.payload },
      };

      return {
        ...state,
        billingHistory: merge({}, state.billingHistory, billingHistory),
      };
    case ActionTypes.API_GET_RENTER_TOTAL_DEBT_SUCCESS:
      return {
        ...state,
        totalDebt: action.payload,
      };
    default:
      return state;
  }
};

export default apiRenter;
