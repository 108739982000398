import merge from 'lodash/merge';
import { ActionTypes } from './apiReturnActions';

export const initialState = {
  errors: {},
  returnItems: [],
  reasons: {},
  successes: {},
};

const apiReturn = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_GET_RETURN_ITEMS_FAILURE:
    case ActionTypes.API_POST_RENTAL_INITIATE_RETURN_FAILURE:
    case ActionTypes.API_GET_RENTAL_CANCELLING_REASONS_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case ActionTypes.API_GET_RETURN_ITEMS_SUCCESS:
      return {
        ...state,
        returnItems: action.payload,
      };
    case ActionTypes.API_POST_RENTAL_INITIATE_RETURN_SUCCESS:
      return {
        ...state,
        successes: action.payload,
        returnItems: merge(state.returnItems, action.payload),
      };
    case ActionTypes.API_GET_RENTAL_CANCELLING_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload,
      };
    default:
      return state;
  }
};

export default apiReturn;
