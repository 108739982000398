import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { NavLink } from 'react-router-dom';

import classnames from 'classnames';
import { provideContext } from '../../../../context/context.jsx';
import linkEnums from '../../../../enums/linksEnum.js';
import './mobileMainMenu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

class MobileMainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  toggleMenu() {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
    document.body.style.overflowY = this.state.mobileMenuOpen ? 'auto' : 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
  }

  render() {
    const {
      t,
      context,
      buyPackage,
      showInitiateReturn,
      logoutUser,
      showPayments,
      showBills,
      showBuyPackage,
      showReferrals,
    } = this.props;
    let linkSupport;
    let showReferralLink = false;
    if (window.location.host.endsWith('eu')) {
      linkSupport = linkEnums.FAQ_LINK.eu;
    } else if (window.location.host.endsWith('uk')) {
      linkSupport = linkEnums.FAQ_LINK.uk;
    } else if (window.location.host.endsWith('au')) {
      linkSupport = linkEnums.FAQ_LINK.au;
    } else if (window.location.host.endsWith('com')) {
      linkSupport = linkEnums.FAQ_LINK.us;
      if (showReferrals) {
        showReferralLink = true;
      }
    }

    return (
      <div className={classnames('MobileMainMenu', { open: this.state.mobileMenuOpen })}>
        <div className="burger-menu" onClick={() => this.toggleMenu()}>
          {this.state.mobileMenuOpen ? (
            <FontAwesomeIcon icon={faTimes} className="menu-icon" />
          ) : (
            <FontAwesomeIcon icon={faBars} className="menu-icon" />
          )}
        </div>
        {this.state.mobileMenuOpen && (
          <ul>
            <li>
              <NavLink activeClassName="active" to={context.appRoutes.HOME.url} exact>
                {t('account_overview_title')}
              </NavLink>
            </li>
            {showReferralLink && (
              <li>
                <NavLink activeClassName="active" to={context.appRoutes.REFFERALS.url} exact>
                  {t('referrals_title')}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink activeClassName="active" to={context.appRoutes.PROFILE.url} exact>
                {t('menu_edit_profile')}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to={context.appRoutes.DEVICES.url} exact>
                {t('devices')}
              </NavLink>
            </li>
            {showPayments && (
              <li>
                <NavLink activeClassName="active" to={context.appRoutes.PAYMENT.url} exact>
                  {t('payments_title')}
                </NavLink>
              </li>
            )}
            {showBills && (
              <li>
                <NavLink activeClassName="active" to={context.appRoutes.BILLS.url}>
                  {t('menu_bills')}
                </NavLink>
              </li>
            )}
            {showBuyPackage && (
              <li>
                <a target="_blank" href={buyPackage.permalink}>
                  {t('buy_packaging')}
                </a>
              </li>
            )}
            <li>
              {showInitiateReturn && (
                <NavLink activeClassName="active" to={context.appRoutes.RETURN.url} exact>
                  {t('initiate_return_text')}
                </NavLink>
              )}
            </li>
            <li>
              <a target="_blank" href={linkSupport}>
                {t('cc_title')}
              </a>
            </li>
            <li>
              <span onClick={logoutUser}>{t('button_text_logout')}</span>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

MobileMainMenu.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  showInitiateReturn: PropTypes.bool.isRequired,
  isNotFlagged: PropTypes.bool.isRequired,
  showPayments: PropTypes.bool.isRequired,
  showBills: PropTypes.bool.isRequired,
  showBuyPackage: PropTypes.bool.isRequired,
  showReferrals: PropTypes.bool.isRequired,
};

export default provideContext(MobileMainMenu);
