import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

extendMoment(Moment);

import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Container,
  FormGroup,
} from 'reactstrap';
import DatePicker from 'react-datepicker';

import calendarIcon from '../../../../assets/shipping-date/calendar.svg';
import DefaultButton from '../../buttons/defaultButton/defaultButton.jsx';
import { provideContext } from '../../../../context/context.jsx';
import Message from '../../presentation/message/message.jsx';
import './changeShippingDateModal.scss';

class ChangeShippingDateModal extends Component {
  state = {
    rentalId: this.props.rentalId,
    startDate: this.props.shippingDate,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rentalId !== state.rentalId) {
      return {
        rentalId: props.rentalId,
        startDate: props.shippingDate,
      };
    }
    return null;
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  renderError() {
    const message = this.props.error.error.message;
    return <Message type="error">{message}</Message>;
  }

  renderSuccess() {
    return (
      <Message type="success">
        {this.props.t('change_shipping_date_modal_success','Your shipping date has been updated successfully')}
      </Message>
    );
  }

  handleOnClick = () => {
    this.props.onClick(this.state.rentalId, this.state.startDate);
  };

  render() {
    const { t, toggle, modal, isLoading } = this.props;
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="ChangeShippingDateModal"
      >
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <Container>
            <Row>
              <Col md={12}>
                <div className="ChangeShippingDateModal__title">
                  {t('change_shipping_date_modal_select_date','Please select desired shipping date')}
                </div>
                {!isEmpty(this.props.error) && this.renderError()}
                {!isEmpty(this.props.success) && this.renderSuccess()}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup size="large" className="text-center pb-5">
                  <img src={calendarIcon} className="calendar-icon" />
                  <DatePicker
                    className="form-component"
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>
                <FormGroup size="large" className="pt-3 text-center">
                  <DefaultButton
                    label={t('change_shipping_date_modal_change','Change Shipping Date')}
                    onClick={this.handleOnClick}
                    isBlock={true}
                    isLoading={isLoading}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

ChangeShippingDateModal.defaultProps = {
  error: {},
  success: {},
};

ChangeShippingDateModal.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
};

export default provideContext(ChangeShippingDateModal);
