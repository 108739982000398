import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import i18next from './utils/i18n/i18next';


import App from './app.jsx';

const renderApp = () => {
  ReactDOM.render(React.createElement(App), document.getElementById('root'));
};

renderApp();
