import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Input from '../../input/input.jsx';
import classnames from 'classnames';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { provideContext } from '../../../../context/context.jsx';
import ScreenSubTitle from '../../presentation/screenSubTitle/screenSubTitle.jsx';
import Button from '../../button/button.jsx';
import Message from '../../presentation/message/message.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestion } from '@fortawesome/free-solid-svg-icons';

import visa from '../../../../assets/icons/visa.png';
import mastercard from '../../../../assets/icons/mastercard.png';
import americanExpress from '../../../../assets/icons/american-express.png';
import discover from '../../../../assets/icons/discover.png';

import DefaultLoader from '../../../../components/views/loaders/defaultLoader/defaultLoader.jsx';

import './creditCardForm.scss';

class CreditCardForm extends Component {
  state = {
    card: this.props.card,
    customer: this.props.customer,
    required: {},
    cardEditMode: false,
    cardNumber: {
      focus: false,
      empty: true,
    },
    cardExpiry: {
      focus: false,
      empty: true,
    },
    cardCvc: {
      focus: false,
      empty: true,
    },
    cancelClicked: false,
  };

  renderError(message) {
    return <Message type="error">{message}</Message>;
  }

  renderErrorRequired(required) {
    return <Message type="error">{required.message}</Message>;
  }

  renderSuccess() {
    return (
      <Message type="success">
        {this.props.t('credit_card_form_message_update_success', 'Your card details have been updated successfully')}
      </Message>
    );
  }

  updateCreditCard = async event => {
    event.preventDefault();
    try {
      if (!this.state.card.name) {
        const required = { message: this.props.t('all_fields_are_required', 'All fields are required') };
        this.setState({ required, error: undefined });
      } else {
        this.setState({ required: {} });
        const { clientSecret } = this.props.card.setupIntent;
        const { setupIntent, error } = await this.props.stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: this.props.elements.getElement('cardNumber'),
          },
        });

        if (error) {
          console.error(error);
          this.setState({ error });
        } else {
          const { payment_method, status } = setupIntent;
          if (status === 'succeeded') {
            await this.props.apiPaymentSaveCard({
              paymentMethod: payment_method,
              cardholderName: this.state.card.name
            })
            await this.props.apiPaymentUpdateCustomer()
          }
          this.setState({
            cardEditMode: false,
            error: undefined,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  formatCardExpiry() {
    return ('0' + this.props.card.expMonth).slice(-2) + '/' + String(this.props.card.expYear).slice(-2);
  }

  handleChange = e => {
    e.persist();
    this.setState(state => ({
      card: {
        ...state.card,
        name: e.target.value,
      },
    }));
  };

  changeStripeElementFocus = (e, set) => {
    this.setState({
      [e.elementType]: {
        focus: set,
        empty: this.state[e.elementType].empty,
      },
    });
  };

  onStripeElementChange = e => {
    this.setState({
      [e.elementType]: {
        empty: e.empty,
        focus: this.state[e.elementType].focus,
      },
    });
  };

  renderCardNumberElement() {
    const { t } = this.props;
    const { cardNumber } = this.state;
    if (this.state.cardEditMode) {
      return (
        <div className="stripe-input">
          <label
            className={classnames({
              focus: cardNumber.focus || !cardNumber.empty,
            })}
          >
            {t('credit_card_element_card_number', 'Card number')}
          </label>
          <div className="input-wrap">
            <CardNumberElement
              placeholder={''}
              onReady={c => (this._elementCreditCardNumber = c)}
              onFocus={e => this.changeStripeElementFocus(e, true)}
              onBlur={e => this.changeStripeElementFocus(e, false)}
              onChange={this.onStripeElementChange}
            />
          </div>
          <FontAwesomeIcon icon={faLock} className="stripe-icon" />
        </div>
      );
    }
    return (
      <Input
        disabled={true}
        type="name"
        placeholder={t('card_number', 'Card number')}
        defaultValue={'***********' + this.props.card.last4}
      />
    );
  }

  renderCardNameElement() {
    return (
      <Input
        disabled={!this.state.cardEditMode}
        type="name"
        placeholder={this.props.t('cardholder_name', 'Cardholder name')}
        value={this.state.card.name || ''}
        onChange={this.handleChange}
      />
    );
  }

  renderCardExpiryElement() {
    const { t } = this.props;
    const { cardExpiry } = this.state;
    if (this.state.cardEditMode) {
      return (
        <div className="stripe-input">
          <label
            className={classnames({
              focus: cardExpiry.focus || !cardExpiry.empty,
            })}
          >
            {t('mm/yy', 'MM/YY')}
          </label>
          <div className="input-wrap">
            <CardExpiryElement
              placeholder={''}
              onReady={c => (this._elementCreditCardExpiry = c)}
              onFocus={e => this.changeStripeElementFocus(e, true)}
              onBlur={e => this.changeStripeElementFocus(e, false)}
              onChange={this.onStripeElementChange}
            />
          </div>
        </div>
      );
    }
    return (
      <Input disabled={true} type="name" placeholder={t('mm/yy', 'MM/YY')} defaultValue={this.formatCardExpiry()} />
    );
  }

  renderCVVElement() {
    const { t } = this.props;
    const { cardCvc } = this.state;
    if (this.state.cardEditMode) {
      return (
        <div className="stripe-input">
          <label
            className={classnames({
              focus: cardCvc.focus || !cardCvc.empty,
            })}
          >
            {t('cvv', 'CVV')}
          </label>
          <div className="input-wrap">
            <CardCVCElement
              placeholder={''}
              onReady={c => (this._elementCreditCardCvv = c)}
              onFocus={e => this.changeStripeElementFocus(e, true)}
              onBlur={e => this.changeStripeElementFocus(e, false)}
              onChange={this.onStripeElementChange}
            />
          </div>
          <FontAwesomeIcon icon={faQuestion} className="stripe-icon" />
        </div>
      );
    }
    return <Input disabled={true} type="name" placeholder={t('cvv', 'CVV')} defaultValue={'***'} />;
  }

  enterCardEditMode = async e => {
    e.preventDefault();
    try {
      this.setState({
        cardEditMode: true,
        error: undefined,
        cancelClicked: false,
      });
      await this.props.apiPaymentCreateSetupIntent();
    } catch (err) {
      console.error(err);
      this.setState({ cardEditMode: false });
    }
  };

  exitCardEditMode = e => {
    e.preventDefault();
    this.setState({
      cardEditMode: false,
      error: undefined,
      required: {},
      card: {
        ...this.props.card,
      },
      cancelClicked: true,
    });
  };

  render() {
    const { t } = this.props;
    return !this.props.isLoading ? (
      <div className="CreditCardForm">
        <div className="row">
          <div className="col-1">
            {!isEmpty(this.props.success) &&
              !this.state.cardEditMode &&
              !this.state.cancelClicked &&
              this.renderSuccess()}
            {!isEmpty(this.state.error) && this.renderError(this.state.error.message || this.state.error.error.message)}
            {!isEmpty(this.state.required) && this.renderErrorRequired(this.state.required)}
          </div>
        </div>
        <ScreenSubTitle>{t('credit_card_form_payment_method', 'Payment method')}</ScreenSubTitle>
        <div className="sub-header">
          {t('credit_card_form_transaction_encription_info', 'All transactions are secure and encrypted.')}
        </div>
        <div className="stripe-form">
          <React.Fragment>
            <div className="form-heading">
              <div className="form-heading-left">
                <h5>{t('global_credit_card', 'Credit Card')}</h5>
              </div>
              <div className="form-heading-right">
                {/* <span className="card visa" /> */}
                <img
                  className="card"
                  alt={t('credit_card_form_supported_card_visa', 'Supported card - Visa')}
                  src={visa}
                />
                <img
                  className="card"
                  alt={t('credit_card_form_supported_card_mastercard', 'Supported card - Mastercard')}
                  src={mastercard}
                />
                <img
                  className="card"
                  alt={t('credit_card_form_supported_card_american_express', 'Supported card - American Express card')}
                  src={americanExpress}
                />
                <img
                  className="card"
                  alt={t('credit_card_form_supported_card_discover_card', 'Supported card - Discover card')}
                  src={discover}
                />
                <span className="text">{t('credit_card_form_more_options', 'and more...')}</span>
              </div>
            </div>
            <div className="form-body">
              <div className="row">
                <div className="col-1">{this.renderCardNumberElement()}</div>
              </div>
              <div className="row">
                <div className="col-1-2">{this.renderCardNameElement()}</div>
                <div className="col-1-4">{this.renderCardExpiryElement()}</div>
                <div className="col-1-4">{this.renderCVVElement()}</div>
              </div>
            </div>
          </React.Fragment>
        </div>
        {!this.state.cardEditMode && (
          <div className="button-wrap">
            <Button color={'green'} onClick={this.enterCardEditMode}>
              {t('button_text_update_credit_card', 'Update Credit Card')}
            </Button>
          </div>
        )}
        {this.state.cardEditMode && (
          <div className="button-wrap">
            <Button color={'green'} onClick={this.updateCreditCard}>{t('button_text_save_credit_card', 'Save Credit Card')}</Button>
            <Button color={'green-inverted'} onClick={this.exitCardEditMode}>
              {t('button_text_cancel', 'Cancel')}
            </Button>
          </div>
        )}
      </div>
    ) : (
      <DefaultLoader type="block" />
    );
  }
}

CreditCardForm.defaultProps = {
  readOnly: false,
  success: {},
};

CreditCardForm.propTypes = {
  t: PropTypes.func.isRequired,
  customer: PropTypes.object,
  card: PropTypes.object,
  readOnly: PropTypes.bool,
  context: PropTypes.object.isRequired,
};

export default injectStripe(provideContext(CreditCardForm));
