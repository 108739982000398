import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';
import moment from 'moment';

import './referralsTable.scss';

class ReferralsTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, referrals } = this.props;

    if (referrals.length != undefined && referrals.length === 0) {
      return <div>{t('no_referrals')}</div>;
    }

    return (
      <div className="ReferralsTable">
        <table className="referrals-table">
          <thead>
            <tr className="">
              <th />
              <th className="header-description">{t('global_email')}</th>
              <th className="header-period">{t('referrals_table_date', 'Date')}</th>
              <th className="header-amount">{t('referrals_table_status', 'Status')}</th>
            </tr>
          </thead>
          <tbody>
            {referrals.map((pi, idx) => {
              return (
                <tr key={idx}>
                  <td />
                  <td>{pi.email}</td>
                  <td>{moment(pi.createdAt).format('YYYY-MM-DD')}</td>
                  <td>{pi.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

ReferralsTable.propTypes = {
  t: PropTypes.func.isRequired,
  referrals: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
};

export default provideContext(ReferralsTable);
