export const ActionTypes = {
  API_GET_PAYMENT_CUSTOMER_REQUEST: 'API_GET_PAYMENT_CUSTOMER_REQUEST',
  API_GET_PAYMENT_CUSTOMER_SUCCESS: 'API_GET_PAYMENT_CUSTOMER_SUCCESS',
  API_GET_PAYMENT_CUSTOMER_FAILURE: 'API_GET_PAYMENT_CUSTOMER_FAILURE',
  API_POST_PAYMENT_CUSTOMER_REQUEST: 'API_POST_PAYMENT_CUSTOMER_REQUEST',
  API_POST_PAYMENT_CUSTOMER_SUCCESS: 'API_POST_PAYMENT_CUSTOMER_SUCCESS',
  API_POST_PAYMENT_CUSTOMER_FAILURE: 'API_POST_PAYMENT_CUSTOMER_FAILURE',
  API_PUT_PAYMENT_CUSTOMER_REQUEST: 'API_PUT_PAYMENT_CUSTOMER_REQUEST',
  API_PUT_PAYMENT_CUSTOMER_SUCCESS: 'API_PUT_PAYMENT_CUSTOMER_SUCCESS',
  API_PUT_PAYMENT_CUSTOMER_FAILURE: 'API_PUT_PAYMENT_CUSTOMER_FAILURE',
  API_POST_PAYMENT_SETUPINTENT_REQUEST: 'API_POST_PAYMENT_SETUPINTENT_REQUEST',
  API_POST_PAYMENT_SETUPINTENT_SUCCESS: 'API_POST_PAYMENT_SETUPINTENT_SUCCESS',
  API_POST_PAYMENT_SETUPINTENT_FAILURE: 'API_POST_PAYMENT_SETUPINTENT_FAILURE',
  API_POST_PAYMENT_CARD_REQUEST: 'API_POST_PAYMENT_CARD_REQUEST',
  API_POST_PAYMENT_CARD_SUCCESS: 'API_POST_PAYMENT_CARD_SUCCESS',
  API_POST_PAYMENT_CARD_FAILURE: 'API_POST_PAYMENT_CARD_FAILURE',
  API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_REQUEST:
    'API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_REQUEST',
  API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_SUCCESS:
    'API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_SUCCESS',
  API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_FAILURE:
    'API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_FAILURE',
  API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_REQUEST:
    'API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_REQUEST',
  API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_SUCCESS:
    'API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_SUCCESS',
  API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_FAILURE:
    'API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_FAILURE',
  API_GET_PAYMENT_CARD_REQUEST: 'API_GET_PAYMENT_CARD_REQUEST',
  API_GET_PAYMENT_CARD_SUCCESS: 'API_GET_PAYMENT_CARD_SUCCESS',
  API_GET_PAYMENT_CARD_FAILURE: 'API_GET_PAYMENT_CARD_FAILURE',
  API_POST_CHARGE_PAST_DUE_INVOICES_REQUEST: 'API_POST_CHARGE_PAST_DUE_INVOICES_REQUEST',
  API_POST_CHARGE_PAST_DUE_INVOICES_SUCCESS: 'API_POST_CHARGE_PAST_DUE_INVOICES_SUCCESS',
  API_POST_CHARGE_PAST_DUE_INVOICES_FAILURE: 'API_POST_CHARGE_PAST_DUE_INVOICES_FAILURE',
};

export const apiGetPaymentCustomerRequestAction = customerId => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_REQUEST,
  payload: { customerId },
});

export const apiGetPaymentCustomerSuccessAction = customer => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_SUCCESS,
  payload: customer,
});

export const apiGetPaymentCustomerFailureAction = error => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_FAILURE,
  payload: error,
});

export const apiPostPaymentCustomerRequestAction = customer => ({
  type: ActionTypes.API_POST_PAYMENT_CUSTOMER_REQUEST,
  payload: { customer },
});

export const apiPostPaymentCustomerSuccessAction = customer => ({
  type: ActionTypes.API_POST_PAYMENT_CUSTOMER_SUCCESS,
  payload: customer,
});

export const apiPostPaymentCustomerFailureAction = error => ({
  type: ActionTypes.API_POST_PAYMENT_CUSTOMER_FAILURE,
  payload: error,
});

export const apiPutPaymentCustomerRequestAction = () => ({
  type: ActionTypes.API_PUT_PAYMENT_CUSTOMER_REQUEST,
});

export const apiPutPaymentCustomerSuccessAction = customer => ({
  type: ActionTypes.API_PUT_PAYMENT_CUSTOMER_SUCCESS,
  payload: customer,
});

export const apiPutPaymentCustomerFailureAction = error => ({
  type: ActionTypes.API_PUT_PAYMENT_CUSTOMER_FAILURE,
  payload: error,
});

export const apiPostSetupIntentRequestAction = () => ({
  type: ActionTypes.API_POST_PAYMENT_SETUPINTENT_REQUEST
})

export const apiPostSetupIntentSuccessAction = setupIntent => ({
  type: ActionTypes.API_POST_PAYMENT_SETUPINTENT_SUCCESS,
  payload: setupIntent
})

export const apiPostSetupIntentFailureAction = error => ({
  type: ActionTypes.API_POST_PAYMENT_SETUPINTENT_FAILURE,
  payload: error
})

export const apiPostPaymentCardRequestAction = payload => ({
  type: ActionTypes.API_POST_PAYMENT_CARD_REQUEST,
  payload,
});

export const apiPostPaymentCardSuccessAction = card => ({
  type: ActionTypes.API_POST_PAYMENT_CARD_SUCCESS,
  payload: card,
});

export const apiPostPaymentCardFailureAction = error => ({
  type: ActionTypes.API_POST_PAYMENT_CARD_FAILURE,
  payload: error,
});

export const apiGetPaymentCustomerAccountBalanceRequestAction = customerId => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_REQUEST,
  payload: customerId,
});

export const apiGetPaymentCustomerAccountBalanceSuccessAction = accountBalance => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_SUCCESS,
  payload: accountBalance,
});

export const apiGetPaymentCustomerAccountBalanceFailureAction = error => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_FAILURE,
  payload: error,
});

export const apiGetPaymentCustomerUpcomingInvoicesRequestAction = id => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_REQUEST,
  payload: id,
});

export const apiGetPaymentCustomerUpcomingInvoicesSuccessAction = upcomingInvoices => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_SUCCESS,
  payload: upcomingInvoices,
});

export const apiGetPaymentCustomerUpcomingInvoicesFailureAction = error => ({
  type: ActionTypes.API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_FAILURE,
  payload: error,
});

export const apiGetPaymentCardRequestAction = id => ({
  type: ActionTypes.API_GET_PAYMENT_CARD_REQUEST,
  payload: id,
});

export const apiGetPaymentCardSuccessAction = card => ({
  type: ActionTypes.API_GET_PAYMENT_CARD_SUCCESS,
  payload: card,
});

export const apiGetPaymentCardFailureAction = error => ({
  type: ActionTypes.API_GET_PAYMENT_CARD_FAILURE,
  payload: error,
});

export const apiPostPaymentCustomerChargePastDueInvoicesAction = (
  customerId,
) => ({
  type: ActionTypes.API_POST_CHARGE_PAST_DUE_INVOICES_REQUEST,
  payload: { customerId },
});

export const apiPostPaymentCustomerChargePastDueInvoicesSuccessAction = message => ({
  type: ActionTypes.API_POST_CHARGE_PAST_DUE_INVOICES_SUCCESS,
  payload: message,
});

export const apiPostPaymentCustomerChargePastDueInvoicesFailureAction = error => ({
  type: ActionTypes.API_POST_CHARGE_PAST_DUE_INVOICES_FAILURE,
  payload: error,
});
