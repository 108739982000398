import { takeEvery, call, put, take, select, fork } from 'redux-saga/effects';
import {
  savePaymentCustomer,
  updatePaymentCustomer,
  getPaymentCustomer,
  getPaymentCard,
  createSetupIntent,
  savePaymentCard,
  getPaymentCustomerAccountBalance,
  getPaymentCustomerUpcomingInvoices,
  postPaymentCustomerChargePastDueInvoices,
} from './api';

import {
  ActionTypes,
  apiPostPaymentCustomerSuccessAction,
  apiPostPaymentCustomerFailureAction,
  apiPutPaymentCustomerSuccessAction,
  apiPutPaymentCustomerFailureAction,
  apiGetPaymentCustomerSuccessAction,
  apiGetPaymentCustomerFailureAction,
  apiPostSetupIntentSuccessAction,
  apiPostSetupIntentFailureAction,
  apiPostPaymentCardSuccessAction,
  apiPostPaymentCardFailureAction,
  apiGetPaymentCustomerAccountBalanceSuccessAction,
  apiGetPaymentCustomerAccountBalanceFailureAction,
  apiGetPaymentCustomerUpcomingInvoicesSuccessAction,
  apiGetPaymentCustomerUpcomingInvoicesFailureAction,
  apiGetPaymentCardSuccessAction,
  apiGetPaymentCardFailureAction,
  apiPostPaymentCustomerChargePastDueInvoicesSuccessAction,
  apiPostPaymentCustomerChargePastDueInvoicesFailureAction,
} from './apiPaymentsActions';

import * as Rental from '../apiRental/apiRentalActions';

import * as apiPaymentsSelectors from './apiPaymentsSelectors';
import * as apiRentalSelectors from '../apiRental/apiRentalSelectors';

export function* apiSavePaymentCustomer(action) {
  const { response, error } = yield call(savePaymentCustomer, action.payload);
  if (response) {
    yield put(apiPostPaymentCustomerSuccessAction(response.data));
  } else {
    yield put(apiPostPaymentCustomerFailureAction(error));
  }
}

export function* apiUpdatePaymentCustomer(action) {
  yield take(ActionTypes.API_POST_PAYMENT_CARD_SUCCESS);
  const card = yield select(apiPaymentsSelectors.getCard);
  const { response, error } = yield call(updatePaymentCustomer, {
    paymentMethod: card.id,
  });
  if (response) {
    yield put(apiPutPaymentCustomerSuccessAction(response.data));
  } else {
    yield put(apiPutPaymentCustomerFailureAction(error));
  }
}

export function* apiGetPaymentCustomer() {
  yield take([Rental.ActionTypes.API_GET_RENTER_FROM_USER_ID_SUCCESS]);
  const renter = yield select(apiRentalSelectors.getRenter);
  const { response, error } = yield call(
    getPaymentCustomer,
    renter.paymentCustomerId,
  );
  if (response) {
    yield put(apiGetPaymentCustomerSuccessAction(response.data));
  } else {
    yield put(apiGetPaymentCustomerFailureAction(error));
  }
}

export function* apiGetPaymentCard(action) {
  yield take([ActionTypes.API_GET_PAYMENT_CUSTOMER_SUCCESS]);
  let cardId = action.payload;
  if (cardId === undefined) {
    const customer = yield select(apiPaymentsSelectors.getCustomer);
    cardId = customer.defaultSource;
  }
  const { response, error } = yield call(getPaymentCard, cardId);
  if (response) {
    yield put(apiGetPaymentCardSuccessAction(response.data));
  } else {
    yield put(apiGetPaymentCardFailureAction(error));
  }
}

export function* apiCreateSetupIntent() {
  const { response, error } = yield call(createSetupIntent)
  if (response) {
    yield put(apiPostSetupIntentSuccessAction(response.data))
  } else {
    yield put(apiPostSetupIntentFailureAction(error))
  }
}

export function* apiSavePaymentCard(action) {
  const { response, error } = yield call(savePaymentCard, action.payload);
  if (response) {
    yield put(apiPostPaymentCardSuccessAction(response.data));
  } else {
    yield put(apiPostPaymentCardFailureAction(error));
  }
}

export function* apiGetPaymentCustomerAccountBalance() {
  yield take([
    Rental.ActionTypes.API_GET_RENTER_FROM_USER_ID_SUCCESS,
    Rental.ActionTypes.API_POST_GENERATE_GIFT_CARD_SUCCESS,
    Rental.ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_SUCCESS,
  ]);

  const renter = yield select(apiRentalSelectors.getRenter);
  const { response, error } = yield call(
    getPaymentCustomerAccountBalance,
    renter.paymentCustomerId,
  );
  if (response) {
    yield put(apiGetPaymentCustomerAccountBalanceSuccessAction(response.data));
  } else {
    yield put(
      apiGetPaymentCustomerAccountBalanceFailureAction(error),
    );
  }
}

export function* apiGetPaymentCustomerUpcomingInvoices() {
  yield take(Rental.ActionTypes.API_GET_RENTER_FROM_USER_ID_SUCCESS);

  const renter = yield select(apiRentalSelectors.getRenter);
  const { response, error } = yield call(
    getPaymentCustomerUpcomingInvoices,
    renter.paymentCustomerId,
  );
  if (response) {
    yield put(
      apiGetPaymentCustomerUpcomingInvoicesSuccessAction(response.data),
    );
  } else {
    yield put(
      apiGetPaymentCustomerUpcomingInvoicesFailureAction(error),
    );
  }
}

export function* apiPostPaymentCustomerChargePastDueInvoices(action) {
  const { customerId } = action.payload;
  const { response, error } = yield call(postPaymentCustomerChargePastDueInvoices, customerId);
  if (response) {
    yield put(apiPostPaymentCustomerChargePastDueInvoicesSuccessAction(response.data));
  } else {
    yield put(apiPostPaymentCustomerChargePastDueInvoicesFailureAction(error));
  }
}

export default function* apiPaymentsSaga() {
  yield takeEvery(
    ActionTypes.API_POST_PAYMENT_CUSTOMER_REQUEST,
    apiSavePaymentCustomer,
  );
  yield takeEvery(
    ActionTypes.API_GET_PAYMENT_CUSTOMER_REQUEST,
    apiGetPaymentCustomer,
  );
  yield takeEvery(ActionTypes.API_GET_PAYMENT_CARD_REQUEST, apiGetPaymentCard);
  yield takeEvery(ActionTypes.API_POST_PAYMENT_SETUPINTENT_REQUEST, apiCreateSetupIntent)
  yield takeEvery(
    ActionTypes.API_POST_PAYMENT_CARD_REQUEST,
    apiSavePaymentCard,
  );
  yield takeEvery(
    ActionTypes.API_PUT_PAYMENT_CUSTOMER_REQUEST,
    apiUpdatePaymentCustomer,
  );
  yield takeEvery(
    ActionTypes.API_GET_PAYMENT_CUSTOMER_ACCOUNT_BALANCE_REQUEST,
    apiGetPaymentCustomerAccountBalance,
  );
  yield takeEvery(
    ActionTypes.API_GET_PAYMENT_CUSTOMER_UPCOMING_INVOICES_REQUEST,
    apiGetPaymentCustomerUpcomingInvoices,
  );
  yield takeEvery(
    ActionTypes.API_POST_CHARGE_PAST_DUE_INVOICES_REQUEST,
    apiPostPaymentCustomerChargePastDueInvoices,
  );
}
