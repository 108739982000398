import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import isEmpty from 'lodash/isEmpty';
import ReactTooltip from 'react-tooltip';

import { provideContext } from '../../../../context/context.jsx';
import Currency from '../../../../components/views/formats/currencyFormat/currency.jsx';
import ShippingTimeline from '../../../../components/views/presentation/shippingTimeline/shippingTimeline.jsx';
import OverviewPanel from '../../../../components/views/presentation/overviewPanel/overviewPanel.jsx';

import linkEnums from '../../../../enums/linksEnum';

import babyInSnooIcon from '../../../../assets/icons/baby-in-snoo.svg';
import snooBoxPickedUpInactiveIcon from '../../../../assets/icons/snoo-box-picked-up-inactive.svg';
import snooBoxPickedUpActiveIcon from '../../../../assets/icons/snoo-box-picked-up-active.svg';
import snooBoxReceivedInactiveIcon from '../../../../assets/icons/snoo-box-received-inactive.svg';
import snooBoxReceivedActiveIcon from '../../../../assets/icons/snoo-box-received-active.svg';
import calendarIcon from '../../../../assets/icons/calendar-active.svg';
import subscriptionStopedIcon from '../../../../assets/icons/subscription-stoped-active.svg';
import creditCardActiveIcon from '../../../../assets/icons/credit-card-active.svg';

import ReactGA from 'react-ga4';
import { Trans } from 'react-i18next';

class SnooAccountOverview extends PureComponent {
  getDataForOverviewPanel(rentalItem) {
    const { t, context, rental, upcomingInvoices } = this.props;
    const upcomingInvoice = upcomingInvoices.find((invoice) => {
      return invoice.id === rental.rental.subscriptionId;
    });

    const overviewPanelData = {
      title:
        rental.currentPeriod < 4
          ? (
            <Trans i18nKey="snoo_account_overview_month_of_rental" values={{ count: rental.currentPeriod }} postProcess="interval">
              {{ count: rental.currentPeriod }} month of your rental
            </Trans>
          )
          : (
            <Trans i18nKey="snoo_account_overview_month_of_rental" values={{ count: rental.currentPeriod }} postProcess="interval">
              {{ count: rental.currentPeriod }} month of your rental
            </Trans>
          ),
      iconName: calendarIcon,
      showButton: true,
      showShippingDate: false,
      editShippingDate: false,
      itemData: [
        {
          title: t('next_billing_date_text'),
          value: upcomingInvoice && (
            <Moment format={context.apiConfig.date.format} parse={'YYYY-MM-DD'}>
              {upcomingInvoice.nextBillingDate}
            </Moment>
          ),
        },
        {
          title: t('next_billing_amount_text'),
          value: upcomingInvoice && (
            <Currency divider={1} value={upcomingInvoice.nextMonthInstalment.amount} context={context} />
          ),
        },
      ],
    };

    switch (rentalItem.active) {
      case false: {
        return overviewPanelData;
      }
      case 'initiated': {
        if (rentalItem.status === 'CANCELING') {
          return overviewPanelData;
        }
        return null;
      }
      case 'shipped':
        const dataShipped = {
          title: t('subscription_stopped', 'Your subscription for SNOO has been stopped'),
          iconName: subscriptionStopedIcon,
          showButton: false,
          showShippingDate: false,
          editShippingDate: false,
          itemData: [
            {
              title: t('snoo_tracking_number', 'Snoo tracking number'),
              value: (
                <a
                  className="tracking-number-link"
                  href={linkEnums.FEDEX_TRACKING_NUMBER + rentalItem.trackingNumber}
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Link Clicked',
                      action: 'rental_return_tracking_requested',
                      label: 'User requested rental return tracking information',
                    });
                  }}
                >
                  {rentalItem.trackingNumber}
                </a>
              ),
            },
          ],
        };

        if (
          rentalItem.returnShippingFee &&
          rentalItem.returnShippingFee.amount > 0 &&
          !rentalItem.returnShippingFee.depositAmount
        ) {
          dataShipped.itemData.push({
            title: t('snoo_account_overview_return_shipping_fee', 'Return shipping fee charge'),
            value: <Currency value={rentalItem.returnShippingFee.amount} context={context} />,
          });
        }
        return dataShipped;
      case 'delivered':
        return {
          title: t('subscription_stopped'),
          iconName: subscriptionStopedIcon,
          showButton: false,
          showShippingDate: false,
          editShippingDate: false,
          itemData: [
            {
              title: t('snoo_tracking_number'),
              value: (
                <a
                  className="tracking-number-link"
                  href={linkEnums.FEDEX_TRACKING_NUMBER + rentalItem.trackingNumber}
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Link Clicked',
                      action: 'rental_return_tracking_requested',
                      label: 'User requested rental return tracking information',
                    });
                  }}
                >
                  {rentalItem.trackingNumber}
                </a>
              ),
            },
          ],
        };
      case 'inspected':
        const data = {
          title: '',
          iconName: creditCardActiveIcon,
          showButton: true,
          showShippingDate: false,
          editShippingDate: false,
          itemData: [],
        };

        if (!isEmpty(rentalItem.depositRefund) && rentalItem.depositRefund.amount != 0) {
          let value = <Currency value={rentalItem.depositRefund.amount} context={context} />;
          if (
            rentalItem.returnShippingFee &&
            rentalItem.returnShippingFee.depositAmount &&
            rentalItem.withheldSecurityDeposit
          ) {
            value = (
              <React.Fragment>
                Security Deposit: <Currency value={rentalItem.returnShippingFee.depositAmount} context={context} />
                <br />
                -shipping fee charge: <Currency value={rentalItem.returnShippingFee.amount} context={context} />
                <br />
                -withheld amount:{' '}
                <Currency value={rentalItem.withheldSecurityDeposit.withheldAmount} context={context} />
                <br />
                =
                <Currency value={rentalItem.withheldSecurityDeposit.amount} context={context} />
              </React.Fragment>
            );
          } else if (rentalItem.returnShippingFee && rentalItem.returnShippingFee.depositAmount) {
            value = (
              <React.Fragment>
                Security Deposit: <Currency value={rentalItem.returnShippingFee.depositAmount} context={context} />
                <br />
                -shipping fee charge: <Currency value={rentalItem.returnShippingFee.amount} context={context} />
                <br />
                =
                <Currency value={rentalItem.depositRefund.amount} context={context} />
              </React.Fragment>
            );
          } else if (rentalItem.withheldSecurityDeposit) {
            value = (
              <React.Fragment>
                Security Deposit:{' '}
                <Currency value={rentalItem.withheldSecurityDeposit.originalAmount} context={context} />
                <br />
                -withheld amount:{' '}
                <Currency value={rentalItem.withheldSecurityDeposit.withheldAmount} context={context} />
                <br />
                =
                <Currency value={rentalItem.withheldSecurityDeposit.amount} context={context} />
              </React.Fragment>
            );
          }
          data.itemData.push({
            title: t('snoo_account_overview_refund_security_deposit', 'Refund security deposit'),
            value: value,
          });
        }

        if (!isEmpty(rentalItem.proratedRefund) && rentalItem.proratedRefund.amount != 0) {
          data.itemData.unshift({
            title: t('snoo_account_overview_prorated_refund', 'Prorated refund'),
            value: <Currency value={rentalItem.proratedRefund.amount} context={context} />,
          });
        }

        if (
          rentalItem.minimumRentalFeeChargeSucceeded !== false &&
          rentalItem.minimumRentalFee &&
          rentalItem.minimumRentalFee > 0
        ) {
          data.itemData.unshift({
            title: t('snoo_account_overview_rental_minimum_charge', 'Rental minimum charge'),
            value: <Currency value={rentalItem.minimumRentalFee} context={context} />,
          });
        }

        return data;
      default:
        return null;
    }
  }

  renderOverview(rental, userData) {
    const { t, context } = this.props;

    const orderDelivered = rental.orderHistory.find((history) => {
      return history.status === 'delivered';
    });
    const userRentals = rental.rentalItems.map((rentalItem, rentalItemIndex) => {
      const headerData = [
        {
          className: 'snoo active',
          title: t('order_account_overview_snoo_id', { snooId: rentalItem.deviceSerial }),
        },
        {
          className: 'order active',
          title: t('order_account_overview_order_id', { shopifyOrderName: rental.shopifyOrderName }),
        },
      ];

      const returnShipped = rentalItem.returnHistory.find((history) => {
        return history.status === 'shipped';
      });

      const returnDelivered = rentalItem.returnHistory.find((history) => {
        return history.status === 'delivered';
      });

      const rentalData = [
        {
          active: rentalItem.active === false,
          iconActive: babyInSnooIcon,
          iconInactive: babyInSnooIcon,
          title: t('snoo_account_overview_snoo_is_being_used', 'SNOO is being used'),
          date: orderDelivered ? <Moment format={context.apiConfig.date.format}>{orderDelivered.date}</Moment> : '',
        },
        {
          active: rentalItem.active === 'shipped' || rentalItem.active === 'delivered',
          iconActive: snooBoxPickedUpActiveIcon,
          iconInactive: snooBoxPickedUpInactiveIcon,
          title: t('snoo_account_overview_snoo_is_in_transit', 'SNOO is in transit'),
          date: returnShipped ? <Moment format={context.apiConfig.date.format}>{returnShipped.date}</Moment> : '',
        },
        {
          active: rentalItem.active === 'inspected',
          iconActive: snooBoxReceivedActiveIcon,
          iconInactive: snooBoxReceivedInactiveIcon,
          title: t('snoo_account_overview_received_and_inspected', 'SNOO has been received and inspected'),
          date: returnDelivered ? <Moment format={context.apiConfig.date.format}>{returnDelivered.date}</Moment> : '',
        },
      ];

      const overviewData = this.getDataForOverviewPanel(rentalItem);

      return (
        <React.Fragment key={rentalItemIndex}>
          <div className="row">
            <div className="col-1 mt-60">
              <ShippingTimeline
                headerData={headerData}
                bodyData={rentalData}
                rentalPeriodStarted={rentalItem.active === false}
              />
            </div>
            <div className="col-1">
              <OverviewPanel
                userData={userData}
                {...overviewData}
                rental={rental.rental}
                order={rental}
                showEditAddressModal={this.props.showEditAddressModal}
              />
            </div>
          </div>
          <ReactTooltip />
        </React.Fragment>
      );
    });

    return <div>{userRentals}</div>;
  }

  render() {
    const { userData, rental } = this.props;
    return this.renderOverview(rental, userData);
  }
}

SnooAccountOverview.defaultProps = {
  userData: {},
  rental: {},
};

SnooAccountOverview.propTypes = {
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired,
};

export default provideContext(SnooAccountOverview);
