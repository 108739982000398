const mapCurrencyToSymbol = (currency) => {
  switch(currency) {
    case 'eur':
      return '€';
    default:
      return '$';
  }
}
export const apiConfig = {
  env: process.env.REACT_APP_ENV || 'local',
  web: {
    shopUrl: process.env.REACT_APP_SHOP_URL
  },
  api: {
    rental: process.env.REACT_APP_API_RENTAL_URL,
    payment: process.env.REACT_APP_API_PAYMENT_URL,
    warehouse: process.env.REACT_APP_API_WAREHOUSE_URL,
    shipping: process.env.REACT_APP_API_SHIPPING_URL,
    user: process.env.REACT_APP_API_USER_URL,
    orders: process.env.REACT_APP_API_ORDERS_URL,
    legal: process.env.REACT_APP_API_LEGAL_URL,
    personalData: process.env.REACT_APP_API_PERSONAL_DATA_URL,
  },
  auth: {
    rental: process.env.REACT_APP_API_RENTAL_URL,
    payment: process.env.REACT_APP_API_PAYMENT_URL,
    warehouse: process.env.REACT_APP_API_WAREHOUSE_URL,
    shipping: process.env.REACT_APP_API_SHIPPING_URL,
    user: process.env.REACT_APP_API_USER_URL,
  },
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_API_KEY,
    currency: mapCurrencyToSymbol(process.env.REACT_APP_STRIPE_CURRENCY),
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  zendesk: {
    subDomain: process.env.REACT_APP_ZENDESK_SUBDOMAIN || 'happiestbaby',
  },
  googleAnalytics: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS,
  },
  date: {
    format: process.env.REACT_APP_DATE_FORMAT || 'MM/DD/YYYY',
    saveFormat: process.env.REACT_APP_DATE_FORMAT_SAVE || 'YYYY-MM-DD',
  },
  aws: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  shopify: {
    retailSnooVaraintIds: process.env.REACT_APP_RETAIL_SNOO_VARIANT_IDS,
    lolaCribSkus: process.env.REACT_APP_LOLA_CRIB_SKUS || '1616LOCR101,1616LOCR100,1616LOTC100,1616LOTC101,1616LOCB200,1616LOCB201',
    preLovedSnooSku: process.env.REACT_APP_PRE_LOVED_SNOO_SKU || 'F4767SNXX100',
  },
  insurance: {
    aetnaUrl: process.env.REACT_APP_AETNA_URL,
    anthemUrl: process.env.REACT_APP_ANTHEM_URL,
    companyAliasToShowRedirectButtons: process.env.REACT_APP_COMPANY_ALIAS_TO_SHOW_REDIRECT_BUTTONS,
  },
  restockingFee: process.env.REACT_APP_RESTOCKING_FEE || 199,
  snooShippingFee: process.env.REACT_APP_SNOO_SHIPPING_FEE || 89,
  softGoodsShippingFee: process.env.REACT_APP_SOFT_GOODS_SHIPPING_FEE || 7.5,
};
