import get from 'lodash/get';
import replace from 'lodash/replace';

export const getLoaderStatus = (loaders, action) => get(loaders, replace(action, '_REQUEST', ''));
export const getAllLoadersFromState = state => state.apiUtils.loaders;

export const getAllErrorsFromState = state => state.apiUtils.errors;
export const getErrorStatus = (errors, action) => get(errors, replace(action, '_FAILURE', ''));

export const getAllSuccessesFromState = state => state.apiUtils.successes;
export const getSuccessStatus = (successes, action) => get(successes, replace(action, '_SUCCESS', ''));

