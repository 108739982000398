import { createSelector } from 'reselect';
import { ActionTypes } from './apiRenterActions';

import {
  getAllLoadersFromState,
  getLoaderStatus,
  getAllErrorsFromState,
  getErrorStatus,
  getAllSuccessesFromState,
  getSuccessStatus,
} from '../apiUtils/apiUtils';

export const getRenterBillingAddress = state => state.apiRenter.billingAddress;
export const getBuyPackage = state => state.apiRenter.buyPackage;
export const getRentals = state => state.apiRenter.rentals;
export const getOrders = state => state.apiRenter.orders;
export const getBillingHistory = state => state.apiRenter.billingHistory;
export const getTotalDebt = state => state.apiRenter.totalDebt;

export const isLoadingChangePassword = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_REQUEST),
);

export const getRenterChangePasswordSuccess = createSelector(
  getAllSuccessesFromState,
  success => getSuccessStatus(success, ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_SUCCESS),
);

export const getRenterChangePasswordErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_FAILURE),
);

export const getRenterUpdateBillingAddressSuccess = createSelector(
  getAllSuccessesFromState,
  success => getSuccessStatus(success, ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_SUCCESS),
);

export const getRenterUpdateBillingAddressErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_FAILURE),
);

export const isLoadingGetRenterBillingAddress = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_BILLING_ADDRESS_REQUEST),
);

export const isLoadingGetRenterRentals = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_RENTALS_REQUEST),
);

export const isLoadingGetBuyPackage = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_BUY_PACKAGE_REQUEST),
);

export const isLoadingGetRenterOrders = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_ORDERS_REQUEST),
);

export const isLoadingGetRenterBillingHistory = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTER_BILLING_HISTORY_REQUEST),
);
export const putRenterUpdateSuccess = createSelector(
  getAllSuccessesFromState,
  success => getSuccessStatus(success, ActionTypes.API_PUT_RENTER_UPDATE_SUCCESS),
);

export const putRenterUpdateErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_PUT_RENTER_UPDATE_FAILURE),
);
