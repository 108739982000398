import React from 'react';
import { provideContext } from '../../../context/context.jsx';

import './language-picker.scss';

const languages = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Swedish',
    value: 'sv',
  },
];

class LanguagePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: null,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    this.setState(state => {
      return {
        ...state,
        selected: languages.find(l => l.value === this.props.i18n.language),
      };
    });
  }
  componentDidUpdate() {
    const { open } = this.state;
    setTimeout(() => {
      if (open) {
        window.addEventListener('click', this.closeDropdown);
      } else {
        window.removeEventListener('click', this.closeDropdown);
      }
    }, 0);
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.closeDropdown);
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  toggleDropdown() {
    this.setState(state => {
      return {
        ...this.state,
        open: !state.open,
      };
    });
  }
  closeDropdown() {
    this.setState(state => ({
      ...state,
      open: false,
    }));
  }
  selectLanguage(language) {
    this.props.context.i18n.changeLanguage(language.value);
    localStorage.setItem('i18nextLng', language.value);
    this.setState(state => {
      return {
        ...state,
        selected: language,
        open: false,
      };
    });
  }
  handleKeyDown = event => {
    if (event.key === 'ArrowDown' && event.target.nextSibling) {
      event.target.nextSibling.focus();
    }
    if (event.key === 'ArrowUp' && event.target.previousSibling) {
      event.target.previousSibling.focus();
    }
    if (event.key === 'Escape') {
      this.closeDropdown();
    }
  };
  render() {
    const { open, selected } = this.state;
    return (
      <div className="language-selector">
        {selected && (
          <>
            <button onClick={this.toggleDropdown} onKeyDown={this.handleKeyDown}>
              <span>{selected.label}</span>
              <span className="dropdown-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                </svg>
              </span>
            </button>
            {open && (
              <ul className="menu dropdown" aria-expanded={open}>
                {languages.map(item => (
                  <li key={item.label}>
                    <button onClick={() => this.selectLanguage(item)}>
                      {item.label}
                      {selected.value === item.value && (
                        <span className="check-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                          </svg>
                        </span>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    );
  }
}

export default provideContext(LanguagePicker);
