import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';

import './legalDocsChange.scss';
import { apiConfig } from '../../../../config/apiConfig';

class LegalDocsChange extends Component {
  renderLinks(legalDocs) {
    const { i18n, t } = this.props;

    const documents = {
      tos: {
        url: `${apiConfig.api.legal}/terms-of-service?lang=${i18n.language}`,
        name: t('general_terms_of_service', 'Terms of Service')
      },
      eula: {
        url: `${apiConfig.api.legal}/eula?lang=${i18n.language}`,
        name: t('general_eula', 'EULA')
      },
      pp: {
        url: `${apiConfig.api.legal}/privacy-policy?lang=${i18n.language}`,
        name: t('general_privacy_policy', 'Privacy Policy')
      }
    };
    return (
      <ul>
        {Object.keys(legalDocs).map((doc, index) => (
          <li key={index}>
            <a href={documents[doc].url} target="_blank">{documents[doc].name}</a>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { t, onSubmitForm, legalDocs } = this.props;
    return (
      <div className="LegalDocsChange">
        <div className="modal-center">
          <div className="modal-header">
            <h3>{t('legal_update_title', 'Updates to our Legal Policies')}</h3>
          </div>
          <div className="inner-modal-center">
            <div className="modal-text">
              <p>{t(
                'legal_update_terms_description',
                'We\'ve updated some of our Legal Documents (together, “Terms”) to support our customers with new features and better explain our services.'
              )}</p>
              <p>{t('legal_update_terms_changed', 'Following Terms are updated:')}</p>
              {this.renderLinks(legalDocs)}
              <p className="mt-40">{t('legal_update_accept_updates', 'Please, read each one carefully. To be able to use your Happiest Baby account, you must acknowledge and accept all of the updates.')}</p>
            </div>
            <div className="button-wrap">
              <Button color={'green'} onClick={() => onSubmitForm()}>
                {t('legal_update_button', 'I agree to the updated Terms')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LegalDocsChange.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  legalDocs: PropTypes.object.isRequired,
};

export default provideContext(LegalDocsChange);
