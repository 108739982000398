import { ActionTypes } from './apiOrdersActions';

export const initialState = {
  errors: {},
  orders: [],
  successUpdateDate: false,
  successEditOrderAddress: false,
  devices: [],
};

const apiOrders = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_GET_ORDERS_SUCCESS:
      const retailOrders = action && action.payload && action.payload.filter(order => order.type !== 'RENTAL') || [];
      return {
        ...state,
        orders: retailOrders.reverse(),
      };
    case ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_SUCCESS:
      return {
        ...state,
        successUpdateDate: true,
      }
    case ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        successEditOrderAddress: true,
      }
    case ActionTypes.API_GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
      }
    case ActionTypes.API_GET_DEVICES_FAILURE:
    case ActionTypes.API_GET_ORDERS_FAILURE:
    case ActionTypes.API_CANCEL_ORDER_FAILURE:
    case ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_FAILURE:
    case ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case ActionTypes.RESET_SUCCESS_EDIT_ORDER_ADDRESS_SUCCESS:
      return {
        ...state,
        successEditOrderAddress: false,
      };
    default:
      return state;
  }
};

export default apiOrders;
