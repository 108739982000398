import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { provideContext } from '../context/context.jsx';
import classnames from 'classnames';
import Header from '../components/views/header/header.jsx';
import ErrorBoundary from '../components/views/errorBoundary/errorBoundary.jsx';
import {
  apiAcknowledgeLegalRequestAction,
  apiCheckLegalRequestAction,
  apiGetCorporateBenefitsRequestAction,
  apiGetUserMeRequestAction
} from '../redux-api/apiUsers/apiUsersActions.js';
import { apiGetRenterFromUserIdRequestAction } from '../redux-api/apiRental/apiRentalActions.js';

import './appContainer.scss';
import { Auth } from 'aws-amplify';
import { apiGetRenterRentalsRequestAction } from '../redux-api/apiRenter/apiRenterActions';
import * as apiRenterSelectors from '../redux-api/apiRenter/apiRenterSelectors';
import * as apiUsersSelector from '../redux-api/apiUsers/apiUsersSelectors';
import DefaultLoader from '../components/views/loaders/defaultLoader/defaultLoader';
import LegalDocsChange from '../components/views/modals/legalDocsChangeModal/legalDocsChange';

class AppContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingCheckLegal: props.isLoadingCheckLegal
    };
  }

  async componentWillMount() {
    const path = this.props.location.pathname;
    if (path !== '/login' && path !== '/reset') {
      this.props.actions.getUser();
      this.props.actions.getCorporateBenefits();
      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      this.props.actions.apiGetRenterFromUserId(currentUser.attributes.email);

      const lastFetchedLegal = localStorage.getItem('lastFetchedLegal');
      const hasAcknowledge = localStorage.getItem('hasAcknowledge');
      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const userHasNotAcknowledged = !hasAcknowledge;

      const needsLegalCheck = !lastFetchedLegal || (Date.now() - parseInt(lastFetchedLegal, 10) > millisecondsInADay);

      if (userHasNotAcknowledged || needsLegalCheck) {
        this.props.actions.apiCheckLegal();
        localStorage.setItem('lastFetchedLegal', Date.now().toString());
      }

      this.setState({ isLoadingCheckLegal: false });
    }
  }

  goToMainContent(e) {
    e.preventDefault();
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.focus();
      mainContent.scrollIntoView(true);
    }
  }

  acknowledgedLegal = () => {
    const { actions, legalDocs } = this.props;
    actions.apiAcknowledgeLegal(legalDocs);
  }

  render() {
    const { paddingBottom, t, legalDocs } = this.props;
    const { isLoadingCheckLegal } = this.state;
    if (isLoadingCheckLegal) {
      return <DefaultLoader type="block" />
    }

    if (Object.keys(legalDocs).length > 0) {
      localStorage.removeItem('hasAcknowledge');
    }

    return (
      <div>
        <a href="#main-content" className="main-content-link" onClick={this.goToMainContent} tabIndex={0}>
          {t('app_container_skip_to_main_content','skip to main content')}
        </a>
        <Header />
        <ErrorBoundary>
          <main
            role="main"
            className={classnames('container', {
              'padding-bottom': paddingBottom,
            })}
          >
            {this.props.children}
            {Object.keys(legalDocs).length > 0 && <LegalDocsChange legalDocs={legalDocs} onSubmitForm={() => this.acknowledgedLegal()}/>}
          </main>
        </ErrorBoundary>
      </div>
    );
  }
}

AppContainer.propTypes = {
  actions: PropTypes.shape({
    getUser: PropTypes.func,
    apiGetRenterFromUserId: PropTypes.func,
    getCorporateBenefits: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  paddingBottom: PropTypes.bool,
};

const mapStateToProps = state => ({
  rentals: apiRenterSelectors.getRentals(state),
  user: apiUsersSelector.getUser(state),
  corporateBenefits: apiUsersSelector.getCorporateBenefits(state),
  legalDocs: apiUsersSelector.getLegalCheck(state),
  isLoadingCheckLegal: apiUsersSelector.isLoadingCheckLegal(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      getRentals: apiGetRenterRentalsRequestAction,
      getUser: apiGetUserMeRequestAction,
      getCorporateBenefits: apiGetCorporateBenefitsRequestAction,
      apiGetRenterFromUserId: apiGetRenterFromUserIdRequestAction,
      apiCheckLegal: apiCheckLegalRequestAction,
      apiAcknowledgeLegal: apiAcknowledgeLegalRequestAction,
    },
    dispatch,
  );
  return { actions };
};

AppContainer.defaultProps = {
  isLoadingCheckLegal: true,
};

export default withRouter(
  provideContext(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(AppContainer),
  ),
);
