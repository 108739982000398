export const ActionTypes = {
  API_GET_SHIPPING_COUNTRIES_REQUEST: 'API_GET_SHIPPING_COUNTRIES_REQUEST',
  API_GET_SHIPPING_COUNTRIES_SUCCESS: 'API_GET_SHIPPING_COUNTRIES_SUCCESS',
  API_GET_SHIPPING_COUNTRIES_FAILURE: 'API_GET_SHIPPING_COUNTRIES_FAILURE',
  API_GET_SHIPPING_PROVINCES_REQUEST: 'API_GET_SHIPPING_PROVINCES_REQUEST',
  API_GET_SHIPPING_PROVINCES_SUCCESS: 'API_GET_SHIPPING_PROVINCES_SUCCESS',
  API_GET_SHIPPING_PROVINCES_FAILURE: 'API_GET_SHIPPING_PROVINCES_FAILURE',
};

export const apiGetShippingCountriesRequestAction = () => ({
  type: ActionTypes.API_GET_SHIPPING_COUNTRIES_REQUEST,
  payload: {},
});

export const apiGetShippingCountriesSuccessAction = countries => {
  return {
    type: ActionTypes.API_GET_SHIPPING_COUNTRIES_SUCCESS,
    payload: countries,
  };
};

export const apiGetShippingCountriesFailureAction = error => ({
  type: ActionTypes.API_GET_SHIPPING_COUNTRIES_FAILURE,
  payload: error,
});

export const apiGetShippingProvincesRequestAction = id => ({
  type: ActionTypes.API_GET_SHIPPING_PROVINCES_REQUEST,
  payload: id,
});

export const apiGetShippingProvincesSuccessAction = (provinces, countryId) => {
  return {
    type: ActionTypes.API_GET_SHIPPING_PROVINCES_SUCCESS,
    payload: { provinces, countryId },
  };
};

export const apiGetShippingProvincesFailureAction = error => ({
  type: ActionTypes.API_GET_SHIPPING_PROVINCES_FAILURE,
  payload: error,
});
