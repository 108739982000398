import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { provideContext } from '../context/context.jsx';
import isEmpty from 'lodash/isEmpty';
import { filter } from 'lodash';
import AppContainer from '../containers/appContainer.jsx';
import * as apiRenterSelectors from '../redux-api/apiRenter/apiRenterSelectors.js';
import InitiateReturnForm from '../components/views/forms/initiateReturnForm/initiateReturnForm.jsx';
import * as apiRentalSelectors from '../redux-api/apiRental/apiRentalSelectors.js';
import * as apiReturnSelectors from '../redux-api/apiReturn/apiReturnSelectors.js';
import { apiGetRentalItemsRequestAction, apiGetPricesRequestAction } from '../redux-api/apiRental/apiRentalActions.js';
import linksEnum from '../enums/linksEnum.js';
import Message from '../components/views/presentation/message/message.jsx';
import DownloadLabel from '../components/views/presentation/downloadLabel/downloadLabel.jsx';
import ScrenTitle from '../components/views/presentation/screenTitle/screenTitle.jsx';
import { apiGetRenterRentalsRequestAction } from '../redux-api/apiRenter/apiRenterActions.js';
import Button from '../components/views/button/button.jsx';
import ExtButton from '../components/views/button/external-button.jsx';
import { Trans } from 'react-i18next'

import returnImage from '../assets/initiate-return-img.png';

import ConfirmReturnAddressesModal from '../components/views/modals/confirmReturnAddressesModal/confirmReturnAddressesModal.jsx';

import {
  apiGetReturnItemsRequestAction,
  apiPostRentalInitiateReturnRequestAction,
  apiGetRentalCancellingReasonsRequestAction,
} from '../redux-api/apiReturn/apiReturnActions.js';
import { t } from 'i18next/dist/commonjs';

const hasPendingReturns = returnItems => returnItems.some(({ status }) => status === 'PENDING');

const containsOnlyPending = returnItems => returnItems.every(({ status }) => status === 'PENDING');
class ReturnScreen extends PureComponent {
  state = {
    confirmReturnShippingAddressModalVisible: false,
  };

  componentWillMount() {
    this.props.actions.getCancellingReturnReasons();
    this.props.actions.getRentals();
    this.props.actions.getPrices();
  }

  renderInitiateReturnForm() {
    const {
      t,
      reasons,
      rentalItems,
      rentals,
      initiateReturnError,
      initiateReturnSuccess,
      isLoadingInitiateReturn,
    } = this.props;
    return (
      <InitiateReturnForm
        title={t('initiate_return_text')}
        reasons={reasons}
        rentalItems={rentalItems}
        rentals={rentals}
        initiateReturnError={initiateReturnError}
        initiateReturnSuccess={initiateReturnSuccess}
        isLoadingInitiateReturn={isLoadingInitiateReturn}
        apiInitiateReturn={request => {
          this.setState({
            initiateReturnRequest: request,
            confirmReturnShippingAddressModalVisible: true,
          });
        }}
        prices={this.props.prices}
      />
    );
  }

  renderSchedulePickup() {
    const { t } = this.props;
    return (
      <div
        className="row"
        style={{
          fontSize: '0.85em',
          color: 'rgb(77, 89, 107)',
        }}
      >
        <br />
        {t(
          'return_screen_schedule_pickup_hero',
          'Ready for SNOO pick-up? Just click on the button below to schedule it with FedEx.',
        )}
        <br />
        <ExtButton color={'green'} to={linksEnum.SCHEDULE_PICKUPS} newTab={true}>
          {t('button_text_schedule_pickup', 'Schedule Pickup')}
        </ExtButton>
      </div>
    );
  }

  undeliveredMessage(rentals) {
    const { t } = this.props;
    const selectedRental = rentals[0];
    const snooName = selectedRental.rentalItems.length > 1 ? 'SNOOs' : 'a SNOO';

    return (
      <Message type="info">
        <Trans 
          i18nKey="return_screen_undelivered_message_info"
          values={{ shopifyOrderName: selectedRental.shopifyOrderName, snooName }}
        >
            {'You’ve placed an Order {{ values.shopifyOrderName }}. Please contact'}
            <a href="mailto:customercare@happiestbaby.com" target="_blank">us</a>
            {'if you’d like to return {{ values.snooName }} from that Order.'}
        </Trans>
      </Message>
    );
  }

  hiddenRentalItemsMesssage(rentalItems) {
    const { t } = this.props;
    return (
      <Message type="success-light">
        <Trans 
          i18nKey="return_screen_hidden_rental_items_message"
        >
          You have successfully initiated a return for one SNOO. To return another SNOO, please contact us at
          <a href="mailto:customercare@happiestbaby.com" target="_blank">
            customercare@happiestbaby.com
          </a>
          or 1855 424 6323. Please note
          Initiating your return does not cancel your SNOO rental. Your rental subscription will stop once SNOO is picked up by FedEx.
        </Trans>
      </Message>
    );
  }

  renderDownloadLabel() {
    const { returnItems, t } = this.props;
    return returnItems
      .filter(item => item.status === 'INITIATED')
      .map(({ shipmentLabel, rentalItem, createdAt }) => (
        <DownloadLabel link={shipmentLabel} snooId={rentalItem.deviceSerial} initiatedDate={createdAt} />
      ));
  }

  renderPendingMessages() {
    const { returnItems, t } = this.props;
    const pendingReturnSerials = returnItems.filter(item => !item.initiated).map(item => item.rentalItem.deviceSerial);
    return pendingReturnSerials.length > 0 ? (
      <div>
        <Message type="success">
          {t(
            'return_screen_render_pending_messages_return_initiated_success',
            'You have successfully initiated your return!',
          )}
          {t(
            'return_screen_render_pending_messages_reteurn_note',
            'Please note: Initiating your return does not cancel your SNOO rental. Your rental subscription will stop once SNOO is picked up by FedEx.',
          )}
        </Message>
        <br />
        <Message type="info">
          <Trans 
            i18nKey="return_screen_render_pending_messages_return_process_for_device_info"
          >
            Return process for device is underway. Shipping label will be visible in 30 minutes or less.
            If you don't see a label in 30 minutes please contact us at
            <a href="mailto:customercare@happiestbaby.com" target="_blank">
              customercare@happiestbaby.com
            </a> 
            or
            <a href="tel:18554246323" target="_blank">
              1.855.424.6323
            </a>
          </Trans>
        </Message>
      </div>
    ) : null;
  }

  renderSuccess() {
    const { t } = this.props;

    return (
      <div>
        <Message type="success-light">
          <Trans 
            i18nKey="return_screen_render_success_message"
          >
            You successfully initiated SNOO return. Please note <br/>
            Initiating your return does not cancel your SNOO rental. Your rental subscription will stop once SNOO is picked up by FedEx.
          </Trans>
        </Message>
      </div>
    );
  }

  renderError() {
    const { t } = this.props;
    return (
      <div className="row" style={{ marginTop: '12px' }}>
        <Message type="error-light">
          {t(
            'return_screen_render_error',
            'Note: Initiate return process has failed. Please try again later or call our customer care.',
          )}
        </Message>
      </div>
    );
  }

  render() {
    const {
      t,
      isLoadingGetCancellingReasons,
      isLoadingGetRentalItems,
      isLoadingGetReturnItems,
      isLoadingGetRenterRentals,
      isLoadingGetPrices,
      returnItems,
      rentals,
      rentalItems,
    } = this.props;

    let undeliveredOrders;
    let hiddenRentalItems;

    if (isEmpty(rentalItems) && isEmpty(returnItems)) {
      this.props.history.push('/');
    }

    if (!isLoadingGetRenterRentals) {
      undeliveredOrders = filter(rentals, rental => {
        if (
          rental.rental.status == 'PENDING_FULFILLMENT' ||
          rental.rental.status == 'FULFILLING' ||
          rental.rental.status == 'FULFILLED'
        ) {
          return rental;
        }
      });

      const returnRentalItemIds = returnItems.map(r => r.rentalItem._id);

      hiddenRentalItems = rentals
        .filter(r => r.active === 'delivered')
        .map(r =>
          r.rentalItems
            .filter(ri => returnRentalItemIds.includes(ri._id) === false)
            .filter(ri => ri.status !== 'CANCELED'),
        )
        .reduce((s, c) => s.concat(c), []);
    }

    if (isLoadingGetCancellingReasons && isLoadingGetRentalItems && isLoadingGetPrices) {
      return <AppContainer />;
    }
    return (
      <AppContainer>
        {this.state.confirmReturnShippingAddressModalVisible && (
          <ConfirmReturnAddressesModal
            onConfirm={this.onConfirmReturnShippingAddress}
            onChangeAddress={this.onChangeShippingAddress}
            onCloseModal={this.onCloseConfirmReturnShippingAddressModal}
            rentals={this.props.rentals}
          />
        )}
        <div className="row">
          <div className="col-1">
            <ScrenTitle>{t('return_screen_title', 'RETURNING YOUR SNOO')}</ScrenTitle>
          </div>
          <img style={{ marginTop: '20px' }} src={returnImage} alt="" />
        </div>
        {!isEmpty(this.props.initiateReturnError) && this.renderError()}
        <div className="row" style={{ marginTop: '12px' }}>
          {!isLoadingGetReturnItems && isEmpty(returnItems) && isEmpty(this.props.initiateReturnSuccess) && (
            <div className="col-1-2" style={{ color: '#4d596b' }}>
              {t('return_screen_enjoy_message', 'We hope you enjoyed your SNOO rental!')} <br />
              {t(
                'return_screen_ready_to_send_back',
                'If you’re ready to send back your SNOO, please click “initiate return” below. ',
              )}
              <br /> <br />
              {t(
                'return_screen_general_message_all_babies',
                `All babies are unique…and some require just a few minor adjustments to improve sleep using SNOO. It can be hard to figure out exactly which tips and tricks to try with your little one. We recently developed a Sleep Questionnaire that can provide more personalized insights into improving sleep specifically for your baby. We encourage you to try before initiating your return!`,
              )}
              <br />
              <a href="https://zingtree.com/show/670458718" target="_blank">
                {t('return_screen_questionnaire_link', 'You can try our new Sleep Questionnaire here!')}
              </a>
            </div>
          )}
        </div>
        <div>
          <div className="col-1-2">
            {!isEmpty(this.props.initiateReturnSuccess) && !hasPendingReturns(returnItems) && this.renderSuccess()}
            {!isLoadingGetReturnItems &&
              isEmpty(returnItems) &&
              isEmpty(this.props.initiateReturnSuccess) &&
              this.renderInitiateReturnForm()}
          </div>
        </div>
        <div className="row">
          <div className="col-1-2">{hasPendingReturns(returnItems) && this.renderPendingMessages()}</div>
        </div>
        <div className="row">
          <div className="col-1-2">
            {!isEmpty(returnItems) && !isEmpty(hiddenRentalItems) && this.hiddenRentalItemsMesssage(hiddenRentalItems)}
          </div>
        </div>
        <div className="row">
          <div className="col-1-2">{!isLoadingGetReturnItems && this.renderDownloadLabel()}</div>
        </div>
        <div className="row">
          <div className="col-1-2">
            {!isEmpty(returnItems) && !isEmpty(undeliveredOrders) && this.undeliveredMessage(undeliveredOrders)}
          </div>
        </div>
        <div className="row">
          <div className="col-1-2" style={{ width: '53%' }}>
            {!isLoadingGetReturnItems &&
              !isEmpty(returnItems) &&
              !containsOnlyPending(returnItems) &&
              this.renderSchedulePickup()}
          </div>
        </div>
      </AppContainer>
    );
  }

  onConfirmReturnShippingAddress = e => {
    this.setState({
      confirmReturnShippingAddressModalVisible: false,
    });
    this.props.actions.postInitiateReturn(this.state.initiateReturnRequest);
  };

  onCloseConfirmReturnShippingAddressModal = e => {
    this.setState({
      confirmReturnShippingAddressModalVisible: false,
    });
  };

  onChangeShippingAddress = e => {
    this.onCloseConfirmReturnShippingAddressModal();
    this.props.history.push(this.props.context.appRoutes.HOME.url);
  };
}

ReturnScreen.defaultProps = {
  initiateReturnSuccess: {},
  returnItems: [],
  isLoadingGetRenterRentals: true,
};

ReturnScreen.propTypes = {
  actions: PropTypes.shape({
    getCancellingReturnReasons: PropTypes.func,
    getRentalItems: PropTypes.func,
    getReturnItems: PropTypes.func,
    getPrices: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  reasons: apiReturnSelectors.getCancellingReturnReasons(state),
  rentalItems: apiRentalSelectors.getRentalItems(state),
  prices: apiRentalSelectors.getPrices(state),
  returnItems: apiReturnSelectors.getReturnItems(state),
  rentals: apiRenterSelectors.getRentals(state),
  isLoadingGetRenterRentals: apiRenterSelectors.isLoadingGetRenterRentals(state),
  isLoadingGetCancellingReasons: apiReturnSelectors.isLoadingGetCancellingReasons(state),
  isLoadingGetRentalItems: apiRentalSelectors.isLoadingGetRentalItems(state),
  isLoadingGetReturnItems: apiReturnSelectors.isLoadingGetReturnItems(state),
  isLoadingInitiateReturn: apiReturnSelectors.isLoadingInitiateReturn(state),
  isLoadingGetPrices: apiRentalSelectors.isLoadingGetPrices(state),
  initiateReturnError: apiReturnSelectors.getInitiateReturnErrors(state),
  initiateReturnSuccess: apiReturnSelectors.getInitiateReturnSuccess(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      getRentals: apiGetRenterRentalsRequestAction,
      getCancellingReturnReasons: apiGetRentalCancellingReasonsRequestAction,
      getRentalItems: apiGetRentalItemsRequestAction,
      postInitiateReturn: apiPostRentalInitiateReturnRequestAction,
      getReturnItems: apiGetReturnItemsRequestAction,
      getPrices: apiGetPricesRequestAction,
    },
    dispatch,
  );
  return { actions };
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(ReturnScreen));
