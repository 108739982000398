export default {
  LOGIN: {
    url: '/login',
    name: 'Login',
  },
  HOME: {
    url: '/',
    name: 'Home',
  },
  COMPLETE_REGISTER: {
    url: '/user/:token',
    name: 'Register',
  },
  PAYMENT: {
    url: '/payment',
    name: 'Payment',
    edit: 'Edit payment',
  },
  REFFERALS: {
    url: '/referrals',
    name: 'Referrals',
    edit: 'Edit referrals',
  },
  RETURN: {
    url: '/return',
    name: 'Initiate return',
  },
  PROFILE: {
    url: '/profile',
    name: 'Profile',
    edit: 'Edit profile',
  },
  BILLS: {
    url: '/bills',
    name: 'Bill History',
  },
  DEVICES: {
    url: '/devices',
    name: 'Devices'
  }
};
