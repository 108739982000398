import axios from 'axios';
import { routes, getApiRouteUrl } from '../routes';
import { constructAuthHeaders } from '../../utils/webAuth';

export const createUserPassword = ({ token, passwordData }) =>
  axios
    .post(getApiRouteUrl(routes.RENTAL_USER_ACTIVATE_FROM_TOKEN, { token }), passwordData)
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const getUserFromToken = token =>
  axios
    .get(getApiRouteUrl(routes.RENTAL_USER_FROM_TOKEN, { token }))
    .then(response => ({ response }))
    .catch(error => ({ error }));

export const getRenterFromUserId = async (email) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.RENTAL_RENTER_FROM_USER_ID), { email }, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const postApplyGiftCard = async (giftCard) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.RENTAL_RENTER_APPLY_GIFT_CARD), { giftCard }, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getAppliedGiftCards = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_APPLIED_GIFT_CARDS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getGiftCardsUsageSummary = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_GIFT_CARD_USAGE_SUMMARY), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const postGenerateGiftCard = async (amount) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.RENTAL_RENTER_GENERATE_GIFT_CARD), { amount }, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const updateShippingDate = async ({ rentalId, shippingDate }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.put(getApiRouteUrl(routes.RENTAL_SHIPPING_DATE, { id: rentalId }), { shippingDate }, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getRentalItems = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_RENTER_RENTAL_ITEMS), headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const postCancelRental = async ({ rentalId }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.post(getApiRouteUrl(routes.RENTAL_CANCEL_RENTAL, { id: rentalId }), {}, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const putUpdateRentalShippingAddress = async ({ rentalId, address }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.put(getApiRouteUrl(routes.RENTAL_SHIPPING_ADDRESS, { id: rentalId }), address, headers);
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getRentalShippingAddress = async ({ rentalId }) => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_SHIPPING_ADDRESS, { id: rentalId }), headers);
    return { response };
  } catch (error) {
    return { error };
  }
}

export const getPrices = async () => {
  try {
    const headers = await constructAuthHeaders();
    const response = await axios.get(getApiRouteUrl(routes.GET_PRICES), headers);
    return { response };
  } catch (error) {
    return { error };
  }
}

export const getRentalPrice = async () => {
  try {
    const response = await axios.get(getApiRouteUrl(routes.RENTAL_PRICE));
    return { response };
  } catch (error) {
    return { error };
  }
}
