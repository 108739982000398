import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import { provideContext } from '../../../../context/context.jsx';
import Currency from '../../../../components/views/formats/currencyFormat/currency.jsx';
import ShippingTimeline from '../../../../components/views/presentation/shippingTimeline/shippingTimeline.jsx';
import OverviewPanel from '../../../../components/views/presentation/overviewPanel/overviewPanel.jsx';
import linkEnums from '../../../../enums/linksEnum';

import snooBoxInactiveIcon from '../../../../assets/icons/snoo-box-inactive.svg';
import snooBoxActiveIcon from '../../../../assets/icons/snoo-box-active.svg';
import fedexTruckInactiveIcon from '../../../../assets/icons/fedex-truck.svg';
import fedexTruckActiveIcon from '../../../../assets/icons/fedex-truck-active.svg';
import babyFaceInactiveIcon from '../../../../assets/icons/baby-face-inactive.svg';
import babyFaceActiveIcon from '../../../../assets/icons/baby-face-active.svg';
import CompanyImage from './corporateBenefits/companyImage';

import ReactGA from 'react-ga4';

class OrderAccountOverview extends PureComponent {
  getDataForOverviewPanel() {
    const { t, context, upcomingInvoices, rental } = this.props;
    const upcomingInvoice = upcomingInvoices.find((invoice) => {
      return invoice.id === rental.rental.subscriptionId;
    });

    switch (rental.active) {
      case 'placed':
        return {
          title: t('order_account_overview_order_placed_success', 'Your order has been placed!'),
          iconName: snooBoxActiveIcon,
          showButton: false,
          showShippingDate: true,
          editShippingDate: rental.rental.canBeEdited,
          shippingDate: rental.rental.shippingDate,
          itemData: [
            {
              title: t('order_id'),
              value: t('order_account_overview_order_id', { shopifyOrderName: rental.shopifyOrderName }),
            },
          ],
        };
      case 'shipped':
        return {
          title: t('order_account_overview_order_shipped', 'Your order has been shipped'),
          iconName: fedexTruckActiveIcon,
          showButton: false,
          showShippingDate: false,
          editShippingDate: false,
          itemData: [
            {
              title: t('order_id'),
              value: t('order_account_overview_order_id', { shopifyOrderName: rental.shopifyOrderName }),
            },
            {
              title: t('order_account_overview_order_tracking_number', 'Tracking number'),
              value: (
                <a
                  className="tracking-number-link"
                  href={linkEnums.FEDEX_TRACKING_NUMBER + rental.trackingNumber}
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Link Clicked',
                      action: 'rental_order_tracking_requested',
                      label: 'User requested rental order tracking information',
                    });
                  }}
                >
                  {rental.trackingNumber}
                </a>
              ),
            },
          ],
        };
      case 'delivered':
        return {
          title: t('order_account_overview_order_delivered', 'Your order has been delivered'),
          iconName: babyFaceActiveIcon,
          showButton: true,
          showShippingDate: false,
          editShippingDate: false,
          itemData: [
            {
              title: t('next_billing_date_text', 'Next billing date'),
              value: upcomingInvoice && (
                <Moment format={context.apiConfig.date.format} parse={'YYYY-MM-DD'}>
                  {upcomingInvoice.nextBillingDate}
                </Moment>
              ),
            },
            {
              title: t('next_billing_amount_text', 'Next billing amount'),
              value: upcomingInvoice && (
                <Currency divider={1} value={upcomingInvoice.nextMonthInstalment.amount} context={context} />
              ),
            },
          ],
        };
      default:
        return null;
    }
  }

  renderOverview() {
    const { t, context, rental, userData } = this.props;
    const headerData = [
      {
        className: 'order active',
        title: t('order_account_overview_order_id', { shopifyOrderName: rental.shopifyOrderName }),
      },
    ];

    const orderPlaced = rental.orderHistory.find((history) => {
      return history.status === 'placed';
    });

    const orderShipped = rental.orderHistory.find((history) => {
      return history.status === 'shipped';
    });

    if (orderShipped) {
      rental.rentalItems.map((rentalItem) => {
        headerData.push({
          className: 'snoo active',
          title: t('order_account_overview_snoo_id', { snooId: rentalItem.deviceSerial }),
        });
      });
    } else {
      headerData.push({
        className: 'snoo',
        title: t('order_account_overview_snoo_id_notice', 'SNOO ID WILL BE DISPLAYED HERE'),
      });
    }

    const orderDelivered = rental.orderHistory.find((history) => {
      return history.status === 'delivered';
    });

    const orderData = [
      {
        active: rental.active === 'placed',
        iconActive: snooBoxActiveIcon,
        iconInactive: snooBoxInactiveIcon,
        title: t('order_placed', 'Order placed'),
        date: orderPlaced ? <Moment format={context.apiConfig.date.format}>{orderPlaced.date}</Moment> : '',
      },
      {
        active: rental.active === 'shipped',
        iconActive: fedexTruckActiveIcon,
        iconInactive: fedexTruckInactiveIcon,
        title: t('order_account_overview_order_is_shipped', 'Order shipped'),
        date: orderShipped ? <Moment format={context.apiConfig.date.format}>{orderShipped.date}</Moment> : '',
      },
      {
        active: rental.active === 'delivered',
        iconActive: babyFaceActiveIcon,
        iconInactive: babyFaceInactiveIcon,
        title: t('order_account_overview_order_snoo_delivered', 'SNOO is delivered'),
        date: orderDelivered ? <Moment format={context.apiConfig.date.format}>{orderDelivered.date}</Moment> : '',
      },
    ];
    const overviewData = this.getDataForOverviewPanel();
    const { corporateBenefits } = rental.rental;

    return (
      <React.Fragment key={rental.id}>
        <div className="row">
          <div className="col-1 mt-60">
            <ShippingTimeline
              headerData={headerData}
              bodyData={orderData}
              rentalPeriodStarted={rental.active === 'delivered'}
              companyImage={corporateBenefits ? corporateBenefits.companyImage : null}
            />
          </div>
          <div className="col-1">
            <OverviewPanel
              userData={userData}
              {...overviewData}
              rental={rental.rental}
              order={rental}
              apiUpdateShippingDate={this.props.apiUpdateShippingDate}
              showCancelOrderModal={this.props.showCancelOrderModal}
              showEditAddressModal={this.props.showEditAddressModal}
              errorShippingDateUpdate={this.props.errorShippingDateUpdate}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.renderOverview();
  }
}

OrderAccountOverview.defaultProps = {
  userData: {},
  rental: {},
};

OrderAccountOverview.propTypes = {
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired,
  showCancelOrderModal: PropTypes.func.isRequired,
  showEditAddressModal: PropTypes.func.isRequired,
};

export default provideContext(OrderAccountOverview);
