import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { provideContext } from '../../../../context/context.jsx';
import Currency from '../../../../components/views/formats/currencyFormat/currency.jsx';
import ShippingTimeline from '../../../../components/views/presentation/shippingTimeline/shippingTimeline.jsx';
import OverviewPanelRetail from '../../presentation/overviewPanelRetail/overviewPanelRetail';
import linkEnums from '../../../../enums/linksEnum';
import isRetailSnooOrder from '../../../../utils/isRetailSnooOrder';
import orderStatuses from '../../../../enums/orderStatuses';
import returnStatuses from '../../../../enums/returnStatuses';

import hbBoxActiveIcon from '../../../../assets/icons/snoo-box-active_v2.svg';
import hbBoxInactiveIcon from '../../../../assets/icons/snoo-box-inactive_v2.svg';
import snooBoxInactiveIcon from '../../../../assets/icons/snoo-box-inactive.svg';
import snooBoxActiveIcon from '../../../../assets/icons/snoo-box-active.svg';
import fedexTruckInactiveIcon from '../../../../assets/icons/fedex-truck.svg';
import fedexTruckActiveIcon from '../../../../assets/icons/fedex-truck-active.svg';
import babyFaceInactiveIcon from '../../../../assets/icons/baby-face-inactive.svg';
import babyFaceActiveIcon from '../../../../assets/icons/baby-face-active.svg';
import cancelledIcon from '../../../../assets/icons/subscription-stoped-active.svg';
import { getReturns } from '../../../../redux-api/apiOrders/api';
import './retailOrderAccountOverview.scss'
import snooBoxReceivedActiveIcon from '../../../../assets/icons/snoo-box-received-active.svg';
import snooBoxReceivedInactiveIcon from '../../../../assets/icons/snoo-box-received-inactive.svg';
import getCurrencyForOrder from '../../../../utils/getCurrencyForOrder';
import { Trans } from 'react-i18next';
import subscriptionStopedIcon from '../../../../assets/icons/subscription-stoped-active.svg';
import ReactTooltip from 'react-tooltip';

import ReactGA from 'react-ga4';

const getStatusIcon = (status, hasSnoo) => {
  const iconMap = {
    [orderStatuses.PLACED]: hasSnoo ? snooBoxActiveIcon : hbBoxActiveIcon,
    [orderStatuses.FULFILLING]: hasSnoo ? snooBoxActiveIcon : hbBoxActiveIcon,
    [orderStatuses.SHIPPED]: fedexTruckActiveIcon,
    [orderStatuses.FULFILLED]: fedexTruckActiveIcon,
    [orderStatuses.DELIVERED]: babyFaceActiveIcon,
  };
  return iconMap[status] || '';
};

const findStatus = (item, targetStatus) => {
  return item.statusHistory.find((history) => history.status === targetStatus);
};

class RetailOrderAccountOverview extends PureComponent {
  _isMounted = false;
  state = {
    returns: [],
    refetch: null,
    currentReturnTab: -1,
    isMounted: false,
  };

  async componentDidMount() {
      this._isMounted = true;
      try {
        const returnsData = await getReturns({ orderId: this.props.order._id });
        if (this._isMounted) {
          this.setState({ returns: returnsData.response && returnsData.response.data });
        }
      } catch (error) {
        if (this._isMounted) {
          this.setState({ returns: [] });
        }
      }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDataForOverviewPanel() {
    const { t, order } = this.props;

    const isRetailSnoo = isRetailSnooOrder(order.line_items);
    const currency = getCurrencyForOrder(order);
    const getLineItems = () => {
      let lineItems = order.line_items.map((item, index) => {
        const itemPrice = item.finalPrice >= 0 ? item.finalPrice : item.price;
        return (
          <div className="line-item" key={index}>
            <span className="line-item-name" data-tip={item.name}>{item.name}</span>
            <ReactTooltip place="top" type="dark" effect="float"/>
          <span className="quantity">{item.quantity}x</span>
            <Currency prefix={currency} divider={1} value={itemPrice} />
          </div>
        );
      });

      lineItems.push(<hr className="hr-order-summary" key={`hr-${order.id}`} />);
      lineItems.push(
        <div className="line-item" key="subtotal">
          <span className="line-item-name">{t('order_summary_subtotal', 'Subtotal')}</span>
          <Currency prefix={currency} divider={1} value={order.subtotal_price} />
        </div>,
      );

      lineItems.push(
        <div className="line-item" key="totalTax">
          <span className="line-item-name">{t('order_summary_total_tax', 'Total Tax')}</span>
          <Currency prefix={currency} divider={1} value={order.total_tax} />
        </div>,
      );
      lineItems.push(<hr className="hr-order-summary" key={`hr-${order.id}-2`} />);

      lineItems.push(
        <div className="line-item" key="total">
          <span className="line-item-name">{t('order_summary_total', 'Total')}</span>
          <Currency prefix={currency} divider={1} value={order.total_price} />
        </div>,
      );

      return lineItems;
    };

    const getReturnLineItems = (currentReturn) => {
      return currentReturn.lineItems.map((item, index) => {
        return (
        <div key={index+item.name}>
          <div className="line-item">
            <span className="quantity">{item.quantity}x</span>
            <span className="line-item-name">{item.name}</span>
          </div>
          <div className="device-serial">
            {item.deviceSerial && <span>{item.deviceSerial}</span>}
          </div>
        </div>
        )
      });
    };
    if (this.state.currentReturnTab >= 0) {
      const currentReturn = this.state.returns[this.state.currentReturnTab];
      const isSnooReturn = isRetailSnooOrder(currentReturn.lineItems);
      if (currentReturn.cancelledAtWarehouse) {
        let itemData = [
          {
            title: t('order_id'),
            value: t('order_account_overview_order_id', { shopifyOrderName: order.name }),
          },
          {
            title: t('return_items', 'Return Items'),
            value: getReturnLineItems(currentReturn)
          }
        ]

        return {
          title: t('order_account_overview_return_cancelled', 'Your return has been cancelled'),
          iconName: cancelledIcon,
          showButton: false,
          showShippingDate: false,
          editShippingDate: false,
          itemData: itemData,
        };

      }
      switch (currentReturn.status) {
        case returnStatuses.INITIATED:
          let itemData = [
            {
              title: t('order_id'),
              value: t('order_account_overview_order_id', { shopifyOrderName: order.name }),
            },
            {
              title: t('return_items', 'Return Items'),
              value: getReturnLineItems(currentReturn)
            },
            {
              title: t('order_account_overview_order_tracking_number', 'Tracking number'),
              value: (
                <a
                  className="tracking-number-link"
                  href={linkEnums.UPS_TRACKING_NUMBER + currentReturn.shipmentTrackingNumber}
                  target="_blank"
                >
                  {currentReturn.shipmentTrackingNumber}
                </a>
              ),
            },
          ];

          if (currentReturn.shipmentLabel) {
            itemData.push({
              value: (
                <a
                  className="shipping-label-link"
                  href={currentReturn.shipmentLabel}
                  target="_blank"
                >
                  {t('download_lable_printable', 'Download Shipping Label')}
                </a>
              ),
            });
          } else {
            itemData.push({
              value: <Trans i18nKey="shipping_label_creation_error">Please <a className="link" target="_blank" href={linkEnums.SLEEP_CONSULTATION_LINK}>contact Customer Care</a> as there was an issue creating the shipping label.</Trans>
            });
          }

          return {
            title: t('order_account_overview_return_initiated', 'Your return has been initiated'),
            iconName: isSnooReturn ? snooBoxActiveIcon : hbBoxActiveIcon,
            showButton: false,
            showShippingDate: false,
            editShippingDate: false,
            itemData: itemData,
          };
        case returnStatuses.TRANSIT:
        case returnStatuses.DELIVERED:
          return {
            title: t('order_account_overview_return_in_transit', 'Your return is in transit'),
            iconName: fedexTruckActiveIcon,
            showButton: false,
            showShippingDate: false,
            editShippingDate: false,
            itemData: [
              {
                title: t('order_id'),
                value: t('order_account_overview_order_id', { shopifyOrderName: order.name }),
              },
              {
                title: t('return_items', 'Return Items'),
                value: getReturnLineItems(currentReturn)
              },
              {
                title: t('order_account_overview_order_tracking_number', 'Tracking number'),
                value: (
                  <a
                  className="tracking-number-link"
                  href={linkEnums.UPS_TRACKING_NUMBER + currentReturn.shipmentTrackingNumber}
                  target="_blank"
                  >
                    {currentReturn.shipmentTrackingNumber}
                  </a>
                ),
              },
            ]
          }
        case returnStatuses.INSPECTED:
          const refund = currentReturn.refunds[0]
          return {
            title: t('order_account_overview_return_inspected', 'Your return is inspected'),
            iconName: isSnooReturn ? snooBoxReceivedActiveIcon : snooBoxInactiveIcon,
            showButton: false,
            showShippingDate: false,
            editShippingDate: false,
            itemData: [
              {
                title: t('order_id'),
                value: t('order_account_overview_order_id', { shopifyOrderName: order.name }),
              },
              {
                title: t('return_items', 'Return Items'),
                value: getReturnLineItems(currentReturn)
              },
              {
                title: t('refund_amount', 'Refunded Amount'),
                value: <p>{currency}{order.type === 'CORPORATE_RENTAL_10' ? order.corpSecurityDepositAmount/100 : refund && refund.amount / 100 }</p>
              },
            ]
          }
      }
    }

    switch (order.status) {
      case orderStatuses.PLACED:
      case orderStatuses.FULFILLING:
        return {
          title: t('order_account_overview_order_placed_success', 'Your order has been placed!'),
          iconName: getStatusIcon(order.status, isRetailSnoo),
          showButton: false,
          showShippingDate: isRetailSnoo,
          editShippingDate: isRetailSnoo,
          shippingDate: order.shippingDate,
          itemData: [
            {
              title: t('order_id'),
              value: t('order_account_overview_order_id', { shopifyOrderName: order.name }),
            },
            {
              title: t('order_summary', 'Order summary'),
              value: getLineItems(),
            },
          ],
        };
      case orderStatuses.FULFILLED:
      case orderStatuses.SHIPPED:
        return {
          title: t('order_account_overview_order_shipped', 'Your order has been shipped'),
          iconName: getStatusIcon(order.status, isRetailSnoo),
          showButton: false,
          showShippingDate: false,
          editShippingDate: false,
          itemData: [
            {
              title: t('order_id'),
              value: t('order_account_overview_order_id', { shopifyOrderName: order.name }),
            },
            {
              title: t('order_account_overview_order_tracking_number', 'Tracking number'),
              value:
                order.trackingData.map((tracking, index) =>
                  <p key={index}>
                    <a
                      className="tracking-number-link"
                      href={tracking.url}
                      target="_blank"
                    onClick={() => {
                    ReactGA.event({
                      category: 'Link Clicked',
                      action: 'retail_order_tracking_requested',
                      label: 'User requested retail order tracking information',
                    });
                  }}>
                      {tracking.number}
                    </a>
                  </p>
                )
            },
          ],
        };
      case orderStatuses.DELIVERED:
        return {
          title: t('order_account_overview_order_delivered', 'Your order has been delivered'),
          iconName: getStatusIcon(order.status, isRetailSnoo),
          showButton: true,
          showShippingDate: false,
          editShippingDate: false,
          itemData: [
            {
              title: t('order_summary', 'Order summary'),
              value: getLineItems(),
            },
          ],
        };
      default:
        return null;
    }
  }

  backToOrder = () => {
    this.setState({
      currentReturnTab: -1
    })
  }

  handleReturnTabChange = (index) => {
    this.setState({
      currentReturnTab: index
    })
  }


  handleMouseDown = (event) => {
    this.dragStart = event.clientX;
    this.scrollLeft = this.returnTabsWrapper.scrollLeft;
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = (event) => {
    const delta = this.dragStart - event.clientX;
    this.returnTabsWrapper.scrollLeft = this.scrollLeft + delta;
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };

  renderOverview() {
    const { t, context, order, userData } = this.props;
    const headerData = [
      {
        className: 'order active',
        title: t('order_account_overview_order_id', { shopifyOrderName: order.name }),
      },
    ];

    const orderPlaced = findStatus(order, orderStatuses.PLACED);
    const orderShipped = findStatus(order, orderStatuses.SHIPPED);
    const orderDelivered = findStatus(order, orderStatuses.DELIVERED);
    const isDigitalProductOrderOnly = order.line_items.every(li => !li.requires_shipping);
    let orderData;
    const hasSnoo = isRetailSnooOrder(order.line_items);
    if (isDigitalProductOrderOnly) {
      orderData = [
        {
          active: true,
          iconActive: hasSnoo ? snooBoxActiveIcon : hbBoxActiveIcon,
          iconInactive: hasSnoo ? snooBoxInactiveIcon : hbBoxInactiveIcon,
          title: t('order_placed', 'Order placed'),
          date: orderPlaced ? <Moment format={context.apiConfig.date.format}>{orderPlaced.date}</Moment> : '',
        }
      ]
    } else {
      orderData = [
        {
          active: order.status === orderStatuses.PLACED || order.status === orderStatuses.FULFILLING,
          iconActive: hasSnoo ? snooBoxActiveIcon : hbBoxActiveIcon,
          iconInactive: hasSnoo ? snooBoxInactiveIcon : hbBoxInactiveIcon,
          title: t('order_placed', 'Order placed'),
          date: orderPlaced ? <Moment format={context.apiConfig.date.format}>{orderPlaced.date}</Moment> : '',
        },
        {
          active: order.status === orderStatuses.SHIPPED || order.status === orderStatuses.FULFILLED,
          iconActive: fedexTruckActiveIcon,
          iconInactive: fedexTruckInactiveIcon,
          title: t('order_account_overview_order_is_shipped', 'Order shipped'),
          date: orderShipped ? <Moment format={context.apiConfig.date.format}>{orderShipped.date}</Moment> : '',
        },
        {
          active: order.status === orderStatuses.DELIVERED,
          iconActive: babyFaceActiveIcon,
          iconInactive: babyFaceInactiveIcon,
          title: t('order_account_overview_order_snoo_delivered_retail', 'Order delivered'),
          date: orderDelivered ? <Moment format={context.apiConfig.date.format}>{orderDelivered.date}</Moment> : '',
        },
      ];
    }
    let returnData;
    if (this.state.currentReturnTab >= 0) {
      const currentReturn = this.state.returns[this.state.currentReturnTab];
      const returnPlaced = findStatus(currentReturn, returnStatuses.INITIATED);
      const returnInTransit = findStatus(currentReturn, returnStatuses.TRANSIT);
      const returnInspected = findStatus(currentReturn, returnStatuses.INSPECTED);
      const isSnooReturn = isRetailSnooOrder(currentReturn.lineItems);
      returnData = [
        {
          active: currentReturn.status === returnStatuses.INITIATED,
          iconActive: isSnooReturn ? snooBoxActiveIcon : hbBoxActiveIcon,
          iconInactive: isSnooReturn ? snooBoxInactiveIcon : hbBoxInactiveIcon,
          title: t('return_initiated', 'Return initiated'),
          date: returnPlaced ? <Moment format={context.apiConfig.date.format}>{returnPlaced.date}</Moment> : '',
        },
        {
          active: currentReturn.status === returnStatuses.TRANSIT || currentReturn.status === returnStatuses.DELIVERED ,
          iconActive: fedexTruckActiveIcon,
          iconInactive: fedexTruckInactiveIcon,
          title: t('return_in_transit', 'Return in transit'),
          date: returnInTransit ? <Moment format={context.apiConfig.date.format}>{returnInTransit.date}</Moment> : '',
        },
        {
          active: currentReturn.status === returnStatuses.INSPECTED,
          iconActive: isSnooReturn ? snooBoxReceivedActiveIcon : hbBoxActiveIcon,
          iconInactive: isSnooReturn ? snooBoxReceivedInactiveIcon : hbBoxInactiveIcon,
          title: t('return_inspected', 'Return Inspected'),
          date: returnInspected ? <Moment format={context.apiConfig.date.format}>{returnInspected.date}</Moment> : '',
        },
      ]

      if (this.state.returns[this.state.currentReturnTab].cancelledAtWarehouse) {
        returnData = [
          {
            active: false,
            iconActive: isSnooReturn ? snooBoxInactiveIcon : hbBoxActiveIcon,
            iconInactive: isSnooReturn ? snooBoxInactiveIcon : hbBoxInactiveIcon,
            title: t('return_initiated', 'Return initiated'),
            date: returnPlaced ? <Moment format={context.apiConfig.date.format}>{returnPlaced.date}</Moment> : '',
          },
          {
            active: true,
            iconActive: subscriptionStopedIcon,
            iconInactive: subscriptionStopedIcon,
            title: t('return_cancelled', 'Return canceled'),
            date: '',
          },
        ];
      }
    }
    const overviewData = this.getDataForOverviewPanel();
    return (
      <React.Fragment key={order.id}>
        <div className="row">
          <div
            ref={(node) => (this.returnTabsWrapper = node)}
            className="return-tabs-wrapper"
            onMouseDown={this.handleMouseDown}
          >
            {this.state.returns.length !== 0 && (
              <div className="action-wrapper">
                <div
                  className={`tab ${this.state.currentReturnTab < 0 && 'active'}`}
                  onClick={this.backToOrder}
                >
                  Order
                </div>
              </div>
            )}
            {this.state.returns.map((el, index) => (
              <div
                key={index}
                className={`tab ${index === this.state.currentReturnTab && 'active'}`}
                onClick={() => this.handleReturnTabChange(index)}
              >
                {index === 0 ? 'Return' : `Return ${index + 1}`}
              </div>
            ))}
          </div>
          {this.state.currentReturnTab >= 0 ?
            <div key={this.state.currentReturnTab}>
                <div className="col-1">
                  {this.state.returns[this.state.currentReturnTab].cancelledAtWarehouse  ? (
                  <ShippingTimeline
                    headerData={headerData}
                    bodyData={returnData}
                    rentalPeriodStarted={false}
                    key={this.state.currentReturnTab}
                  />) : (
                    <ShippingTimeline
                      headerData={headerData}
                      bodyData={returnData}
                      rentalPeriodStarted={false}
                      key={this.state.currentReturnTab}
                      />
                  )}
                </div>


              <div className="col-1">
                <OverviewPanelRetail
                  {...overviewData}
                  userData={userData}
                  order={order}
                  isReturn={true}
                  currentReturn={this.state.returns[this.state.currentReturnTab]}
                  showInitReturnModal={this.props.showInitReturnModal}
                  apiUpdateShippingDate={this.props.apiUpdateShippingDate}
                  showCancelOrderModal={this.props.showCancelOrderModal}
                  showEditAddressModal={this.props.showEditAddressModal}
                  errorShippingDateUpdate={this.props.errorShippingDateUpdate}
                  key={this.state.currentReturnTab}
                />
              </div>
            </div>
            :
            <div key={this.state.currentReturnTab}>
              <div className="col-1">
                <ShippingTimeline
                  headerData={headerData}
                  bodyData={orderData}
                  rentalPeriodStarted={false}
                />
              </div>
              <div className="col-1">
                <OverviewPanelRetail
                  {...overviewData}
                  userData={userData}
                  order={order}
                  returns={this.state.returns}
                  showInitReturnModal={this.props.showInitReturnModal}
                  apiUpdateShippingDate={this.props.apiUpdateShippingDate}
                  showCancelOrderModal={this.props.showCancelOrderModal}
                  showEditAddressModal={this.props.showEditAddressModal}
                  errorShippingDateUpdate={this.props.errorShippingDateUpdate}
                />
              </div>
            </div>
          }
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.renderOverview();
  }
}

RetailOrderAccountOverview.defaultProps = {
  userData: {},
  order: {},
};

RetailOrderAccountOverview.propTypes = {
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  showInitReturnModal: PropTypes.func.isRequired,
  apiUpdateShippingDate: PropTypes.func.isRequired,
  showCancelOrderModal: PropTypes.func.isRequired,
  showEditAddressModal: PropTypes.func.isRequired,
};

export default provideContext(RetailOrderAccountOverview);
