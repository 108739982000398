import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { provideContext } from '../../../context/context.jsx';
import MainMenu from '../menus/mainMenu/mainMenu.jsx';

import * as apiUsersSelectors from '../../../redux-api/apiUsers/apiUsersSelectors.js';
import MobileMainMenu from '../menus/mobileMainMenu/mobileMainMenu.jsx';
import { apiGetBuyPackageRequestAction } from '../../../redux-api/apiRenter/apiRenterActions.js';
import { apiGetRentalItemsRequestAction } from '../../../redux-api/apiRental/apiRentalActions.js';

import * as apiRentalSelectors from '../../../redux-api/apiRental/apiRentalSelectors.js';
import * as apiReturnSelectors from '../../../redux-api/apiReturn/apiReturnSelectors.js';
import * as apiRenterSelectors from '../../../redux-api/apiRenter/apiRenterSelectors.js';
import * as apiOrdersSelector from '../../../redux-api/apiOrders/apiOrdersSelectors.js';
import linkEnums from '../../../enums/linksEnum.js';
import { apiGetReturnItemsRequestAction } from '../../../redux-api/apiReturn/apiReturnActions.js';

import './header.scss';
import hbLogo from '../../../assets/icons/hb-logo.svg';
import LanguagePicker from '../language/LanguagePicker.jsx';
import { Auth } from "aws-amplify";
import { modifyHost, redirectToLogout } from '../../../utils/redirect.js';
import { apiGetOrdersRequestAction } from '../../../redux-api/apiOrders/apiOrdersActions';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enable_language_selector: false
    }
  }

  componentDidMount() {
    this.props.actions.getRentalItems();
    this.props.actions.getReturnItems();
    this.props.actions.apiGetBuyPackage();
    this.setState({
      enable_language_selector: process.env.REACT_APP_ENABLE_LANGUAGE_SELECTOR === 'true' && window.location.host.endsWith('eu')
    })
  }



  logoutUser = () => {
    Auth.signOut()
      .then(() => {
        redirectToLogout();
      })
      .catch(error => {
        console.error(error);
      });
  };


  render() {
    const { returnItems, rentalItems, buyPackage, rentals, flaggedModes, orders } = this.props;
    const { enable_language_selector } = this.state
    const hasDeliveredItems = rentals.some(r => r.lastStatus === 'delivered');
    return (
      <React.Fragment>
        <nav role="navigation" className="Header">
          <div className="center">
            <a href={`${window.location.protocol}//${modifyHost(window.location.host).replace('account.', '')}`}
               className="logo">
              <img src={hbLogo} />
            </a>
            {/* Show initiate return only if there are any rental items or if there are some return items */}
            <MainMenu
              language={this.props.context.i18n.language}
              showBills={rentals.length > 0}
              showPayments={rentals.length > 0}
              showInitiateReturn={(hasDeliveredItems && rentalItems.length > 0) || returnItems.length > 0}
              showBuyPackage={rentals.length > 0}
              buyPackage={buyPackage}
              isNotFlagged={
                flaggedModes !== undefined &&
                !flaggedModes.some(flaggedMode => ['auto', 'manual'].includes(flaggedMode))
              }
              showReferrals={rentals.length > 0 || orders.length > 0}
              {...this.props}
            />
            {enable_language_selector && <LanguagePicker {...this.props} />}
            <div className={enable_language_selector ? "logout ml-auto" : "logout-without-language-selector ml-auto"}>
              <button onClick={this.logoutUser}>{this.props.context.t('button_text_logout','Log out')}</button>
            </div>
          </div>
        </nav>
        {/* Show initiate return only if there are any rental items or if there are some return items */}
        <MobileMainMenu
          logoutUser={this.logoutUser}
          showBills={rentals.length > 0}
          showPayments={rentals.length > 0}
          showInitiateReturn={(hasDeliveredItems && rentalItems.length > 0) || returnItems.length > 0}
          showBuyPackage={rentals.length > 0}
          buyPackage={buyPackage}
          isNotFlagged={
            flaggedModes !== undefined && !flaggedModes.some(flaggedMode => ['auto', 'manual'].includes(flaggedMode))
          }
          showReferrals={rentals.length > 0 || orders.length > 0}
        />
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.shape({
    getRentalItems: PropTypes.func,
    getReturnItems: PropTypes.func,
    getOrders: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  flaggedModes: apiRentalSelectors.getFlaggedModes(state),
  rentals: apiRenterSelectors.getRentals(state),
  rentalItems: apiRentalSelectors.getRentalItems(state),
  returnItems: apiReturnSelectors.getReturnItems(state),
  buyPackage: apiRenterSelectors.getBuyPackage(state),
  orders: apiOrdersSelector.getOrders(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      getRentalItems: apiGetRentalItemsRequestAction,
      getReturnItems: apiGetReturnItemsRequestAction,
      apiGetBuyPackage: apiGetBuyPackageRequestAction,
      getOrders: apiGetOrdersRequestAction,
    },
    dispatch,
  );

  return { actions };
};

export default provideContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
