import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';
import { Row, Col, Container } from '../../../../styles/bootstrap';
import giftCardsInactive from '../../../../assets/gift-cards/gift_card_inactive.svg';

import './noGiftCards.scss';

class NoGiftCards extends Component {
  render() {
    const { t, title, messageHeadline } = this.props;

    return (
      <Container>
        <Row>
          <Col>
            <div className="NoGiftCards">
              <div className="">
                <h3>{title}</h3>
              </div>
              <div className="outer-circle">
                <div className="inner-circle">
                  <img className="inactive" src={giftCardsInactive} />
                </div>
              </div>
              <div className="message">
                <h4>{messageHeadline}</h4>
                <p>{t('no_gift_card_transactions','All your Transactions will be shown here')}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

NoGiftCards.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  messageHeadline: PropTypes.string.isRequired,
};

export default provideContext(NoGiftCards);
