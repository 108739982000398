import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';

import './screenSubTitle.scss';

class ScreenSubTitle extends Component {
  render() {
    return <h4 className="ScreenSubTitle">{this.props.children}</h4>;
  }
}

ScreenSubTitle.propTypes = {
  t: PropTypes.func.isRequired,
};

export default provideContext(ScreenSubTitle);
