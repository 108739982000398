import merge from 'lodash/merge';

export const saveLoadingFlag = actionName => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(actionName);
  if (matches) {
    const [, requestName, requestState] = matches;
    return {
      [requestName]: requestState === 'REQUEST',
    };
  }
  return null;
};

export const saveError = (action, errors) => {
  const matches = /(.*)_(REQUEST|FAILURE|SUCCESS)/.exec(action.type);
  if (matches) {
    const [, requestName, requestState] = matches;
    if (requestState === 'FAILURE') {
      return merge({}, errors, { [requestName]: action.payload });
    }
    const newErrors = merge({}, errors);
    delete newErrors[requestName];
    return newErrors;
  }
  return null;
};

export const saveSuccess = (action, successes) => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action.type);
  if (matches) {
    const [, requestName, requestState] = matches;
    if (requestState === 'SUCCESS') {
      return merge({}, successes, { [requestName]: action.payload });
    }
    const newSuccesses = merge({}, successes);
    delete newSuccesses[requestName];
    return newSuccesses;
  }
  return null;
};

export const initialState = {
  loaders: {},
  errors: {},
  successes: {},
};

const apiUtils = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...saveLoadingFlag(action.type),
        },
        successes: {
          ...saveSuccess(action, state.successes),
        },
        errors: {
          ...saveError(action, state.errors, state),
        },
      };
  }
};

export default apiUtils;
