import { getRouteUrl } from '../utils/urlRoutes';
import { apiConfig } from '../config/apiConfig';

export const routes = {
  USERS_CORPORATE_BENEFITS: {
    route: '/me/v10/corporate-benefits',
    base: apiConfig.api.user,
  },
  USERS_RESET_PASSWORD: {
    route: '/me/v10/password',
    base: apiConfig.api.user,
  },
  USERS_ME: {
    route: '/me/v10/me',
    base: apiConfig.api.user,
  },
  USERS_ANONYMIZATION: {
    route: '/me/v10/anonymize-user',
    base: apiConfig.api.user,
  },
  USERS_LEGAL_CHECK: {
    route: '/me/v10/legal/check',
    base: apiConfig.api.user,
  },
  USERS_LEGAL_ACKNOWLEDGE: {
    route: '/me/v10/legal/acknowledgement',
    base: apiConfig.api.user,
  },
  PAYMENTS_CUSTOMER_SAVE: {
    route: '/me/v10',
    base: apiConfig.api.payment,
  },
  PAYMENTS_CUSTOMER_UPDATE: {
    route: '/me/v10',
    base: apiConfig.api.payment,
  },
  PAYMENTS_CUSTOMER_GET: {
    route: '/me/v10',
    base: apiConfig.api.payment,
  },
  PAYMENTS_CUSTOMER_ACCOUNT_BALANCE: {
    route: '/me/v10/account-balance',
    base: apiConfig.api.payment,
  },
  PAYMENTS_CHARGE_PAST_DUE_INVOICES: {
    route: '/me/v10/invoices/charge',
    base: apiConfig.api.payment,
  },
  PAYMENTS_CUSTOMER_UPCOMING_INVOICES: {
    route: '/me/v10/upcoming-invoice',
    base: apiConfig.api.payment,
  },
  PAYMENTS_SETUPINTENT_CREATE: {
    route: '/me/v10/setup-intent',
    base: apiConfig.api.payment
  },
  PAYMENTS_CARD_SAVE: {
    route: '/me/v10/cards',
    base: apiConfig.api.payment,
  },
  PAYMENTS_CARD_GET: {
    route: '/me/v10/cards/:id',
    base: apiConfig.api.payment,
  },
  PAYMENTS_CARD_UPDATE: {
    route: '/me/v10/cards/:id',
    base: apiConfig.api.payment,
  },
  RENTAL_RENTER_FROM_USER_ID: {
    route: '/me/v10/renters',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_REFFERALS: {
    route: '/me/v10/referrals',
    base: apiConfig.api.rental,
  },
  RETAIL_REFERRALS: {
    route: '/me/v10/referrals',
    base: apiConfig.api.orders,
  },
  RENTAL_RENTER_BILLING_ADDRESS: {
    route: '/me/v10/renters/billing-address',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_APPLIED_GIFT_CARDS: {
    route: '/me/v10/renters/gift-cards',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_GIFT_CARD_USAGE_SUMMARY: {
    route: '/me/v10/renters/gift-cards/usage-summary',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_APPLY_GIFT_CARD: {
    route: '/me/v10/shopify/gift-cards/apply',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_GENERATE_GIFT_CARD: {
    route: '/me/v10/shopify/gift-cards/generate',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_RENTALS: {
    route: '/me/v10/rentals',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_ORDERS: {
    route: '/me/v10/orders',
    base: apiConfig.api.rental,
  },
  RENTAL_CANCEL_RENTAL: {
    route: '/me/v10/rentals/:id/cancel',
    base: apiConfig.api.rental,
  },
  RENTAL_SHIPPING_ADDRESS: {
    route: '/me/v10/rentals/:id/shipping-address',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_BILLING_HISTORY: {
    route: '/me/v10/orders/:orderId/billing-history/:period',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_TOTAL_DEBT: {
    route: '/me/v10/debt',
    base: apiConfig.api.payment,
  },
  RENTAL_SHIPPING_DATE: {
    route: '/me/v10/rentals/:id/shipping-date',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_BUY_PACKAGE: {
    route: '/me/v10/buy-packaging',
    base: apiConfig.api.rental,
  },
  RENTAL_CANCELLING_REASONS: {
    route: '/me/v10/returns/reasons',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_RENTAL_ITEMS: {
    route: '/me/v10/rental-items',
    base: apiConfig.api.rental,
  },
  RENTAL_RENTER_INITIATE_RETURN: {
    route: '/me/v10/returns',
    base: apiConfig.api.rental,
  },
  RENTAL_RETURN_GET_RETURNS: {
    route: '/me/v10/returns',
    base: apiConfig.api.rental,
  },
  RENTAL_PRICE: {
    route: '/shopify/current-price',
    base: apiConfig.api.rental
  },
  SHIPPING_GET_COUNTRIES: {
    route: '/countries',
    base: apiConfig.api.shipping,
  },
  SHIPPING_GET_PROVINCES: {
    route: '/provinces/:id',
    base: apiConfig.api.shipping,
  },
  GET_PRICES: {
    route: '/me/v10/prices',
    base: apiConfig.api.rental,
  },
  GET_ORDERS: {
    route: '/me/v10/order',
    base: apiConfig.api.orders,
  },
  CANCEL_ORDER: {
    route: '/me/v10/orders/:id/cancel',
    base: apiConfig.api.orders,
  },
  UPDATE_ORDER: {
    route: '/me/v10/orders/:id',
    base: apiConfig.api.orders,
  },
  UPDATE_SHIPPING_DATE: {
    route: '/me/v10/orders/:id/shipping-date',
    base: apiConfig.api.orders,
  },
  RETURNS: {
    route: '/me/v10/orders/:id/returns',
    base: apiConfig.api.orders,
  },
  DEVICES: {
    route: '/me/v10/devices',
    base: apiConfig.api.orders,
  },
  PRODUCT_SUPPORT: {
    route: '/me/v10/products/support',
    base: apiConfig.api.orders,
  },
  PDS_DATA_EXPORT: {
    route: '/me/v10/export/jobs',
    base: apiConfig.api.personalData,
  }
};

export const getApiRouteUrl = (route, params = {}, queryParams = {}) => getRouteUrl(route, params, queryParams);

export const getAuthRouteUrl = (route, params = {}, queryParams = {}) => getRouteUrl(route, params, queryParams);
