import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../context/context.jsx';

import './checkbox.scss';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.checked || false,
    };
  }

  handleCheckboxClick = () => {
    const { onClick, onChange } = this.props;
    this.setState(
      (prevState) => {
        const isChecked = !prevState.isChecked;
        if (onClick) {
          onClick(isChecked);
        }
        return { isChecked };
      },
      () => {
        if (onChange) {
          onChange(this.state.isChecked);
        }
      }
    );
  };

  render() {
    const { label, name } = this.props;
    const { isChecked } = this.state;

    return (
      <label className="checkbox" htmlFor={name}>
        {label}
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={isChecked}
          onChange={this.handleCheckboxClick}
        />
        <span className="checkmark" />
      </label>
    );
  }
}

Checkbox.defaultProps = {
  checked: false,
};

Checkbox.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func, // Add the onChange prop type
  checked: PropTypes.bool,
};

export default provideContext(Checkbox);
