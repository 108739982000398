import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import CurrencyFormat from 'react-currency-format';
import Input from '../../input/input.jsx';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';
import Message from '../../presentation/message/message.jsx';
import './generateGiftCardForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

class GenerateGiftCardForm extends Component {
  state = {
    accountBalance: this.props.accountBalance,
    amount: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.accountBalance !== state.accountBalance) {
      return {
        accountBalance: props.accountBalance,
        amount: '',
      };
    }
    return null;
  }
  generateGiftCard = event => {
    event.preventDefault();
    this.props.apiRentalPostGenerateGiftCard(Number.parseFloat(this.state.amount));
    this.formRef.reset();
    this.props.apiPaymentGetCustomerAccountBalance();
    this.props.apiRentalGetRenterGiftCardsUsageSummary();
    this.forceUpdate();
  };

  renderSuccess() {
    return (
      <Message type="success">
        {this.props.t('generate_gift_card_form_success', 'Your gift card has been created. You will receive an email with the gift card number shortly.')}
      </Message>
    );
  }

  renderError() {
    return <Message type="error">{this.props.error.error.message}</Message>;
  }

  onChangeForm = e => {
    const { value } = e.target;
    this.setState({
      amount: value,
    });
  };

  render() {
    const { t, isLoadingGeneratedGiftCard } = this.props;
    return (
      <div className="GenerateGiftCardForm">
        <div className="row">
          {!isEmpty(this.props.error) && this.renderError()}
          {!isEmpty(this.props.success) && this.renderSuccess()}
        </div>
        <div className="row">
          <form ref={fr => (this.formRef = fr)}>
            <div className="input-wrap">
              <CurrencyFormat
                name="amount"
                value={this.state.amount}
                className="form-control"
                decimalScale={2}
                fixedDecimalScale={true}
                autoComplete="off"
                customInput={Input}
                placeholder={t('generate_gift_card_amount_placeholder', 'Enter amount')}
                onChange={() => this.onChangeForm(event)}
              />
            </div>
            <div className="button-wrap">
              <Button color={'green'} onClick={this.generateGiftCard} fullWidth={true} disabled={isLoadingGeneratedGiftCard}>
                {t('button_text_generate_gift_card', 'Generate gift card')}
              </Button>
            </div>
            <div>
              <span className="info-wrap">
                <FontAwesomeIcon icon={faExclamationCircle} className="info" size="lg" />
                <span className="tooltip">
                  {t('generate_gift_card_tooltip',
                    'You may remove funds from your Rental Account by generating a gift card. A digital gift card will be emailed to your email address.',
                  )}
                  <span className="arrow" />
                </span>
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

GenerateGiftCardForm.propTypes = {
  t: PropTypes.func.isRequired,
  apiRentalPostGenerateGiftCard: PropTypes.func.isRequired,
  apiPaymentGetCustomerAccountBalance: PropTypes.func.isRequired,
  apiRentalGetRenterGiftCardsUsageSummary: PropTypes.func.isRequired,
  isLoadingGeneratedGiftCard: PropTypes.bool,
  error: PropTypes.object,
  success: PropTypes.object,
  context: PropTypes.object.isRequired,
};

export default provideContext(GenerateGiftCardForm);
