import { ActionTypes } from './apiRentalActions';

export const initialState = {
  errors: {},
  registeringUser: {},
  renter: {},
  giftCardApplied: {},
  appliedGiftCards: {},
  giftCardsUsageSummary: [],
  giftCard: {},
  shippingDate: {},
  rentalItems: [],
  shippingAddressSuccess: {},
  shippingAddressError: {},
  shippingAddress: {},
};

const apiRental = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_GET_USER_TOKEN_INFO_SUCCESS:
    case ActionTypes.API_POST_USER_PASSWORD_CREATE_SUCCESS:
      return {
        ...state,
        registeringUser: action.payload,
      };
    case ActionTypes.API_GET_RENTER_FROM_USER_ID_SUCCESS:
      return {
        ...state,
        renter: action.payload,
      };
    case ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCardApplied: action.payload,
      };
    case ActionTypes.API_GET_RENTER_APPLIED_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        appliedGiftCards: action.payload,
      };
    case ActionTypes.API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_SUCCESS:
      return {
        ...state,
        giftCardsUsageSummary: action.payload,
      };
    case ActionTypes.API_POST_GENERATE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCard: action.payload,
      };
    case ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_DATE_SUCCESS:
      return {
        ...state,
        shippingDate: action.payload,
      };

    case ActionTypes.API_GET_RENTAL_ITEMS_SUCCESS:
      return {
        ...state,
        rentalItems: action.payload,
      };

    case ActionTypes.API_POST_CANCEL_RENTAL_SUCCESS:
      return {
        ...state,
        rental: action.payload,
      };

    case ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case ActionTypes.API_GET_RENTAL_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case ActionTypes.API_PUT_RENTAL_UPDATE_SHIPPING_ADDRESS_FAILURE:
      return {
        ...state,
        shippingAddressError: action.payload,
      };
    case ActionTypes.API_GET_PRICES_SUCCESS:
      return {
        ...state,
        prices: action.payload,
      };
    case ActionTypes.API_GET_RENTAL_PRICE_SUCCESS:
      return {
        ...state,
        rentalPrice: action.payload,
      };

    case ActionTypes.API_POST_USER_PASSWORD_CREATE_FAILURE:
    case ActionTypes.API_GET_USER_TOKEN_INFO_FAILURE:
    case ActionTypes.API_GET_RENTER_FROM_USER_ID_FAILURE:
    case ActionTypes.API_POST_RENTER_APPLY_GIFT_CARD_FAILURE:
    case ActionTypes.API_GET_RENTER_APPLIED_GIFT_CARDS_FAILURE:
    case ActionTypes.API_POST_GENERATE_GIFT_CARD_FAILURE:
    case ActionTypes.API_GET_RENTAL_ITEMS_FAILURE:
    case ActionTypes.API_POST_CANCEL_RENTAL_FAILURE:
    case ActionTypes.API_GET_RENTER_GIFT_CARD_USAGE_SUMMARY_FAILURE:
    case ActionTypes.API_GET_RENTAL_SHIPPING_ADDRESS_FAILURE:
    case ActionTypes.API_GET_PRICES_FAILURE:
    case ActionTypes.API_GET_RENTAL_PRICE_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default apiRental;
