import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';

import AddressForm from '../../forms/addressForm/addressForm.jsx';
import Message from '../../presentation/message/message.jsx';
import shippingAddressIcon from '../../../../assets/icons/shipping-address.svg';
import addressType from '../../../../enums/addressType.js';

import './confirmReturnAddressesModal.scss';

class ConfirmReturnAddressesModal extends Component {
  renderError(error) {
    return <Message type="error">{error.error.message}</Message>;
  }

  renderRentalOrderAddress = rentalOrder => {
    const { t } = this.props;
    const { address1, address2, city, zip, province, country } = rentalOrder.rental.shippingAddress;
    const shippingAddress = `${address1}, ${address2 ? address2 + ', ' : ''}${city} ${zip}, ${
      province ? province + ',' : ''
    } ${country}`;

    return (
      <div className="confirm-address-box" key={rentalOrder.rental.id}>
        <h4 className="confirm-address-header">{t('confirm_return_address_modal_order', 'Order')}:</h4>
        <div className="confirm-address-line">{rentalOrder.shopifyOrderName}</div>
        <h4 className="confirm-address-header">{t('global_address', 'Address')}:</h4>
        <div className="confirm-address-line">{shippingAddress}</div>
      </div>
    );
  };
  render() {
    const { t, onCloseModal, onChangeAddress, onConfirm, errors, rentals } = this.props;

    return (
      <div className="ConfirmReturnAddressesModal" onClick={onCloseModal}>
        <div className="modal-center" onClick={e => e.stopPropagation()}>
          <div className="inner-modal-center">
            <div className="icon-wrap">
              <span className="icon">
                <img src={shippingAddressIcon} alt="" />
              </span>
            </div>
            <h3>{t('confirm_return_address_modal_confirm', 'Please confirm your address')}</h3>
            <div className="modal-text">
              {rentals
                .filter(r => {
                  return (
                    r.rental.shippingAddressCanBeEdited &&
                    ['PENDING_FULFILLMENT', 'FULFILLING', 'FULFILLED'].includes(r.rental.status) === false
                  );
                })
                .map(this.renderRentalOrderAddress)}
            </div>
            {errors && <div className="modal-errors">{this.renderError(errors)}</div>}
            <div className="button-wrap">
              <Button onClick={onChangeAddress} color={'green-inverted'}>
                {t('confirm_return_address_modal_change', 'I want to change my address')}
              </Button>
              <Button onClick={onConfirm}>
                {t('button_text_initiate_return_with_confirmation', 'Yes, initiate return')}
              </Button>
            </div>
            <div className="clear">&nbsp;</div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmReturnAddressesModal.defaultProps = {
  title: null,
};

ConfirmReturnAddressesModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  errors: PropTypes.object,
  rentals: PropTypes.array.isRequired,
};

export default provideContext(ConfirmReturnAddressesModal);
