import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';

import './shippingTimeline.scss';
import CompanyImage from "../../wrapper/accountOverview/corporateBenefits/companyImage";

class ShippingTimeline extends Component {
  renderItems() {
    const { bodyData } = this.props;
    return bodyData.map((item, index) => (
      <div className={`item ${item.active && 'active'}`} key={index}>
        <div className="circle">
          <img src={item.iconInactive} className="inactive" alt="" />
          <img src={item.iconActive} className="active" alt="" />
        </div>
        <div className="label">
          <h4>{item.title}</h4>
          <h5>{item.date}</h5>
        </div>
      </div>
    ));
  }

  renderHeader() {
    const { headerData } = this.props;

    return headerData.map((item, index) => (
      <span key={index} className={item.className} aria-label={item.title}>
        {item.title}
      </span>
    ));
  }
  render() {
    const { t, rentalPeriodStarted, companyImage } = this.props;

    return (
      <div className="ShippingTimeline">
        <div className="inner-wrap">
          <div className="timeline-heading">
            <div>
              {this.renderHeader()}
            </div>
            <CompanyImage image={companyImage} />

          </div>
          <div className="timeline-body">
            <div className="circle-center">{this.renderItems()}</div>
          </div>
        </div>
        {rentalPeriodStarted && <div className="outside-message">{t('shipping_timeline_period_has_started','Your rental period has started')}</div>}
      </div>
    );
  }
}

ShippingTimeline.propTypes = {
  t: PropTypes.func.isRequired,
  headerData: PropTypes.array.isRequired,
  bodyData: PropTypes.array.isRequired,
  rentalPeriodStarted: PropTypes.bool,
  companyImage: PropTypes.object,
};

export default provideContext(ShippingTimeline);
