import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { provideContext } from '../../../../context/context.jsx';
import Checkbox from '../../checkbox/checkbox.jsx';
import Input from '../../input/input.jsx';
import Button from '../../button/button.jsx';
import Message from '../../presentation/message/message.jsx';
import isEmpty from 'lodash/isEmpty';
import validateTelephone from '../../../../utils/validateTelephone';
import './profileForm.scss';
import ConfirmEmailChangeModal from '../../modals/confirmEmailChangeModal/confirmEmailChangeModal';
import { Auth } from 'aws-amplify';

import ReactGA from 'react-ga4';

class ProfileForm extends Component {
  state = {
    givenName: this.props.profile.givenName,
    surname: this.props.profile.surname,
    email: this.props.profile.email,
    phone: this.props.phone,
    errors: this.props.errorUserLogin,
    emailMarketing: this.props.emailMarketing,
    requiredError: {},
    showConfirmEmailChangeEmail: false,
    isCodeConfirmed: null,
  };

  constructor(props) {
    super(props);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isCodeConfirmed !== this.state.isCodeConfirmed) {
      await this.saveProfile();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.errorUserLogin !== state.errors) {
      return {
        errors: props.errorUserLogin,
      };
    }
    return null;
  }

  onChangeForm = (e) => {
    const { name, value, checked, type } = e.target;
    if (name == 'phone') {
      return null;
    }
    if (type != 'checkbox') {
      this.setState({
        [name]: value,
      });
    } else {
      this.setState({
        [name]: checked,
      });
    }
  };

  saveProfile = async () => {
    const { t } = this.props;

    await this.setState({
      requiredError: {},
    });
    const profile = {
      givenName: this.state.givenName || null,
      surname: this.state.surname || null,
      email: this.state.email || null,
    };
    const phone = this.state.phone || '';

    if (!profile.givenName || !profile.surname || !profile.email) {
      this.setState({
        requiredError: {
          message: t('all_fields_must_be_filled'),
        },
      });
      return null;
    }

    if (phone && !validateTelephone(phone)) {
      this.setState({
        requiredError: {
          message: t('profile_form_error_phone_number', 'Please enter valid phone number'),
        },
      });
      return null;
    }

    const emailMarketing = this.state.emailMarketing;
    return this.props.apiSaveProfile(profile, phone, emailMarketing);
  };

  handlePhoneChange(e) {
    const { name, value } = e.target;
    this.setState({
      phone: value,
    });
  }

  handleProfileUpdate = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.email !== this.props.profile.email) {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        email: this.state.email,
      });
      this.setState({
        showModal: true,
      });
      ReactGA.event({
        category: 'Button Clicked',
        action: 'profile_email_updated',
        label: 'User email has been updated.',
      });
      return null;
    }

    await this.saveProfile();
  };

  renderError(error) {
    if (error.error.code === 1001) {
      error.error.message = this.props.t('profile_form_invalid_email_address_error', 'Invalid Email Address');
    }
    return <Message type="error">{error.error.message}</Message>;
  }

  renderSuccsess() {
    const { t } = this.props;
    return (
      <Message type="success">{t('profile_form_change_success', 'Account information changed successfully!')}</Message>
    );
  }

  renderRequiredError() {
    const { requiredError } = this.state;
    return <Message type="error">{requiredError.message}</Message>;
  }

  render() {
    const { t, renterSuccess, renterError } = this.props;
    const { requiredError } = this.state;
    return (
      <form className="ProfileForm" onChange={this.onChangeForm}>
        <div className="row">
          <div className="col-1">
            {!isEmpty(renterError) && isEmpty(requiredError) && this.renderError(renterError)}
            {!isEmpty(renterSuccess) && isEmpty(requiredError) && this.renderSuccsess()}
            {!isEmpty(requiredError) && this.renderRequiredError()}
          </div>
        </div>
        <div className="row">
          <div className="col-1-2">
            <Input name="givenName" placeholder={t('first_name')} defaultValue={this.state.givenName} type="text" />
          </div>
          <div className="col-1-2">
            <Input name="surname" placeholder={t('last_name')} defaultValue={this.state.surname} type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col-1-2">
            <Input name="email" placeholder={t('global_email')} defaultValue={this.state.email} type="email" />
            <span className="note">{t('change_email_helper')}</span>
          </div>
          <div className="col-1-2">
            {this.props.phone && (
              <Input
                name="phone"
                type="text"
                placeholder={t('global_phone')}
                value={this.state.phone}
                onChange={(e) => this.handlePhoneChange(e)}
              />
            )}
          </div>
        </div>
        {this.props.showMarketing && (
          <div className="row">
            <div className="col-1 checkbox-wrap">
              <Checkbox
                name="emailMarketing"
                label={t(
                  'profile_page_sign_up_special_sleep',
                  `Sign up for emails with updates, special offers, and Dr. Karp's special sleep and baby tips!`,
                )}
                checked={this.state.emailMarketing}
              />
            </div>
          </div>
        )}
        <div className="row button-wrap">
          <div className="col-1">
            <Button color={'green'} onClick={this.handleProfileUpdate}>
              {t('button_text_save_changes')}
            </Button>
          </div>
        </div>
        {this.state.showModal && (
          <ConfirmEmailChangeModal
            t={t}
            onCloseModal={() => this.setState({ showModal: false })}
            onSubmitForm={() => this.setState({ isCodeConfirmed: new Date() })}
          />
        )}
      </form>
    );
  }
}

ProfileForm.defaultProps = {
  errorUserLogin: {},
};

ProfileForm.propTypes = {
  t: PropTypes.func.isRequired,
  phone: PropTypes.string,
  emailMarketing: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  apiSaveProfile: PropTypes.func.isRequired,
  errorUserLogin: PropTypes.object,
  context: PropTypes.object.isRequired,
  renterSuccess: PropTypes.object,
  renterError: PropTypes.object,
  showMarketing: PropTypes.bool,
};

export default provideContext(ProfileForm);
