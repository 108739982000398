import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { provideContext } from '../../../../../context/context.jsx';
import Currency from '../../../formats/currencyFormat/currency.jsx';
import billItemTypesEnum from '../../../../../enums/billItemTypes.js';
import downloadIcon from '../../../../../assets/download-icon.svg';

import './../billHistoryTable.scss';

class BillHistoryTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: this.props.period,
      recurringItems: [],
      recurringTotal: 0,
      oneTimeItems: [],
      oneTimeTotal: 0,
      taxItems: [],
      taxTotal: 0,
      collapsePreviousActivity: this.props.collapsePreviousActivity,
      collapseRental: this.props.collapseRental,
      collapseRecurring: false,
      collapseOneTime: false,
      collapseTaxes: false
    };
  }

  formatTableData(periodItems) {
    const oneTimeItems = [];
    const recurringItems = [];
    let recurringTotal = 0;
    let oneTimeTotal = 0;

    periodItems.map(item => {
      if (billItemTypesEnum.ONE_TIME_ITEM_TYPES.includes(item.type)) {
        oneTimeItems.push(item);
        oneTimeTotal += item.amount;
      }
      if (billItemTypesEnum.RECURRING_ITEM_TYPES.includes(item.type)) {
        recurringItems.push(item);
        recurringTotal += item.amount;
      }
    });

    this.setState({
      oneTimeItems,
      oneTimeTotal,
      recurringItems,
      recurringTotal,
    });
  }

  toggleOneTime = event => {
    this.setState({
      collapseOneTime: !this.state.collapseOneTime,
    });
  };

  toggleRecurring = event => {
    this.setState({
      collapseRecurring: !this.state.collapseRecurring,
    });
  };

  toggleTaxes = event => {
    this.setState(state => ({
      collapseTaxes: !state.collapseTaxes,
    }))
  }

  static getDerivedStateFromProps(props, state) {
    if (props.collapseRental !== state.collapseRental) {
      return {
        collapseRental: props.collapseRental,
      };
    }
    const recurringItems = [];
    const oneTimeItems = [];
    const taxItems = []
    let recurringTotal = 0;
    let oneTimeTotal = 0;
    let taxTotal = 0;

    props.periodItems.forEach(item => {
      if (billItemTypesEnum.ONE_TIME_ITEM_TYPES.includes(item.type)) {
        oneTimeItems.push(item);
        oneTimeTotal += item.amount || 0;
        if (item.withheldSecurityDeposit) {
          oneTimeItems.push({
            name: 'Withhold Security Deposit',
            amount: item.withheldSecurityDeposit.withheldAmount
          });
          oneTimeTotal += item.withheldSecurityDeposit.withheldAmount;
        }
      }
      if (billItemTypesEnum.RECURRING_ITEM_TYPES.includes(item.type)) {
        recurringItems.push(item);
        recurringTotal += item.amount || 0;
      }
      if (billItemTypesEnum.TAX_ITEM_TYPES.includes(item.type)) {
        taxItems.push(item)
        taxTotal += item.amount || 0
      }
    });

    return {
      recurringItems,
      recurringTotal,
      oneTimeItems,
      oneTimeTotal,
      taxItems,
      taxTotal,
      period: props.period,
    };
  }

  render() {
    const { t, context } = this.props;
    const {
      recurringItems,
      recurringTotal,
      oneTimeItems,
      oneTimeTotal,
      taxItems,
      taxTotal,
      collapseRental,
      collapseRecurring,
      collapseOneTime,
      collapseTaxes,
    } = this.state;

    return (
      <React.Fragment>
        <tr
          className={classnames('sub-head', {
            collapse: collapseRental,
          })}
          onClick={this.toggleRecurring}
        >
          <td
            className={classnames('arrow', {
              close: collapseRecurring,
            })}
          />
          <td colSpan="2">{t('bill_history_table_row_recurring','Recurring')}</td>
          <td>
            <div>
              <Currency value={recurringTotal} context={context} />
            </div>
          </td>
        </tr>
        {recurringItems.map((item, index) => {
          return (
            <tr
              className={classnames({
                collapse: collapseRecurring || collapseRental,
              })}
              key={index}
            >
              <td />
              <td colSpan="2">{item.name}</td>
              <td>
                {item.amount && <div>
                  <Currency value={item.amount} context={context} />
                </div>}
              </td>
            </tr>
          );
        })}
        <tr
          className={classnames('sub-head', {
            collapse: collapseRental,
          })}
          onClick={this.toggleOneTime}
        >
          <td
            className={classnames('arrow', {
              close: collapseOneTime,
            })}
          />
          <td colSpan="2">{t('bill_history_table_row_once','One time')}</td>
          <td>
            <div>
              <Currency value={oneTimeTotal} context={context} />
            </div>
          </td>
        </tr>
        {oneTimeItems.map((item, index) => {
          return (
            <tr
              className={classnames({
                collapse: collapseOneTime || collapseRental,
              })}
              key={index}
            >
              <td />
              <td colSpan="2">{item.name}</td>
              <td>
                {item.amount && <div>
                  <Currency value={item.amount} context={context} />
                </div>}
              </td>
            </tr>
          );
        })}
        <tr
          className={classnames('sub-head', {
            collapse: collapseRental,
          })}
          onClick={this.toggleTaxes}
        >
          <td
            className={classnames('arrow', {
              close: collapseTaxes,
            })}
          />
          <td colSpan="2">{t('bill_history_table_row_taxes','Taxes')}</td>
          <td>
            <div>
              <Currency value={taxTotal} context={context} />
            </div>
          </td>
        </tr>
        {taxItems.map((item, index) => {
          return (
            <tr
              className={classnames({
                collapse: collapseTaxes || collapseRental,
              })}
              key={index}
            >
              <td />
              <td colSpan="2">{item.name}</td>
              <td>
                {item.amount && <div>
                  <Currency value={item.amount} context={context} />
                </div>}
              </td>
            </tr>
          );
        })}
      </React.Fragment>
    );
  }
}

BillHistoryTableRow.defaultProps = {
  period: '1',
};

BillHistoryTableRow.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired,
  periodItems: PropTypes.array.isRequired,
};

export default provideContext(BillHistoryTableRow);
