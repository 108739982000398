import { ActionTypes } from './apiShippingActions';
import assign from 'lodash/assign';

export const initialState = {
  errors: {},
  countries: [],
  provinces: [],
};

const apiShipping = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_GET_SHIPPING_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };
    case ActionTypes.API_GET_SHIPPING_PROVINCES_SUCCESS:
      const provinces = {
        [action.payload.countryId]: action.payload.provinces,
      };

      return {
        ...state,
        provinces: assign({}, state.provinces, provinces),
      };
    case ActionTypes.API_GET_SHIPPING_COUNTRIES_FAILURE:
    case ActionTypes.API_GET_SHIPPING_PROVINCES_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default apiShipping;
