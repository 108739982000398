export const ActionTypes = {
    API_GET_RENTER_REFERRALS_REQUEST: 'API_GET_RENTER_REFERRALS_REQUEST',
    API_GET_RENTER_REFERRALS_SUCCESS: 'API_GET_RENTER_REFERRALS_SUCCESS',
    API_GET_RENTER_REFERRALS_FAILURE: 'API_GET_RENTER_REFERRALS_FAILURE',
    API_POST_RENTER_REFERRALS_REQUEST: 'API_POST_RENTER_REFERRALS_REQUEST',
    API_POST_RENTER_REFERRALS_SUCCESS: 'API_POST_RENTER_REFERRALS_SUCCESS',
    API_POST_RENTER_REFERRALS_FAILURE: 'API_POST_RENTER_REFERRALS_FAILURE',
    API_POST_RETAIL_REFERRALS_REQUEST: 'API_POST_RETAIL_REFERRALS_REQUEST',
};

export const apiGetRenterReferralsRequestAction = isRental => ({
    type: ActionTypes.API_GET_RENTER_REFERRALS_REQUEST,
    payload: { isRental },
});

export const apiGetRenterReferralsSuccessAction = response => ({
    type: ActionTypes.API_GET_RENTER_REFERRALS_SUCCESS,
    payload: response,
});

export const apiGetRenterReferralsFailureAction = error => ({
    type: ActionTypes.API_GET_RENTER_REFERRALS_FAILURE,
    payload: error,
});

export const apiPostRenterReferralsRequestAction = (email, isRental) => ({
    type: ActionTypes.API_POST_RENTER_REFERRALS_REQUEST,
    payload: { email, isRental },
});
export const apiPostRetailReferralsRequestAction = customerData => ({
    type: ActionTypes.API_POST_RETAIL_REFERRALS_REQUEST,
    payload: customerData,
})

export const apiPostRenterReferralsSuccessAction = response => ({
    type: ActionTypes.API_POST_RENTER_REFERRALS_SUCCESS,
    payload: response,
});

export const apiPostRenterReferralsFailureAction = error => ({
    type: ActionTypes.API_POST_RENTER_REFERRALS_FAILURE,
    payload: error,
});

