import { createSelector } from 'reselect';
import { ActionTypes } from './apiReturnActions';

import {
  getAllLoadersFromState,
  getLoaderStatus,
  getAllErrorsFromState,
  getErrorStatus,
  getAllSuccessesFromState,
  getSuccessStatus,
} from '../apiUtils/apiUtils';

export const getReturnItems = state => state.apiReturn.returnItems;
export const getCancellingReturnReasons = state => state.apiReturn.reasons;

export const isLoadingGetReturnItems = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RETURN_ITEMS_REQUEST),
);

export const getInitiateReturnErrors = createSelector(
  getAllErrorsFromState,
  errors => getErrorStatus(errors, ActionTypes.API_POST_RENTAL_INITIATE_RETURN_FAILURE),
);

export const getInitiateReturnSuccess = createSelector(
  getAllSuccessesFromState,
  successes => getSuccessStatus(successes, ActionTypes.API_POST_RENTAL_INITIATE_RETURN_SUCCESS),
);

export const isLoadingInitiateReturn = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_POST_RENTAL_INITIATE_RETURN_REQUEST),
);

export const isLoadingGetCancellingReasons = createSelector(
  getAllLoadersFromState,
  loaders => getLoaderStatus(loaders, ActionTypes.API_GET_RENTAL_CANCELLING_REASONS_REQUEST),
);
