import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';

// REDUCER IMPORTS
import apiUsers from './apiUsers/apiUsersReducer';
import apiPayments from './apiPayments/apiPaymentsReducer';
import apiRental from './apiRental/apiRentalReducer';
import apiRenter from './apiRenter/apiRenterReducer';
import apiShipping from './apiShipping/apiShippingReducer';
import apiReturn from './apiReturn/apiReturnReducer';
import apiReferral from './apiReferral/apiReferralReducer';
import apiOrders from './apiOrders/apiOrdersReducer';

import apiUtils from './apiUtils/apiUtilsReducer';

// ACTION IMPORTS
import * as apiUsersActions from './apiUsers/apiUsersActions';
import * as apiPaymentsActions from './apiPayments/apiPaymentsActions';
import * as apiRentalActions from './apiRental/apiRentalActions';
import * as apiRenterActions from './apiRenter/apiRenterActions';
import * as apiShippingActions from './apiShipping/apiShippingActions';
import * as apiReturnActions from './apiReturn/apiReturnActions';
import * as apiReferralActions from './apiReferral/apiReferralActions';
import * as apiOrdersActions from './apiOrders/apiOrdersActions';

// SELECTORS IMPORTS
import * as apiUsersSelector from './apiUsers/apiUsersSelectors';
import * as apiPaymentsSelector from './apiPayments/apiPaymentsSelectors';
import * as apiRentalSelector from './apiRental/apiRentalSelectors';
import * as apiRenterSelector from './apiRenter/apiRenterSelectors';
import * as apiShippingSelector from './apiShipping/apiShippingSelectors';
import * as apiReturnSelector from './apiReturn/apiReturnSelectors';
import * as apiReferralSelector from './apiReferral/apiReferralSelectors';
import * as apiOrdersSelector from './apiOrders/apiOrdersSelectors';

// ACTION EXPORTS
export {
  apiUsersActions,
  apiPaymentsActions,
  apiRentalActions,
  apiRenterActions,
  apiShippingActions,
  apiReturnActions,
  apiReferralActions,
  apiOrdersActions,
};

// SELECTORS EXPORTS
export {
  apiUsersSelector,
  apiPaymentsSelector,
  apiRentalSelector,
  apiRenterSelector,
  apiShippingSelector,
  apiReturnSelector,
  apiReferralSelector,
  apiOrdersSelector,
};

export { routes } from './routes';

export const reduxApiReducers = {
  apiUtils,
  apiPayments,
  apiUsers,
  apiRental,
  apiRenter,
  apiShipping,
  apiReturn,
  apiReferral,
  apiOrders,
};

export const reduxApiSagaMiddleware = createSagaMiddleware();

export function reduxApiRunSagas() {
  reduxApiSagaMiddleware.run(rootSaga);
}
