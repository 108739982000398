export const ActionTypes = {
  API_PUT_RENTER_UPDATE_REQUEST: 'API_PUT_RENTER_UPDATE_REQUEST',
  API_PUT_RENTER_UPDATE_SUCCESS: 'API_POST_RENTER_UPDATE_SUCCESS',
  API_PUT_RENTER_UPDATE_FAILURE: 'API_POST_RENTER_UPDATE_FAILURE',
  API_POST_RENTER_CHANGE_PASSWORD_REQUEST: 'API_POST_RENTER_CHANGE_PASSWORD_REQUEST',
  API_POST_RENTER_CHANGE_PASSWORD_SUCCESS: 'API_POST_RENTER_CHANGE_PASSWORD_SUCCESS',
  API_POST_RENTER_CHANGE_PASSWORD_FAILURE: 'API_POST_RENTER_CHANGE_PASSWORD_FAILURE',
  API_PUT_RENTER_UPDATE_BILLING_ADDRESS_REQUEST: 'API_PUT_RENTER_UPDATE_BILLING_ADDRESS_REQUEST',
  API_PUT_RENTER_UPDATE_BILLING_ADDRESS_SUCCESS: 'API_PUT_RENTER_UPDATE_BILLING_ADDRESS_SUCCESS',
  API_PUT_RENTER_UPDATE_BILLING_ADDRESS_FAILURE: 'API_PUT_RENTER_UPDATE_BILLING_ADDRESS_FAILURE',
  API_GET_RENTER_BILLING_ADDRESS_REQUEST: 'API_GET_RENTER_BILLING_ADDRESS_REQUEST',
  API_GET_RENTER_BILLING_ADDRESS_SUCCESS: 'API_GET_RENTER_BILLING_ADDRESS_SUCCESS',
  API_GET_RENTER_BILLING_ADDRESS_FAILURE: 'API_GET_RENTER_BILLING_ADDRESS_FAILURE',
  API_GET_RENTER_RENTALS_REQUEST: 'API_GET_RENTER_RENTALS_REQUEST',
  API_GET_RENTER_RENTALS_SUCCESS: 'API_GET_RENTER_RENTALS_SUCCESS',
  API_GET_RENTER_RENTALS_FAILURE: 'API_GET_RENTER_RENTALS_FAILURE',
  API_GET_BUY_PACKAGE_REQUEST: 'API_GET_BUY_PACKAGE_REQUEST',
  API_GET_BUY_PACKAGE_SUCCESS: 'API_GET_BUY_PACKAGE_SUCCESS',
  API_GET_BUY_PACKAGE_FAILURE: 'API_GET_BUY_PACKAGE_FAILURE',
  API_GET_RENTER_ORDERS_REQUEST: 'API_GET_RENTER_ORDERS_REQUEST',
  API_GET_RENTER_ORDERS_SUCCESS: 'API_GET_RENTER_ORDERS_SUCCESS',
  API_GET_RENTER_ORDERS_FAILURE: 'API_GET_RENTER_ORDERS_FAILURE',
  API_GET_RENTER_BILLING_HISTORY_REQUEST: 'API_GET_RENTER_BILLING_HISTORY_REQUEST',
  API_GET_RENTER_BILLING_HISTORY_SUCCESS: 'API_GET_RENTER_BILLING_HISTORY_SUCCESS',
  API_GET_RENTER_BILLING_HISTORY_FAILURE: 'API_GET_RENTER_BILLING_HISTORY_FAILURE',
  API_GET_RENTER_TOTAL_DEBT_REQUEST: 'API_GET_RENTER_TOTAL_DEBT_REQUEST',
  API_GET_RENTER_TOTAL_DEBT_SUCCESS: 'API_GET_RENTER_TOTAL_DEBT_SUCCESS',
  API_GET_RENTER_TOTAL_DEBT_FAILURE: 'API_GET_RENTER_TOTAL_DEBT_FAILURE',
};

export const apiPutUpdateRenterRequestAction = renterData => ({
  type: ActionTypes.API_PUT_RENTER_UPDATE_REQUEST,
  payload: renterData,
});

export const apiPutUpdateRenterSuccessAction = response => ({
  type: ActionTypes.API_PUT_RENTER_UPDATE_SUCCESS,
  payload: response,
});

export const apiPutUpdateRenterFailureAction = error => ({
  type: ActionTypes.API_PUT_RENTER_UPDATE_FAILURE,
  payload: error,
});

export const apiPostChangePasswordRequestAction = passwordData => ({
  type: ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_REQUEST,
  payload: passwordData,
});

export const apiPostChangePasswordSuccessAction = response => ({
  type: ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_SUCCESS,
  payload: response,
});

export const apiPostChangePasswordFailureAction = error => ({
  type: ActionTypes.API_POST_RENTER_CHANGE_PASSWORD_FAILURE,
  payload: error,
});

export const apiPutUpdateRenterBillingAddressRequestAction = address => ({
  type: ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_REQUEST,
  payload: address,
});

export const apiPutUpdateRenterBillingAddressSuccessAction = response => ({
  type: ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_SUCCESS,
  payload: response,
});

export const apiPutUpdateRenterBillingAddressFailureAction = error => ({
  type: ActionTypes.API_PUT_RENTER_UPDATE_BILLING_ADDRESS_FAILURE,
  payload: error,
});

export const apiGetRenterBillingAddressRequestAction = () => ({
  type: ActionTypes.API_GET_RENTER_BILLING_ADDRESS_REQUEST,
});

export const apiGetRenterBillingAddressSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTER_BILLING_ADDRESS_SUCCESS,
    payload: response,
  };
};

export const apiGetRenterBillingAddressFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_BILLING_ADDRESS_FAILURE,
  payload: error,
});

export const apiGetRenterRentalsRequestAction = () => ({
  type: ActionTypes.API_GET_RENTER_RENTALS_REQUEST,
});

export const apiGetRenterRentalsSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTER_RENTALS_SUCCESS,
    payload: response,
  };
};

export const apiGetRenterRentalsFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_RENTALS_FAILURE,
  payload: error,
});

export const apiGetBuyPackageRequestAction = () => ({
  type: ActionTypes.API_GET_BUY_PACKAGE_REQUEST,
});

export const apiGetBuyPackageSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_BUY_PACKAGE_SUCCESS,
    payload: response,
  };
};

export const apiGetBuyPackageFailureAction = error => ({
  type: ActionTypes.API_GET_BUY_PACKAGE_FAILURE,
  payload: error,
});

export const apiGetRenterOrdersRequestAction = () => ({
  type: ActionTypes.API_GET_RENTER_ORDERS_REQUEST,
});

export const apiGetRenterOrdersSuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTER_ORDERS_SUCCESS,
    payload: response,
  };
};

export const apiGetRenterOrdersFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_ORDERS_FAILURE,
  payload: error,
});

export const apiGetRenterBillingHistoryRequestAction = ({ orderId, period }) => ({
  type: ActionTypes.API_GET_RENTER_BILLING_HISTORY_REQUEST,
  payload: { orderId, period },
});

export const apiGetRenterBillingHistorySuccessAction = response => {
  return {
    type: ActionTypes.API_GET_RENTER_BILLING_HISTORY_SUCCESS,
    payload: response,
  };
};

export const apiGetRenterBillingHistoryFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_BILLING_HISTORY_FAILURE,
  payload: error,
});

export const apiGetRenterTotalDebtRequestAction = () => ({
  type: ActionTypes.API_GET_RENTER_TOTAL_DEBT_REQUEST,
});

export const apiGetRenterTotalDebtSuccessAction = response => ({
  type: ActionTypes.API_GET_RENTER_TOTAL_DEBT_SUCCESS,
  payload: response,
});

export const apiGetRenterTotalDebtFailureAction = error => ({
  type: ActionTypes.API_GET_RENTER_TOTAL_DEBT_FAILURE,
  payload: error,
});
