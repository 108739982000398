import { takeEvery, call, put } from 'redux-saga/effects';

import { getReferrals, postReferrals } from './api';

import {
  ActionTypes,
  apiGetRenterReferralsSuccessAction,
  apiGetRenterReferralsFailureAction,
  apiPostRenterReferralsSuccessAction,
  apiPostRenterReferralsFailureAction,
} from './apiReferralActions';

export function* apiGetReferrals(action) {
  const { response, error } = yield call(getReferrals, action.payload);

  if (response) {
    yield put(apiGetRenterReferralsSuccessAction(response.data));
  } else {
    yield put(apiGetRenterReferralsFailureAction(error.response.data));
  }
}

export function* apiPostReferrals(action) {
  const { email, isRental } = action.payload;

  const { response, error } = yield call(postReferrals, { email, isRental });

  if (response) {
    yield call(apiGetReferrals, { payload: { isRental } });
    yield put(apiPostRenterReferralsSuccessAction(response.data));
  } else {
    yield put(apiPostRenterReferralsFailureAction(error.response.data));
  }
}

export default function* apiRentalSaga() {
  yield takeEvery(ActionTypes.API_GET_RENTER_REFERRALS_REQUEST, apiGetReferrals);
  yield takeEvery(ActionTypes.API_POST_RENTER_REFERRALS_REQUEST, apiPostReferrals);
}
