import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { extendMoment } from 'moment-range';
import { apiGetBuyPackageRequestAction } from '../../../../redux-api/apiRenter/apiRenterActions.js';
import * as apiRenterSelectors from '../../../../redux-api/apiRenter/apiRenterSelectors.js';
extendMoment(moment);
import Radio from '../../radio/radio.jsx';
import Select from '../../select/select.jsx';
import { provideContext } from '../../../../context/context.jsx';
import Message from '../../presentation/message/message.jsx';
import Button from '../../button/button.jsx';
import isEmpty from 'lodash/isEmpty';
import packagingOptionsEnum from '../../../../enums/packagingOptions.js';
import './initiateReturnForm.scss';

import ReactGA from 'react-ga4';

import InitiateReturnConfirmModal from '../../../../components/views/modals/simpleConfirmModal/simpleConfirmModal.jsx';

class InitiateReturnForm extends Component {
  state = {
    reason: null,
    returnItems: [],
    selectedOption: [],
    initiateReturnError: this.props.initiateReturnError,
    initiateReturnSuccess: this.props.initiateReturnSuccess,
    selectedReason: 0,
    prices: {},
    rentalCommitmentInFoul: null,
    initiateReturnConfirmModalVisible: false,
  };

  componentDidMount() {
    this.props.actions.apiGetBuyPackage();
    this.getRentalCommitmentInFoul();
  }

  getRentalCommitmentInFoul() {
    const rentals = this.props.rentals;

    const committedRentalInFoul = rentals.find((r) => r.rental.commitment && r.rental.commitment.inFoul);
    if (committedRentalInFoul) {
      this.rentalCommitmentInFoul = committedRentalInFoul.rental.commitment;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.initiateReturnError !== state.initiateReturnError) {
      return {
        initiateReturnError: props.initiateReturnError,
      };
    }
    if (props.initiateReturnSuccess !== state.initiateReturnSuccess) {
      return {
        initiateReturnSuccess: props.initiateReturnSuccess,
      };
    }
    return null;
  }

  renderDefaultMessage() {
    const { t, prices } = this.props;
    const shippingFee = prices ? (prices.returnShippingFee / 100).toFixed(2) : '0.00';
    return (
      <div>
        <Message type="info">
          {t(
            'billhistory_default_message',
            `Your payment method will be charged until the day FedEx picks up your SNOO.`,
          )}
          <br />
          {t(
            'billhistory_default_message_with_shipping_fee',
            `You will be charged {{shippingFee}} for return shipping when you ship your return SNOO. If the charge fails, return shipping fee will be deducted from your security deposit refund.`,
            { shippingFee },
          )}
          <br />
          <br />
          {t(
            'billhistory_return_label_message',
            `We will generate a return label for your SNOO. If you choose to keep your SNOO longer but you’ve already printed your label, simply keep your SNOO and use the same label when you’re ready to send it back. The label will be valid for 1 year.`,
          )}
          {this.rentalCommitmentInFoul && (
            <span>
              <br />
              <br />
              {t(
                'billhistory_newborn_special_plan',
                `With the Newborn Special Plan, you have agreed to rent your SNOO for a minimum of {{minimumRentalPeriod}} months. If you decide to end your subscription before the set agreement, you will still be charged the {{minimumRentalInFould}} month commitment.`,
                {
                  minimumRentalPeriod: this.rentalCommitmentInFoul.minimumRentalPeriod,
                  minimumRentalPeriodInFoul: this.rentalCommitmentInFoul.minimumRentalPeriod,
                },
              )}
            </span>
          )}
        </Message>
      </div>
    );
  }

  onChangeForm = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value === 'true',
    });
  };

  handleChangeSelect = (name, value, item) => {
    this.setState({
      selectedReason: value,
      reason: item.key,
    });
  };

  initiateReturn = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.rentalCommitmentInFoul) {
      this.setState({
        initiateReturnConfirmModalVisible: true,
      });
    } else {
      this.processReturn();
      ReactGA.event({
        category: 'Button Clicked',
        action: 'rental_return_initiated',
        label: 'Rental return has been initiated.',
      });
    }
  };

  processReturn = () => {
    const returnItems = this.props.rentalItems.map((item) => ({ rentalItem: item.id }));
    const reason = this.state.reason;

    const data = {
      reason,
      returnItems,
    };

    return this.props.apiInitiateReturn(data);
  };

  renderCancellingReasonOptions() {
    const cancelingReasons = [];
    Object.keys(this.props.reasons).map((item, index) =>
      cancelingReasons.push({
        key: item,
        title: this.props.reasons[item],
      }),
    );
    return cancelingReasons;
  }

  render() {
    const { t, context, buyPackage } = this.props;
    const { packaging, reason } = this.state;
    return (
      <div className="InitiateReturnForm">
        <form>
          <p>
            <span style={{ color: 'red' }}>*</span>
            {t('initiate_return_form_reason', 'Please let us know the reason for sending back your SNOO')}:
          </p>
          <Select
            list={this.renderCancellingReasonOptions()}
            placeholder={t('initiate_return_form_select_reason', 'Select reason')}
            labelKey="title"
            onChange={this.handleChangeSelect}
          />
          <p>{t('initiate_return_form_original_packaging_question', 'Do you have the original packaging?')}</p>
          <Radio list={packagingOptionsEnum.PACKAGING_OPTIONS} onChange={this.onRadioChange} name="packaging" />
          <div className="row">
            <div className="col-1">
              {isEmpty(this.state.initiateReturnSuccess) &&
                isEmpty(this.state.initiateReturnError) &&
                this.renderDefaultMessage()}
            </div>
          </div>
          <div className="button-wrap">
            <Button to={context.appRoutes.HOME.url} color={'green'}>
              {t('button_text_cancel_return', 'Cancel Return')}
            </Button>
            <Button
              color={'green'}
              onClick={this.initiateReturn}
              disabled={packaging == undefined || !reason || this.props.isLoadingInitiateReturn === true}
            >
              {t('initiate_return_text', 'Initiate Return')}
            </Button>
          </div>

          {packaging == undefined ||
            (packaging == false && (
              <div>
                <p>
                  <b>
                    {t(
                      'initiate_return_form_damage_message',
                      'You are responsible for any damage to SNOO related to inadequate packaging. ',
                    )}
                  </b>
                  {t(
                    'initiate_return_form_packaging_not_approved',
                    'Use of packaging not approved by Happiest Baby—or improper use of packaging provided by Happiest Baby—may result in severe damage to the product during shipping. Please click below to purchase approved packaging.',
                  )}
                </p>
                <Button color={'green'} to={buyPackage.permalink} newTab={true}>
                  {t('buy_packaging', 'Buy Packaging')}
                </Button>
              </div>
            ))}
        </form>
        {this.state.initiateReturnConfirmModalVisible && (
          <InitiateReturnConfirmModal
            content={t(
              'initiate_return_form_newborn_special_plan',
              `With the Newborn Special Plan, you have agreed to rent your SNOO for a minimum of {{rentalCommitmentInFoul}} months. If you decide to end your subscription before the set agreement, you will still be charged the {{rentalCommitmentInFoul}} month commitment. Are you sure you want to continue?`,
              { rentalCommitmentInFoul: this.rentalCommitmentInFoul.minimumRentalPeriod },
            )}
            onClose={this.onCloseInitiateReturnConfirmModal}
            onConfirm={this.onConfirmInitiateReturn}
          />
        )}
      </div>
    );
  }

  onCloseInitiateReturnConfirmModal = () => {
    this.setState({
      initiateReturnConfirmModalVisible: false,
    });
  };

  onConfirmInitiateReturn = () => {
    this.setState({
      initiateReturnConfirmModalVisible: false,
    });

    this.processReturn();
  };
}

InitiateReturnForm.propTypes = {
  t: PropTypes.func.isRequired,
  reasons: PropTypes.object.isRequired,
  prices: PropTypes.object,
  rentalItems: PropTypes.array.isRequired,
  apiInitiateReturn: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  buyPackage: apiRenterSelectors.getBuyPackage(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators(
    {
      apiGetBuyPackage: apiGetBuyPackageRequestAction,
    },
    dispatch,
  );
  return { actions };
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(InitiateReturnForm));
