export const ActionTypes = {
  API_GET_ORDERS_REQUEST: 'API_GET_ORDERS_REQUEST',
  API_GET_ORDERS_SUCCESS: 'API_GET_ORDERS_SUCCESS',
  API_GET_ORDERS_FAILURE: 'API_GET_ORDERS_FAILURE',
  API_CANCEL_ORDER_REQUEST: 'API_CANCEL_ORDER_REQUEST',
  API_CANCEL_ORDER_SUCCESS: 'API_CANCEL_ORDER_SUCCESS',
  API_CANCEL_ORDER_FAILURE: 'API_CANCEL_ORDER_FAILURE',
  API_UPDATE_ORDER_SHIPPING_ADDRESS_REQUEST: 'API_UPDATE_ORDER_SHIPPING_ADDRESS_REQUEST',
  API_UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS: 'API_UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS',
  API_UPDATE_ORDER_SHIPPING_ADDRESS_FAILURE: 'API_UPDATE_ORDER_SHIPPING_ADDRESS_FAILURE',
  API_UPDATE_ORDER_SHIPPING_DATE_REQUEST: 'API_UPDATE_ORDER_SHIPPING_DATE_REQUEST',
  API_UPDATE_ORDER_SHIPPING_DATE_SUCCESS: 'API_UPDATE_ORDER_SHIPPING_DATE_SUCCESS',
  API_UPDATE_ORDER_SHIPPING_DATE_FAILURE: 'API_UPDATE_ORDER_SHIPPING_DATE_FAILURE',
  RESET_SUCCESS_EDIT_ORDER_ADDRESS_REQUEST: 'RESET_SUCCESS_EDIT_ORDER_ADDRESS_REQUEST',
  RESET_SUCCESS_EDIT_ORDER_ADDRESS_SUCCESS: 'RESET_SUCCESS_EDIT_ORDER_ADDRESS_SUCCESS',
  API_GET_DEVICES_REQUEST: 'API_GET_DEVICES_REQUEST',
  API_GET_DEVICES_SUCCESS: 'API_GET_DEVICES_SUCCESS',
  API_GET_DEVICES_FAILURE: 'API_GET_DEVICES_FAILURE',
};

export const apiGetOrdersRequestAction = () => ({
  type: ActionTypes.API_GET_ORDERS_REQUEST,
  payload: {},
});

export const apiGetOrdersSuccessAction = orders => {
  return {
    type: ActionTypes.API_GET_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const apiGetOrdersFailureAction = error => ({
  type: ActionTypes.API_GET_ORDERS_FAILURE,
  payload: error,
});

export const apiCancelOrderFailureAction = error => ({
  type: ActionTypes.API_CANCEL_ORDER_FAILURE,
  payload: error,
});

export const apiCancelOrderRequestAction = id => ({
  type: ActionTypes.API_CANCEL_ORDER_REQUEST,
  payload: id,
});

export const apiCancelOrderSuccessAction = orders => {
  return {
    type: ActionTypes.API_CANCEL_ORDER_SUCCESS,
    payload: orders,
  };
};

export const apiUpdateOrderShippingAddressFailureAction = error => ({
  type: ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const apiUpdateOrderShippingAddressRequestAction = id => ({
  type: ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_REQUEST,
  payload: id,
});

export const apiUpdateOrderShippingAddressSuccessAction = order => {
  return {
    type: ActionTypes.API_UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS,
    payload: order,
  };
};

export const resetSuccessEditOrderAddressRequestAction = () => ({
  type: ActionTypes.RESET_SUCCESS_EDIT_ORDER_ADDRESS_REQUEST,
  payload: {},
});

export const resetSuccessEditOrderAddressSuccessAction = () => ({
  type: ActionTypes.RESET_SUCCESS_EDIT_ORDER_ADDRESS_SUCCESS,
  payload: {},
});

export const apiUpdateOrderShippingDateFailureAction = error => ({
  type: ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_FAILURE,
  payload: error,
});

export const apiUpdateOrderShippingDateRequestAction = id => ({
  type: ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_REQUEST,
  payload: id,
});

export const apiUpdateOrderShippingDateSuccessAction = order => {
  return {
    type: ActionTypes.API_UPDATE_ORDER_SHIPPING_DATE_SUCCESS,
    payload: order,
  };
};

export const apiGetDevicesRequestAction = () => ({
  type: ActionTypes.API_GET_DEVICES_REQUEST,
  payload: {},
})

export const apiGetDevicesSuccessAction = devices => ({
  type: ActionTypes.API_GET_DEVICES_SUCCESS,
  payload: devices,
})

export const apiGetDevicesFailureAction = error => ({
  type: ActionTypes.API_GET_DEVICES_FAILURE,
  payload: error,
})
