import React, { useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { redirectToLogin } from './utils/redirect';

import appRoutes from './enums/appRoutes';
import HomeScreen from './screens/homeScreen.jsx';
import LoginScreen from './screens/loginScreen.jsx';
import PaymentScreen from './screens/paymentScreen.jsx';
import ReferralsScreen from './screens/referralsScreen.jsx';
import ProfileScreen from './screens/profileScreen.jsx';
import ReturnScreen from './screens/returnScreen.jsx';
import BillsScreen from './screens/billsScreen.jsx';
import DevicesScreen from './screens/devicesScreen';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (err) {
        setIsAuthenticated(false);
      }
    }

    checkAuthentication();
  }, []);

  if (isAuthenticated === null) {
    return <></>; // render nothing while checking authentication
  }

  if (isAuthenticated) {
    return <Route {...rest} component={Component} />;
  }

  redirectToLogin(rest?.path);
  return <></>;
};


const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path={appRoutes.LOGIN.url} component={LoginScreen} />
      <PrivateRoute path={appRoutes.RETURN.url} component={ReturnScreen} />
      <PrivateRoute path={appRoutes.PAYMENT.url} component={PaymentScreen} />
      <PrivateRoute path={appRoutes.REFFERALS.url} component={ReferralsScreen} />
      <PrivateRoute path={appRoutes.PROFILE.url} component={ProfileScreen} />
      <PrivateRoute path={`${appRoutes.BILLS.url}/:order?/:period?`} component={BillsScreen} />
      <PrivateRoute path={appRoutes.DEVICES.url} component={DevicesScreen} />
      <PrivateRoute path={appRoutes.HOME.url} component={HomeScreen} />
    </Switch>
  </ConnectedRouter>
);

Router.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Router;
