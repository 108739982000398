/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { Provider } from 'react-redux';
import ReactGA from "react-ga4";
import { withTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { Provider as HbiProvider } from './context/context.jsx';
import { apiConfig } from './config/apiConfig';
import appRoutes from './enums/appRoutes';
import * as Sentry from '@sentry/react';

import { reduxApiReducers, reduxApiRunSagas, reduxApiSagaMiddleware } from './redux-api/index';

import Router from './router.jsx';
import { useTranslation } from 'react-i18next';

import { Amplify, Auth, Hub } from 'aws-amplify';


const config = {
  auth: {
    region: apiConfig.aws.region,
    userPoolId: apiConfig.aws.userPoolId,
    userPoolWebClientId: apiConfig.aws.userPoolWebClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
  },
}

if (['prod'].includes(apiConfig.env)) {
  Sentry.init({
    dsn: apiConfig.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    environment: config.env,
  });
  Sentry.setTag('app', 'rental-portal');
}

Amplify.configure({
  Auth: config.auth,
});

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

ReactGA.initialize([
  {
    trackingId: apiConfig.googleAnalytics.gaId,
  }
]);
history.listen(location => ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname }));

const composeEnhancers =
  process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  ...reduxApiReducers,
});

const rootReducer = (state, action) => {
  if (action.type === 'API_POST_USER_LOGOUT_SUCCESS') {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(applyMiddleware(reduxApiSagaMiddleware, routerMiddleware(history))),
);

const LANGUAGE = process.env.REACT_APP_LANGUAGE;
reduxApiRunSagas();



const App = () => {
  useEffect(() => {
    const listener = (data) => {
      if (data.payload.event === 'signIn') {
        location.href = appRoutes.HOME.url;
      }
    }

    Hub.listen('auth', listener);
  }, [])

  const { t, i18n } = useTranslation();
  if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', LANGUAGE.toLowerCase());
  }
  return (
    <HbiProvider
      value={{
        apiConfig,
        appRoutes,
        i18n,
        t,
        history
      }}
    >
        <Provider store={store}>
          <Router history={history} />
        </Provider>
    </HbiProvider>
  );
};

export default withTranslation()(App);
