import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { provideContext } from '../../../context/context.jsx';

import './input.scss';

class Input extends Component {
  state = {
    smallPlaceholder: this.props.defaultValue || this.props.value ? true : false,
  };

  onFocus = () => {
    this.iputRef.focus();
    if (!this.props.disabled) {
      this.setState({
        smallPlaceholder: true,
      });
    }
  };

  onBlur = () => {
    if (!this.iputRef.value) {
      this.setState({
        smallPlaceholder: false,
      });
    }
  };

  render() {
    const { error, bottomLine, disabled, placeholder, value, defaultValue, onChange, name, type } = this.props;
    const { smallPlaceholder } = this.state;
    return (
      <div
        className={classnames('Input', {
          'bottom-line': bottomLine,
          'small-placeholder': smallPlaceholder,
          error: error,
          disabled: disabled,
        })}
        onClick={() => this.onFocus()}
        onFocus={() => this.onFocus()}
      >
        <label htmlFor={name}>{placeholder}</label>
        <input
          onBlur={() => this.onBlur()}
          name={name}
          type={type}
          id={name}
          ref={ip => (this.iputRef = ip)}
          {...(value != undefined && onChange ? { value: value } : {})}
          {...(onChange ? { onChange } : {})}
          {...(defaultValue ? { defaultValue } : {})}
          {...(disabled ? { disabled: true } : {})}
        />
      </div>
    );
  }
}

Input.defaultProps = {
  bottomLine: false,
  disabled: false,
  error: false,
};

Input.propTypes = {
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  bottomLine: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};

export default provideContext(Input);
