import React, { PureComponent } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StripeProvider, Elements } from 'react-stripe-elements';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { provideContext } from '../context/context.jsx';

import * as apiPaymentsActions from '../redux-api/apiPayments/apiPaymentsActions.js';
import * as apiRentalActions from '../redux-api/apiRental/apiRentalActions.js';
import * as apiRenterActions from '../redux-api/apiRenter/apiRenterActions.js';
import * as apiPaymentsSelectors from '../redux-api/apiPayments/apiPaymentsSelectors.js';
import * as apiRentalSelectors from '../redux-api/apiRental/apiRentalSelectors.js';
import * as apiRenterSelectors from '../redux-api/apiRenter/apiRenterSelectors.js';

import GenerateGiftCardForm from '../components/views/forms/generateGiftCardForm/generateGiftCardForm.jsx';
import AppContainer from '../containers/appContainer.jsx';
import CreditCardForm from '../components/views/forms/creditCardForm/creditCardForm.jsx';
import GiftCardForm from '../components/views/forms/giftCardForm/giftCardForm.jsx';
import GiftCardsTable from '../components/views/tables/giftCardsTable/giftCardsTable.jsx';
import NoGiftCards from '../components/views/presentation/noGiftCards/noGiftCards.jsx';
import UsageSummaryTable from '../components/views/tables/usageSummaryTable/usageSummaryTable.jsx';
import DefaultLoader from '../components/views/loaders/defaultLoader/defaultLoader.jsx';
import ScreenTitle from '../components/views/presentation/screenTitle/screenTitle.jsx';
import ScreenSubTitle from '../components/views/presentation/screenSubTitle/screenSubTitle.jsx';
import PaymentPlanTable from '../components/views/tables/paymentPlanTable/paymentPlanTable.jsx';

class PaymentScreen extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.actions.apiPaymentGetCustomer();
    this.props.actions.apiPaymentGetCard();
    this.props.actions.apiPaymentGetCustomerAccountBalance();
    this.props.actions.apiRentalGetRenterAppliedGiftCards();
    this.props.actions.apiRentalGetRenterGiftCardsUsageSummary();
    this.props.actions.getRentals();
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  renderNoGiftCards() {
    return (
      <NoGiftCards
        messageHeadline={this.props.t('payment_screen_no_gift_card', "You don't have any gift card.")}
        title={this.props.t('gift_cards_title', 'Gift Cards')}
      />
    );
  }

  renderScreen() {
    const { t, context, accountBalance, giftCards, giftCardsSummary } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-1">
            <ScreenTitle>{t('payments_title', 'Payments')}</ScreenTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-2-3">
            <StripeProvider apiKey={context.apiConfig.stripe.apiKey}>
              <Elements>
                <CreditCardForm
                  card={this.props.card}
                  customer={this.props.customer}
                  apiPaymentCreateSetupIntent={this.props.actions.apiPaymentCreateSetupIntent}
                  apiPaymentSaveCard={this.props.actions.apiPaymentSaveCard}
                  apiPaymentUpdateCustomer={this.props.actions.apiPaymentUpdateCustomer}
                  error={
                    this.props.errorSetupIntentCreate ||
                    this.props.errorCreditCardReplace ||
                    this.props.errorCreditCardUpdate
                  }
                  success={this.props.successCreditCardReplace || this.props.successCreditCardUpdate}
                  isLoading={
                    this.props.isLoadingCreateSetupIntent ||
                    this.props.isLoadingReplaceCustomerCreditCard ||
                    this.props.isLoadingUpdateCustomerCreditCard
                  }
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
        <div className="row">
          <div className="col-2-3">
            <ScreenSubTitle>
              {t('payment_screen_gift_cards_account_balance', 'Gift cards & account balance')}
            </ScreenSubTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-2-3">
            <GiftCardForm
              accountBalance={accountBalance.accountBalance}
              apiRentalApplyGiftCardCode={this.props.actions.apiRentalApplyGiftCardCode}
              apiPaymentGetCustomerAccountBalance={this.props.actions.apiPaymentGetCustomerAccountBalance}
              apiRentalGetRenterAppliedGiftCards={this.props.actions.apiRentalGetRenterAppliedGiftCards}
              isLoadingGiftCardApplied={this.props.isLoadingGiftCardApplied}
              error={this.props.errorsGiftCardApplied}
              success={this.props.successGiftCardApplied}
            />
          </div>
        </div>
        {!isEmpty(giftCards) && (
          <div className="row">
            <div className="col-2-3">
              <ScreenSubTitle>{t('gift_cards_title')}</ScreenSubTitle>
            </div>
          </div>
        )}
        {!isEmpty(giftCards) && (
          <div className="row">
            <div className="col-2-3">{!isEmpty(giftCards) && <GiftCardsTable giftCards={giftCards} />}</div>
          </div>
        )}
        {!isEmpty(giftCardsSummary) && (
          <div className="row">
            <div className="col-2-3">
              <ScreenSubTitle>{t('payment_screen_gift_card_usage_summary', 'Usage Summary')}</ScreenSubTitle>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-2-3">
            {!isEmpty(giftCardsSummary) && (
              <UsageSummaryTable accountBalance={accountBalance.accountBalance} giftCardsSummary={giftCardsSummary} />
            )}
          </div>
        </div>
        {this.props.rentals &&
          this.props.rentals.length > 0 &&
          this.props.rentals
            .filter(r => r.rental.paymentPlan && r.rental.paymentPlan.kind === 'complex')
            .map((rental, idx) => (
              <React.Fragment key={idx}>
                <div className="row">
                  <div className="col-2-3">
                    {rental.rental.status !== 'CANCELED' && <PaymentPlanTable rental={rental} />}
                  </div>
                </div>
              </React.Fragment>
            ))}
        <div className="row">
          <div className="col-2-3">
            {accountBalance.accountBalance != 0 && (
              <GenerateGiftCardForm
                apiRentalPostGenerateGiftCard={this.props.actions.apiRentalPostGenerateGiftCard}
                apiPaymentGetCustomerAccountBalance={this.props.actions.apiPaymentGetCustomerAccountBalance}
                apiRentalGetRenterGiftCardsUsageSummary={this.props.actions.apiRentalGetRenterGiftCardsUsageSummary}
                accountBalance={accountBalance.accountBalance}
                error={this.props.errorsGiftCardGenerate}
                success={this.props.successGiftCardGenerate}
                isLoadingGeneratedGiftCard={this.props.isLoadingGeneratedGiftCard}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { isLoadingGetCustomerCreditCard } = this.props;

    return (
      <AppContainer>
        {isLoadingGetCustomerCreditCard ? <DefaultLoader type="block" /> : this.renderScreen()}
      </AppContainer>
    );
  }
}

PaymentScreen.defaultProps = {
  accountBalance: {},
  isLoadingGetCustomer: true,
  isLoadingGetCustomerCreditCard: true,
  isLoadingAccountBalance: true,
};

PaymentScreen.propTypes = {
  actions: PropTypes.shape({
    apiPaymentGetCustomerAccountBalance: PropTypes.func,
    apiPaymentGetCustomer: PropTypes.func,
    apiPaymentGetCard: PropTypes.func,
    apiPaymentSaveCard: PropTypes.func,
    apiPaymentUpdateCustomer: PropTypes.func,
    apiRentalApplyGiftCardCode: PropTypes.func,
    apiRentalGetRenterAppliedGiftCards: PropTypes.func,
    apiRentalPostGenerateGiftCard: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  customer: apiPaymentsSelectors.getCustomer(state),
  card: apiPaymentsSelectors.getCard(state),
  accountBalance: apiPaymentsSelectors.getCustomerAccountBalance(state),
  giftCards: apiRentalSelectors.getRenterAppliedGiftCards(state),
  giftCardsSummary: apiRentalSelectors.getGiftCardsUsageSummary(state),
  rentals: apiRenterSelectors.getRentals(state),
  isLoadingGeneratedGiftCard: apiRentalSelectors.isLoadingGetGeneratedGiftCard(state),
  isLoadingGiftCardApplied: apiRentalSelectors.isLoadingGiftCardApplied(state),
  isLoadingAccountBalance: apiPaymentsSelectors.isLoadingGetCustomerAccountBalance(state),
  isLoadingReplaceCustomerCreditCard: apiPaymentsSelectors.isLoadingReplaceCustomerCreditCard(state) || false,
  isLoadingCreateSetupIntent: apiPaymentsSelectors.isLoadingCreateSetupIntent(state) || false,
  isLoadingGetCustomer: apiPaymentsSelectors.isLoadingGetCustomer(state),
  isLoadingGetCustomerCreditCard: apiPaymentsSelectors.isLoadingGetCustomerCreditCard(state),
  isLoadingUpdateCustomerCreditCard: apiPaymentsSelectors.isLoadingUpdateCustomerCreditCard(state),
  errorsGiftCardApplied: apiRentalSelectors.getGiftCardAppliedErrors(state),
  successGiftCardApplied: apiRentalSelectors.getGiftCardAppliedSuccess(state),
  errorsGiftCardGenerate: apiRentalSelectors.getGeneratedGiftCardErrors(state),
  successGiftCardGenerate: apiRentalSelectors.getGeneratedGiftCardSuccess(state),
  successCreditCardReplace: apiPaymentsSelectors.getPaymentCardSaveSuccessMessage(state),
  errorCreditCardReplace: apiPaymentsSelectors.getPaymentCardSaveErrorMessage(state),
  successCreditCardUpdate: apiPaymentsSelectors.getPaymentCardUpdateSuccessMessage(state),
  errorCreditCardUpdate: apiPaymentsSelectors.getPaymentCardUpdateErrorMessage(state),
  successSetupIntentCreate: apiPaymentsSelectors.getPaymentSetupIntentCreateSuccessMessage(state),
  errorSetupIntentCreate: apiPaymentsSelectors.getPaymentSetupIntentCreateErrorMessage(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      apiPaymentGetCustomerAccountBalance: apiPaymentsActions.apiGetPaymentCustomerAccountBalanceRequestAction,
      apiPaymentGetCustomer: apiPaymentsActions.apiGetPaymentCustomerRequestAction,
      apiPaymentGetCard: apiPaymentsActions.apiGetPaymentCardRequestAction,
      apiPaymentCreateSetupIntent: apiPaymentsActions.apiPostSetupIntentRequestAction,
      apiPaymentSaveCard: apiPaymentsActions.apiPostPaymentCardRequestAction,
      apiPaymentUpdateCustomer: apiPaymentsActions.apiPutPaymentCustomerRequestAction,
      apiRentalApplyGiftCardCode: apiRentalActions.apiPostRenterApplyGiftCardRequestAction,
      apiRentalGetRenterAppliedGiftCards: apiRentalActions.apiGetRenterAppliedGiftCardsRequestAction,
      apiRentalGetRenterGiftCardsUsageSummary: apiRentalActions.apiGetGiftCardsUsageSummaryRequestAction,
      apiRentalPostGenerateGiftCard: apiRentalActions.apiPostGenerateGiftCardRequestAction,
      getRentals: apiRenterActions.apiGetRenterRentalsRequestAction,
    },
    dispatch,
  );

  return { actions };
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(PaymentScreen));
