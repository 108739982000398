import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { provideContext } from '../../../../context/context.jsx';
import { NavLink } from 'react-router-dom';
import Message from '../../presentation/message/message.jsx';
import AutopayDetailSegment from '../autopayDetailSegment/autopayDetailSegment.jsx';
import Currency from '../../formats/currencyFormat/currency.jsx';
import autopayIcon from '../../../../assets/icons/autopay.svg';
import './autopaySegment.scss';

class AutopaySegment extends Component {
  state = {
    showDetails: false,
  };

  showDetails = e => {
    e.preventDefault();
    this.setState(prevState => ({
      showDetails: !prevState.showDetails,
    }));
  };

  scheduleChargePastDueInvoices = e => {
    e.preventDefault();
    this.props.postChargePastDueInvoices(this.props.customer.id);
    this.props.lastManualChargeAttempt;
  };

  render() {
    const {
      t,
      context,
      period,
      details,
      total,
      paid,
      upcoming,
      items,
      card,
      periodIndex,
      active,
      totalDebt,
    } = this.props;
    const { debt } = totalDebt;
    const canInitiateCharging =
      !this.props.lastManualChargeAttempt || moment().diff(this.props.lastManualChargeAttempt, 'minutes') > 20;

    return (
      <React.Fragment>
        {!this.state.showDetails ? (
          <div className="AutopaySegment">
            <h3>
              <img src={autopayIcon} />{' '}
              {active
                ? t('autopay_segment_active', 'Autopay active')
                : t('autopay_segment_inactive', 'Autopay inactive')}
            </h3>
            <table>
              <tbody>
                <tr>
                  <td>
                    <h4>{t('current_amount_due_text', 'Current Amount Due')}</h4>
                    <p>{period}</p>
                  </td>
                  <td />
                  <td>
                    <div className={!paid && !upcoming ? 'price red' : 'price'}>
                      <Currency value={total} context={context} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>{t('autopay_segment_payment_credits', 'Payment & Credits')}</h4>
                    <button onClick={this.showDetails}>{t('view_details_text')}</button>
                  </td>
                  <td />
                  <td>
                    <div className={!paid && !upcoming ? 'price red' : 'price'}>
                      {!paid && !upcoming ? (
                        t('autopay_segment_failed_charge', 'Failed charge')
                      ) : (
                        <Currency value={paid ? total : 0} context={context} />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>{t('autopay_segment_current_due', 'Your current due')}</h4>
                  </td>
                  <td />
                  <td>
                    <div className={!paid && !upcoming ? 'price red' : 'price'}>
                      <Currency value={paid ? 0 : total} context={context} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>{t('autopay_segment_past_due', 'Your past due')}</h4>
                    <p>On {moment().format('MMM DD')}</p>
                  </td>
                  <td />
                  <td>
                    <div className={'price'}>
                      <Currency value={!paid ? debt - total : debt} context={context} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>{t('total_due_text', 'Your total due')}</h4>
                    <p>On {moment().format('MMM DD')}</p>
                  </td>
                  <td />
                  <td>
                    <div className={'price'}>
                      <Currency value={debt} context={context} />
                    </div>
                  </td>
                </tr>
                {upcoming ? (
                  <tr>
                    <td colSpan="2">
                      <p className="description">
                        {t('autopay_segment_charge_info', 'We will charge the credit card on file on ')}
                        {moment.unix(details.nextPaymentAttempt).format('MMMM D, Y')}
                      </p>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
            {debt > 0 && canInitiateCharging && !this.props.chargeAttemptSuccess && (
              <a className="navlink border" style={{ width: '190px' }} onClick={this.scheduleChargePastDueInvoices}>
                {t('autopay_segment_pay_past_due', 'Pay past due')}
              </a>
            )}
            <NavLink
              className="navlink invert transparent"
              style={{ width: '190px' }}
              to={context.appRoutes.PAYMENT.url}
            >
              {t('autopay_segment_edit_payment_method', 'Edit payment method')}
            </NavLink>
            {this.props.chargeAttemptSuccess && (
              <Message type="success">
                {t(
                  'autopay_segment_sucess_charging_process',
                  'You have successfully initiated the charging process. We will attempt to charge your card in the next 20 minutes.',
                )}
              </Message>
            )}
            {this.props.chargeAttemptFailure && (
              <Message type="error">
                {t(
                  'autopay_segment_failure_charging_process',
                  'We were unable to start the charging process. Please try again or contact Customer Care.',
                )}
              </Message>
            )}
          </div>
        ) : (
          <AutopayDetailSegment
            period={period}
            details={details}
            upcoming={upcoming}
            paid={paid}
            card={card}
            total={total}
            items={items}
            onClickClose={this.showDetails}
            periodIndex={periodIndex}
          />
        )}
      </React.Fragment>
    );
  }
}

AutopaySegment.propTypes = {
  t: PropTypes.func.isRequired,
  lastManualChargeAttempt: PropTypes.string,
};

export default provideContext(AutopaySegment);
