import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../../select/select';
import Input from '../../input/input';
import Button from '../../button/button';
import './productSupportModal.scss';
import validateTelephone from '../../../../utils/validateTelephone';
import { Auth } from 'aws-amplify';
import Message from '../../presentation/message/message';
import ReactGA from 'react-ga4';
import * as apiRenterSelectors from '../../../../redux-api/apiRenter/apiRenterSelectors';
import { bindActionCreators } from 'redux';
import { apiGetBuyPackageRequestAction } from '../../../../redux-api/apiRenter/apiRenterActions';
import { provideContext } from '../../../../context/context';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import fileIcon from '../../../../assets/icons/file-icon.png';
import successIcon from '../../../../assets/icons/success-icon.svg';
import Stepper from '../../stepper/stepper';
import { initProductSupportRequest } from '../../../../redux-api/apiOrders/api';

class ProductSupportModal extends Component {
  constructor(props) {
    super(props);

    const { device, provinces, countries } = props;

    const isInWarranty = device.isInWarranty || false;
    const steps = isInWarranty ? 3 : 2;

    this.state = {
      returnItems: {},
      selectedIssue: null,
      shippingAddress: { ...device.shippingAddress },
      provinces: provinces,
      countries: countries,
      activeStep: 0,
      steps: steps,
      shippingAddressErrors: {},
      faqLink: null,
      selectSpecificIssueError: null,
      selectIssueCategoryError: null,
      isDragOver: false,
      droppedFiles: [],
      isInWarranty: isInWarranty,
      showSuccessMessage: false,
      initProductSupportRequestError: false,
    };

    this.lastStep = this.state.steps - 1;


    this.stepConfigs = [
      {
        heading: 'Issue',
        render: this.renderSelectIssueStep
      },
      {
        heading: 'Additional Details',
        render: this.renderUploadFilesStep
      },
      {
        heading: 'Confirmation',
        render: this.renderShippingDetailsStep
      }
    ];

    this.issues = {
      "Technical Issue": [
        {
          "Motion Issue": "https://support.happiestbaby.com/hc/en-us/articles/22062065237271-Why-does-my-SNOO-have-unexpected-movements"
        },
        {
          "No Motion": "https://support.happiestbaby.com/hc/en-us/articles/22062360243991-Why-has-my-SNOO-stopped-moving"
        },
        {
          "Sound Issue": "https://support.happiestbaby.com/hc/en-us/articles/22063157384215-Why-is-my-SNOO-white-noise-having-unusual-sounds"
        },
        {
          "Unusual Noise": "https://support.happiestbaby.com/hc/en-us/articles/22062942917015-Why-is-my-SNOO-making-unusual-noises"
        },
        {
          "Sessions Stop": "https://support.happiestbaby.com/hc/en-us/articles/22063753109655-Why-did-my-SNOO-stop-unexpectedly"
        },
        {
          "Leveling Up Unnecessarily": "https://support.happiestbaby.com/hc/en-us/articles/12603109729303-SNOO-is-too-sensitive-or-not-sensitive-enough-to-my-baby-s-cries-Is-there-a-setting-in-the-App-that-can-help"
        },
        {
          "Not Responding to Crying": "https://support.happiestbaby.com/hc/en-us/articles/12603109729303-SNOO-is-too-sensitive-or-not-sensitive-enough-to-my-baby-s-cries-Is-there-a-setting-in-the-App-that-can-help"
        },
        {
          "Activity Button Flashing White": "https://support.happiestbaby.com/hc/en-us/articles/115004381468-Why-is-my-SNOO-Activity-Button-blinking-white"
        },
        {
          "Not Powering On": "https://support.happiestbaby.com/hc/en-us/articles/22063861819031-Why-is-my-SNOO-not-powering-on"
        }
      ],
      "App Issue": [
        {
          "\"Unable to Join the Network 'snoo_xxxx'\" error message": "https://support.happiestbaby.com/hc/en-us/articles/12603216268439-I-am-getting-the-error-Can-t-Join-SNOO-Network-What-should-I-do"
        },
        {
          "\"We Cannot Find Your SNOO\" error message": "https://support.happiestbaby.com/hc/en-us/articles/12603216268439-I-am-getting-the-error-Can-t-Join-SNOO-Network-What-should-I-do"
        },
        {
          "\"SNOO Can't Connect\" error message": "https://support.happiestbaby.com/hc/en-us/articles/12603204509463-I-am-getting-the-error-message-SNOO-can-t-connect-What-should-I-do"
        },
        {
          "\"SNOO Is Connecting / Not Connected\" error message": "https://support.happiestbaby.com/hc/en-us/articles/22064832136983-Why-is-my-SNOO-showing-Connecting-or-Not-connected"
        },
        {
          "Other SNOO pairing issues": "https://support.happiestbaby.com/hc/en-us/articles/12602571758103-How-do-I-pair-my-SNOO-to-the-App"
        },
        {
          "Subscription status": "https://support.happiestbaby.com/hc/en-us/articles/24774562096279-I-purchased-SNOO-from-Happiest-Baby-or-an-authorized-partner-Will-I-have-to-pay-for-the-Premium-Subscription-to-the-Happiest-Baby-App"
        }
      ],
      "Missing or damaged part": [],
      "Other": [],
      "Product Question": [],
    };

    this.faqForCategory = {
      "Missing or damaged part": "https://support.happiestbaby.com/hc/en-us/articles/229983868-What-s-included-with-SNOO",
      "Product Question": "https://support.happiestbaby.com/hc/en-us/categories/203851108-Getting-Started-With-SNOO"
    }
  };

  componentDidMount() {
    this.props.actions.apiGetBuyPackage();
    const country = this.state.countries.find(c => c.name === this.state.shippingAddress.country);
    if (country) {
      this.props.apiGetProvinces(country._id);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.provinces !== state.provinces) {
      const selectedCountry = props.countries.find(country => country.name === state.shippingAddress.country);
      const provinceElement = props.provinces[selectedCountry._id].find(p => p.name === state.province);

      const provinceState = {
        provinces: props.provinces,
      };

      if (provinceElement) {
        provinceState.province = provinceElement.code;
      }

      return provinceState;
    }

    return null;
  };

  goToNextStep = () => {
    this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
        faqLink: null,
      }));
  };


  handleSubmit = async (event) => {
    event.preventDefault();
    const { issues, faqForCategory } = this;
    const { selectedIssueCategory, selectedSpecificIssue, shippingAddress, activeStep, droppedFiles, issueDescription, fileLink, isInWarranty } = this.state;
    const { t, device } = this.props;
    const user = await Auth.currentAuthenticatedUser();
    if (activeStep === 0) {
      if (!selectedIssueCategory) {
        this.setState({ selectIssueCategoryError: t('product_support_modal_issue_category_required_error') });
        return;
      } else {
        this.setState({ selectIssueCategoryError: null });
      }
      if (!selectedSpecificIssue && issues[selectedIssueCategory].length > 0) {
        this.setState({ selectSpecificIssueError: t('product_support_modal_specific_issue_required_error', 'Specific issue is required.') });
        return;
      }

      const specificIssueObj = issues[selectedIssueCategory].find(el => el[selectedSpecificIssue]);
      if (specificIssueObj) {
        this.setState({
          faqLink: specificIssueObj[selectedSpecificIssue],
        });
        return;
      }

      if(faqForCategory[selectedIssueCategory]) {
        this.setState({
          faqLink: faqForCategory[selectedIssueCategory],
        });
        return;
      }


    }
    if (activeStep !== this.lastStep) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
      return;
    }

    const shippingAddressErrors = selectedIssueCategory !== 'Product Question' && isInWarranty ? this.validateShippingAddress(shippingAddress) : {};

    this.setState({
      shippingAddressErrors: Object.keys(shippingAddressErrors).length > 0
        ? { ...shippingAddressErrors }
        : {},
    });

    if (Object.keys(shippingAddressErrors).length > 0) {
      return;
    }

    const formData = new FormData();

    const orderNameWithoutHash = device.orderNumber.replace(/^#/, '');
    if (orderNameWithoutHash) {
      formData.append('orderName', orderNameWithoutHash);
    }
    const deviceSerialWithoutPrefix = device.deviceSerial.replace(/^SNOO/, '');
    formData.append('deviceSerial', deviceSerialWithoutPrefix);

    formData.append('orderType', device.orderType);

    formData.append('issueCategory', selectedIssueCategory);
    formData.append('orderType', device.orderType);
    if (selectedSpecificIssue) {
      formData.append('specificIssue', selectedSpecificIssue);
    }
    if (issueDescription) {
      formData.append('description', issueDescription);
    }
    formData.append('fileLink', fileLink)

    if (Object.keys(shippingAddress).length > 0) {
      formData.append('shippingAddress', JSON.stringify({
        ...shippingAddress,
        provinceCode: shippingAddress.province_code,
        countryCode: shippingAddress.country_code,
        firstName: shippingAddress.first_name,
        lastName: shippingAddress.last_name,
        name: `${shippingAddress.first_name} ${shippingAddress.last_name}`,
      }));
    }

    droppedFiles.forEach((file) => formData.append('images', file));

    try {
      this.setState({ loading: true });
      ReactGA.event({
        category: "Button clicked",
        action: "product_support_request_initiated",
        label: "Product support request has been initiated",
      });

      const { response, error } = await initProductSupportRequest(formData);
      if (!response && error) {
        throw new Error("Product support request initiation failed.");
      }

      this.setState({ showSuccessMessage: true });
    } catch (error) {
      this.setState({ initProductSupportRequestError: true });
    } finally {
      this.setState({ loading: false });
    }

  };

  validateShippingAddress(shippingAddress) {
    const { t } = this.props;
    const errors = {};

    if (!shippingAddress.first_name) {
      errors.firstName = t('return_form_first_name_required_error', 'First name is required.');
    }

    if (!shippingAddress.last_name) {
      errors.lastName = t('return_form_last_name_required_error', 'Last name is required.')
    }

    if (!shippingAddress.phone) {
      errors.phone = t('return_form_phone_required_error', 'Phone is required.')
    } else if (!validateTelephone(shippingAddress.phone)) {
      errors.phone = t('return_form_invalid_phone_error', 'Invalid phone number.');
    }

    if (!shippingAddress.city) {
      errors.city = t('return_form_city_required_error', 'City is required.');
    }

    if (!shippingAddress.address1) {
      errors.address1 = t('return_form_address_required_error', 'Address is required.');
    }

    if (!shippingAddress.zip) {
      errors.zip = t('return_form_zip_required_error', 'Zip code is required.');
    }

    return errors;
  };

  onSelectReasonChange = (e , i) => {
    const { issues } = this;
    const issuesByCategory = issues[Object.keys(issues)[i]].map(el => Object.keys(el)[0]);

    this.setState({
      selectedIssueCategory: Object.keys(issues)[i],
      issuesByCategory,
      selectedSpecificIssue: null,
      selectIssueError: null,
    });
  };

  renderSelectIssueStep = () => {
    const { reasons, issues, selectedSpecificIssue } = this;
    const { t } = this.props;
    const handleSecondSelectChange = (e, i) => {
      this.setState({ selectedSpecificIssue: this.state.issuesByCategory[i] });
    }

    return (
      <>
        <p className="modal-text">{t('product_support_issue_label')}</p>
        <Select
          list={Object.keys(issues)}
          onChange={this.onSelectReasonChange}
          labelKey={''}
          placeholder={t('product_support_issue_category')}
          style={{ margin: '10px 0' }}
          selectListStyle={{height: '100px'}}
        />
        {this.state.selectIssueCategoryError && (
          <p className="error-message">{this.state.selectIssueCategoryError}</p>
        )}
        {this.state.issuesByCategory && this.state.issuesByCategory.length > 0 && (
          <>
            <Select
              key={JSON.stringify(this.state.selectedIssueCategory)}
              list={this.state.issuesByCategory}
              onChange={handleSecondSelectChange}
              labelKey={''}
              placeholder={t('product_support_specific_issue')}
              style={{ margin: '20px 0' }}
              selectListStyle={{height: '100px'}}
            />
          </>
        )}
        {this.state.selectSpecificIssueError && (
          <p className="error-message">{this.state.selectSpecificIssueError}</p>
        )}
      </>
    );
  };


  renderFaqStep = (faqLink) => {
    const { t, onCloseModal } = this.props;

    const handleLinkClick = () => {
      ReactGA.event({
        category: "Button clicked",
        action: "product_support_faq_link",
        label: "FAQ link clicked on product support form",
      });
    }

    const handleContinueBtn = () => {
      ReactGA.event({
        category: "Button clicked",
        action: "product_support_faq_no",
        label: "FAQ wasn't helpful button clicked",
      });

      this.goToNextStep();
    }

    const handleCloseBtn = () => {
      ReactGA.event({
        category: "Button clicked",
        action: "product_support_faq_yes",
        label: "FAQ was helpful button clicked",
      });
      onCloseModal()
    }

    return (
      <>
        <div className="header-wrapper">
          <h3>{t('product_support_modal_faq_header', 'Related Troubleshooting Information')}</h3>
          <span className="close-modal" onClick={() => onCloseModal()}>X</span>
        </div>
        <p className="modal-text">
          <Trans i18nKey="product_support_modal_faq_body_text">
            Check out our detailed troubleshooting steps for this issue.
            <a href={faqLink} target="_blank" onClick={() => handleLinkClick()}>Tap the link</a> for guidance.
          </Trans>
        </p>
        <div className="row">
          <div className="col-1-6">
          <p>{t('product_support_modal_faq_is_issue_resolved')}</p>
          </div>
        </div>
        <div className="button-wrap">
          <div className="col-1-6">
            <Button onClick={() => handleContinueBtn()} color={'green-inverted'}>{t('product_support_modal_faq_no_button')}</Button>
          </div>
          <div className="col-1-6">
            <Button onClick={() => handleCloseBtn()} color={'green'}>{t('product_support_modal_faq_yes_button')}</Button>
          </div>
        </div>
      </>
    );
  };

  renderSuccessMessage = () => {
    const { t, onCloseModal } = this.props;
    return (
      <>
        <div className="header-wrapper">
          <span className="close-modal" onClick={() => onCloseModal()}>X</span>
        </div>
        <img src={successIcon}/>
        <h3>{t('product_support_modal_success_message_header')}</h3>
        <p className="modal-text text-center">{t('product_support_modal_success_message_header')}</p>
        <div className="button-wrap center">
          <div className="col-1-6">
            <Button onClick={() => onCloseModal()} color={'green'}>{t('product_support_modal_success_message_btn', 'Ok')}</Button>
          </div>
        </div>
      </>
    );
  };

  renderUploadFilesStep = () => {
    const { t } = this.props;
    const { isDragOver, droppedFiles } = this.state;
    const maxSize = 10 * 1024 * 1024;
    const maxFiles = 3;

    const isWithinMaxFilesLimit = (currentFiles, newFiles, maxFiles) => {
      return currentFiles.length + newFiles.length <= maxFiles;
    };

    const isFileValid = (file, maxSize) => {
      return file.size <= maxSize;
    };

    const areFilesWithinSizeLimit = (files, maxSize) => {
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);
      return totalSize <= maxSize;
    };

    const hasDuplicates = (files) => {
      const fileNames = new Set();

      for (const file of files) {
        if (fileNames.has(file.name)) {
          return true;
        }

        fileNames.add(file.name);
      }

      return false;
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const files = event.dataTransfer.files;



      if (!isWithinMaxFilesLimit(droppedFiles, files, maxFiles) || !areFilesWithinSizeLimit([...droppedFiles, ...files], maxSize)) {
        alert(`You can only upload a maximum of ${maxFiles} files with a total size of ${maxSize / (1024 * 1024)} MB.`);
        this.setState({ isDragOver: false });
        return;
      }

      if (hasDuplicates([...droppedFiles, ...files])) {
        this.setState({ isDragOver: false });
        return;
      }

      const validFiles = Array.from(files).filter(file => isFileValid(file, maxSize));

      this.setState(prevState => ({
        isDragOver: false,
        droppedFiles: [...prevState.droppedFiles, ...validFiles]
      }));
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      this.setState({ isDragOver: true });
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      this.setState({ isDragOver: false });
    };

    const handleFileInputChange = (event) => {
      const files = event.target.files;

      if (!isWithinMaxFilesLimit(droppedFiles, files, maxFiles) || !areFilesWithinSizeLimit([...droppedFiles, ...files], maxSize)) {
        alert(`You can only upload a maximum of ${maxFiles} files with a total size of ${maxSize / (1024 * 1024)} MB.`);
        this.setState({ isDragOver: false });
        return;
      }

      if (hasDuplicates([...droppedFiles, ...files])) {
        this.setState({ isDragOver: false });
        return;
      }

      const validFiles = Array.from(files).filter(file => isFileValid(file, maxSize));


      this.setState(prevState => ({
        droppedFiles: [...prevState.droppedFiles, ...validFiles],
      }));
    };


    const handleRemoveFile = (fileToRemove) => {
      this.setState((prevState) => ({
        droppedFiles: prevState.droppedFiles.filter((file) => file !== fileToRemove),
      }));
      document.getElementById('images').value = '';
    };

    const handleLinkChange = (value) => {
      this.setState({ fileLink: value.trim() });
    };

    const handleDescriptionChange = (value) => {
      this.setState({ issueDescription: value });
    }

    const disableUpload = droppedFiles.length >= maxFiles;
    return (
      <div>
        <p className="modal-text">{t('attachments_modal_text')}</p>
        <label
          htmlFor="images"
          className={`drop-container ${disableUpload ? 'disabled' : ''}  ${isDragOver ? 'drag-over' : ''}`}
          id="dropcontainer"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <span className="drop-title">Drop files here or</span>
          <input
            type="file"
            id="images"
            name="images"
            onChange={handleFileInputChange}
            accept="image/*, video/*"
            required
            multiple
            disabled={disableUpload}
            style={{display: "none"}}
          />
          <label htmlFor="images" className={`${disableUpload ? 'file-btn-disabled' : 'file-btn'}`}>Choose files</label>
        </label>
        <p className="note">{t('attachments_max_files_note')}</p>
        <p className="note">{t('attachments_link_note')}</p>
        <div className="row">
          <Input
            name="fileLink"
            placeholder={t('paste_link', 'Link')}
            defaultValue={this.state.fileLink}
            type="text"
            onChange={(e) => handleLinkChange(e.target.value)}
          />
        </div>
        <div className="row">
          <p className="modal-text">{t('issue_description')}</p>
          <Input
            name="description"
            placeholder={t('description', 'Description')}
            defaultValue={this.state.description}
            type="text"
            onChange={(e) => handleDescriptionChange(e.target.value)}
          />
        </div>
        {droppedFiles.length > 0 && <p className="modal-text">{t('general_uploaded_files', 'Uploaded Files')}</p>}
        {droppedFiles.map(file =>
          (<div className="file-item" key={file.lastModified}>
            <span className="file-name"><img src={fileIcon} className="file-icon" /> {file.name}</span>
            <span className="remove-file" onClick={() => handleRemoveFile(file)}>x</span>
          </div>)
        )}
      </div>
    );
  };

  renderShippingDetailsStep = () => {
    const {
      disabled,
      t
    } = this.props;
    const { selectedIssueCategory } = this.state;
    return (
      selectedIssueCategory !== 'Product Question' ?
      (<>
        <p
          className="modal-text">{t('shipping_address_note_modal_text')}</p>
        <p className="modal-text">{t('fill_shipping_info_modal_text')}</p>
        <div className="shipping-address-wrapper">
          <div className="input-row">
            <div className="row">
              <div className="col-1-2">
                <Input
                  name="firstName"
                  placeholder={t('first_name')}
                  value={this.state.shippingAddress.first_name}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('first_name', e.target.value)}
                />
                {this.state.shippingAddressErrors.firstName && (
                  <p className="error-message">{this.state.shippingAddressErrors.firstName}</p>
                )}
              </div>
              <div className="col-1-2">
                <Input
                  name="lastName"
                  placeholder={t('last_name')}
                  defaultValue={this.state.shippingAddress.last_name}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('last_name', e.target.value)}
                />
                {this.state.shippingAddressErrors.lastName && (
                  <p className="error-message">{this.state.shippingAddressErrors.lastName}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="phone"
                  placeholder={t('global_phone')}
                  defaultValue={this.state.shippingAddress.phone}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('phone', e.target.value)}
                />
                {this.state.shippingAddressErrors.phone && (
                  <p className="error-message">{this.state.shippingAddressErrors.phone}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="address1"
                  placeholder={t('global_address')}
                  defaultValue={this.state.shippingAddress.address1}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('address1', e.target.value)}
                />
                {this.state.shippingAddressErrors.address1 && (
                  <p className="error-message">{this.state.shippingAddressErrors.address1}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="address2"
                  placeholder={t('address_form_apartment')}
                  defaultValue={this.state.shippingAddress.address2}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('address2', e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <Input
                  name="city"
                  placeholder={t('address_form_city')}
                  defaultValue={this.state.shippingAddress.city}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('city', e.target.value)}
                />
                {this.state.shippingAddressErrors.city && (
                  <p className="error-message">{this.state.shippingAddressErrors.city}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-1-3" style={{margin: '10px 2px'}}>{this.renderCountriesOptions()}</div>
              <div className="col-1-3" style={{margin: '10px 2px'}}>{this.renderProvincesOptions()}</div>
              <div className="col-1-3">
                <Input
                  name="zip"
                  smallPlaceholder={false}
                  placeholder={t('address_form_zip')}
                  defaultValue={this.state.shippingAddress.zip}
                  type="text"
                  disabled={disabled}
                  onChange={(e) => this.handleShippingAddressChange('zip', e.target.value)}
                />
                {this.state.shippingAddressErrors.zip && (
                  <p className="error-message">{this.state.shippingAddressErrors.zip}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
      ) : (
        <div className="row">
          <div className="col-1">
            <p className="modal-text">{t('product_support_cc_response')}</p>
          </div>
        </div>
        )
    );
  };

  handleShippingAddressChange = (field, value) => {
    this.setState((prevState) => ({
      shippingAddress: {
        ...prevState.shippingAddress,
        [field]: value,
      },
    }));
  };

  renderCountriesOptions() {
    let { countries } = this.state;
    const countriesToShow = ['United States'];
    countries = countries.filter(country => countriesToShow.includes(country.name))

    const defaultValue = countries.findIndex(country => country.name === this.state.shippingAddress.country);
    const { disabled, t } = this.props;
    return (
      <Select
        placeholder={t('general_country')}
        list={countries}
        onChange={this.onSelectCountry}
        labelKey={'name'}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    );
  };

  getProvinceList() {
    let { countries, provinces } = this.state;
    const { isLoadingGetProvinces } = this.props;

    const selectedCountry = countries.find(country => country.name === this.state.shippingAddress.country || country.code === this.state.shippingAddress.country_code);
    if (!isLoadingGetProvinces && selectedCountry && provinces[selectedCountry._id] && provinces[selectedCountry._id].length) {
      return provinces[selectedCountry._id];

    }
    return [
      {
        name: 'No provinces',
      },
    ];
  };

  getProvinceDefaultValue(list) {
    const defaultValue = list.findIndex(item => item.name === this.state.shippingAddress.province);
    if (defaultValue !== -1) {
      return defaultValue;
    }
    return 0;
  };

  renderProvincesOptions() {
    const countriesToShowState = ['United States']
    if (!countriesToShowState.includes(this.state.shippingAddress.country)) {
      return null;
    }
    const list = this.getProvinceList();
    const defaultValue = this.getProvinceDefaultValue(list);
    const { disabled, t } = this.props;
    return (
      <Select
        list={list}
        onChange={this.onSelectProvinceChange}
        labelKey={'name'}
        placeholder={t('general_state')}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    );
  };

  onSelectCountry = (e, i, selectedCountry) => {
    if (selectedCountry.name !== this.state.shippingAddress.country) {
      this.setState((prevState) => ({
        shippingAddress: {
          ...prevState.shippingAddress,
          country: selectedCountry.name,
        },
      }));

      this.props.apiGetProvinces(selectedCountry._id);
    }
  };

  onSelectProvinceChange = (e, i) => {
    const { countries } = this.state;
    const selectedCountry = countries.find(country => country.name === this.state.shippingAddress.country);
    const selectedProvince = this.state.provinces[selectedCountry._id][i];
    if (selectedProvince.name !== this.state.shippingAddress.province) {
      this.setState((prevState) => ({
        shippingAddress: {
          ...prevState.shippingAddress,
          province: selectedProvince.name,
          province_code: selectedProvince.code,
        },
      }));
    }
  };

  handleCancelBtn = () => {
    const { activeStep } = this.state;

    if (activeStep === 0) {
      this.props.onCloseModal();
    }

    if (activeStep === 1) {
      this.setState({
        selectedSpecificIssue: null,
        selectedIssueCategory: null,
        issuesByCategory: null,
        selectSpecificIssueError: null,
      })
    } else if (activeStep === 2) {
      this.setState({
        droppedFiles: [],
        initProductSupportRequestError: false,
        fileLink: null,
      })
    }

    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));

  };

  renderContent() {
    const { t } = this.props;
    const { loading, activeStep, faqLink, droppedFiles, steps, showSuccessMessage, fileLink, initProductSupportRequestError, issueDescription, selectedSpecificIssue, selectedIssueCategory } = this.state;
    const stepHeadings = this.stepConfigs.map(stepConfig => stepConfig.heading);

    if (faqLink) {
      return this.renderFaqStep(faqLink);
    }

    if (showSuccessMessage) {
      return this.renderSuccessMessage();
    }
    const isUploadFilesStep = activeStep === 1;
    const isFirstStep = activeStep === 0;
    const isProductSupportSelected = selectedIssueCategory === 'Product Question';
    const hasCategoryIssues = selectedIssueCategory && this.issues[selectedIssueCategory].length > 0;
    const disabledFirstStep = isFirstStep && (!selectedIssueCategory || (hasCategoryIssues && !selectedSpecificIssue));
    const disabledUploadStep = isUploadFilesStep && ((!isProductSupportSelected && droppedFiles.length === 0 && !fileLink) || !issueDescription);
    const disabled = disabledFirstStep || disabledUploadStep;
    return (
      <>
        <div className="header-wrapper">
          <h3>{t('product_support_heading', 'Product Support')}</h3>
          <span className="close-modal" onClick={() => this.props.onCloseModal()}>X</span>
        </div>
        <Stepper activeStep={activeStep} steps={steps} headings={stepHeadings}/>
        {this.stepConfigs[activeStep].render()}
        {initProductSupportRequestError && (
          <div className="row">
            <div className="col-1">
              <div className="error-message-wrapper">
                <Message type="error">{t('generic_error', 'An unexpected error occurred. Please try again.')}</Message>
              </div>
            </div>
          </div>
        )}
        <div className="button-wrap">
          <div className="col-1-6">
            <Button color={'green-inverted'} onClick={this.handleCancelBtn}>
              {activeStep !== 0 ? t('back_button') : t('cancel_button')}
            </Button>
          </div>
          <div className="col-1-6">
            <Button
              onClick={this.handleSubmit}
              color={'green'}
              disabled={disabled}
            >
              {activeStep !== this.lastStep ? t('continue_button') : loading ? t('loading') : t('initiate_request_button')}
            </Button>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="ProductSupportModal">
        <div className="modal-center">{this.renderContent()}</div>
      </div>
    );
  }
}

ProductSupportModal.propTypes = {
  device: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  buyPackage: apiRenterSelectors.getBuyPackage(state),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      apiGetBuyPackage: apiGetBuyPackageRequestAction,
    },
    dispatch,
  );
  return { actions };
};

export default provideContext(connect(mapStateToProps, mapDispatchToProps)(ProductSupportModal));
