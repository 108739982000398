import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { provideContext } from '../../../../context/context.jsx';
import Input from '../../input/input.jsx';
import Button from '../../button/button.jsx';

import './addressForm.scss';
import Message from '../../presentation/message/message.jsx';
import addressType from '../../../../enums/addressType.js';
import Select from '../../select/select.jsx';
import availableCountries from '../../../../enums/availableCountries.js';

class AddressForm extends Component {
  state = {
    address1: this.props.address.address1,
    address2: this.props.address.address2,
    city: this.props.address.city,
    country: this.props.address.country,
    province: this.props.address.province,
    firstName: this.props.address.firstName || this.props.address.first_name,
    lastName: this.props.address.lastName || this.props.address.last_name,
    phone: this.props.address.phone,
    zip: this.props.address.zip,
    provinces: this.props.provinces,
    countries: this.props.countries,
    requiredError: {},
  };

  componentDidMount() {
    const country = this.state.countries.find(c => c.name === this.state.country);
    const countries = this.filterCountries();

    if (countries[0]) {
      this.setState({ countries, countryCode: country.code });
      this.props.apiGetProvinces(country._id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.shippingAddressError !== state.shippingAddressError) {
      return {
        shippingAddressError: props.shippingAddressError,
      };
    }
    if (props.shippingAddressSuccess !== state.shippingAddressSuccess) {
      return {
        shippingAddressSuccess: props.shippingAddressSuccess,
      };
    }
    if (props.billingAddressError !== state.billingAddressError) {
      return {
        billingAddressError: props.billingAddressError,
      };
    }
    if (props.billingAddressSuccess !== state.billingAddressSuccess) {
      return {
        billingAddressSuccess: props.billingAddressSuccess,
      };
    }
    if (props.provinces !== state.provinces) {
      const selectedCountry = props.countries.find(country => country.name === state.country);
      const provinceElement = props.provinces[selectedCountry._id].find(p => p.name === state.province);

      const provinceState = {
        provinces: props.provinces,
      };

      if (provinceElement) {
        provinceState.province = provinceElement.code;
      }

      return provinceState;
    }
    if (props.address !== state.address) {
      return {
        address: props.address,
      };
    }

    return null;
  }

  filterCountries() {
    return this.props.type === addressType.SHIPPING
      ? this.state.countries.filter(country => country.name === this.state.country)
      : this.state.countries;
  }

  renderError(addressError) {
    return (
      <div className="AddressForm__margin_top_minus" role="alert" aria-live="polite">
        <Message type="error">{addressError.message}</Message>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className="AddressForm__margin_top_minus" role="alert" aria-live="polite">
        <Message type="success">{this.props.t('address_form_change_success', 'Changes Saved Successfully')}</Message>
      </div>
    );
  }

  onChangeForm = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSelectCountry = (e, i) => {
    const selectedCountry = this.state.countries[i];
    if (selectedCountry.name !== this.state.country) {
      this.setState({
        country: selectedCountry.name,
        countryCode: selectedCountry.code,
      });
      this.props.apiGetProvinces(selectedCountry._id);
    }
  };

  onSelectProvinceChange = (e, i) => {
    const { countries } = this.state;
    const selectedCountry = countries.find(country => country.name === this.state.country);
    const selectedProvince = this.state.provinces[selectedCountry._id][i];
    if (selectedProvince.name !== this.state.province) {
      this.setState({
        province: selectedProvince.code,
      });
    }
  };

  saveAddress = async (e, fromModal) => {
    const { t, includeNameAndPhone } = this.props;
    e.preventDefault();

    let nameAndPhone = {
      firstName: this.props.address.firstName || this.props.address.first_name,
      lastName: this.props.address.lastName || this.props.address.last_name,
    };

    if (includeNameAndPhone) {
      nameAndPhone = {
        firstName: this.state.firstName || '',
        lastName: this.state.lastName || '',
        phone: this.state.phone || '',
      };
    }

    const address = {
      address1: this.state.address1 || '',
      address2: this.state.address2 || '',
      city: this.state.city || '',
      countryCode: this.state.countryCode || '',
      country: this.state.country || '',
      province: this.state.province || '',
      zip: this.state.zip || '',
      company: this.props.address.company || '',
      ...nameAndPhone,
    };

    await this.setState({
      requiredError: {},
    });

    if (
      address.address1 === '' ||
      address.city === '' ||
      address.zip === '' ||
      address.firstName === '' ||
      address.lastName === ''
    ) {
      this.setState({
        requiredError: {
          message: t('all_fields_must_be_filled', 'All fields must be filled'),
        },
      });
      return null;
    }

    return this.props.apiSaveAddress(address);
  };

  renderCountriesOptions() {
    const { countries } = this.state;
    const defaultValue = countries.findIndex(country => country.name === this.state.country);
    const { disabled, t } = this.props;
    return (
      <Select
        placeholder={t('country', 'Country')}
        list={countries}
        onChange={this.onSelectCountry}
        labelKey={'name'}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    );
  }

  getProvinceList() {
    const { countries, provinces } = this.state;
    const { isLoadingGetProvinces } = this.props;
    const selectedCountry = countries.find(country => country.name === this.state.country || country.code === this.state.country);
    if (!isLoadingGetProvinces && selectedCountry && provinces[selectedCountry._id] && provinces[selectedCountry._id].length) {
      return provinces[selectedCountry._id];
    }
    return [
      {
        name: 'No provinces',
      },
    ];
  }

  getProvinceDefaultValue(list) {
    const defaultValue = list.findIndex(item => item.code === this.state.province);
    if (defaultValue !== -1) {
      return defaultValue;
    }
    return 0;
  }

  renderProvincesOptions() {
    const countriesToShowState = ['United States', 'Australia']
    if (!countriesToShowState.includes(this.state.country)) {
      return null;
    }
    const list = this.getProvinceList();
    const defaultValue = this.getProvinceDefaultValue(list);
    const { disabled, t } = this.props;
    return (
      <Select
        list={list}
        onChange={this.onSelectProvinceChange}
        labelKey={'name'}
        placeholder={t('state', 'state')}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    );
  }

  renderRequiredError() {
    const { requiredError } = this.state;
    return <Message type="error">{requiredError.message}</Message>;
  }

  render() {
    const { t, disabled, includeNameAndPhone } = this.props;
    const {
      shippingAddressError,
      billingAddressError,
      shippingAddressSuccess,
      billingAddressSuccess,
      requiredError,
    } = this.state;
    return (
      <form className="AddressForm" onChange={this.onChangeForm}>
        <div className="row">
          <div className="col-1">
            {!isEmpty(requiredError) && this.renderRequiredError()}
            {!isEmpty(shippingAddressError) && isEmpty(requiredError) && this.renderError(shippingAddressError.error)}
            {!isEmpty(billingAddressError) && isEmpty(requiredError) && this.renderError(billingAddressError.error)}
            {(!isEmpty(shippingAddressSuccess) || !isEmpty(billingAddressSuccess)) &&
              isEmpty(requiredError) &&
              this.renderSuccess()}
          </div>
        </div>
        <div className="input-row">
          {includeNameAndPhone && (
            <React.Fragment>
              <div className="row">
                <div className="col-1-2">
                  <Input
                    name="firstName"
                    placeholder={t('first_name', 'First Name')}
                    defaultValue={this.state.firstName}
                    type="text"
                    disabled={disabled}
                  />
                </div>
                <div className="col-1-2">
                  <Input
                    name="lastName"
                    placeholder={t('last_name', 'Last Name')}
                    defaultValue={this.state.lastName}
                    type="text"
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <Input
                    name="phone"
                    placeholder={t('global_phone', 'Phone')}
                    defaultValue={this.state.phone}
                    type="text"
                    disabled={disabled}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="row">
            <div className="col-1">
              <Input
                name="address1"
                placeholder={t('global_address')}
                defaultValue={this.state.address1}
                type="text"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <Input
                name="address2"
                placeholder={t('address_form_apartment', 'Apartment, suite, etc (optional)')}
                defaultValue={this.state.address2}
                type="text"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <Input
                name="city"
                placeholder={t('address_form_city', 'City')}
                defaultValue={this.state.city}
                type="text"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-1-3">{this.renderCountriesOptions()}</div>
            <div className="col-1-3">{this.renderProvincesOptions()}</div>
            <div className="col-1-3">
              <Input
                name="zip"
                placeholder={t('address_form_zip', 'Zip')}
                defaultValue={this.state.zip}
                type="text"
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <div className="button-row">
          <div className="row">
            {this.props.submitType === 'OK' && (
              <div className="col-1">
                <Button color={'green'} disabled={disabled} onClick={e => this.saveAddress(e, false)}>
                  {this.props.submitText}
                </Button>
              </div>
            )}
            {this.props.submitType === 'OK_CANCEL' && (
              <div className="col-1 button-wrap">
                <Button onClick={this.props.cancelChanges} color={'green-inverted'}>
                  {this.props.cancelText}
                </Button>
                <Button color={'green'} disabled={disabled} onClick={e => this.saveAddress(e, false)}>
                  {this.props.submitText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
    );
  }
}

AddressForm.defaultProps = {
  isLoadingGetProvinces: true,
  shippingAddressError: {},
  billingAddressSuccess: {},
  submitType: 'OK',
  submitText: 'Save changes',
  cancelText: 'Cancel',
};

AddressForm.propTypes = {
  t: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  apiSaveAddress: PropTypes.func.isRequired,
  cancelChanges: PropTypes.func,
  apiGetProvinces: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  isLoadingGetProvinces: PropTypes.bool,
  disabled: PropTypes.bool,
  submitType: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default provideContext(AddressForm);
