import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import { provideContext } from '../../../../context/context.jsx';
import ShippingTimeline from '../../../../components/views/presentation/shippingTimeline/shippingTimeline.jsx';
import OverviewPanel from '../../../../components/views/presentation/overviewPanel/overviewPanel.jsx';

import snooBoxInactiveIcon from '../../../../assets/icons/snoo-box-inactive.svg';
import subscriptionStopedIcon from '../../../../assets/icons/subscription-stoped-active.svg';

class OrderAccountOverview extends PureComponent {
  getDataForOverviewPanel(active, rental) {
    const { t } = this.props;
    return {
      title: t('cancel_account_overview_order_cancelled_success', 'Your order has been canceled'),
      iconName: subscriptionStopedIcon,
      showButton: false,
      showShippingDate: false,
      editShippingDate: false,
      itemData: [
        {
          title: t('order_id', 'Order ID'),
          value: t('order_account_overview_order_id', { shopifyOrderName: rental.shopifyOrderName})
        },
      ],
    };
  }

  renderOverview(rental, userData) {
    const { t, context } = this.props;
    const headerData = [
      {
        className: 'order active',
        title: t('order_account_overview_order_id', { shopifyOrderName: rental.shopifyOrderName})
      },
      {
        className: 'snoo',
        title: t('order_account_overview_snoo_id_notice', 'SNOO ID WILL BE DISPLAYED HERE'),
      },
    ];

    const orderData = [
      {
        active: false,
        iconActive: snooBoxInactiveIcon,
        iconInactive: snooBoxInactiveIcon,
        title: t('order_placed', 'Order placed'),
        date: <Moment format={context.apiConfig.date.format}>{rental.createdAt}</Moment>,
      },
      {
        active: true,
        iconActive: subscriptionStopedIcon,
        iconInactive: subscriptionStopedIcon,
        title: t('cancel_account_overview_order_cancelled', 'Order canceled'),
        date: <Moment format={context.apiConfig.date.format}>{rental.canceledAtDate}</Moment>,
      },
    ];
    const overviewData = this.getDataForOverviewPanel(rental.active, rental);
    return (
      <React.Fragment key={rental.id}>
        <div className="row">
          <div className="col-1">
            <ShippingTimeline headerData={headerData} bodyData={orderData} rentalPeriodStarted={false} />
          </div>
          <div className="col-1">
            <OverviewPanel userData={userData} {...overviewData} order={rental} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { t, userData, rental } = this.props;
    return this.renderOverview(rental, userData);
  }
}

OrderAccountOverview.defaultProps = {
  userData: {},
  rental: {},
};

OrderAccountOverview.propTypes = {
  t: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  rental: PropTypes.object.isRequired,
};

export default provideContext(OrderAccountOverview);
