import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button.jsx';
import { provideContext } from '../../../../context/context.jsx';

import AddressForm from '../../forms/addressForm/addressForm.jsx';
import Message from '../../presentation/message/message.jsx';
import shippingAddressIcon from '../../../../assets/icons/shipping-address.svg';
import addressType from '../../../../enums/addressType.js';

import './editAddressModal.scss';

class EditAddressModal extends Component {
  renderError(error) {
    return <Message type="error">{error.error.message}</Message>;
  }

  render() {
    const {
      t,
      title,
      onCloseModal,
      onEditAddress,
      isLoadingEditAddress,
      errors,
      address,
      addressError,
      addressSuccess,
      countries,
      provinces,
      isLoadingGetProvinces,
      apiGetProvinces,
    } = this.props;

    const disabled = !!isLoadingEditAddress;

    return (
      <div className="EditAddressModal" onClick={onCloseModal}>
        <div className="modal-center" onClick={e => e.stopPropagation()}>
          <div className="inner-modal-center">
            <div className="icon-wrap">
              <span className="icon">
                <img src={shippingAddressIcon} alt="" />
              </span>
            </div>
            <h3>{title}</h3>
            <div className="modal-text">
              <AddressForm
                includeNameAndPhone={true}
                title={title}
                disabled={disabled}
                submitType="OK_CANCEL"
                submitText={t('button_text_save','Save')}
                cancelText={t('button_text_cancel', 'Cancel')}
                address={{ ...address }}
                shippingAddressError={addressError}
                shippingAddressSuccess={addressSuccess}
                countries={countries}
                provinces={provinces}
                type={addressType.SHIPPING}
                isLoadingGetProvinces={isLoadingGetProvinces}
                apiGetProvinces={apiGetProvinces}
                apiSaveAddress={onEditAddress}
                cancelChanges={onCloseModal}
              />
              <div className="clear">&nbsp;</div>
            </div>
            {errors && <div className="modal-errors">{this.renderError(errors)}</div>}
          </div>
        </div>
      </div>
    );
  }
}

EditAddressModal.defaultProps = {
  title: null,
};

EditAddressModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  isLoadingEditAddress: PropTypes.bool,
  errors: PropTypes.object,
  address: PropTypes.object.isRequired,
};

export default provideContext(EditAddressModal);
